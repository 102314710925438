import service from '@/helpers/serviceGPAO'
// import { constantesId } from "@/helpers/constantes"

export function ajouterMenuPlan(structure) {
  preparerDonneeStructureMenu(structure)
  return service.post('menus/plan/ajouter', structure)
}
export function modifierMenuPlan(structure) {
  preparerDonneeStructureMenu(structure)
  return service.patch('menus/plan/modifier', structure)
}
export function obtenirMenuPlan(id, asBlob) {
  let url = 'menus/structure/'
  if ((typeof (id) == 'string') && id.startsWith('p')) {
    url += id.substr(1)
  } else {
    url += id;
  }
  if (asBlob) {
    return service.get(url, { responseType: 'blob' })
  }
  return service.get(url)
}

export function obtenirMenusJour(etablissementId) {
  return service.get("menus/menusdujour/" + etablissementId)
}
export function desactiverMenuPlan(id) {
  let url='menus/plan/desactiver/'
  if (typeof(id) == 'string' && id.startsWith('p')) {
    url += id.substr(1)
  } else {
    url += id
  }
  return service.patch(url)
}
export function supprimerMenuPlan(id) {
  let url='menus/plan/supprimer/'
  if ((typeof (id) == 'string') && id.startsWith('p')) {
    url+= id.substr(1)
  }
  else {
    url += id;
  }
  return service.delete(url )
}

export function chargerTousLesPlans(etablissementId) {
  return service.get("menus/plan/liste/" + etablissementId)
}
export function chargerTousLesMenus(etablissementId) {
  return service.get("menus/liste/" + etablissementId)
}
export function chargerMenuDateDebut(payload) {
  return service.get("menus/liste/" + payload.etablissementId + "/" + payload.apiDate)
}

export function chargerMenuDateDebutDateFin(payload) {
  return service.get("menus/liste/" + payload.etablissementId + "/" + payload.apiDateDebut + "/" + payload.apiDateFin)
}

export async function chargerMenuEtStructurePourDate(payload) {
  const url = "menus/liste/avecStructures/" + payload.etablissementId + "/" + payload.apiDate + "/" + payload.actifSeulement
  return service.get(url).then(response => {
    response.data.liste.forEach(x => {
      x.estStructure = !x.structId
      x.structureId= (x.estStructure)? x.id : x.structId
    })
    return response
  })
}

export function chargerStructures(etablissementId) {
  return service.get("menus/liste/structures/" + etablissementId)
}

export function chargerMenuPlansAvecRecetteDeuxDates(payload) {
  return service.get("menus/structure/liste/" + payload.etablissementId + "/" + payload.apiDateDebut + "/" + payload.apiDateFin)
}
export function chargerMenuBesoins(payload) {
  let url = "menus/besoins/" + payload.etablissementId + "/" + payload.menuPlanIds + "/" + payload.apiDateDebut + "/" + payload.apiDateFin
  if (payload.serviceIds) {
    url += "/" + payload.serviceIds
  }
  return service.get(url)
}
export function creerMenu(data) {
  return service.post('menus/creer', data)
}
export function obtenirMenu(id) {
  return service.get('menus/' + id)
}
export function supprimerMenu(id) {
  return service.delete('menus/supprimer/' + id)
}
export function modifierMenu(data) {
  return service.patch('menus/modifier', data)
}
export function dupliquerMenu(etablissementId, parametres) {
  return service.post('menus/dupliquer/' + etablissementId, parametres)
}
export function validerMenu(menuId) {
  return service.patch('menus/valider/' + menuId)
}
export function devaliderMenu(menuId) {
  return service.patch('menus/devalider/' + menuId)
}

// pour : format AAAAMMJJ
export function appliquerEffectifPortage(etabId, tabPour) {
  return service.patch("menus/appliquerEffectif/" + etabId ,tabPour)
}

function preparerDonneeStructureMenu(structure) {
  structure.services.forEach(service => {
    if (!service.effectif) {
      service.effectif = 0
    }
    if (service.planComposantes) {
      service.planComposantes.forEach(composante => {
        if (!composante.effectif) {
          composante.effectif = 0
        }
        if (composante && composante.id < 0) {
          composante.id = 0;
        }
        if (structure.gestionParPlan) {
          composante.regimeId = structure.regimeId
          composante.textureId = structure.textureId
        }
      })
    }
  })
  if (!structure.gestionParPlan) {
    structure.regimeId = undefined
    structure.textureId = undefined
  }
}