<template>
  <div class="home">
    <div>
      <template v-if="nouveauDesign">
        <div v-if="inventaireOuvert" class="alert alert-warning mb-2" role="alert">
          <strong>Inventaire en cours.</strong> Les fonctionnalités suivantes sont désactivées afin de ne pas fausser le stock.<br />
          Mouvements de stock, réception de commande, sortie de stock d'une production.
        </div>
        <div class="flex-container">
          <div class="widget" style="width:300px;"><WidgetMenus /></div>
          <div class="widget" style="width:300px;"><WidgetLivraisons /></div>
          <div class="widget" style="width:300px;"><WidgetDLC /></div>
          <div class="widget" style="width:300px;"><WidgetMemo /></div>
          <div class="widget" style="width:600px;"><WidgetInformations /></div>
          <div class="widget" style="width:300px;"><WidgetActualites /></div>
        </div>
      </template>

      <div v-else class="container-fluid">
        <div class="row">
          <div class="col">
            <div v-for="(info, i) in informations.messages" :key="i">
              <div class="card">
                <div class="card-header bg-gradient-accueil text-white" :style="info.couleur ? ('background-image: linear-gradient(to right, ' + info.couleur + ', ' + info.couleur + ');') : ''">
                  <div class="card-title">
                    {{info.titre}}
                  </div>
                </div>
                <div class="card-content">
                  <div class="card-body">
                    <span v-html="info.message"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import { connecteData } from "@/mixins/connecteData"
/* import WidgetCouts from "@/components/Accueil/WidgetCouts"*/
import WidgetDLC from "@/components/Accueil/WidgetDLC"
import WidgetLivraisons from "@/components/Accueil/WidgetLivraisons"
import WidgetActualites from "@/components/Accueil/WidgetActualites"
import WidgetInformations from "@/components/Accueil/WidgetInformations"
import WidgetMenus from "@/components/Accueil/WidgetMenus"
import WidgetMemo from "@/components/Accueil/WidgetMemo"
import { verifierInventaireOuvert } from "@/helpers/verifications"
export default {
  name: "home",
  mixins: [connecteData],
  components: { /*WidgetCouts,*/ WidgetDLC, WidgetLivraisons, WidgetActualites, WidgetInformations, WidgetMenus, WidgetMemo },
  data() {
    return {
      informations: [],
      nouveauDesign: false,
      inventaireOuvert: true
    }
  },
  mounted() {
    this.nouveauDesign = process.env.VUE_APP_NOUVEAU_DESIGN_ACCUEIL == "true"
    if (!this.nouveauDesign) {
      this.chargerInformations()//ancienne page d'accueil
    }
    this.verifierInventaire()
    this.cacherAttente()
  },
  methods: {
    ...mapActions("uxHelpers", ["cacherAttente"]),
    chargerInformations() {
      this.informations = this.$store.state.definitions.informationsAccueil
    },
    async verifierInventaire() {
      this.inventaireOuvert = await verifierInventaireOuvert(this.etabCourantId)
    }
  }
}
</script>

<style scoped>
  .flex-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-content: stretch;
  }
  .widget {
    flex: 1 1 auto;
    margin-left: 5px;
    margin-right: 5px;
  }
</style>
