<template>
  <div>
    <b-button @click="ouvrirDuplicationMenu()" class="btn btn-info" title="Dupliquer menus" :disabled="Boolean(disabled)">
      <i class="fas fa-clone" :class="{ 'fa-spin ' : duplicationEnCours }" style="margin-right:5px"></i>Dupliquer</b-button>
    <b-modal v-model="afficherDuplication" hide-footer no-close-on-backdrop header-bg-variant="gradient-bleu" header-text-variant="light">
      <template slot="modal-title">
        <h3>Duplication d'un menu</h3>
      </template>
      <template slot="default">
        <div v-if="afficherDuplication" class="container-fluid push-bottom-1rem" :key="key">
          <div class="row">
            <h4 class="col-12"><b>Menu d'origine</b></h4>
            <div class="col-12">
              <b-form-select :value="planMenuOrigineId" @change="planMenuOrigineId = $event;checkValide()" value-field="id" text-field="nom" :options="tousLesPlansSource" />
              <div class="error" v-if="!$v.planMenuOrigineId.required">Ce champ est requis</div>
            </div>
            <span class="col-1" style="margin-top: 8px;"><b>Du</b></span>
            <div class="col-11" style="margin-top: 4px;">
              <ManualDatepicker class="date-picker-semaine"
                :value="formatDateAvecTirets(lundiDeLaSemaineOrigine)"
                :max="formatDateAvecTirets(getDimancheDeLaSemaine(dimancheDeLaSemaineOrigine))" :disabled-weekdays="[0, 2, 3, 4, 5, 6]"
                @saved="changerDebutOrigine" />
              <div class="error" v-if="!$v.lundiDeLaSemaineOrigine.required">Ce champ est requis</div>
            </div>
            <span class="col-1" style="margin-top: 14px;"><b>Au</b></span>
            <div class="col-11" style="margin-top: 10px;">
              <ManualDatepicker class="date-picker-semaine"
                :value="formatDateAvecTirets(dimancheDeLaSemaineOrigine)"
                :min="formatDateAvecTirets(lundiDeLaSemaineOrigine || getDimancheDeLaSemaine(new Date()))" :disabled-weekdays="[1, 2, 3, 4, 5, 6]"
                @saved="changerFinOrigine" />
              <div class="error" v-if="!$v.dimancheDeLaSemaineOrigine.required">Ce champ est requis</div>
            </div>
            <h4 class="col-12 push-top-1rem"><b>Destination</b></h4>
            <div class="col-1" style="margin-top: 4px;"><b>Du</b></div>
            <div class="col-11">
              <ManualDatepicker class="date-picker-semaine"
                :value="formatDateAvecTirets(lundiDeLaSemaineDestination)" :disabled-weekdays="[0, 2, 3, 4, 5, 6]"
                @saved="changerDebutDestination" />
              <div class="error" v-if="!$v.lundiDeLaSemaineDestination.required">Ce champ est requis</div>
            </div>
            <div v-if="dimancheDeLaSemaineDestination" class="col-1" style="margin: 10px 0;"><b>Au</b></div>
            <div v-if="dimancheDeLaSemaineDestination" class="col-11" style="margin: 10px 0;"><b>{{dimancheDeLaSemaineDestination}}</b></div>
            <div v-if="multiEtablissements" class="col-1" style="margin: 4px 0 12px 0;"><b>Vers</b></div>
            <div v-if="multiEtablissements" class="col-11">
              <b-button type="submit" class="btn btn-secondary" title="Ajouter une destination" style="transform: scale(.9);" :disabled="!estValide" @click="ajouterDestination()"><i class="fas fa-plus"></i></b-button>
            </div>
            <div v-if="!multiEtablissements" class="col-12">
              <MultiSelect :value="destinations[0].planMenuDestinationIds" :items="destinations[0].options || []" itemText="nom" itemValue="id" placeholder="Structures de menus..." emptyText="Toutes les structures ont été ajoutées" @change="changerPlansDestination(destinations[0], $event)" />
              <div class="error" v-if="!destinations[0].plansValides">Ce champ est requis</div>
              <div v-if="menuSurPlanAlimentaire" class="row mt-2">
                <div class="col-12">
                  <v-select placeholder="Plan alimentaire de destination..." v-model="destinations[0].planAlimentaireId" :options="optionsPlansAlimentaires" label='nom' :reduce="pa => pa.id"></v-select>
                </div>
                <div v-if="destinations[0].planAlimentaireId" class="col-12 text-warning m-1">
                  <a class="fas fa-exclamation-triangle"></a> Les menus seront dupliqués sur la période complète du plan alimentaire à partir de la date de destination
                </div>
              </div>
            </div>
            <div v-else class="col-12">
              <div v-for="(destination, iDest) in destinations" :key="iDest" class="row" style="border: 1px solid black;padding: 5px;margin-top: 5px;">
                <div v-if="destinations.length > 1" class="col-12" style="padding-right: 5px;"><b class="float-right" style="cursor: pointer;" title="Supprimer cette destination" @click="supprimerDestination(iDest)">X</b></div>
                <div class="col-1" style="padding-left: 0;margin-top: 8px;"><b>Etab.</b></div>
                <div class="col-10" style="margin-left: 10px;height: 41px;">
                  <v-select style="width: 100%;position: absolute;" :clearable="false" placeholder="Etablissement de destination..." v-model="destination.etablissementDestinationId" :options="optionsEtablissement" @input="changerEtablissementDestination(destination)"></v-select>
                </div>
                <div class="col-12 error" v-if="!destination.etabValide">Ce champ est requis</div>
                <div class="col-1" style="padding-left: 0;margin-top: 8px;"><b>Menu(s)</b></div>
                <MultiSelect class="col-10" style="margin-left: 10px;" :value="destination.planMenuDestinationIds" :items="destination.options || []" itemText="nom" itemValue="id" placeholder="Structures de menus..." emptyText="Toutes les structures ont été ajoutées" @change="changerPlansDestination(destination, $event)" />
                <div class="col-12 error" v-if="!destination.plansValides">Ce champ est requis</div>
              </div>
            </div>
            <div class="col-12 push-top-1rem">
              <div class="row">
                <div class="col col-2">
                  <ToggleButton name="ecraserMenuDestination" id="ecraserMenuDestination" v-model="ecraserMenuDestination" :key="ecraserMenuDestination" @input="hasMods = true" :height="21" :width="60" style="margin-bottom:0;margin-left:10px;" 
                            :labels="{checked: 'Oui', unchecked: 'Non'}" :color="{checked: '#ea0000de', unchecked: '#cccccc'}" v-tooltip="{ content: 'Attention, action irréversible qui supprimera tout menu présent sur la(les) destinations.'}" />
                </div>
                <div class="col col-10">
                  <label for="ecraserMenuDestination"><i class="fas fa-exclamation-triangle"></i> &Eacute;craser le(s) menu(s) de destination présent(s).</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid flex-end" :key="'1' + key">
          <b-button type="submit" class="btn btn-primary" alt="Dupliquer" :disabled="duplicationEnCours || !estValide" @click="dupliquer()">Valider</b-button>
          <b-button style="margin-left: 10px;" type="submit" class="btn btn-danger" alt="Annuler" @click="fermerDuplicationMenu()">Annuler</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { connecteData } from "@/mixins/connecteData"
import { dupliquerMenu, chargerMenuDateDebut, chargerTousLesPlans } from '@/api/gpao/menus'
import { getLundiDeLaSemaine, getDimancheDeLaSemaine } from '@/mixins/index'
import { required } from "vuelidate/lib/validators"
import { showAlerts } from '@/mixins/alerts'
import { formatageDate } from '@/mixins/dateFormats'
import ManualDatepicker from '@/components/Inputs/ManualDatepicker'
import MultiSelect from '@/components/Inputs/MultiSelect'
import { mapGetters } from "vuex"

export default {
  props: ['menuPlanId', 'lundiDeLaSemaine', 'disabled', 'menuSurPlanAlimentaire'],
  mixins: [connecteData, getLundiDeLaSemaine, getDimancheDeLaSemaine, showAlerts, formatageDate],
  components: {
    ManualDatepicker,
    MultiSelect
  },
  data() {
    return {
      duplicationEnCours: false,
      estValide: false,
      tousLesPlansSource: [],
      tousLesPlansDestination: {},
      menusDateDebut: [],
      plansDeMenusAAfficher: [],
      afficherDuplication: false,
      planMenuOrigineId: this.menuPlanId,
      lundiDeLaSemaineOrigine: this.lundiDeLaSemaine,
      dimancheDeLaSemaineOrigine: this.getDimancheDeLaSemaine(this.lundiDeLaSemaine),
      optionsPlansAlimentaires: this.$store.state.definitions.plansAlimentaires,
      multiEtablissements: false,
      optionsEtablissement: [],
      destinations: [{
        planAlimentaireId: null,
        planMenuDestinationIds: null,
        etablissementDestinationId: null
      }],
      lundiDeLaSemaineDestination: this.lundiDeLaSemaine,
      key: 1,
      ecraserMenuDestination: false
    }
  },
  validations: {
    planMenuOrigineId: { required },
    lundiDeLaSemaineOrigine: { required },
    dimancheDeLaSemaineOrigine: { required },
    planMenuDestinationIds: { required },
    lundiDeLaSemaineDestination: { required }
  },
  computed: {
    ...mapGetters("user", ["utilisateurCourant"]),
    dimancheDeLaSemaineDestination() {
      if (!this.lundiDeLaSemaineOrigine || !this.dimancheDeLaSemaineOrigine || !this.lundiDeLaSemaineDestination)
        return null

      let ecartNbJours = (this.dimancheDeLaSemaineOrigine.getTime() - this.lundiDeLaSemaineOrigine.getTime()) / (1000 * 3600 * 24)

      let curr = new Date(this.lundiDeLaSemaineDestination.getTime());
      let first = curr.getDate()
      let last = first + ecartNbJours

      return this.formatHumanDate(new Date(curr.setDate(last)))
    }
  },
  methods: {
    init() {
      this.plansDeMenusAAfficher = []
      this.planMenuOrigineId = this.menuPlanId
      this.lundiDeLaSemaineOrigine = this.lundiDeLaSemaine
      this.lundiDeLaSemaineDestination = this.getLundiDeLaSemaineApresAujourdhui(this.lundiDeLaSemaine, true)
      this.optionsEtablissement = this.utilisateurCourant.etablissements.map(e => {
       return {
          value: e.eId,
          label: e.eRS
        }
      })
      this.multiEtablissements = this.optionsEtablissement.length > 1
      if (!this.multiEtablissements) {
        this.destinations[0].planAlimentaireId = null
        this.destinations[0].etablissementDestinationId = {value: this.etabCourantId}
        this.changerEtablissementDestination(this.destinations[0])
      }
      chargerTousLesPlans(this.etabCourantId)
        .then(function (response) {
          this.tousLesPlansSource = response.data.sort((a, b) => a.nom.toLowerCase() > b.nom.toLowerCase() ? 1 : -1)
          this.key += 1
          return
        }.bind(this))
        .catch((error) => {
          this.alerteErreur(error)
        })
      this.changerDebutOrigine(this.lundiDeLaSemaineOrigine)
      this.ecraserMenuDestination = false
    },
    checkValide() {
      this.estValide = this.planMenuOrigineId
        && this.getLundiDeLaSemaineApresAujourdhui(this.lundiDeLaSemaineOrigine, false)
        && this.destinations.length
        && !this.destinations.filter(d => !d.etabValide || !d.plansValides).length
      this.key += 1
    },
    ajouterDestination() {
      this.destinations.unshift({
        planMenuDestinationIds: null,
        etablissementDestinationId: null
      })
      this.checkValide()
    },
    supprimerDestination(index) {
      this.destinations.splice(index, 1)
      this.checkValide()
    },
    changerEtablissementDestination(destination) {
      destination.plansValides = false
      destination.planMenuDestinationIds = []
      if (!destination.etablissementDestinationId.value) {
        destination.etabValide = false
        return
      }
      destination.etabValide = true
      return chargerTousLesPlans(destination.etablissementDestinationId.value)
        .then(response => {
          destination.options = response.data.sort((a, b) => a.nom.toLowerCase() > b.nom.toLowerCase() ? 1 : -1)
          this.checkValide()
          return
        })
    },
    changerPlansDestination(destination, e) {
      destination.planMenuDestinationIds = e
      destination.plansValides = destination.planMenuDestinationIds.length > 0
      this.checkValide()
    },
    getLundiDeLaSemaineApresAujourdhui(date, forcerApresAujourdhui) {
      if (!date)
        return null
      let dateRetour = this.getLundiDeLaSemaine(date)
      if (new Date(dateRetour) < new Date() && forcerApresAujourdhui)
        return null
      return this.getLundiDeLaSemaine(date)
    },
    async changerDebutOrigine(date) {
      this.lundiDeLaSemaineOrigine = this.getLundiDeLaSemaine(date)
      this.key += 1
      let response = await chargerMenuDateDebut({ apiDate: this.formatDatePourApiSansSeparation(this.lundiDeLaSemaineOrigine), etablissementId: this.etabCourantId })
      this.menusDateDebut = response.data
      this.key += 1
    },
    changerFinOrigine(date) {
      this.dimancheDeLaSemaineOrigine = this.getDimancheDeLaSemaine(date)
      this.checkValide()
    },
    changerDebutDestination(date) {
      this.lundiDeLaSemaineDestination = this.getLundiDeLaSemaine(date)
      this.checkValide()
    },
    ouvrirDuplicationMenu() {
      this.init()
      this.afficherDuplication = true
    },
    fermerDuplicationMenu() {
      this.afficherDuplication = false
    },
    async dupliquer() {
      try {
        console.log("duplication")
        this.duplicationEnCours = true
        let params = []
        this.destinations.forEach(d => {
          params.push({
            structureMenuOrigineId: this.planMenuOrigineId,
            dateDebutOrigine: this.formatDateAvecTirets(this.lundiDeLaSemaineOrigine),
            dateFinOrigine: this.formatDateAvecTirets(this.dimancheDeLaSemaineOrigine),
            dateDestination: this.formatDateAvecTirets(this.lundiDeLaSemaineDestination),
            ecraserMenuDestination: this.ecraserMenuDestination,
            structureMenuDestinationIds: d.planMenuDestinationIds,
            etablissementDestinationId: d.etablissementDestinationId.value,
            planAlimentaireId: d.planAlimentaireId
          })
        })
        for (let i = 0; i < params.length; i++) {
          await dupliquerMenu(this.etabCourantId, params[i])
          this.$emit('menu-duplique', params[i])
        }
        
        this.alerteSucces("Menu dupliqué avec succès !")
      } finally {
        this.duplicationEnCours = false
        this.afficherDuplication = false
      }
    }
  }
}
</script>
