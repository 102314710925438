<template>
  <span :key="key">
    <i class="fas fa-chevron-left" :disabled="!Boolean(datePrecedente)" :title="formatHumanDateAvecSlashes(datePrecedente)" @click="selectionnerDate(datePrecedente)"></i>
    <Calendrier
      :selectedDate="dateSelectionnee"
      :dateMin="dateMin"
      :dateMax="dateMax"
      :markedDates="datesLivraisonsPermises"
      :warningDates="datesLivraisonsLimites"
      :verrouAvantMarkedDates="true"
      :verrouEntreMarkedDates="false"
      :verrouApresMarkedDates="true"
      :isModal="isModal || false"
      @selectionner-date="selectionnerDate($event)">
    </Calendrier>
    <i class="fas fa-chevron-right" :disabled="!Boolean(dateSuivante)" :title="formatHumanDateAvecSlashes(dateSuivante)" @click="selectionnerDate(dateSuivante)"></i>
  </span>
</template>

<script>
import Calendrier from '@/components/Stock/Calendrier'
import { formatHumanDateAvecSlashes } from '@/mixins/dateFormats'
export default {
  name: "ChoixDateLivraison",
  components: {
    Calendrier
  },
  props: ["dateMin", "dateMax", "datesLivraisonAutorisees", "datesAvertissements", "dateSelectionnee", "isModal"],
  mixins: [
    formatHumanDateAvecSlashes
  ],
  data() {
    return {
      key: 1
    }
  },
  computed: {
    datesLivraisonsPermises() {
      let dates = []
      this.datesLivraisonAutorisees.forEach(el => {
        dates.push({ date: el })
      })
      return dates
    },
    datesLivraisonsLimites() {
      let dates = []
      this.datesAvertissements.forEach(el => {
        dates.push({ date: el })
      })
      return dates
    },
    datePrecedente() {
      const current = new Date(this.dateSelectionnee)
      var result = false
      this.datesLivraisonAutorisees.forEach(el => {
        var date = new Date(el)
        if (date < current && (!result || result < date)) {
          result = date
        }
      })
      return result
    },
    dateSuivante() {
      const current = new Date(this.dateSelectionnee)
      var result = false
      this.datesLivraisonAutorisees.forEach(el => {
        var date = new Date(el)
        if (date > current && (!result || result > date)) {
          result = date
        }
      })
      return result
    }
  },
  methods: {
    selectionnerDate(date) {
      if (date) {
        this.key += 1
        this.$emit('selectionner-date', date)
      }
    }
  }
}
</script>

<style scoped>
  i {
    margin: 0 10px;
    transform: scale(1.5);
    cursor: pointer;
  }
  i[disabled] {
    cursor: not-allowed;
    color: gray;
  }
</style>