<template>
  <div class="card" style="height:300px;">
    <div class="card-content">
      <div class="card-body">
        <div class="widgetTitle" style="margin-bottom:10px;">Livraisons Prévues</div>
        <div v-if="!gotLivraisons" style="text-align:center;margin-top:40px;">
          <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style='height:100px;margin-left:-8px;' />
        </div>
        <div v-else style="margin-top:5px;">
          <div v-if="livraisons.length">
            <div v-for="(livraison, iLivraison) in livraisons" :key="iLivraison">
              <div class="flex-center-space-between" style="font-size:13px">
                <b>{{livraison.fournisseur}}</b>
                <b>{{livraison.dateLivraison}}</b>
              </div>
              <div class="flex-start-space-between" style="  font-size:12px;overflow:hidden;max-height:25px;">
                <div style=" display: flex;  align-items: flex-start;">
                  <div v-for="(familleId) in livraison.familleIds" :key="familleId">
                    <IconeFamilleIngredient :familleId="familleId" />
                  </div>
                </div>
                <div style="font-size:12px;font-style:italic;max-height:18px;white-space:nowrap;">{{livraison.produits}}</div>
              </div>
              <hr v-if="iLivraison < 3" style="margin:7px 0;" />
            </div>
          </div>
          <div v-else style="font-style:italic;margin-top:20px;text-align:center;">Pas de livraisons prévues</div>

          <div style="position:absolute;bottom:0;left:0;width:100%;height:30px;">
            <router-link tag="div" style="text-align:center;color:grey;cursor:pointer;" :to="{ name: 'ListeDesCommandes', params: { etablissementId: this.etabCourantId } }" v-tooltip="{ content: 'Accéder au suivi des commandes', placement: 'bottom' }">
              <i class="fas fa-chevron-down"></i>
            </router-link>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { connecteData } from "@/mixins/connecteData"
import IconeFamilleIngredient from '@/components/Divers/IconeFamilleIngredient'
import { mapGetters } from "vuex"

export default {
  name: "WidgetLivraisons",
  props: [],
  mixins: [connecteData],
  components: { IconeFamilleIngredient, },
  data() {
    return {
      gotLivraisons: false,
      maxLignesAffichage: 4,
      livraisons: []
    }
  },
  mounted() {
    this.chargerLivraisons();
  },
  computed: {
    ...mapGetters("definitions", ["informationsAccueil", "familleParId"]),
    ...mapGetters("fournisseurs", ["nomFournisseur"]),
  },
  methods: {
    chargerLivraisons() {
      this.livraisons = []
      if (this.informationsAccueil.livraisons) {
        this.informationsAccueil.livraisons.forEach(l => {
          if (this.livraisons.length < this.maxLignesAffichage) {
            let livre= { familleIds: l.familleIds, fournisseurId: l.fournisseurId,dateLivraison: l.dateLivraison, produits: l.produits}
            livre.familleCodes = []
            livre.familleIds.forEach(f => {
              livre.familleCodes.push(this.familleParId(f).code)
            })
            livre.fournisseur = this.nomFournisseur(livre.fournisseurId)
            this.livraisons.push(livre)
          }
        })
      }

      this.$nextTick(() => this.gotLivraisons = true)
      //const dateDebut = new Date()//TODO: activer

      // const dateDebut = new Date('2020-09-06')//données de test
      /*   return chargerCommandes(this.etabCourantId + '?DateDebut=' + this.formatDateAvecTirets(dateDebut) + '&Statuts=EC2')
          .then(r => {
            let commandes = {}
            r.data.forEach(c => {
              c.commandesFournisseurs.forEach(cf => {
                if (cf.etat === "EC2" && new Date(cf.dateLivraison) >= dateDebut) {
                  if (!commandes[cf.dateLivraison]) {
                    commandes[cf.dateLivraison] = []
                  }
                  commandes[cf.dateLivraison].push({
                    fournisseur: cf.raisonSocialeFournisseur,
                    date: cf.dateLivraison,
                    dateLivraison: this.formatHumanDateAvecSlashes(cf.dateLivraison),
                    familles: 'Liste des familles',//TODO: récupérer cette information
                    produits: cf.nbLignes + " produit" + (cf.nbLignes === 1 ? "" : "s")
                  })
                }
              })
            })
            this.livraisons = []
            vm.forEach(commandes, cj => {
              cj.forEach(c => {
                if (this.livraisons.length < this.maxLignesAffichage)
                  this.livraisons.push(c)
              })
            })
            this.$nextTick(() => this.gotLivraisons = true)
            console.log("livraisons:")
            console.log(this.livraisons)
            return
          }) */
    },
  }
}
</script>

<style scoped>
</style>
