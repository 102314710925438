import store from '../store'

export function chargerDefinitions() {
  return store.dispatch('definitions/chargerDefinitions', { key: "" })
    .then(function () {
      var toGet = [
        store.dispatch('definitions/chargerDefinitions', { key: "ingredients" }),

        store.dispatch('produits/produitsUtilisateurCourant'),
        store.dispatch('definitions/chargerRaisonsIncidents'),
        store.dispatch('definitions/chargerTypesReclamations')
      ]
      return Promise.all(toGet)
    }.bind(this))
}

export function chargerDonneesEtablissement(eId) {
  var toGet = [
    store.dispatch('fournisseurs/listeFournisseurs', eId ),
    store.dispatch('recettes/chargerToutes', {key: 'liste', etablissementId: eId }),
    store.dispatch('definitions/chargerDefinitions', { key: "familles", etablissementId: eId }),
    store.dispatch('definitions/chargerDefinitions', { key: "informationsAccueil", etablissementId: eId }),
    store.dispatch('definitions/chargerConfigurationsGEMRCN', eId),
    store.dispatch('definitions/chargerPlansAlimentaires', eId)
  ]
  return Promise.all(toGet)
}
