<template>
  <div class="col-12 d-flex align-items-center justify-content-center">
    <div class="col-md-4 col-10 box-shadow-2 p-0">
      <div class="card border-grey border-lighten-3 m-0">
        <div class="card-header border-0">
          <div class="card-title text-center">
            <div class="p-1">
              <img alt="Mercudyn 5" src="../assets/images/logo/logoMD5.png" srcset="../assets/images/logo/logoMD5.png 1x,
              ../assets/images/logo/logoMD5-retina.png 2x" class="logo" />
            </div>
          </div>
          <h6 class="card-subtitle text-muted text-center font-small-3 pt-2">
            <q>{{motd}}</q>
            <!-- <span>Bonjour et bienvenue !</span> -->
          </h6>
        </div>
        <div class="card-content">
          <div class="card-body">
            <form class="form-horizontal form-simple" @submit.prevent="login">
              <div style="height:48px" v-if="erreurLogin">
                <div class="alert alert-warning" role="alert">
                  {{ erreurLogin }}
                </div>
              </div>
              <div v-if="!selectEtablissementVisible">
                <fieldset class="form-group position-relative has-icon-left mb-0">
                  <input v-model="userName" type="text" required class="form-control form-control-lg" id="user-name" autofocus autocomplete="username" placeholder="Nom d'utilisateur" ref="login-username" />
                  <div class="form-control-position" style="top:0;">
                    <i class="ft-user"></i>
                  </div>
                </fieldset>
                <fieldset class="form-group position-relative has-icon-left" v-if="!selectEtablissementVisible">
                  <input :type="afficherMdP ? 'text' : 'password'" class="form-control form-control-lg" v-model="password" id="user-password" autocomplete="current-password" placeholder="Mot de passe" required />
                  <div class="form-control-position" style="top:0;">
                    <i class="fa fa-key"></i>
                  </div>
                  <div class="form-control-position" style="top:0;right:0;cursor:pointer;" :title="afficherMdP ? 'masquer le mot de passe' : 'afficher le mot de passe'" @click="afficherMdP = !afficherMdP">
                    <i :class="afficherMdP ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                  </div>
                </fieldset>
              </div>
              <SelectEtablissement v-if="selectEtablissementVisible" @etablissement-selectionne="changerEtablissement($event)" />
              <div v-if="erreur" class="erreur">{{erreur}}</div>
              <button v-if="!selectEtablissementVisible" type="submit" class="btn btn-primary btn-lg btn-block btn-round" :disabled="isLoading" :class="{ boutonDesactive: isLoading }">
                <i :class="{ 'fa-spin ': isLoading }" class="fa fa-lock"></i>
                Connexion
              </button>
            </form>
          </div>
          <div v-if="!selectEtablissementVisible" class="card-footer border-0 bg-white push-bottom-1rem push-top" style="margin-top:0;padding-top:0;">
            <span class="float-right font-small-3"><a class="card-link" href="https://adherents.agap.pro/Identity/Account/ForgotPassword">Mot de passe oublié ?</a></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logConnexion } from '@/api/gpao/user'
import { setUserBugsnag } from "@/helpers/bugsnagM5"
import { AUTH_REQUEST } from "../store/actions/auth";
import { connecteData } from "@/mixins/connecteData"
import SelectEtablissement from '@/components/Etablissements/SelectEtablissement'
import { chargerMercuriales } from '@/helpers/mercuriales'
import { chargerDefinitions, chargerDonneesEtablissement } from '@/helpers/definitions'
import vm from "@/helpers/vm-toolkit-vue"

import lscache from 'lscache'
export default {
  name: "login",
  mixins: [ connecteData],
  components: {
    SelectEtablissement
  },
  data() {
    return {
      isLoading: false,
      userName: "",
      password: "",
      erreurLogin: "",
      selectEtablissementVisible: false,
      motd: '',
      erreur: false,
      afficherMdP: false
    };
  },
  created() {
    var env = vm.whereAmI()
    if (env.browser === "ie" || env.browser === "edge") {
      this.erreur = "Votre navigateur est obsolète et le fonctionnement n'est pas garanti avec notre application."
    }
  },
  mounted() {
    var motds = ['La bonne cuisine est honnête, sincère et simple.', 'On doit mettre tout son coeur dans la cuisine.', 'On doit laisser en paix les gens chargés de la cuisine', 'Pas d’artifice en cuisine pour déguiser une nourriture sans goût.'
      , 'En France, la cuisine est une forme sérieuse d’art et un sport national.', "La cuisine simple, c'est ce qu'il y a de plus compliqué.", "On ne peut pas faire de cuisine si l'on n'aime pas les gens!", "Un homme civilisé ne peut vivre sans cuisiner.",
      "Cuisiner est une activité qui exige d’être aussi créatif et imaginatif qu’une activité telle que le dessin, la sculpture sur bois ou la musique."]
    this.motd = motds[this.getRandomInt(motds.length)]
    this.$nextTick(() => {
      this.$refs["login-username"].focus()
    })
  }
  ,
  computed: {

  },
  methods: {
    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max))
    },
    async login() {

      const { userName, password } = this
      this.isLoading = true
      this.erreurLogin = ""

      try {
        var ancienIdEtabEnCours = this.etabCourantId;
        this.$store.commit('etablissements/emptyEtablissementId')
        const error = await this.$store.dispatch('auth/' + AUTH_REQUEST, { userName, password })

        if (error) {
          throw error
        }
      } catch (error) {
        this.isLoading = false
        this.erreurLogin = "Login ou mot de passe invalide !"
        if (error.message && error.message == "Network Error") {
          this.erreurLogin = "Erreur réseau / connexion internet, ou serveur manquant"
        }
        console.error(error)
        return
      }
      setUserBugsnag(this.utilisateurCourant)

      logConnexion()

      if (ancienIdEtabEnCours && this.etablissementsCourants && this.etablissementsCourants.find(et => et.eId === ancienIdEtabEnCours)) {
        await this.$store.commit('etablissements/setEtablissement', { etablissementId: ancienIdEtabEnCours, userId: this.userId })
      } else { // l'etablissement en cours n'appartient pas a l'utilisateur connecté
        if (this.etablissementsCourants && this.etablissementsCourants.length == 1) {
          await this.$store.commit('etablissements/setEtablissement', { etablissementId: this.etablissementsCourants[0].eId, userId: this.userId })
        }
        else {
          this.selectEtablissementVisible = true
        }
      }
  
      this.chargerDefinitions()
    },
    chargerDefinitions() {
      this.isLoading = true
      return chargerDefinitions()
        .then(() => {
          this.isLoading = false
          if (this.etabCourantId) {
            return this.chargerDonneesEtablissement()
          }
          return
        })
    },
    chargerDonneesEtablissement() {
      return chargerDonneesEtablissement(this.etabCourantId)
        .then(function () {
          chargerMercuriales(this.etabCourantId)
        }.bind(this))

    },
    changerEtablissement(etablissementId) {
      this.$store.commit('etablissements/setEtablissement', { etablissementId: etablissementId, userId: this.$store.state.user.userInfo.id })
      lscache.flush()
      this.chargerDonneesEtablissement()
    }
  }
}
</script>

<style lang="scss">
.login {
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 10px;
}
.boutonDesactive {
  cursor: wait;
}
.logo {
  width: 100%;
}
.erreur {
  color: darkred;
  font-size: 12px;
  margin-bottom: 10px;
  text-align: center;
}
</style>
