import service from '@/helpers/serviceGPAO'


export function validerFicheTech(data) {
  return service.patch('fournisseurs/validerFicheTech', data)
}

export function obtenirDonneesProduits(familleId, etablissementId) {
  let url = 'fournisseurs/donneesProduits/' + familleId
  if (etablissementId)
    url += "/" + etablissementId
  return service.get(url)
}

export function obtenirTarif(tarifId) {
  return service.get('fournisseurs/tarif/' + tarifId)
}

export function verifFicheTechDesignation(str) {
  return service.get('fournisseurs/VerifFicheTechParDesignationOuId/' + str)
}

export function chargerFournisseurs(etabId) {

  return service.get(`fournisseurs/${etabId}`)
  /* .then(response =>{
    response.data.forEach(f=>{
          hydraterFournisseur(f)
        }    
      )
    return response
  }) */
}
/* export function listeCompleteFournisseurs() {
  return service.get('fournisseurs/liste')
}

export function listeFournisseursPourFamille(familleId) {
  return service.get('fournisseurs/liste/' + familleId)
}
 */
export function obtenirUrlFt(fournisseurId, reference) {
  return service.get(`fournisseurs/urlft/${fournisseurId}/${reference}`)
}

export function obtenirProduitEtTarifActif(fournisseurId, reference) {
  return service.get(`fournisseurs/produitEtTarifEtAllergenes/${fournisseurId}/${reference}`)
}

export function ajouterTarifHM(data) {
  return service.post('fournisseurs/ajouterTarifHM', data)
}

export function desactiverTarifHM(tarifId) {
  return service.delete('fournisseurs/desactiverTarifHM/' + tarifId)
}

export function importerAllergenes(fournisseurId, fichier) {
  return service.post('fournisseurs/allergenes/' + fournisseurId, fichier, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export function ajouterFournisseurLocal(utilisateurId, data) {
  return service.post('fournisseurs/ajouterFournisseurLocal/' + utilisateurId, data)
}

export function modifierFournisseurLocal(utilisateurId, data) {
  return service.post('fournisseurs/modifierFournisseurLocal/' + utilisateurId, data)
}