const sanitize = require("sanitize-filename");

export { Nombre, afficherDecimal, arrondiInferieur, arrondiNombre, arrondiMercuriale, arrondiPrix, fMod, lancerTelechargement, lancerTelechargementParUrl, lancerTelechargementDeResponse, mettreLesInitialesEnMajuscules, abregerTexte, couleurTexteSelonFond }

function Nombre(texte) {
  return Number(texte.replace(',', '.'))
}

function afficherDecimal(valeur) {
  if (valeur == undefined)
    return ""
  return valeur.toString().replace('.', ',')
}

   /** https://stackoverflow.com/a/7592235/130420
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @param {boolean=false} lower Whether all other letters should be lowercased
 * @return {string}
 * @usage
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
 */
const mettreLesInitialesEnMajuscules = (str, lower = false) =>
(lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

// Source: https: //www.jacklmoore.com/notes/rounding-in-javascript/
// function arrondiNombre(value, decimals) {
//   return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
// }

function abregerTexte(texte, longueur) {
  if (texte.length <= longueur)
    return texte
  return texte.substring(0, longueur) + "..."
}

function couleurTexteSelonFond(couleur, couleurParDefaut, couleurSurFonce) {
  // Il s'agit du seuil de déclenchement du changement de couleur (il faut ajuster cette valeur en cas de problème de lisibilité sur les couleurs intermédiaires)
  let seuilNuance = 22

  if (!couleurParDefaut)
    couleurParDefaut = "black"
  if (!couleurSurFonce)
    couleurSurFonce = "white"

  if (couleur) {
    let c = couleur.replace('#', '')

    // On ne prend que le caractère hexadécimal fort de chaque couleur
    let rouge = parseInt(c.substring(0, 1), 16)
    let vert = parseInt(c.substring(2, 3), 16)
    let bleu = parseInt(c.substring(4, 5), 16)

    if (rouge + vert + bleu < seuilNuance) {
      return couleurSurFonce
    }
  }
  
  return couleurParDefaut
}

function arrondiInferieur(value, dec) {
  dec = Number(dec) || 3
  let p = Math.pow(10, dec)
  return Number(Math.floor(value * p) / p)
}

function arrondiNombre(value, dec) {
  dec = Number(dec) || 3
  return Number(Math.round(value + 'e' + dec) + 'e-' + dec)
}

function arrondiMercuriale(value, dec) {
  dec = Number(dec) || 3
  return arrondiNombre(value, dec).toFixed(dec).replace('.', ',')
}

function arrondiPrix(value, dec) {
  dec = Number(dec) || 3
  var sum = Number(Math.round(value + 'e' + dec) + 'e-' + dec)
  return sum.toString().replace('.', ',') + ' €'
}

// Calcul du modulo de valeurs décimales
function fMod(numerateur, denominateur) {
  let div = Math.floor(numerateur / denominateur)
  return numerateur - (div * denominateur)
}

function lancerTelechargement(blob, nomFichier) {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, nomFichier);
    return;
  }
  const url = window.URL.createObjectURL(blob)
  lancerTelechargementParUrl(url, nomFichier);
}

function lancerTelechargementParUrl(url, nomFichier) {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', sanitize(nomFichier))
  document.body.appendChild(link)
  link.click()
  link.remove()
  window.URL.revokeObjectURL(url)
}

function lancerTelechargementDeResponse(response) {
  const contentDisposition = response.headers['content-disposition'];
  let nomFichier = 'fichier';
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename=(.+);/);
    if (fileNameMatch.length === 2)
      nomFichier = fileNameMatch[1];
  }
  lancerTelechargement(response.data, nomFichier)
}


