<template>
  <div style="display: inline">
    <div class="card">
      <div class="card-header bg-gradient-bleu text-white">
        <span>ARTICLE</span>
      </div>
      <div class="card-body">
        <div>
          <div class="container-fluid">
            <div class="row flex-center-space-between">
              <div class="input-box">
                <input :disabled="disabled" type="text" placeholder="Désignation" required title="Désignation de votre article" v-model.trim="article.designation" v-tooltip="{ content: 'Désignation chez le fournisseur', placement: 'top' }"/>
                <div class="error" v-if="!article.designation">Ce champ est requis</div>
              </div>
              <input :disabled="disabled" type="text" placeholder="Marque" v-model.trim="article.marque" v-tooltip="{ content: 'Marque produit', placement: 'top' }"/>
              <input :disabled="disabled" type="text" placeholder="Référence" v-model.trim="article.reference" v-tooltip="{ content: 'Référence produit', placement: 'top' }"/>
              <div class="input-box">
                <div class="input-box flex-end">
                  <div class="label-input mr-1">P.U</div>
                  <InputNumber :disabled="disabled" typeFiltre="montant" v-model="article.pu" v-tooltip="{ content: 'Prix Unitaire (selon PCB)', placement: 'top' }"/>
                  <div class="input-group-append">
                    <span class="input-group-text">€</span>
                  </div>
                </div>
                <div class="error" v-if="!article.pu">Ce champ est requis</div>
              </div>
            </div>
          </div>
          <div class="inline-inputs push-top-1rem">
            <div class="flex-center-space-between">
              <div>
                <div class="flex-start">
                  <div class="label-input">PCB :</div>
                  <InputNumber :disabled="disabled" typeFiltre="quantite" v-model="article.pcb" style="margin:2px;width:50px"/>
                  <div class="input-group-append">
                    <span class="input-group-text">{{produit.uc}}</span>
                  </div>
                  <div class="error" v-if="!article.pcb">Ce champ est requis</div>
                </div>
              </div>
              <div>
                <div class="flex-end" v-if="!disabled">
                  <div class="input-group-prepend">
                    <span class="input-group-text ">1 {{produit.uc}} =</span>
                  </div>
                  <InputNumber class="saisieRepartition" :disabled="disabled" typeFiltre="quantite" v-model="article.parPCE" :invalideSiVide="true" style="margin:2px;width:65px"/>
                  <div class="input-group-append">
                    <span class="input-group-text">PCE, </span>
                  </div>
                  <InputNumber class="saisieRepartition" :disabled="disabled" typeFiltre="quantite" v-model="article.parKg" :invalideSiVide="true" style="margin:2px;width:65px"/>
                  <div class="input-group-append">
                    <span class="input-group-text"><i v-if="estInvalidePour('KG',article.parKg)" class="error fas fa-exclamation-circle" v-b-tooltip.hover title="Cette valeur devrait être 1"></i>KG, </span>
                  </div>
                  <InputNumber class="saisieRepartition" :disabled="disabled" typeFiltre="quantite" v-model="article.parL" :invalideSiVide="true" style="margin:2px;width:65px"/>
                  <div class="input-group-append">
                    <span class="input-group-text"><i v-if="estInvalidePour('L',article.parL)" class="error fas fa-exclamation-circle" v-b-tooltip.hover title="Cette valeur devrait être 1"></i>L.</span>
                  </div>
                </div>
                <div class="error" v-if="!article.parPCE && !article.parKg && !article.parL">Attention, les répartitions doivent être renseignées pour être prises en compte dans les calculs automatiques</div>
                <div class="flex-end" style="margin-top:3px;">
                  <p>{{phraseConversion(article, produit.uc, false, false)}}</p>
                </div>
                <div class="flex-end">
                  <p>{{phraseConversion(article, produit.uc, true, false)}}</p>
                </div>
                <div class="flex-end">
                  <p>{{phraseConversion(article, produit.uc, true, true)}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid push-top-1rem">
            <div class="row">
              <div class="col-8">
                <MultiSelect class="push-top-1rem push-right-2rem" :disabled="disabled || article.aucunAllergene" :value="article.allergenesMajeurs" :items="optionsAllergenes" itemText="valeur" itemValue="id" placeholder="Allergènes..." emptyText="Tous les allergènes ont été ajoutés" @change="article.allergenesMajeurs = $event" />
              </div>
              <div class="col-2 flex-start">
                <input type="checkbox" id="aucunAllergene" class="form-check-input" v-model="article.aucunAllergene"
                  :disabled="disabled || article.allergenesMajeurs.length !== 0" />
                <label class="form-check-label" for="aucunAllergene">Aucun allergène</label>
              </div>
              <div class="col-2 flex-end" v-if="!disabled">
                <button :disabled="disabled || !articleEstModifie || !articleEstValide" class="btn btn-primary" @click="enregistrer()">Enregistrer</button>
              </div>
              <div class="error pt-0 ml-3">
                <span v-show="!article.aucunAllergene && (!article.allergenesMajeurs || article.allergenesMajeurs.length === 0)" >  Allergènes non renseignés</span>
              </div>
            </div>
            <div class="row">
              <p style="padding-top:15px;font-size:0.8em">
                <em>L’affichage des allergènes est effectué à titre informatif. Chaque utilisateur du logiciel doit vérifier par lui-même la véracité de ces informations auprès du ou des fournisseurs de denrées alimentaires concernés.<br/>
                  En cas d’erreur, l’utilisateur ne pourra pas engager la responsabilité de la société Agap’pro, éditrice du logiciel.</em>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { constantesId } from "@/helpers/constantes"
import { mapGetters } from "vuex"
import { showAlerts } from "@/mixins/alerts"
import { validerFicheTech, ajouterTarifHM } from '@/api/gpao/fournisseurs'
import InputNumber from '@/components/Inputs/InputNumber'
import MultiSelect from "@/components/Inputs/MultiSelect"

export default {
  name: "AjoutArticleGreAGre",
  props: [ 'fournisseurId', 'produit', 'tarif', 'referenceParDefaut', 'disabled' ],
  mixins: [
    showAlerts
  ],
  components: {
    InputNumber,
    MultiSelect
  },
  data() {
    return {
      optionsAllergenes: constantesId.allergenes,
      article: {
        tarifId: this.tarif ? this.tarif.tarifId : null,
        fournisseurId: this.fournisseurId,
        produitId: this.produit ? this.produit.produitId : '',
        designation: this.tarif ? this.tarif.designation : '',
        marque: this.tarif ? this.tarif.marque : '',
        reference: this.tarif ? this.tarif.reference : this.referenceParDefaut,
        pu: this.tarif ? this.tarif.pu : '',
        pcb: this.tarif ? this.tarif.pcb : '',
        parPCE: this.tarif ? this.tarif.parPCE : '',
        parKg: this.tarif ? this.tarif.parKg : '',
        parL: this.tarif ? this.tarif.parL : '',
        allergenesMajeurs: this.tarif ? this.tarif.allergenesMajeurs : [],
        codeEAN: this.tarif ? this.tarif.codeEan : '',
        aucunAllergene: this.tarif ? this.tarif.aucunAllergene : false
      }
    }
  },
  computed: {
    ...mapGetters("definitions", ["ingredients"]),
    articleEstModifie() {
      if (!this.tarif) {
        return true
      }
      return this.article.designation !== this.tarif.designation
        || this.article.marque !== this.tarif.marque
        || this.article.reference !== this.tarif.reference
        || this.article.pu !== this.tarif.pu
        || this.article.pcb !== this.tarif.pcb
        || this.article.parPCE !== this.tarif.parPCE
        || this.article.parKg !== this.tarif.parKg
        || this.article.parL !== this.tarif.parL
        || this.article.allergenesMajeurs !== this.tarif.allergenesMajeurs
        || this.article.aucunAllergene !== this.tarif.aucunAllergene
    },
    articleEstValide() {
      if (!this.article.designation || !this.article.pu
        || (!this.article.parPCE && this.article.parPCE !== 0)
        || (!this.article.parKg && this.article.parKg !== 0)
        || (!this.article.parL && this.article.parL !== 0)
        || (this.article.allergenesMajeurs.length === 0 && !this.article.aucunAllergene)) {
        return false
      }
      return true
    }
  },
  methods: {
    estInvalidePour(up, repartition) {
      if (!this.produit.uc)
        return false
      if (up !== this.produit.uc.toUpperCase()) {
        return false;
      }
      return repartition != 1;
    },
    arrondirDecimales(nombre) {
      return nombre = Math.round((nombre + Number.EPSILON) * 100) / 100
    },
    phraseConversion(tarif, produitUc, auPcb, casReel) {
      var phrase = ""
      if (tarif.pcb > 0) {
        if (auPcb) {
          if (casReel)
            phrase += "Si je commande " + (1.5 * tarif.pcb) + " " + produitUc + " je recevrai "
          else
            phrase += "Au PCB je recevrai "
        }
        else
          phrase += "Si je commande 1 " + produitUc + " je recevrai "
        if (tarif.parKg > 0) {
          if (auPcb) {
            if (casReel)
              phrase += this.arrondirDecimales((tarif.parKg * (2 * tarif.pcb))) + " kg"
            else
              phrase += this.arrondirDecimales((tarif.parKg * tarif.pcb)) + " kg"
          }
          else
            phrase += this.arrondirDecimales(tarif.parKg) + " kg"

          if (tarif.parPCE > 0 || tarif.parL > 0)
            if (auPcb) {
              if (casReel)
                phrase += " (soit " + (tarif.parPCE > 0 ? this.arrondirDecimales(tarif.parPCE * (2 * tarif.pcb)) + " unité" + ((this.arrondirDecimales(tarif.parPCE * (2 * tarif.pcb))) > 1 ? "s" : "") : "") + (tarif.parPCE > 0 && tarif.parL > 0 ? " ou " : "") + (tarif.parL > 0 ? this.arrondirDecimales(tarif.parL * (2 * tarif.pcb)) + " litre" + ((this.arrondirDecimales(tarif.parL * (2 * tarif.pcb))) > 1 ? "s" : "") : "") + ")."
              else
                phrase += " (soit " + (tarif.parPCE > 0 ? this.arrondirDecimales(tarif.parPCE * tarif.pcb) + " unité" + ((this.arrondirDecimales(tarif.parPCE * tarif.pcb)) > 1 ? "s" : "") : "") + (tarif.parPCE > 0 && tarif.parL > 0 ? " ou " : "") + (tarif.parL > 0 ? this.arrondirDecimales(tarif.parL * tarif.pcb) + " litre" + ((this.arrondirDecimales(tarif.parL * tarif.pcb)) > 1 ? "s" : "") : "") + ")."
            }
            else
              phrase += " (soit " + (tarif.parPCE > 0 ? this.arrondirDecimales(tarif.parPCE) + " unité" + (tarif.parPCE > 1 ? "s" : "") : "") + (tarif.parPCE > 0 && tarif.parL > 0 ? " ou " : "") + (tarif.parL > 0 ? this.arrondirDecimales(tarif.parL) + " litre" + (tarif.parL > 1 ? "s" : "") : "") + ")."

          else
            phrase += "."
        }
        else if (tarif.parPCE > 0 || tarif.parL > 0)
          if (auPcb)
            phrase += (tarif.parPCE > 0 ? this.arrondirDecimales(tarif.parPCE * (2 * tarif.pcb)) + " unité" + ((this.arrondirDecimales(tarif.parPCE * (2 * tarif.pcb))) > 1 ? "s" : "") : "") + (tarif.parPCE > 0 && tarif.parL > 0 ? " ou " : "") + (tarif.parL > 0 ? this.arrondirDecimales(tarif.parL * (2 * tarif.pcb)) + " litre" + ((this.arrondirDecimales(tarif.parL * (2 * tarif.pcb)))  > 1 ? "s." : ".") : ".")
          else
            phrase += (tarif.parPCE > 0 ? this.arrondirDecimales(tarif.parPCE) + " unité" + (tarif.parPCE > 1 ? "s" : "") : "") + (tarif.parPCE > 0 && tarif.parL > 0 ? " ou " : "") + (tarif.parL > 0 ? this.arrondirDecimales(tarif.parL) + " litre" + (tarif.parL > 1 ? "s." : ".") : ".")
        
        else
          phrase = "Aucune conversion n'est paramétrée."
      }
      else
        phrase = "Aucun PCB n'est paramétré."

      return phrase
    },
    async enregistrer() {
      let response = {}
      if (this.article.allergenesMajeurs.length === 0 && !this.article.aucunAllergene
          && (this.tarif.allergenesMajeurs.length !== 0 || this.tarif.aucunAllergene)) {
        this.$swal({
          title: "Opération impossible",
          html: "Des allergènes sont déjà renseignés sur cet article, vous devez renseigner des allergènes ou cocher la case 'Aucun allergène'",
          icon: "error"
        })
        return false
      }
      if (!this.article.tarifId) {
        this.article.prixAchat = this.article.pu
        this.article.tracesAllergenes = []
        this.article.codeEan = ''
        this.article.ftEstOK = false
        this.article.ftLien = ''
        response = await ajouterTarifHM(this.article)
        this.article.tarifId = response.data.id
      }
      response = await validerFicheTech(this.article)
      this.$emit('article-ajoute', { article: this.article })
      this.$toast('Article sauvegardé', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        className: ['toast-success'],
        duration: 1500
      })
      console.log(response.data)
    }
  }
}
</script>
