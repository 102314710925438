<template>

  <div class="card-list-besoins cursor-pointer" :key="'besoins' + index" :class="ingredient.designation === 'NON REMPLI'  ? 'li-ingredient-erreur' : currentItem === index ? 'besoin-selected' : ''" @click="apercuRecettes(ingredient, index)">
    <div v-if="ingredientChoisi.designation === 'NON REMPLI'" class="erreur-ingredient">
      Ingredient<span v-if="isAdmin"> {{ingredientChoisi.ingredientId}}</span> indisponible : {{ingredientNom(ingredientChoisi.ingredientId)}}
      ({{ingredient.erreurs && Array.from(new Set(ingredient.erreurs)).join(", ")}})
    </div>
    <div v-else class="flex-space-between" style="width:100%">
      <div style="width:35px;text-align:left;">
        <IconeFamilleIngredient :familleId="ingredientChoisi.familleId" />
        <br />
        <div v-if="ingredientChoisi.estFavori" style="text-align:center;" v-tooltip="{ content: 'Article Favori', placement: 'top' }">
          <i class="fa fa-star" />
        </div>
      </div>
      <div style="width:90%;text-align:left;">
        {{ingredientChoisi.reference ? ingredientChoisi.reference + ' -' : '' }} {{ingredientChoisi.designation}} {{ingredientChoisi.marque ? ' - ' + ingredientChoisi.marque : ''}} <i>-</i> {{fournisseurNom(ingredientChoisi.fournisseurId)}}<br />
        <div style="display:flex">
          <div style="display:flex;margin-bottom:10px;width:80%;">
            <div style="width:200px">
              Besoin <b> {{ingredientChoisi.quantiteBesoinEnUC | mettreVirgule}} {{ingredient.uc}} </b>
            </div>
            <div style="display:flex;justify-content: flex-start;width:60%;">
              Commande
              <QuantiteDemandeeEtPcb v-model="ingredientChoisi.quantitePCB"  @save="surQuantiteModifiee(ingredientChoisi)"
              :pcb="ingredientChoisi.pcb" :uc="ingredientChoisi.uc" :modifiable="true" :ligneId="'besoin'+index" style="width:100px;margin-left:5px;margin-right:5px" />   {{ingredientChoisi.uc}}
            </div>
            <div style="width:30%">
              <span v-tooltip="{content: 'En stock', placement:'top'}"><i style="margin-left:5px;" aria-hidden="true" class="icone-stocks"></i> : {{ingredientChoisi.quantiteStock}} {{ingredientChoisi.uc}}</span><br/>
              <span v-tooltip="{content: 'Excédent commandé', placement:'bottom'}">({{excedentCommande}} {{ingredientChoisi.uc}})</span>
            </div>
          </div>
          <div style="margin-left:5px;width:60%;display:flex">
            <div style="width:40%">PU : {{ingredientChoisi.prixUnitaire | afficherPrix}}</div>
            <div style="width:60%"> Total : {{prixIngredientTotal(ingredientChoisi)}}</div>
          </div>
        </div>
      </div>
      <div>
        <SubstitutionArticle ref="substitution" :idsBloques="[]" :ingredient='ingredient' @selection-article="articleSelectionnePourIngredient($event)" @toggle-favori="toggleFavori" :recettes="recettes" />

        <!-- on va partir du principe que pour l'instant, on a toujours une FT de renvoyé, donc pas besoin de
 | (ingredient.fournisseurId && ingredient.reference) -->
        <BoutonFicheTechnique v-if="ingredientChoisi.urlFT" :fournisseurId="ingredientChoisi.fournisseurId" :reference="ingredientChoisi.reference" :urlFT="ingredientChoisi.urlFT" style="margin-top:10px" />

      </div>

    </div>
  </div>

</template>

<script>

import { fournisseurs} from '@/mixins/fournisseurs'
import IconeFamilleIngredient from '@/components/Divers/IconeFamilleIngredient'
import SubstitutionArticle from '@/components/Divers/SubstitutionArticle'
import { arrondiMercuriale, arrondiNombre } from '@/helpers/utils'
import BoutonFicheTechnique from '@/components/Divers/BoutonFicheTechnique'
import { connecteData } from '@/mixins/connecteData'
import QuantiteDemandeeEtPcb from '@/components/SuiviCommandes/QuantiteDemandeeEtPcb'

export default {
  name: "ligneProduit",
  props: ['index', 'currentItem', 'ingredient', 'recettes'],
  components: { IconeFamilleIngredient, QuantiteDemandeeEtPcb, SubstitutionArticle, BoutonFicheTechnique },
  mixins: [fournisseurs, connecteData],
  data() {
    return {
      besoins: [],
      prixTotal: [],
      ingredientChoisi: this.ingredient,
    }
  },
  computed: {
    excedentCommande() {
      var excedent = this.ingredientChoisi.quantiteStock + this.ingredientChoisi.quantitePCB - this.ingredientChoisi.quantiteBesoinEnUC
      if (excedent >= 0) {
        return ("+ " + arrondiNombre(excedent)).replace('.', ',')
      }
      return String(arrondiNombre(excedent)).replace('.', ',').replace('-', '- ')
    }
  },
  methods: {
    surQuantiteModifiee(ing) {
      this.calculTotalLigne(ing)
      this.$emit("quantite-modifiee",)
    },
    prixIngredientTotal(ing) {
      return arrondiMercuriale(ing.prixTotal) + ' €'
    },
    calculPrixTotal() {
      this.prixTotal = this.besoins
        .filter(el => el.designation !== 'NON REMPLI')
        .map(el => Number(el.prixTotal))
        .reduce((a, b) => a + b, 0)
      return arrondiMercuriale(this.prixTotal) + ' €'
    },
    calculTotalLigne(ing) {
      ing.prixTotal = (ing.quantitePCB * ing.prixUnitaire)
      this.calculPrixTotal()
    },

    articleSelectionnePourIngredient(event) {
      this.ingredientChoisi.produitId = event.produitId
      this.ingredientChoisi.tarifId = event.produit.tarifId
      this.ingredientChoisi.marque = event.produit.marque
      this.ingredientChoisi.reference = event.produit.reference
      this.ingredientChoisi.fournisseurId = event.produit.fournisseurId
      this.ingredientChoisi.prixUnitaire = event.produit.prixUnitaire
      this.ingredientChoisi.pcb = event.produit.pcb
      this.ingredientChoisi.designation = event.produit.designation
      this.ingredientChoisi.estFavori = event.estFavori
      this.ingredientChoisi.urlFT = event.produit.urlFT
      this.calculTotalLigne(this.ingredientChoisi)
      this.$emit('selection-article', this.ingredientChoisi)
    },

    apercuRecettes() {
      this.$emit('apercu-recettes', { ingredient: this.ingredient, index: this.index })
    },
    ingredientNom(id) {
      if (id) {
        return this.$store.state.definitions.ingredients.find(el => el.id === id).nom
      }
    },
    toggleFavori(event) {
      if (this.ingredientChoisi && this.ingredientChoisi.fournisseurId === event.fournisseurId) {
        this.ingredientChoisi.estFavori = event.estFavori
      }
      this.$emit("toggle-favori", event)
    }
  },
  created() {
  }
}
</script>

<style scoped>
.input-number-besoins {
  width: 60px;
  margin: 0px 0.3rem;
  height: 1.5rem;
  background: white;
  color: black;
}
.icone-stocks::before {
  transform: scale(1.1);
}
</style>
