<template>
  <div style="display:inline-block;">
    <FunctionalCalendar ref="calendrier" class="calendrier" v-model="calendarData" :configs="calendarConfigs" @choseDay="selectionnerDate($event)"></FunctionalCalendar>
  </div>
</template>

<script>
import { FunctionalCalendar } from 'vue-functional-calendar'
import { formatHumanDateAvecSlashes } from '@/mixins/dateFormats'
export default {
  name: "Calendrier",
  components: {
    FunctionalCalendar
  },
  mixins: [
    formatHumanDateAvecSlashes
  ],
  props: ['markedDates', 'verrouAvantMarkedDates', 'verrouEntreMarkedDates', 'verrouApresMarkedDates', 'warningDates', 'selectedDate', 'dateMin', 'dateMax', 'isModal', 'enabledDates'],
  data() {
    return {
      calendarData: {
        selectedDate: this.convertirDatePourCalendrier(new Date(this.selectedDate)),
        dateRange: { start: "", end: "" }
      },
      calendarConfigs: {
        sundayStart: false,
        isDatePicker: true,
        isDateRange: false,
        markedDates: [],
        disabledDates: [],
        enabledDates: [],
        limits: { min: '', max: '' },
        applyStylesheet: false,
        showWeekNumbers: false,
        isModal: this.isModal || false
      }
    }
  },
  methods: {
    convertirDatePourCalendrier(date) {
      return this.formatHumanDateAvecSlashes(date)
    },
    lireDateCalendrier(date) {
      if (typeof date !== "string" || date.indexOf("/") === -1) {
        return new Date(date)
      }
      date = date.split("/")
      if (date.length < 3) {
        console.log("date illisible !")
      }
      return new Date(date[2] + "-" + date[1] + "-" + date[0])
    },
    calculerDateLimiteMin() {
      if (this.dateMin)
        return this.convertirDatePourCalendrier(this.dateMin)
      if (!this.verrouAvantMarkedDates)
        return ""
      let dateMin = Math.min.apply(null, this.markedDates.map(el => this.lireDateCalendrier(el.date)))
      return this.convertirDatePourCalendrier(dateMin)
    },
    calculerDateLimiteMax() {
      if (this.dateMax)
        return this.convertirDatePourCalendrier(this.dateMax)
      if (!this.verrouApresMarkedDates)
        return ""
      let dateMax = new Date(Math.max.apply(null, this.markedDates.map(el => this.lireDateCalendrier(el.date))))
      return this.convertirDatePourCalendrier(dateMax)
    },
    calculerVerrousEntreDatesMarquees() {
      let disabledDates = []
      if (!this.verrouEntreMarkedDates)
        return disabledDates
      let datesCalendrier = this.markedDates.map(el => el.date)
      let dates = this.markedDates.map(el => this.lireDateCalendrier(el.date))
      let dateFin = new Date(Math.max.apply(null, dates))
      let date = new Date(Math.min.apply(null, dates))
      while (date < dateFin) {
        let dateConvertie = this.convertirDatePourCalendrier(date)
        if (!datesCalendrier.includes(dateConvertie)) {
          disabledDates.push(dateConvertie)
        }
        date.setDate(new Date(date.getDate() + 1))
      }
      return disabledDates
    },
    selectionnerDate(sel) {
      if (!sel.date) {
        return
      }
      this.$emit('selectionner-date', this.lireDateCalendrier(sel.date))
    }
  },
  created() {
    this.calendarConfigs.markedDates = this.markedDates
    if (this.calendarConfigs.markedDates) {
      this.calendarConfigs.markedDates.forEach(el => {
        el.date = this.convertirDatePourCalendrier(new Date(el.date))
        if (this.warningDates && this.warningDates.find(w => el.date === this.convertirDatePourCalendrier(new Date(w.date))))
          el.class = "calendar-date-avertissement"
        if (!el.class)
          el.class = "calendar-date-default"
      })
    }
    this.calendarConfigs.disabledDates = this.calculerVerrousEntreDatesMarquees()
    this.calendarConfigs.limits.min = this.calculerDateLimiteMin()
    this.calendarConfigs.limits.max = this.calculerDateLimiteMax()
    if (this.warningDates) {
      this.warningDates.forEach(el => {
        if (!this.markedDates.find(m => m.date === this.convertirDatePourCalendrier(new Date(el.date)))) {
          this.calendarConfigs.markedDates.push({ date: this.convertirDatePourCalendrier(new Date(el.date)), class: "calendar-date-avertissement" })
        }
      })
    }
    if (this.enabledDates) {
      this.calendarConfigs.enabledDates = this.enabledDates
      this.calendarConfigs.disabledDates = []
    }
  },
  mounted() {
    this.$refs.calendrier.ChooseDate(this.convertirDatePourCalendrier(new Date(this.selectedDate)))
  }
}
</script>

<style>
.vfc-single-input {/* fix affichage input du calendrier */
  color: black;
  border-color: black;
}
</style>
