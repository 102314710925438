<template>
  <span>
    <b-button class="btn btn-light" :disabled="disabled" size="sm" @click="init()" title="Substitution d'article">
      <i class="fas fa-exchange-alt"></i>
    </b-button>

    <b-modal v-model="afficherModale" size="xl" hide-footer header-bg-variant="gradient-bleu" header-text-variant="light">
      <template slot="modal-title">
        <h3><i class="fas fa-exchange-alt" style="margin-right:1rem"></i>Substitution d'article</h3>
      </template>
      <template slot="default">
        <div class="container-fluid">
          <recherche-articles :masquerVide="true" :fournisseurId="fournisseurId" typeParDefaut="parReference"  @change-ingredient="changeIngredient($event)" @change-state="changeEtatRecherche($event)" />
          <h4 class="push-top-1rem" :key="stateSearch" v-if="stateSearch">{{stateSearch}}
            <button v-if="!article" type="button" class="btn btn-success" v-tooltip="{ content: 'Créer un nouvel article', placement: 'top' }" @click="creerArticle = true">
              <i class="fas fa-plus"></i>
            </button>
          </h4>
        </div>
        <div class="container-fluid" style="margin-top:5px;">
          <articles-par-produit v-if="ingredient && ingredient.parProduit" :articles="ingredient.parProduit" :favoris="ingredient.favoris" :ingredient="ingredient" :index="0" :key="keyArticles" @selection-article="setArticle($event)" />
        </div>

        <div v-if="creerArticle" class="container-fluid push-top-1rem">
          <div class="row">
            <div class="col-6">
              <b-form-select :value-field="'id'" :text-field="'nom'" :options="famillesFournisseur" class="selectpicker" v-model="familleId"
                title="Choix de la famille à afficher" @change="obtenirProduits()">
                <template slot="first">
                  <option :value="null" disabled>Sélectionnez une famille...</option>
                </template>
              </b-form-select>
            </div>
            <div v-if="familleId" class="col-6 flex-center-space-between mb-3">
              <b-form-select :value-field="'produitId'" :text-field="'designation'" :options="optionsProduits" class="selectpicker" v-model="produitId"
                title="Choix du produit à afficher" @change="obtenirArticle()">
                <template slot="first">
                  <option :value="null" disabled>Sélectionnez un produit...</option>
                </template>
              </b-form-select>
              <button type="button" class="btn btn-success ml-4" v-tooltip="{ content: 'Créer un nouveau produit', placement: 'top' }" @click="ouvrirAjoutProduit()">
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
        <div v-if="ajouterProduit || produit" class="container-fluid push-top-1rem">
          <div class="row">
            <div class="card" style="width:100%">
              <div class="card-header bg-gradient-bleu text-white">
                <span>PRODUIT</span>
              </div>
              <div class="card-body">
                <ajout-produit-gre-a-gre :familleId="familleId" :produit="produit" @produit-ajoute="produitAjoute($event)" :key="'ajoutProduit'+keyProduit"/>
              </div>
            </div>
          </div>
        </div>
        <div v-if="produit || article" class="container-fluid push-top-1rem">
          <div class="row">
            <ajout-article-gre-a-gre :disabled="produit && produit.typeProduit && produit.typeProduit === 'Produit'" :fournisseurId="fournisseurId" :produit="produit" :tarif="article"
              :referenceParDefaut="referenceArticle" @article-ajoute="affecterArticle($event)" :key="'ajoutArticle'+keyArticle"/>
          </div>
        </div>
        <div class="col-12 flex-end">
          <button :disabled="!article" class="btn btn-primary" @click="substituerArticle()">Valider</button>
          <button class="btn btn-danger" @click="annuler()" style="margin:0 10px;">Annuler</button>
        </div>
      </template>
    </b-modal>
  </span>
</template>

<script>
import { mapGetters } from "vuex"
import { obtenirProduitEtTarifActif } from '@/api/gpao/fournisseurs'
import { obtenirDonneesProduits } from "@/api/gpao/fournisseurs"
import { connecteData } from '@/mixins/connecteData'
import { fournisseurs } from '@/mixins/fournisseurs'
import AjoutProduitGreAGre from './AjoutProduitGreAGre'
import AjoutArticleGreAGre from './AjoutArticleGreAGre'
import RechercheArticles from './RechercheArticles.vue'
import ArticlesParProduit from '../Stock/ArticlesParProduit.vue'
import vm from "@/helpers/vm-toolkit-vue"

export default {
  name: "RechercheAjoutProduit",
  props: [ 'fournisseurId', 'disabled' ],
  mixins: [ fournisseurs, connecteData ],
  components: {
    AjoutProduitGreAGre,
    AjoutArticleGreAGre,
    RechercheArticles,
    ArticlesParProduit
  },
  data() {
    return {
      optionsProduits: [],
      afficherModale: false,
      referenceArticle: '',
      afficherRechercheParFamille: false,
      afficherResultat: false,
      ajouterProduit: false,
      familleId: null,
      produitId: null,
      produit: null,
      article: null,
      keyProduit: 0,
      keyArticle: 0,
      keyArticles: 0,
      fournisseur: this.$store.state.fournisseurs.fournisseursCourants.find(el => el.id === this.fournisseurId),
      stateSearch: '',
      ingredient: null,
      creerArticle: false
    }
  },
  computed: {
    ...mapGetters("definitions", ["ingredients"]),
    ...mapGetters("definitions", ["familles"]),
    famillesFournisseur() {
      let etab = this.utilisateurCourant.etablissements.find(x => x.eId === this.etabCourantId)
      let ff = etab.choixFournisseurs.filter(x => x.foId === this.fournisseurId)
      return this.familles.filter(x => ff.map(y => y.fId).includes(x.id) && x.actif)
    }
  },
  methods: {
    init() {
      this.referenceArticle = ''
      this.afficherRechercheParFamille = false
      this.afficherResultat = false
      this.ajouterProduit = false
      this.familleId = null
      this.produit = null
      this.article = null
      this.show()
    },
    show() {
      this.afficherModale = true
    },
    hide() {
      this.afficherModale = false
    },
    changeEtatRecherche(etat) {
      this.stateSearch = etat
    },
    changeIngredient(ingredient) {
      this.creerArticle = false
      this.ajouterProduit = false
      this.ingredient = ingredient
      this.familleId = null
      this.produitId = null
      this.produit = null
      this.article = null
      this.stateSearch = ""
      ++this.keyArticles
    },
    ouvrirAjoutProduit() {
      this.article = null
      this.produit = null
      this.produitId = null
      this.ajouterProduit = true
      ++this.keyProduit
      ++this.keyArticle
    },
    setArticle(elt) {
      let article = elt.produit
      if (this.ingredient.parProduit) {//ouvre l'article sélectionné
        vm.forEach(this.ingredient.parProduit, a => {
          a.forEach(r => {
            r.ouvrirAccordeon = r.tarifId === article.tarifId
          })
        })
      }
      this.article = article
      this.ingredient.tarifId = article.tarifId
      this.ingredient.uc = article.uc
      if (article.id) {
        this.$set(this.article, 'id', article.id)
      }
      this.$set(this.article, 'tarifId', article.tarifId)
      this.$set(this.article, 'designationFournisseur', article.designationFournisseur || article.designation)
      this.$set(this.article, 'uc', article.uc)
      this.$set(this.article, 'valeurUnitaire', article.valeurUnitaire || article.prixUnitaire)
      this.$set(this.article, 'reference', article.reference)
      this.$set(this.article, 'marque', article.marque)
      this.$set(this.article, 'fournisseurId', article.fournisseurId)
      if (elt.produitId) {
        this.$set(this.article, 'produitId', elt.produitId)
      }
      ++this.keyArticles
    },
    async obtenirProduits() {
      this.$store.commit('uxHelpers/showSpinner')
      this.produit = null
      this.article = null
      this.optionsProduits = []
      try {
        const response = await obtenirDonneesProduits(this.familleId, this.etabCourantId)
        let data = response.data
        let categorie = null
        let sousCategorie = null
        for (let i = 0; i < data.produits.length; i++) {
          let produit = data.produits[i]
          if (produit.hierarchieProduit === 2) {
            categorie = produit.designation
          }
          if (produit.hierarchieProduit === 3) {
            sousCategorie = produit.designation
          }
          if (produit.hierarchieProduit === 1) {
            produit["categorie"] = categorie
            produit["sousCategorie"] = sousCategorie
            this.optionsProduits.push(produit)
            this.optionsProduits = this.optionsProduits.sort((p1, p2) => p1.designation.localeCompare(p2.designation))
          }
        }
      }
      finally {
        ++this.keyProduit
        ++this.keyArticle
        this.$store.commit("uxHelpers/hideSpinner")
      }
    },
    obtenirArticle() {
      this.produit = null
      this.article = null
      this.produit = this.optionsProduits.find(p => p.produitId === this.produitId)
      if (this.produit) {
        this.article = this.produit.tarifs.find(el => el.fournisseurId === this.fournisseurId)
      }
      ++this.keyProduit
      ++this.keyArticle
    },
    produitAjoute(event) {
      this.produit = event.produit
      if (this.optionsProduits.find(p => p.produitId === this.produit.produitId)) {
        this.optionsProduits = this.optionsProduits.filter(p => p.produitId !== this.produit.produitId)
      }
      this.optionsProduits.push(this.produit)
      this.optionsProduits.sort((p1, p2) => p1.designation.localeCompare(p2.designation))
      this.produitId = this.produit.produitId
      this.ajouterProduit = false
      ++this.keyArticle
      ++this.keyProduit
    },
    affecterArticle(event) {
      this.article = event.article
      ++this.keyProduit
      ++this.keyArticle
    },
    substituerArticle() {
      this.$emit('substituer-article', { article: this.article })
      this.hide()
    },
    annuler() {
      this.hide()
    }
  }
}
</script>
