<template>
  <table class="table table-striped" :key="cleRerender">
    <colgroup>
      <col style="width:325px;">
      <col style="width:150px;">
      <col>
    </colgroup>
    <thead>
      <tr>
        <th>Nom</th>
        <th>Total</th>
        <th>Date</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="fourn in fournisseurs" :key="fourn.foId">
        <th style="vertical-align:middle;">{{fourn.nom}}</th>
        <th style="vertical-align:middle;">{{fourn.totalCommande|afficherPrix}}</th>
        <th>
          <div v-if="!fourn.peutLivrerLe.length && !fourn.dateLivraison" class="dateDeLivraison" style="display:inline-block;width:210px;">
            Aucune date de livraison trouvée
          </div>
          <div v-else-if="disabled" class="dateDeLivraison" style="display:inline-block;width:210px;">
            Date choisie : {{formatHumanDate(fourn.dateLivraison)}}
          </div>
          <div v-else style="display: inline;">
            <ChoixDateLivraison
              :dateSelectionnee="fourn.dateLivraison"
              :datesLivraisonAutorisees="fourn.peutLivrerLe"
              :datesAvertissements="[fourn.premiereUtilisation]"
              :dateMin="lendemain(new Date)"
              :dateMax="veille(fourn.premiereUtilisation)"
              :disabled="disabled"
              :isModal="true"
              @selectionner-date="changementDate(fourn, $event, true)"
              style="display:inline-block;width:210px;" />
          </div>
        </th>
        <th>
          <div class="datepicker-trigger" style="display:inline-block;">
            <b-button :id="'datepicker-trigger-datelivraison-' + fourn.foId" :variant="fourn.dateLivraisonEstManuelle ? 'primary' : 'secondary'" v-tooltip="{ content: fourn.dateLivraisonEstManuelle ? 'Utilisation d\'une date personnalisée' : 'Changer la date de livraison souhaitée', placement: 'top' }">
              Date personnalisée
            </b-button>
            <AirbnbStyleDatepicker :trigger-element-id="'datepicker-trigger-datelivraison-' + fourn.foId" mode="single" :fullscreen-mobile="true" :showShortcutsMenuTrigger="false" :months-to-show="1" :date-one="fourn.dateLivraison" :customized-dates="standardDates(fourn)" @opened="fourn.dpCustomOpen = true" @closed="onCloseDP(fourn.dpCustomOpen)" @date-one-selected="changementDate(fourn, $event)" />
          </div>
          
          <!--<div v-if="fourn.datesLivraison.length > 0" class="datepicker-trigger" style="display:inline-block;margin-left:10px;">
            <b-button :id="'datepicker-trigger-datelivraison-perso-' + fourn.foId" variant="secondary" v-tooltip="{ content: 'Ajouter une date personnalisée', placement: 'top' }" style="padding: 0.375rem 0.85rem;font-weight: bold;">
              +
            </b-button>
            <AirbnbStyleDatepicker :trigger-element-id="'datepicker-trigger-datelivraison-perso-' + fourn.foId" mode="single" :fullscreen-mobile="true" :showShortcutsMenuTrigger="false" :months-to-show="1" :date-one="fourn.datesLivraison[0]" :customized-dates="standardDates(fourn)" @opened="fourn.dpAddOpen = true" @closed="onCloseDP(fourn.dpAddOpen)" @date-one-selected="ajoutDate(fourn, $event)" />
          </div>

          <b-button v-if="fourn.datesLivraison.length > 1" variant="danger" v-tooltip="{ content: 'Vider les dates de livraison', placement: 'top' }" style="padding: 0.375rem 0.85rem;font-weight: bold;margin-left: 10px;" @click="viderDates(fourn)">
            ×
          </b-button>-->
        </th>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { fournisseurs } from "@/mixins/fournisseurs"
import { connecteData } from "@/mixins/connecteData"
import { showAlerts } from "@/mixins/alerts"
import { formatageDate } from '@/mixins/dateFormats'
import ChoixDateLivraison from '@/components/Divers/ChoixDateLivraison'

export default {
  name: "DatesLivraisonParFournisseur",
  mixins: [fournisseurs, connecteData, showAlerts, formatageDate],
  components: {
    ChoixDateLivraison
  },
  props: {
    fournisseurs: { type: Array },
    besoins: { type: Array },
    disabled: { type: Boolean }
  },
  data() {
    return {
      cleRerender: 0,
    }
  },
  computed: {
    besoinsActuels() {
      return this.besoins;
    }
  },
  methods: {
    changementDate(fournisseur, dateLivraisonChoisie, external) {
      console.log("changementDate", dateLivraisonChoisie)
      if ((fournisseur.dpCustomOpen || external) && this.formatDateAvecTirets(fournisseur.dateLivraison) !== this.formatDateAvecTirets(dateLivraisonChoisie)) {
        fournisseur.dateLivraison = this.formatDateAvecTirets(dateLivraisonChoisie)
        fournisseur.dateLivraisonEstManuelle = fournisseur.peutLivrerLe.filter(d => this.formatDateAvecTirets(d) === this.formatDateAvecTirets(dateLivraisonChoisie)).length === 0
        this.cleRerender += 1
        this.$emit("date-modifiee")
      }
    },
    /*ajoutDate(fournisseur, dateLivraisonChoisie) {
      if (fournisseur.dpAddOpen && dateLivraisonChoisie && !fournisseur.datesLivraison.includes(this.formatDateAvecTirets(dateLivraisonChoisie))) {
        console.log("ajoutDate", this.formatDateAvecTirets(dateLivraisonChoisie), fournisseur.datesLivraison)
        fournisseur.datesLivraison.push(this.formatDateAvecTirets(dateLivraisonChoisie))
        fournisseur.datesLivraison = fournisseur.datesLivraison.sort((a, b) => a < b ? -1 : 1)
        fournisseur.dateLivraisonEstManuelle = fournisseur.peutLivrerLe.filter(d => this.formatDateAvecTirets(d) === this.formatDateAvecTirets(dateLivraisonChoisie)).length === 0
        this.cleRerender += 1
      }
    },
    viderDates(fournisseur) {
      this.dateParDefaut(fournisseur)
      this.cleRerender += 1
    },*/
    onCloseDP(ref) {
      setTimeout(() => ref = false, 10)
    },
    standardDates(fourn) {
      var dates = []
      var start = new Date()
      var end = new Date()
      end.setDate(end.getDate() + 365)
      for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
        if ((d.getDay() === 0 && fourn.Jours.includes("Di"))
        || (d.getDay() === 1 && fourn.Jours.includes("Lu"))
        || (d.getDay() === 2 && fourn.Jours.includes("Ma"))
        || (d.getDay() === 3 && fourn.Jours.includes("Me"))
        || (d.getDay() === 4 && fourn.Jours.includes("Je"))
        || (d.getDay() === 5 && fourn.Jours.includes("Ve"))
        || (d.getDay() === 6 && fourn.Jours.includes("Sa"))) {
          dates.push(this.formatDateAvecTirets(d))
        }
      }
      return [{cssClass: "standard", dates: dates}]
    },
    veille(date) {
      let d = new Date(date)
      d.setDate(d.getDate() - 1)
      return d
    },
    lendemain(date) {
      let d = new Date(date)
      d.setDate(d.getDate() + 1)
      return d
    },
    dateParDefaut(fourn) {
      if (fourn.peutLivrerLe.length > 0) {
        fourn.dateLivraison = fourn.peutLivrerLe[fourn.peutLivrerLe.length - 1]
      } else {
        fourn.dateLivraison = ""
      }
    }
  },
  created() {
    this.fournisseurs.forEach(f => {
      var besoins = this.besoinsActuels.filter(x => x.fournisseurId == f.foId)
      var utilisations = besoins.flatMap(x => x.utilisations)
      f.totalCommande = besoins.reduce((a, b) => a + b.prixTotal, 0)
      f.premiereUtilisation = utilisations.reduce((precedent, courant) => precedent.dateUsage < courant.dateUsage ? precedent : courant).dateUsage

      this.affecterJourLivraisonEtDateDeLivraison(f, this.etabCourant.infoFournisseurs, new Date(f.premiereUtilisation))
      f.peutLivrerLe = f.peutLivrerLe.map(date => this.formatDateAvecTirets(date))
      this.dateParDefaut(f)
    })
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/couleurs.scss";
.dateLivraison {
  width: 100%;
  border-style: inset;
  text-align: center;
}
.dateSelectionnee {
  transform: scale(1, 1.01);
  background: $gestion-dark;
  color: white;
}
</style>

<style>
.asd__day--standard {
  background: lightgray;
  color: white;
}
</style>