import { render, staticRenderFns } from "./WidgetMenus.vue?vue&type=template&id=cb85dbe6&scoped=true&"
import script from "./WidgetMenus.vue?vue&type=script&lang=js&"
export * from "./WidgetMenus.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb85dbe6",
  null
  
)

export default component.exports