import Vue from 'vue'
import { produitsUtilisateurCourant } from '@/api/gpao/produits'

const fournisseurs = {
  namespaced: true,
  state: {
    produitsUtilisateur: []
  },
  actions: {
    async produitsUtilisateurCourant({ commit }) {
      return produitsUtilisateurCourant()
      .then(function (response) {
        commit('setItems', {key: 'produitsUtilisateur', data: response.data})
        return response
      })
      .catch(function (error) {
        console.log(error)
      })
    }
  },
  getters: {
    produitsFamille: (state) => (familleId) => {
      return state.familles.find(item => item.id === familleId)
    },
    getProduit: state => id => {
      return state.produitsUtilisateur.find(p => p.id === id)
    },
    getProduits: state => {
      return state.produitsUtilisateur
    }
  },
  mutations: {
    setItems(state, items) {
      Vue.set(state, items.key, items["data"])
    }
  }
}

export default fournisseurs
