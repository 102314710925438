<template>
  <div id="recette-container">
    <b-form @submit.prevent class="container-fluid">
      <div class="row" v-if="ready">
        <!-- Navigation -->
        <div class="col-6 flex-center-space-between push-bottom-1rem">
          <div>
            <router-link class="btn btn-primary" :to="this.$route.params.retour ? this.$route.params.retour : {name: 'Recettes', params :{etablissementId:etabCourantId}}">RETOUR À LA LISTE</router-link>
          </div>
          <div v-if="!enAjout" v-tooltip="{ content: 'Exporter la recette', placement: 'top' }" class="push-left-2rem" :disabled="enExportation">
            <i class="fas fa-file-export cursor-pointer" :class="{ 'fa-spin ' : enExportation }" style="font-size: 1.6rem;margin-right: 1rem;" @click="exporter()"></i>
          </div>
          <div v-if="!enAjout && isAdmin" v-tooltip="{ content: 'Exporter la recette en JSON', placement: 'top' }" class="push-left-2rem" :disabled="enExportation">
            <i class="fas fa-file-export cursor-pointer" style="font-size: 1.6rem;margin-right: 1rem;" @click="exporterJSON()"></i>
          </div>
          <!--    <div v-else class="push-left-2rem">
             <div v-if="!enAjout" v-tooltip="{ content: 'Exporter la recette', placement: 'top' }" class="push-left-2rem">
            <i class="fas fa-file-export cursor-pointer" style="font-size: 1.6rem;margin-right: 1rem;" @click="exporterJson()"></i>
          </div>
            <input type="file" id="selectedFile" :ref="'fichierJson'" style="visibility: hidden;" @change="uploadJson()" />
            <i class="fas fa-file-import cursor-pointer" :class="{ 'fa-spin ' : enExportation }"
            style="font-size: 1.6rem;margin-right: 1rem;" v-tooltip="{ content: 'Importer une recette', placement: 'top' }" onclick="document.getElementById('selectedFile').click();"></i>
          </div> -->
        </div>
        <div class="col-6 flex-center-flex-end push-bottom-1rem" v-if="!spinnerShown">
          <div class="switch-container" v-if="isAdmin">
            <div>
              <!--<i v-tooltip="{ content: detailsAffectation('user') }" class="fas fa-user cursor-pointer" style="font-size:1.8rem" id="rdbUser" @click="affecterRecette('user')" :class="typeRecette === 'user' ? 'selected' : ''"></i>-->
              <i v-tooltip="{ content: detailsAffectation('etab') }" class="far fa-building cursor-pointer" style="margin-left:3rem;font-size:1.8rem" id="rdbEtab" @click="affecterRecette('etab')" :class="typeRecette === 'etab' ? 'selected' : ''"></i>
            </div>
            <div>
              <i v-tooltip="{ content: detailsAffectation('univ') }" class="fas fa-globe cursor-pointer" style="margin-left: 3rem;font-size: 2rem;display: flex;" id="rdbUniv" @click="affecterRecette('univ')" :class="typeRecette === 'univ' ? 'selected' : ''"></i>
            </div>
          </div>
          <b-button class="btn btn-success push-left-2rem" @click="enregistrer()" :disabled="!estRecetteValide || (!donneesModifiees && recette && (!recette.recetteOrigineId || recette.estDeclinaisonPersonnalisee))" alt="Enregistrer">ENREGISTRER</b-button>
          <b-button v-if="recette.recetteOrigineId" class="btn btn-danger push-left-2rem" @click="reinitialiser()" alt="Enregistrer">REINITIALISER</b-button>
          <!--<label v-if="!enAjout && etablissementsCourants && etablissementsCourants.length > 1" class="label-checkbox" style="margin-left:10px;" v-tooltip="{ content: 'Appliquer les modifications sur tous mes établissements', placement: 'bottom' }"><input type="checkbox" v-model="appliquerTousEtablissements"> Tous</label>-->
        </div>

        <!-- Colonne de gauche -->
        <div class="col-12 col-xxl-6">
          <!-- Card Création de Recettes -->
          <div class="card">
            <div class="card-header bg-gradient-bleu text-white flex-center-space-between" style="padding: 0 1.25rem;min-height:40px">
              <h4 class="card-title">{{recette.nom}}</h4>
            </div>
            <div class="card-content" aria-expanded="true">
              <div class="card-body">
                <div class="form-group row">
                  <div class="col-12 col-md-6">
                    <input type="text" v-model.trim="recette.nom" :readonly="recette.lectureSeule" @change="copyNom()" placeholder="Titre interne" style="width:100%">
                    <div class="error" v-if="!$v.recette.nom.required  && !spinnerShown">Ce champ est requis</div>
                  </div>
                  <div class="col-12 col-md-6">
                    <input type="text" v-model="recette.nomCommercial" :readonly="recette.lectureSeule" placeholder="Titre public" style="width:100%">
                  </div>
                </div>
                <div class="form-group row" style="margin-bottom: 0 !important">
                  <div class="col-12 col-md-6">
                    <div class="form-group row">
                      <div class="col-12 col-md-2" style="white-space:nowrap;text-align:left;padding:.3rem 0 0 1.2rem;">
                        Pour
                      </div>
                      <div class="col-12 col-md-5">
                        <span class="k-widget k-maskedtextbox k-textbox">
                          <input v-if="!recette.lectureSeule" type="number" v-model.trim="recette.pourNombre" class="k-textbox" aria-describedby="nombre" placeholder="Nombre" step="1" lang="fr" min="0" />
                          <span v-else>{{recette.pourNombre}}</span>
                        </span>
                        <div class="error" v-if="inputForPersonsIntegerRequired  && !spinnerShown">
                          Les personnes doivent être des nombres entiers
                        </div>
                        <div class="error" v-if="!$v.recette.pourNombre.required && !spinnerShown">
                          Ce champ est requis
                        </div>
                      </div>
                      <div class="col-12 col-md-5">
                        <b-form-select v-model.trim="recette.up" :disabled="recette.lectureSeule" :text-field="'text'" :value-field="'value'" :options="optionsUniteDeProductions" @input="checkIfQuantitesParAssietteExists($event)">
                          <template slot="first">
                            <option :value="null" disabled>Unité...</option>
                          </template>
                        </b-form-select>
                        <div class="error" v-if="!$v.recette.up.required  && !spinnerShown">
                          Ce champ est requis
                        </div>
                      </div>
                      <div class="col-12 col-md-12">
                        <div class="form-group row">
                          <div class="col-12 col-md-12" style="margin-top:1.5rem">
                            <b-form-select v-model.trim="recette.zonePreparationId" :disabled="recette.lectureSeule" :text-field="'text'" :value-field="'value'" :options="zonesPreparations">
                              <template slot="first">
                                <option :value="null" disabled>Zone de préparation...</option>
                              </template>
                            </b-form-select>
                            <div class="error" v-if="!recette.zonePreparationId && !spinnerShown">
                              Ce champ est requis
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group row" v-if="qteTypeforPersons">
                      <div class="col-12 col-md-12">
                        <b-form-select v-model.trim="recette.conviveTypeId" :disabled="recette.lectureSeule" :text-field="'text'" :value-field="'value'" :options="optionsConviveType" @input="addQuantitesParAssietteRow($event)">
                          <template slot="first">
                            <option :value="null" disabled>Convive Type...</option>
                          </template>
                        </b-form-select>
                        <div class="error" v-if="!recette.conviveTypeId && !spinnerShown">
                          Ce champ est requis
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-12 col-md-12">
                        <b-form-select v-model.trim="recette.categorieRecetteId" :disabled="recette.lectureSeule" :text-field="'text'" :value-field="'value'" :options="categorieRecettes">
                          <template slot="first">
                            <option :value="null" disabled>Catégorie...</option>
                          </template>
                        </b-form-select>
                        <div class="error" v-if="!recette.categorieRecetteId && !spinnerShown">
                          Ce champ est requis
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Card Ingrédients -->
          <div class="card">
            <div class="card-header bg-gradient-bleu text-white">
              <h4 class="card-title">Ingrédients</h4>
            </div>
            <div class="card-content" aria-expanded="true">
              <div class="card-body">
                <div class="col-xs-12">
                  <div>
                    <b-card no-body>
                      <b-tabs pills card class="tabs-lignes" v-model="tabEnCours">
                        <b-tab v-if="!recette.lectureSeule" id="tabIngredientsMercuriale" title="Ingredients" active>
                          <b-card-text>
                            <RechercheIngredients :liste="ingredients" :ajouterBIO="true" placeHolder="Rechercher un ingrédient..." @selection-ingredient="ajouterLigneIngredient($event, 'ingredientMercuriale')" />
                          </b-card-text>
                        </b-tab>
                        <b-tab v-if="!recette.lectureSeule" id="tabRecettes" title="Recettes">
                          <b-card-text style="display:flex;">
                            <RechercheIngredients ref="rechercheSousRecette" :liste="recettes" placeHolder="Rechercher une recette..." style="width:calc(100% - 240px);" @selection-ingredient="ajouterLigneIngredient($event, 'recette')" />
                            <MultiSelect :value="upSousRecettes" :items="optionsUniteDeProductions" itemText="text" itemValue="value" placeholder="Unités..." emptyText="Toutes les unités ont été ajoutées" style="width:230px;margin-left:10px;" @change="upSousRecettes = $event" @blur="modifUPSousRecettes" />
                          </b-card-text>
                        </b-tab>
                        <b-tab v-if="recette.lignes && recette.lignes.length && estRecetteValide" @click="obtenirBesoinsRecette()">
                          <template slot="title">
                            <i v-tooltip="{content: 'Actualiser le calcul', show: tooltipQuantiteModifie}" :class="quantiteModifie ? 'fas fa-calculator background-red' : 'fas fa-calculator'" style="margin: 0 10px;"></i>
                          </template>
                          <b-card-text>
                            <!-- onglet simulation -->
                            <!--   <div v-if="besoinsRecette.lignesEnErreur.length" class="push-bottom-1rem">
                              <div v-for="(besoin, index) in besoinsRecette.lignesEnErreur" :key="index">
                                <div v-if="besoin.erreur">{{besoin.erreur}}</div>
                              </div>
                            </div> -->
                            <div v-if="enCoursCalculBesoin" style="text-align:center">
                              <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style='height:90px' />
                            </div>
                            <div v-else>
                              <div v-for="(besoin, index) in besoinsRecette.lignesOk" :key="index" class="push-bottom-1rem">
                                <div class="flex-space-between" style="width:100%">
                                  <div style="width:90%;text-align:left;">
                                    <IconeFamilleIngredient :familleId="besoin.familleId" />
                                    <span v-if="besoin.reference">{{ besoin.reference + ' - '}}</span>{{besoin.designation}}<span v-if="besoin.marque"> - {{besoin.marque}}</span> <span v-if="besoin.fournisseurId"> - {{nomFournisseur(besoin.fournisseurId)}}</span>
                                    <br />
                                    <span v-if="besoin.quantiteBesoinEnUC && besoin.uc"> Besoin <b>{{besoin.quantiteBesoinEnUC}} {{besoin.uc}}</b></span>
                                    <span v-if="besoin.quantitePCB && besoin.uc"> - À commander : <b>{{besoin.quantitePCB}} {{besoin.uc}}</b> - {{besoin.quantitePCB * besoin.prixUnitaire |afficherPrix}}
                                      <em v-if="besoin.allergenes && besoin.allergenes.length"> - Allergènes: {{besoin.allergenes.join(", ")}}.</em>
                                      <strong v-if="!besoin.allergenes"> - Allergènes non renseignés</strong>
                                    </span>
                                  </div>
                                  <div style="align-items:center;justify-content:center">
                                    <SubstitutionArticle :idsBloques="[]" :ingredient='besoin' :gestion-favori-uniquement="true" :recette="recette" @sur-fermeture="fermetureSubstitution" />
                                  </div>
                                  <div style="align-items:center;justify-content:center">
                                    <BoutonFicheTechnique v-if="besoin.urlFT" :fournisseurId="besoin.fournisseurId" :reference="besoin.reference" :urlFT="besoin.urlFT" style="display:inline;" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-card-text>
                        </b-tab>
                        <template #tabs-end>
                          <li v-if="recette.lignes && recette.lignes.length && !estRecetteValide" v-tooltip="{content: 'Recette invalide, actualisation impossible', show: tooltipQuantiteModifie}" role="presentation" class="fake-nav-item">
                            <i :class="quantiteModifie ? 'fas fa-calculator strike background-red' : 'fas fa-calculator strike'" style="margin: 0 10px;"></i>
                          </li>
                        </template>
                      </b-tabs>
                    </b-card>
                  </div>
                  <div class="table-responsive">
                    <table class="table">
                      <draggable v-if="recette.lignes && recette.lignes.length" :disabled="recette.lectureSeule" v-model="compRecetteLignes" ordre=".ordre" tag="tbody" :key="recette.lignes.length" @end="choixFamilleKey +=1">
                        <tr v-for="(ligne, index) in compRecetteLignes" :key="index" style="cursor: grab">
                          <td>
                            <dropDownChoixFamille :familleSelectionnee="ligne.famille" :familles="(recette.lectureSeule && []) || (ligne.familleId && famillesPour(ligne)) || []" :ingredient="ligne" @select-famille="setFamille($event, ligne)" :key="choixFamilleKey" />
                          </td>
                          <td>
                            <div v-if="ligne.composant && ligne.composant.produits === undefined && !ligne.familleId">
                              <!--- affichage recette avec popup info -->
                              <RecettePopUpIngredients :recetteId="ligne.composant.id" :recetteNom="ligne.composant.nom" />
                            </div>
                            <div v-else :class="{warningText: estSansCotation(ligne)}"> {{ligne.composant && ligne.composant.nom}} </div>
                          </td>
                          <td class="flex-center" style="padding-top: 16px !important;">
                            <div style="width:70px">
                              <InputNumber v-if="!recette.lectureSeule" :id="ligne.id" :key="ligne.id" v-model="ligne.quantite" :readonly="recette.lectureSeule" typeFiltre="quantite" :ref="'field-'+index" @input="ingredientQuantite(index, ligne)" />
                              <span v-else>{{ligne.quantite}}</span>
                            </div>
                            <div v-if="ligne.composant && ligne.composant.produits && ligne.composant.produits.length && ligne.UPs && ligne.UPs.length > 1 && !recette.lectureSeule" style="display:flex;align-items:center">
                              <b-form-select v-model.trim="ligne.up" style="width: 70px !important;margin-left:1rem" :options="ligne.UPs" @input="setUniteDeProduction($event, ligne)">
                              </b-form-select>
                            </div>
                            <div v-else-if="ligne.composant && ligne.composant.produits && ligne.composant.produits.length && ligne.cotationManquante && !recette.lectureSeule" style="display:flex;align-items:center">
                              <b-form-select v-model.trim="ligne.up" style="width: 70px !important;margin-left:1rem" :options="optionsUp" @input="setUniteDeProduction($event, ligne)">
                              </b-form-select>
                            </div>
                            <div v-else class="ligne-up-unique">
                              {{ligne.up}}
                            </div>
                            <div v-if="gramPossiblePourLigne(ligne) && gramPossiblePourLigne(ligne).length > 1 && ligne.up === 'PCE' " style="display:flex;align-items:center">
                              <b-form-select v-model.trim="ligne.grammage" :disabled="recette.lectureSeule" style="margin-left:1rem;min-width: 45px;" :options="gramPossiblePourLigne(ligne)" class="ingredients-liste" @input="setGram($event, ligne)">
                              </b-form-select>
                              <span style="margin-left:10px;">g</span>
                            </div>

                            <div v-if="ligne.up === 'PCE' && ligne.familleId && ligne.typeConviveId" style="margin-left:10px;">pour</div>
                            <div v-if="ligne.up === 'PCE' && ligne.familleId && ligne.typeConviveId">
                              <b-form-select v-model.trim="ligne.typeConviveId" :disabled="recette.lectureSeule" style="max-width:126px;margin-left:1rem;" :text-field="'text'" :value-field="'value'" :options="optionsConviveType" @input="setConvive($event, ligne)">
                                <template slot="first">
                                  <option :value="null" disabled>Convives...</option>
                                </template>
                              </b-form-select>
                            </div>
                          </td>
                          <td v-if="ligne.estEnCalculPrix">
                            <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style='height:45px' />
                          </td>
                          <td v-else>
                            <div class="nowrap" v-if="ligne.quantite && ligne.famille && ligne.composant && ligne.composant.estPerso === undefined" v-tooltip="{ content: detailsFournisseurs(ligne.details,ligne.erreurs),                              classes: 'tooltip-details-fournisseurs' }">
                              <span v-if="ligne.prixUnitaire" style="margin-left:20px;margin-right:20px">
                                {{affichePrixIngredient(ligne, ligne.prixUnitaire, ligne.quantite)}}

                                <i v-if="ligne.erreurs" class="fas fa-exclamation"></i>

                              </span>
                            </div>
                            <div v-if="ligne.composant && !ligne.famille">
                              <span v-if="ligne.prixUnitaire" style="margin-left:20px;margin-right:20px">
                                {{affichePrixIngredient(ligne, ligne.prixUnitaire, ligne.quantite)}}
                              </span>
                            </div>
                            <div v-if="estSansCotation(ligne)" :class="{warningText: estSansCotation(ligne)}" v-tooltip="{ content: detailsFournisseurs(ligne.details,ligne.erreurs),                              classes: 'tooltip-details-fournisseurs' }">
                              Indisponible
                            </div>

                            <div v-if="ligne.nonCommandable" style="margin-left: 20px;">
                              <!-- cas de l'eau du robinet -->
                            </div>

                          </td>
                          <td v-if="!isAdmin">
                            <ToggleButton v-if="!ligne.nonCommandable && !(ligne.composant && ligne.composant.produits === undefined && !ligne.familleId)" v-model="ligne.estACommander" :labels="{checked: 'Commander', unchecked: 'Ne pas commander'}" :color="{checked: '#025DBF', unchecked: '#2c3e50'}" :height="30" :width="170" v-tooltip="{content: (ligne.estACommander ? 'P' : 'Ne pas p') + 'rendre en compte cette ligne pour le calcul des besoins lors la génération de commandes', placement: 'bottom'}" />
                          </td>
                          <td>
                            <button type="button" v-if="!recette.lectureSeule" class="close" aria-label="Close" @click="supprimerRecetteLigne(index)">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </td>
                          <td>
                            <div v-show="ligne.up === 'PCE' && !recette.lectureSeule">
                              <i class="fas fa-copy" @click="dupliquerLigneIngredient(ligne)"></i>
                            </div>
                          </td>
                          <td>
                            <div>
                              <BoutonFicheTechnique v-if="ligne.details && ligne.details.length && ligne.details[0].urlFT" :fournisseurId="ligne.details[0].fournisseurId" :reference="ligne.details[0].reference" :urlFT="ligne.details[0].urlFT" style="display:inline;" />
                            </div>
                          </td>
                        </tr>
                      </draggable>
                      <tr scope="row" v-if="recette.lignes && !!recette.lignes.length">
                        <td colspan="12" class="total-price bg-gradient-bleu">
                          <div class="flex-center-space-between">
                            <div>{{totalParPersonne}}</div>
                            <div>TOTAL {{ setTotal(recette.lignes) }}</div>
                          </div>
                        </td>
                      </tr>
                    </table>
                    <div class="error" v-if="recette.lignes && recette.lignes.length < 1">Au moins un ingrédient est nécessaire</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Card Convives -->
          <div class="card">
            <div class="card-header bg-gradient-bleu text-white flex-center-space-between">
              <h4 class="card-title">Quantités par assiette</h4>
              <h6>(Denrée principale sans sauce, sans garniture)</h6>
              <button class="btn btn-primary" v-if="!recette.lectureSeule"><i v-tooltip="{ content: 'Effacer les données des quantités par assiette' }" @click="effacerQuantitesParAssiette()" class="fas fa-eraser cursor-pointer" style="font-size: 1.4rem;"></i>
              </button>
            </div>
            <div class="card-content" aria-expanded="true">
              <div class="card-body">
                <div class="col-xs-12" :key="keyQuantites" :style="quantitesParAssietteValides ? '' : 'border:1px solid red;padding:1px;'">
                  <b-form-select v-if="!recette.lectureSeule" :disabled="optionsConvivesDisponibles.length === 0" value="" :options="optionsConvivesDisponibles" value-field="value" text-field="text" @change="addQuantitesParAssietteRow($event, true)" class="form-control" style="font-size:14px;font-weight:400;color:rgb(44, 62, 80)!important;">
                    <template #first>
                      <b-form-select-option v-if="optionsConvivesDisponibles.length > 0" value="" disabled>Ajouter un type de convive...</b-form-select-option>
                      <b-form-select-option v-else value="" disabled>Tous les types de convive sont utilisés</b-form-select-option>
                    </template>
                  </b-form-select>
                  <div v-if="recette.up && !qteTypeforPersons" class="badge badge-warning">Cette recette étant définie par {{recette.up}}, les quantités de ce tableau ne seront pas utilisées pour les calculs de production</div>
                  <div v-else-if="toutesLignesParPiece" class="badge badge-warning">Cette recette étant déclinée par convive, les quantités de ce tableau ne seront pas utilisées pour les calculs de production</div>
                  <table class="table-grammages table-header-rotated" style="table-layout:fixed;width:100%;">
                    <thead>
                      <tr>
                        <th class="rotate" v-for="service in servicesHeadings" :key="service.value">
                          <div>{{ service }}</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(convive, indexConvive) in quantitesParAssietteAAfficher" :key="indexConvive">
                        <td class="text-smaller">
                          {{ convive.typeConviveNom }}
                        </td>
                        <td v-for="(service, indexService) in convive.services" :key="indexService" :style="recette.lectureSeule ? 'background-color:#efefef;' : ''">
                          <div class="input-group">
                            <span v-if="service.quantite && !recette.lectureSeule">g</span>
                            <InputNumber v-if="!recette.lectureSeule" v-model="service.quantite" :id="'service' + service.serviceId + '-' + convive.typeConviveId" typeFiltre="quantite" :class="'form-control ' + (service.serviceId === convive.base100 ? 'border-base100' : 'border-classique')" @input="setServiceQuantite(Number($event), convive.typeConviveId, service.serviceId)" @move="moveGrammage" />
                            <div v-else style="font-size:11px;">{{service.quantite ? service.quantite + ' g' : ''}}</div>
                          </div>
                          <div class="price">
                            {{ prixParAssiette(service.quantite) }}
                          </div>
                        </td>
                        <td v-if="!recette.lectureSeule">
                          <button type="button" v-if="!recette.lectureSeule && recette.conviveTypeId !== convive.typeConviveId" class="close" title="Supprimer cette ligne" aria-label="Close" @click='removeQuantitesParAssietteRow(convive.typeConviveId)'>
                            <div aria-hidden="true">&times;</div>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-if="!quantitesParAssietteValides" style="color:red;">
                    Veuillez renseigner au moins une quantité par assiette
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Colonne de droite -->
        <div class="col-12 col-xxl-6">
          <!-- Card Marche à suivre -->
          <div class="card">
            <div class="card-header bg-gradient-bleu text-white">
              <h4 class="card-title">Marche à suivre</h4>
            </div>
            <div class="card-content" aria-expanded="true">
              <div class="card-body">
                <div class="col-xs-12">
                  <div class="text-left push-top-1rem"></div>
                  <vue-editor v-if="!recette.lectureSeule" v-model="recette.preparation" :editorToolbar="customToolbar"></vue-editor>
                  <div v-else v-html="recette.preparation"></div>
                  <hr>
                  <ToggleButton :disabled="recette.lectureSeule" v-model="recette.horsBarquettage" :labels="{checked: 'Hors barquettage',unchecked:'En barquette'}" :width=150 title="Cette recette est elle mise en barquette ?" :key="this.recette.id"></ToggleButton>
                </div>
              </div>
            </div>
          </div>
          <!-- Card Compatibilités -->
          <div class="card">
            <div class="card-header bg-gradient-bleu text-white">
              <h4 class="card-title">Compatibilités</h4>
            </div>
            <div class="card-content" aria-expanded="true">
              <div class="card-body">
                <div class="col-xs-12">
                  <label style="font-size:10px!important;color:rgba(0,0,0,.54);margin-bottom:2px;">Textures incompatibles</label>
                  <MultiSelect :disabled="recette.lectureSeule" :value="recette.textureIds" :items="texturesPourExclusion" placeholder="Textures..." emptyText="Toutes les textures ont été ajoutées" @change="recette.textureIds = $event" />

                  <label style="font-size:10px!important;color:rgba(0,0,0,.54);margin-bottom:2px;">Régimes alimentaires incompatibles</label>
                  <MultiSelect :disabled="recette.lectureSeule" :value="recette.regimeIds" :items="regimesPourExclusion" placeholder="Régimes alimentaires..." emptyText="Tous les régimes ont été ajoutés" @change="recette.regimeIds = $event" />

                  <label style="font-size:10px!important;color:rgba(0,0,0,.54);margin-bottom:2px;">Composantes de repas</label>
                  <MultiSelect :disabled="recette.lectureSeule" :value="recette.composanteMenuIds" :items="composantesRepas" placeholder="Composantes..." emptyText="Toutes les composantes ont été ajoutées" @change="recette.composanteMenuIds = $event" />

                  <label style="font-size:10px!important;color:rgba(0,0,0,.54);margin-bottom:2px;">Catégories GEMRCN</label>
                  <MultiSelect :disabled="recette.lectureSeule" :value="recette.gemrcnIds" :items="gemrcn" placeholder="Catégories GEMRCN..." emptyText="Toutes les catégories ont été ajoutées" @change="recette.gemrcnIds = $event" />

                  <span><i>Allergènes : {{texteAllergenes}}</i></span>
                  <div class="error" v-if="recette.saisieAllergenesIncomplete">Attention, la liste des allergènes est peut-être incomplète car ils n'ont pas été renseignés pour un ou plusieurs ingrédients</div>
                  <div style="padding-top:15px;font-size:0.8em">
                    <em>L’affichage des allergènes est effectué à titre informatif. Chaque utilisateur du logiciel doit vérifier par lui-même la véracité de ces informations auprès du ou des fournisseurs de denrées alimentaires concernés.<br />
                      En cas d’erreur, l’utilisateur ne pourra pas engager la responsabilité de la société Agap’pro, éditrice du logiciel.</em>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Card Utilisations -->
          <div class="card" v-if="listeDesUtilisations.length">
            <div class="card-header bg-gradient-bleu text-white">
              <h4 class="card-title">Utilisations</h4>
            </div>
            <div class="card-content" aria-expanded="true">
              <div class="card-body">
                <div class="col-xs-12">
                  Cette recette est utilisée comme ingrédient dans :
                  <VueFuse style="padding-left:-10px;position:absolute;width:98%;"
                    :placeholder="compTermeDeRechercheUtilisation"
                    :minMatchCharLength="3"
                    :threshold="0.1"
                    :list="listeDesUtilisations"
                    :keys="['nom']"
                    :search="termeDeRechercheUtilisation"
                    @fuse-input-changed="getTermeDeRechercheUtilisation"
                    @fuse-results-updated="retourRecherche"
                    @keyup.enter="currentItem = 0" />
                  <div style="text-align:right;position:relative;top:15px;">
                    <span v-if="termeDeRechercheUtilisation" v-tooltip="{ content: 'Effacer la recherche'}" @click="effacerLaRecherche()" class="fas fa-window-close ml-2"></span>
                  </div>
                  <div class="mt-2 usages" style="margin-top:65px!important;" :key="keyUtilisations">
                    <div v-for="(recetteInList, i) in filteredUtilisationList" class="card-list mr-2" :key="i" @click.stop="selectionRecette(recetteInList.id)" style="margin-bottom:10px;">
                      <RecettePopUpIngredients :estLien="true" :recetteId="recetteInList.id" :recetteNom="recetteInList.nom" style="line-height:20px;" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> <!-- fin de la row -->
    </b-form>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor"
import { required } from "vuelidate/lib/validators"
import { constantesId } from "@/helpers/constantes"
import _ from "lodash"
import "array.prototype.move"
import { chargerParFiltre, obtenirPourEditionDelinee, prixUnitaire, ajouterRecette, modifierRecette, modifierRecetteTousEtabs, obtenirBesoins, obtenirSurRecettes, reinitialiserRecette } from "@/api/gpao/recettes"
import { exporterRecette } from '@/api/gpao/documents'
import { nomFamille, estRecetteInvalide, recetteLignesFromData, quantitesParAssietteFromData, recette, verifierAutorisations } from "@/mixins/recette"
import { alerteDonneesModifiees, arraysDifference } from "@/mixins/index"
import { showAlerts } from "@/mixins/alerts"
import { connecteData } from "@/mixins/connecteData"
import { lancerTelechargementDeResponse, lancerTelechargement, arrondiMercuriale } from "@/helpers/utils"
import dropDownChoixFamille from "@/components/Recettes/dropDownChoixFamille"
import draggable from "vuedraggable"
import InputNumber from "@/components/Inputs/InputNumber"
import MultiSelect from "@/components/Inputs/MultiSelect"
import RechercheIngredients from "@/components/Recettes/RechercheIngredients"
import RecettePopUpIngredients from "@/components/Recettes/RecettePopUpIngredients"
import IconeFamilleIngredient from "@/components/Divers/IconeFamilleIngredient"
import BoutonFicheTechnique from "@/components/Divers/BoutonFicheTechnique"
import { mapGetters, mapActions } from "vuex"
import SubstitutionArticle from '@/components/Divers/SubstitutionArticle'
import VueFuse from "@/components/Divers/VueFuse"

export default {
  components: { VueEditor, dropDownChoixFamille, draggable, InputNumber, MultiSelect, RechercheIngredients, RecettePopUpIngredients, IconeFamilleIngredient, SubstitutionArticle, BoutonFicheTechnique, VueFuse },
  name: "PageRecetteEdition",
  display: "Table",
  mixins: [nomFamille, estRecetteInvalide, recetteLignesFromData, recette, quantitesParAssietteFromData, showAlerts, alerteDonneesModifiees, arraysDifference, connecteData, verifierAutorisations],
  data() {
    return {
      enExportation: false,
      tabEnCours: 0,
      recettes: [],
      recette: {
        nom: "",
        pourNombre: null,
        up: null,
        conviveTypeId: null,
        zonePreparationId: null,
        categorieRecetteId: null,
        quantitesParAssiette: [],
        lignes: [],
        textureIds: [],
        horsBarquettage: false,
      },
      listeDesUtilisations: [],
      enCoursCalculBesoin: false,
      ready: false,
      recetteDataApi: {},
      choixFamilleKey: 1,
      quantiteModifie: false,
      tooltipQuantiteModifie: false,
      typeRecette: "",
      optionsUp: ["KG", "L", "PCE"],
      customToolbar: [
        [{ size: ["small", false, "large"] }],
        [{ color: [] }, { background: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }]
      ],
      servicesHeadings: this.$store.state.definitions.services
        .map(item => item.text)
        .reverse()
        .concat("Convives")
        .reverse(),
      ingredient: "",
      besoinsRecette: {
        //lignesEnErreur: [],
        lignesOk: []
      },
      ingredientsEtRecettes: [],
      quantitesParAssiette: [],
      keyQuantites: 1,
      types: [],
      optionsUniteDeProductions: [
        { value: "Personnes", text: "Personnes" },
        { value: "L", text: "L" },
        { value: "KG", text: "KG" }
      ],
      total: 0,
      totalParPersonne: null,
      missingIngredientsCount: 0,
      ligneId: 1,
      sousRecette: null,
      apercuRecetteLignes: false,
      termeDeRechercheUtilisation: "",
      keyUtilisations: 1,
      quantitesParAssietteValides: true,
      upSousRecettes: ["KG", "L"],
      appliquerTousEtablissements: false,
      texturesPourExclusion: [],
      regimesPourExclusion: [],
      enEnregistrement: false
    }
  },
  validations: {
    recette: {
      nom: { required },
      pourNombre: { required },
      up: { required },
      conviveTypeId: { required },
      zonePreparationId: { required },
      categorieRecetteId: { required }
    }
  },
  computed: {
    ...mapGetters("uxHelpers", ["spinnerShown"]),
    ...mapGetters("definitions", ["ingredients", "familles", "convivesTypesNonNeutre", "textures", "composantesRepas", "regimes", "gemrcn", "services", "categorieRecettes", "zonesPreparations"]),
    ...mapGetters("fournisseurs", ["fournisseursCourants"]),
    optionsConviveType() { return this.convivesTypesNonNeutre },
    toutesLignesParPiece() {
      if (!this.recette || !this.recette.lignes.filter(x => x.quantite > 0).length) {
        return false
      }
      return !this.recette.lignes.filter(x => x.quantite > 0 && x.up !== "PCE").length
    },
    servicesIdOrdonnees() {
      return [constantesId.servicePetitDejeuner, constantesId.serviceCollationMatinale, constantesId.serviceDejeuner, constantesId.serviceCollationApresMidi, constantesId.serviceGouter, constantesId.serviceDiner, constantesId.serviceCollationSoir]
    },
    enAjout() {
      return !this.$route.params.recetteId && !this.enDuplication
    },
    enDuplication() {
      return this.$route.name == "RecetteDupliquer"
    },
    donneesModifiees() {
      return !_.isEqual(this.donneesInitiales, this.getDataRecette())
    },
    enregistrerDonneesModifiees() {
      this.enregistrer()
      return true
    },
    compRecetteLignes: {
      get() {
        return this.recette.lignes.slice(0).sort(function (a, b) {
          return a.ordre - b.ordre
        })
      },
      set(lignes) {
        this.reordonner(lignes)
      }
    },
    qteTypeforPersons() {
      return this.recette.up === "Personnes"
    },
    inputForPersonsIntegerRequired() {
      if (
        this.recette.up === "Personnes" &&
        this.recette.pourNombre % 1 !== 0
      ) {
        return true
      } else {
        return false
      }
    },
    allergenes() {
      return constantesId.allergenes
    },
    texteAllergenes() {
      if (this.recette.lignes.length === 0)
        return "Aucun allergène présent"
      let a = this.recette.lignes.filter(x => x.allergenes && x.allergenes.length !== 0).map(x => x.allergenes).toString()
      if (a.length === 0)
        return "Aucun allergène présent"
      return a.split(",").filter((v, i, a) => a.indexOf(v) === i).map(x => this.nomAllergene(x)).join(", ")
    },
    optionsConvivesDisponibles() {
      var options = []
      this.optionsConviveType.forEach(function (o) {
        if (this.quantitesParAssiette.find(s => s.typeConviveId === o.value && !s.afficher)) {
          options.push(o)
        }
      }.bind(this))
      return options
    },
    compTermeDeRechercheUtilisation() {
      if (this.termeDeRechercheUtilisation) {
        return this.termeDeRechercheUtilisation + "..."
      } else {
        return (
          "Rechercher dans " +
          this.listeDesUtilisations.length +
          (this.listeDesUtilisations.length === 0 || this.listeDesUtilisations.length === 1
            ? " recette..."
            : " recettes...")
        )
      }
    },
    quantitesParAssietteAAfficher() {
      var quantites = []
      this.quantitesParAssiette.forEach(q => {
        if (q.afficher) {
          quantites.push(q)
        }
      })
      return quantites
    },
    grammageBase100() {
      var convive = this.quantitesParAssiette.find(c => c.base100)
      var service = convive && convive.services.find(s => s.serviceId === convive.base100)
      return service && service.quantite
    },
    estRecetteValide() {
      return !this.estRecetteInvalide && this.quantitesParAssietteValides
    }
  },
  methods: {
    ...mapActions("uxHelpers", ["afficherAttente", "cacherAttente"]),
    getDataRecette() {
      var data = _.cloneDeep(this.recette)
      data.lignes.forEach(ligne => {
        delete ligne.details
        delete ligne.UPs
        delete ligne.allergenes
        delete ligne.prixUnitaire
        delete ligne.estEnCalculPrix
        delete ligne.erreurs
        delete ligne.cotationManquante
      })
      return JSON.stringify(data)
    },
    fermetureSubstitution(payload) {
      if (payload.favorisModifies) {
        this.obtenirBesoinsRecette()
        const ligne = this.recette.lignes.find(x => x.composantId == payload.ingredient.ingredientId && x.familleId)
        if (ligne) {
          this.fetchIngredientPrice(ligne.composantId, ligne.familleId, ligne.up, ligne.grammage, ligne.ordre)
        }
      }
    },
    quantitesParAssietteFromDataKeepEmpty(keepEmpty) {
      let lignesAvant = _.clone(this.quantitesParAssiette)//on garde les anciennes lignes de côté...
      this.quantitesParAssietteFromData(this.recette, this.optionsConviveType, this.services)
      if (keepEmpty) {
        lignesAvant.forEach(function (l) {
          if (l.afficher) {
            this.quantitesParAssiette.find(q => q.typeConviveId === l.typeConviveId).afficher = true//... et on rajoute les vides pour affichage
          }
        }.bind(this))
      }
    },
    uploadJson() {
      let data = this.$refs.fichierJson.files[0]

      let reader = new FileReader()
      reader.readAsText(data, "UTF-8")

      reader.onload = evt => {
        let text = evt.target.result
        try {
          this.recette = JSON.parse(text)
        } catch (e) {
          alert("JSON data invalide")
        }
      }

      reader.onerror = evt => {
        console.error(evt)
      }
      // Reconstruire recette.lignes
      this.recette.lignes = this.recetteLignesFromData(this.recette)
      // Reconstruire quantitesParAssiette avec la recette
      this.quantitesParAssietteFromData(this.recette, this.optionsConviveType, this.services)
      // Reconstruire l'affectation, UP et texturesIds
      this.reglerRecette()
    },
    exporterJSON() {
      this.recettePourApi()
      let recette = JSON.stringify(this.recetteDataApi)
      lancerTelechargement(new Blob([recette]), this.recette.nom + ".json")
      this.cacherAttente()
    },
    async exporter() {
      try {
        this.enExportation = true
        const response = await exporterRecette(this.recette.id, this.etabCourantId)
        lancerTelechargementDeResponse(response)
      } finally {
        this.enExportation = false
      }
    },
    effacerQuantitesParAssiette() {
      this.quantitesParAssiette.forEach(c => {
        c.afficher = false
        c.services.forEach(s => {
          s.quantite = 0
        })
      })
      this.recette.quantitesParAssiette = []
      this.addQuantitesParAssietteRow(this.recette.conviveTypeId)
      this.checkQuantitesParAssiette()
    },
    detailsAffectation(type) {
      if (type === "user") {
        let username = this.$store.state.user.userInfo.userName
        return "Affectée à " + username
      }
      if (type === "etab") {
        const etab = this.etablissementsCourants.find(
          et => et.eId === this.etabCourantId
        );
        if (!etab) {
          this.alerteErreur("Etablissement courant non trouvé, nous vous conseillons de vous reconnecter.")
          return "Erreur établissement courant"
        }
        let nomEtablissement = etab.eRS
        return "Affectée à " + nomEtablissement
      }
      if (type === "univ") {
        return "Universelle"
      }
    },
    affecterRecette(type) {
      this.recette.etablissementId = undefined
      this.recette.utilisateurId = undefined
      if (type === "user") {
        this.recette.estUniverselle = false
        this.recette.estPerso = true
        this.recette.utilisateurId = this.userId
      }
      if (type === "etab") {
        this.recette.estUniverselle = false
        this.recette.estPerso = false
        this.recette.etablissementId = this.etabCourantId
      }
      if (type === "univ") {
        this.recette.estUniverselle = true
        this.recette.estPerso = false
      }
      this.typeRecette = this.typeAffectationRecette(this.recette)
    },
    nomRecette(id) {
      return this.recettes.find(el => el.id === id).nom
    },
    nomFournisseur(id) {
      const f = this.fournisseursCourants.find(el => el.id === id);

      return (f) ? f.nom : ''
    },
    nomAllergene(id) {
      return this.allergenes.find(el => el.id === id).valeur
    },
    obtenirBesoinsRecette() {
      this.enCoursCalculBesoin = true;
      let data = _.cloneDeep(this.recette)
      /*       const lignesEnErreur = data.lignes.filter(el => el.erreurs)
            this.besoinsRecette["lignesEnErreur"] = lignesEnErreur.map(el => {
              return { erreur: el.composant.nom + " : " + ((el.erreurs) ? el.erreurs : "non communiqué") }
            })*/


      // on supprime les quantités à 0 et les lignes avec convive type


      _.remove(data.lignes, function (el) { return (el.quantite == 0 || el.conviveTypeId) })

      return obtenirBesoins(this.etabCourantId, data)
        .then(response => {
          var data = response.data.filter(l => !l.nonCommandable)

          data.filter(x => x.erreurs).forEach(x => x.designation = this.recette.lignes.find(el => x.familleId == el.familleId && x.ingredientId == el.composantId).composant.nom
            + ': ' + ((x.erreurs) ? x.erreurs.join(", ") : "non communiqué")
          )
          this.besoinsRecette["lignesOk"] = data
          this.quantiteModifie = false
          this.tooltipQuantiteModifie = false
          this.enCoursCalculBesoin = false
          return
        })

    },
    setConvive(typeConviveId, ligne) {
      let ligneAPI = this.recette.lignes.find(r => r.ordre === ligne.ordre)
      this.$set(ligneAPI, "typeConviveId", typeConviveId)
    },
    setUniteDeProduction(up, ligne) {
      this.checkLigneSansProduits(ligne)
      let ligneAPI = this.recette.lignes.find(r => r.ordre === ligne.ordre)
      this.$set(ligneAPI, "up", up)
      // Remet à jour le grammage si précédemment PCE
      let grammage = this.ingredientsEtRecettes.find(
        el => el.id === ligne.composantId
      )["produits"][0]["prod"][0].gram
      this.$set(ligneAPI, "grammage", grammage)
      this.fetchIngredientPrice(ligne.composantId, ligne.familleId, up, ligne.grammage, ligne.ordre)
      this.checkQuantitesParAssiette()
    },
    detailsFournisseurs(details, erreurs) {
      if (details && details.length) {
        result = details.map(i => {
          return `<ul><li><strong>${i.designationF}</strong><br /> ${i.marque
            ? "<strong>Marque</strong>: " + i.marque + "<br />"
            : ""
            } ${i.reference
              ? "<strong>Réf.</strong>: " + i.reference + "<br />"
              : ""
            } ${i.allergenes && i.allergenes.length
              ? "<strong>Allergènes</strong>: " + i.allergenes.join(", ") + "<br />"
              : ""
            } <i class="fas fa-truck-moving" style="margin-right:.95rem;"></i> ${this.nomFournisseur(i.fournisseurId)
            }<br /> </li></ul>`
        })
        return result;
      }

      let erreur = "AUCUN RÉSULTAT TROUVÉ"
      if (erreurs) {
        erreur = erreurs;
      }

      let result = `<ul><i class="fas fa-truck-moving"></i> ` + erreur + `</ul>`
      return result
    },
    copyNom() {
      if (!this.recette.nomCommercial) {
        this.recette.nomCommercial = this.recette.nom
      }
    },
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty
      }
    },
    fetchRecettePrice(itemId, ordre) {
      let ligne = this.recette.lignes.find(r => r.ordre === ordre)

      prixUnitaire(this.etabCourantId, itemId)
        .then(response => {
          if (ligne && response.data.prixUnitaire) {
            this.$set(ligne, "prixUnitaire", response.data.prixUnitaire)
          }
          if (ligne && response.data.allergenes) {
            this.$set(ligne, "allergenes", response.data.allergenes)
          }
          this.$set(ligne, "cotationManquante", response.data.cotationManquante)
          if (ligne && response.data.details) {
            let details = response.data.details.map(d => {
              return {
                reference: d.reference,
                designationF: d.designationF,
                marque: d.marque,
                fournisseurId: d.fournisseurId
              }
            })
            if (response.data.details.length) {
              let UPs = response.data.details[0].quantites // premier objet par défaut, à voir ensuite
              this.$set(ligne, "UPs", UPs)
            }
            this.$set(ligne, "details", details)
          }
          return
        })
        .catch(error => {
          console.error(error)
        })
    },
    fetchIngredientPrice(itemId, familleId, up, grammage, ordre) {
      let ligne = this.recette.lignes.find(r => r.ordre === ordre)
      this.$set(ligne, "estEnCalculPrix", true)
      return prixUnitaire(this.etabCourantId, this.recette.id, itemId, familleId, up, grammage)
        .then(response => {
          ligne.estEnCalculPrix = false
          this.$set(ligne, "erreurs", undefined)
          this.$set(ligne, "cotationManquante", response.data.cotationManquante)
          if (ligne && response.data.erreurs && response.data.erreurs.length > 0) {
            this.$set(ligne, "details", undefined)
            this.$set(ligne, "UPs", undefined)
            this.$set(ligne, "allergenes", undefined)
            this.$set(ligne, "prixUnitaire", undefined)

          }
          else if (ligne && response.data.prixUnitaire) {
            this.$set(ligne, "prixUnitaire", response.data.prixUnitaire)


            if (ligne && response.data.details.length) {
              let details = response.data.details.map(d => {
                return {
                  reference: d.reference,
                  designationF: d.designationF,
                  marque: d.marque,
                  fournisseurId: d.fournisseurId,
                  urlFT: d.urlFT
                }
              })
              let UPs = Object.keys(response.data.details[0].quantites)
              let allergenes = response.data.details[0].allergenes
              this.$set(ligne, "details", details)
              this.$set(ligne, "UPs", UPs)
              this.$set(ligne, "allergenes", allergenes)
            }
          }
          ligne.estEnCalculPrix = false
          return
        })
    },
    setTotal(array) {
      let hasUndefined = function (element) {
        return element === undefined
      }
      let hasEmpty = function (element) {
        return element === ""
      }
      let hasZero = function (element) {
        return element === 0
      }
      let resultArray = _.cloneDeep(array)
      // remove les recettes (pas de prix unitaire)
      _.remove(resultArray, function (el) {
        return el.prixUnitaire === undefined
      })
      _.remove(resultArray, function (el) {
        return el.prixUnitaire === null
      })
      _.remove(resultArray, function (el) {
        return el.typeConviveId !== undefined
      })

      let up = this.recette.up === "Personnes" ? "PERSONNE" : this.recette.up

      if (
        resultArray.length &&
        !resultArray.map(item => item.quantite).some(hasUndefined) &&
        !resultArray.map(item => item.quantite).some(hasEmpty) &&
        !resultArray.map(item => item.quantite).some(hasZero)
      ) {
        this.total = resultArray
          .map(item => item.prixUnitaire * item.quantite)
          .reduce(function (acc, val) {
            return acc + val
          }, 0)

        if (this.recette.pourNombre && this.recette.up) {
          this.totalParPersonne =
            arrondiMercuriale(this.total / this.recette.pourNombre) +
            " €" +
            " / " +
            up
          return arrondiMercuriale(this.total) + " € "
        }
      } else {
        const countBy = (arr, fn) =>
          arr
            .map(typeof fn === "function" ? fn : val => val[fn])
            .reduce((acc, val) => {
              acc[val] = (acc[val] || 0) + 1
              return acc
            }, {})
        this.total = undefined
        let ingredientQuantiteAtZero = countBy(resultArray, "quantite")[0]
        ingredientQuantiteAtZero === undefined
          ? (ingredientQuantiteAtZero = 0)
          : ingredientQuantiteAtZero
        let ingredientQuantiteUndefined = countBy(resultArray, "quantite")
          .undefined
        ingredientQuantiteUndefined === undefined
          ? (ingredientQuantiteUndefined = 0)
          : ingredientQuantiteUndefined
        let ingredientQuantiteVide = countBy(resultArray, "quantite")[""]
        ingredientQuantiteVide === undefined
          ? (ingredientQuantiteVide = 0)
          : ingredientQuantiteVide
        this.missingIngredientsCount =
          ingredientQuantiteAtZero +
          ingredientQuantiteUndefined +
          ingredientQuantiteVide
        return " ..."
      }
    },
    affichePrixIngredient(ligne, lignePrixUnitaire, itemQte) {
      if (ligne) {
        let prixUnitaire = 0
        if (!this.enAjout) {
          prixUnitaire = ligne.prixUnitaire
        } else {
          prixUnitaire = lignePrixUnitaire
        }
        if (itemQte.toString().includes(",")) {
          itemQte = parseFloat(itemQte.replace(",", "."))
        }
        if (prixUnitaire !== null) {
          return arrondiMercuriale(prixUnitaire * itemQte) + " €"
        } else {
          /*        if (ligne.erreurs)
                   return ligne.erreurs; */
          return "Non Communiqué"
        }
      }
    },
    prixParAssiette(itemValue) {
      if (itemValue) {
        let ratio = itemValue / this.grammageBase100
        if (this.total && this.recette.pourNombre) {
          let price = (this.total / this.recette.pourNombre) * ratio
          if (isNaN(price) || price === Infinity) {
            return ""
          } else {
            return arrondiMercuriale(price) + " €"
          }
        }
      } else {
        return ""
      }
    },
    dupliquerLigneIngredient(ligne) {
      let index = this.recette.lignes.findIndex(el => el.ordre === ligne.ordre)
      let ligneDupliquee = _.cloneDeep(
        this.recette.lignes.find(el => el.ordre === ligne.ordre)
      )
      ligneDupliquee.typeConviveId = -1
      ligneDupliquee.id = 0
      ligneDupliquee.ordre++
      this.recette.lignes.splice(index + 1, 0, ligneDupliquee)
      this.reordonner(this.recette.lignes)
      this.checkQuantitesParAssiette()
    },
    ajouterLigneIngredient(event, type) {
      let lignesVide = !this.recette.lignes.length
      let nouvelleLigne = {}
      if (type === "ingredientMercuriale") {
        nouvelleLigne = _.cloneDeep(event)
        nouvelleLigne.estIngredient = true
      }
      if (type === "recette") {
        nouvelleLigne = this.recettes.find(el => el.id === event.id)
      }
      const nonCommandable = event.nonCommandable && event.nonCommandable === true
      let ligne = {}
      let nouvelleLigneFamilleId = null
      let nouvelleLigneFamille = null
      let nouvelleLigneGrammage = null
      let nouvelleLigneDetails = null
      if (type === "ingredientMercuriale") {
        if (!nouvelleLigne.produits || nouvelleLigne.produits.length == 0) {
          nouvelleLigne.produits = [] // ingrédient sans quotation, on le remplit avec tout
          this.familles.forEach(f => {
            nouvelleLigne.produits.push({ "fam": f.code })
          })
        }
        nouvelleLigneFamilleId = this.familles.find(item => item.code === nouvelleLigne.produits[0].fam).id
        nouvelleLigneFamille = nouvelleLigne.produits[0].fam
        if (!nonCommandable) {
          if (nouvelleLigne["produits"][0].prod) {
            nouvelleLigneGrammage = nouvelleLigne["produits"][0].prod[0].gram
            nouvelleLigneDetails = nouvelleLigne["produits"][0]
          }
        }

        ligne = {
          composant: nouvelleLigne,
          quantite: 0,
          pasOk: nouvelleLigne.pasOk,
          famille: nouvelleLigneFamille,
          composantId: nouvelleLigne.id,
          familleId: nouvelleLigneFamilleId,
          up: nouvelleLigne.up,
          grammage: nouvelleLigneGrammage,
          details: nouvelleLigneDetails,
          typeConviveId: undefined,
          nonCommandable: nonCommandable,
          estACommander: true,
          ordre: 0,
          id: this.ligneId++ * -1
        }
      }
      if (type === "recette") {
        const recetteUP = this.recettes.find(
          el => el.id === nouvelleLigne.id
        ).up
        ligne = {
          composant: nouvelleLigne,
          details: [],
          quantite: 0,
          pasOk: null,
          ordre: 0,
          composantId: nouvelleLigne.id,
          familleId: null,
          up: recetteUP,
          typeConviveId: undefined,
          estACommander: true
        }
      }
      let ligneDejaPresente = this.recette.lignes.find(
        el => el.composantId === ligne.composantId
      )
      if (this.recette.lignes.length && !ligneDejaPresente && !lignesVide) {
        this.recette.lignes.unshift(ligne)
        this.reordonner(this.recette.lignes)
        this.$nextTick(() => {
          if (this.$refs["field-0"]) {
            this.$refs["field-0"][0].$el.focus()
          }
        })
      } else if (this.recette.lignes && ligneDejaPresente && !lignesVide) {
        this.$toast("Ingrédient déjà présent", {
          horizontalPosition: "center",
          verticalPosition: "top",
          className: ["toast-error"],
          duration: 1500
        })
      } else {
        this.recette.lignes.unshift(ligne)
        this.reordonner(this.recette.lignes)
        this.$nextTick(() => {
          if (this.$refs["field-0"]) {
            this.$refs["field-0"][0].$el.focus()
          }
        })
      }
      if (type === "ingredientMercuriale") {
        if (
          nouvelleLigne.produits &&
          nouvelleLigne.produits.length &&
          !nonCommandable
        ) {
          nouvelleLigneFamilleId = this.familles.find(
            item => item.code === nouvelleLigne.produits[0].fam
          ).id
          this.fetchIngredientPrice(ligne.composantId, nouvelleLigneFamilleId, ligne.up, ligne.grammage, ligne.ordre)
        } else {
          this.$set(ligne, "prixUnitaire", null)
        }
      }
      if (type === "recette") {
        this.fetchRecettePrice(ligne.composantId, ligne.ordre)
      }
    },
    checkLigneSansProduits(ligne) {
      if (ligne.composant.estPerso === undefined && !ligne.prixUnitaire) {
        this.quantiteModifie = false
        this.tooltipQuantiteModifie = false
      } else {
        this.ajusterSimulation()
      }
    },
    ajusterSimulation() {
      this.quantiteModifie = true
      if (!this.tooltipQuantiteModifie) {
        this.tooltipQuantiteModifie = true
        setTimeout(() => {
          if (this.tabEnCours == 2) {
            this.obtenirBesoinsRecette()
          }
          this.tooltipQuantiteModifie = false
        }, 1000)
      }
    },
    ingredientPrincipalAvecDuplications(ligne) {
      const length = this.recette.lignes.filter(
        el => el.composantId === ligne.composantId
      ).length
      return ligne.familleId && !ligne.typeConviveId && length > 1
    },
    supprimerRecetteLigne(index) {
      if (this.recette.lignes) {
        const ligne = this.recette.lignes[index]
        if (this.ingredientPrincipalAvecDuplications(ligne)) {
          return this.alerteConfirmation("Supprimer l'ingrédient", "Souhaitez-vous supprimer cet ingrédient ?<br/>Toutes ses duplications seront également supprimées.")
          .then(result => {
            if (result) {
              _.remove(
                this.recette.lignes,
                el => el.composantId === ligne.composantId
              )
              this.$set(this.recette.lignes, this.recette.lignes)
            }
            this.reordonner(this.recette.lignes)
            this.ajusterSimulation()
            return
          })
        } else {
          this.recette.lignes.splice(index, 1)
          this.reordonner(this.recette.lignes)
          this.ajusterSimulation()
        }
      }
      this.checkQuantitesParAssiette()
    },
    famillesPour(ligne) {
      if (
        ligne.details !== null &&
        ligne.composant &&
        ligne.composant.produits &&
        ligne.composant.produits.length > 1
      ) {
        return ligne.composant.produits.map(el => el.fam)
      }
      if (ligne.details === null || ligne.composant.produits === undefined) {
        return this.familles.map(el => el.code)
      }
    },
    setFamille(event, ligne) {
      this.checkLigneSansProduits(ligne)
      let evtFamille = event.famille
      let evtComposant = event.composant
      let familleId = this.familles.find(
        famille => famille.code === evtFamille
      ).id
      let famille = evtFamille
      let recetteLigne = this.recette.lignes.find(r => r.ordre === ligne.ordre)
      this.$set(recetteLigne, "familleId", familleId)
      this.$set(recetteLigne, "famille", famille)
      ++this.choixFamilleKey
      if (ligne.composant.produits) {
        let grammage = ligne.composant.produits.find(
          el => el.fam === event.composant.famille
        ).prod[0].gram
        this.$set(recetteLigne, "grammage", grammage)
        this.fetchIngredientPrice(evtComposant.composantId, familleId, ligne.up, grammage, ligne.ordre)
      }
    },
    setGram(grammage, ligne) {
      this.checkLigneSansProduits(ligne)
      if (ligne.composant.produits && ligne.composant.produits.length) {
        let recetteLigne = this.recette.lignes.find(
          ing => ing.ordre === ligne.ordre
        )
        this.$set(recetteLigne, "grammage", grammage)
        let familleId = this.familles.find(
          famille => famille.code === ligne.famille
        ).id
        this.fetchIngredientPrice(ligne.composantId, familleId, ligne.up, grammage, ligne.ordre)
      }
    },
    gramPossiblePourLigne(ligne) {
      // renvoie la liste des grammes possibles pour cette ligne (ou faux)
      if (ligne.composant &&
        ligne.composant.produits &&
        ligne.composant.produits.length) {
        const famille = ligne.composant.produits.find(el => el.fam === ligne.famille);
        if (famille) {
          if (famille.prod)
            return _.uniq(famille.prod.map(i => i.gram))
        }
      }
      return false
    },
    ingredientQuantite(index, item) {
      let ligne = this.recette.lignes.find(ligne => ligne.ordre === item.ordre)
      ligne["quantite"] = event.target.value || 0
      this.checkLigneSansProduits(ligne)
      this.checkQuantitesParAssiette()
    },
    checkIfQuantitesParAssietteExists(up) {
      if (this.recette.conviveTypeId && up === "Personnes") {
        this.addQuantitesParAssietteRow(this.recette.conviveTypeId)
      } else {
        this.recette.conviveTypeId = ""
        //this.effacerQuantitesParAssiette()
      }
    },
    addQuantitesParAssietteRow(typeConviveId, keepEmpty) {
      this.quantitesParAssietteFromDataKeepEmpty(keepEmpty)
      if (typeConviveId) {
        this.quantitesParAssiette.find(q => q.typeConviveId === typeConviveId).afficher = true
      }
      this.keyQuantites += 1
    },
    removeQuantitesParAssietteRow(typeConviveId) {
      _.remove(this.recette.quantitesParAssiette, q => q.typeConviveId === typeConviveId)
      var quantiteConvive = this.quantitesParAssiette.find(q => q.typeConviveId === typeConviveId)
      quantiteConvive.services.forEach(s => {
        s.quantite = 0
      })
      quantiteConvive.afficher = false
    },
    setServiceQuantite(event, conviveId, serviceId) {
      let ratio = event / this.grammageBase100
      if (!event) {//quantité à 0 => suppresion
        let index = this.recette.quantitesParAssiette.findIndex(e => e.typeConviveId === conviveId && e.serviceId === serviceId)
        this.$delete(this.recette.quantitesParAssiette, index)
      } else if (this.recette.quantitesParAssiette.find(e => e.typeConviveId === conviveId && e.serviceId === serviceId) === undefined) {//création
        this.recette.quantitesParAssiette.push({
          typeConviveId: conviveId,
          serviceId: serviceId,
          quantite: event,
          ratio: ratio.toFixed(2)
        })
      } else {//modification
        let current = this.recette.quantitesParAssiette.find(e => e.typeConviveId === conviveId && e.serviceId === serviceId)
        current.serviceId = serviceId
        current.quantite = event
        current.ratio = ratio.toFixed(2)
      }
      this.quantitesParAssietteFromDataKeepEmpty(true)
      this.checkQuantitesParAssiette()
    },
    moveGrammage(event) {
      var idService = event.id.replace("service", "").split("-")[0]
      var idConvive = event.id.replace("service", "").split("-")[1]
      var index
      if (event.direction === "left") {
        index = this.servicesIdOrdonnees.indexOf(idService)
        if (index > 0) {
          idService = this.servicesIdOrdonnees[index - 1]
        } else {
          //go up ?
        }
      } else if (event.direction === "right") {
        index = this.servicesIdOrdonnees.indexOf(idService)
        if (index < this.servicesIdOrdonnees.length - 1) {
          idService = this.servicesIdOrdonnees[index + 1]
        } else {
          //go down ?
        }
      } else if (event.direction === "up") {
        index = this.quantitesParAssietteAAfficher.findIndex(c => c.typeConviveId === idConvive)
        if (index > 0) {
          idConvive = this.quantitesParAssietteAAfficher[index - 1].typeConviveId
        } else {
          //go left ?
        }
      } else if (event.direction === "down") {
        index = this.quantitesParAssietteAAfficher.findIndex(c => c.typeConviveId === idConvive)
        if (index < this.quantitesParAssietteAAfficher.length - 1) {
          idConvive = this.quantitesParAssietteAAfficher[index + 1].typeConviveId
        } else {
          //go right ?
        }
      }
      let next = document.getElementById("service" + idService + "-" + idConvive)
      if (next) {
        next.focus()
      }
    },
    recettePourApi() {
      const parIngredients = this.recette.lignes.reduce(function (
        resultat,
        ligne
      ) {
        if (ligne.familleId && ligne.typeConviveId) {
          resultat[ligne.composantId] = (
            resultat[ligne.composantId] || []
          ).concat(ligne)
        }
        return resultat
      },
        {})

      var aDoublon = false
      for (var ingredient in parIngredients) {
        var typeConvivesPresents = parIngredients[ingredient].map(function (
          ligne
        ) {
          return ligne.typeConviveId
        })
        aDoublon = typeConvivesPresents.some(function (
          typeConviveId,
          index,
          convives
        ) {
          return (
            convives.indexOf(typeConviveId) !=
            convives.lastIndexOf(typeConviveId)
          )
        })
        if (aDoublon) break
      }
      if (aDoublon) {
        this.cacherAttente()
        return this.$toast(
          "Des ingrédients dupliqués avec les mêmes convives ne sont pas permis",
          {
            horizontalPosition: "center",
            verticalPosition: "top",
            className: ["toast-error"],
            duration: 2500
          }
        )
      }
      this.$store.commit("recettes/setDerniereRecetteEnMemoire", this.recette)
      this.afficherAttente()
      this.recetteDataApi = _.cloneDeep(this.recette)
      this.recetteDataApi.lignes.map(function (item) {
        if (item.prixUnitaire === null) {
          item.prixUnitaire = 0
        }
        return item
      })
      this.recetteDataApi.quantitesParAssiette.map(el => delete el.ratio)
      this.recetteDataApi.utilisateurId = null
      this.recetteDataApi.etablissementId = null
      //TODO urement inutile
      switch (this.typeAffectationRecette(this.recette)) {
        case "user":
          this.recetteDataApi.utilisateurId = this.userId
          break
        case "etab":
          this.recetteDataApi.etablissementId = this.etabCourantId
          break
      }
    },
    enregistrer() {
      this.enEnregistrement = true
      this.ready = false
      this.recettePourApi()
      const requete = (
        this.enAjout || this.enDuplication
          ? ajouterRecette(this.recetteDataApi, this.enDuplication)
          : (this.appliquerTousEtablissements
            ? modifierRecetteTousEtabs(this.recetteDataApi)
            : modifierRecette(this.recetteDataApi)))
      requete.then(function (response) {
        if (this.enAjout) {
          const recettes = this.$store.getters['recettes/recetteListe']
          recettes.push({ id: response.data, nom: this.recette.nom, up: this.recette.up })
        }
        this.recetteDataApi = {}
        this.alerteSucces("Recette Sauvegardée !")
        this.ready = true
        this.cacherAttente()
        this.donneesInitiales = this.getDataRecette()
        if (this.$route.params.retour) {
          let to = this.$route.params.retour
          to.params = {ajout: this.$route.params.destination}
          to.params.ajout.recetteId = response.data
          this.$router.push(to)
        } else {
          this.$router.push({ name: "Recettes" })
        }
        return
      }.bind(this))
        .catch(error => {
          console.error(error)
          this.ready = true
          this.cacherAttente()
        })
      /*.finally(() => {//non supporté par Edge
        this.ready = true
        this.cacherAttente()
      })*/
    },
    async reinitialiser() {
      let continuer = await this.alerteConfirmation("Réinitialiser la recette", "Attention, cette action va annuler toutes les modifications effectuées sur la recette.<br/>Continuer ?", "Réinitialiser", "Annuler")
      if (continuer) {
        this.ready = false
        if (this.appliquerTousEtablissements && this.etablissementsCourants && this.etablissementsCourants.length > 1) {
          for (let i = 0; i < this.etablissementsCourants.length; i++) {
            await reinitialiserRecette(this.etablissementsCourants[i].eId, this.recette.id)
          }
        }
        await reinitialiserRecette(this.etabCourantId, this.recette.id)
        return this.loadData()
      }
    },
    reglerRecette() {
      if (this.enDuplication) {
        this.recette.lectureSeule = false
        this.recette.id = 0
        this.recette.lignes.forEach(x => {
          x.id = 0
          x.recetteId = 0
        })
        this.recette.quantitesParAssiette.forEach(x => {
          x.id = 0
        })
        if (!this.isAdmin) {
          // une recette universelle est dupliqué en batiment
          if (this.recette.estUniverselle) {
            this.recette.etablissementId = this.etabCourantId
          } else {
            this.recette.utilisateurId = this.userId
            this.recette.estPerso = true
          }
          this.recette.estUniverselle = false
        }
      }
      if (this.recette.uniteProduction) {
        this.recette["up"] = this.recette.uniteProduction //WEIRD
      }
      this.TypeRecette = this.typeAffectationRecette(this.recette)
    },
    corrigerLectureSeule() {
      if (this.recette.recetteOrigineId) {
        this.recette.lectureSeule = false
      }
    },
    async loadData() {
      // todo : inclure la data et couleur différente pour les ingrédients sans produit
      // let ingredientsNotUndefined = _.cloneDeep(this.$store.state.definitions.ingredients).filter(ingredient => ingredient.produits !== undefined)
      // let ingredients = ingredientsNotUndefined.filter(ingredient => ingredient.produits !== null)

      let ingredients = _.cloneDeep(this.$store.state.definitions.ingredients)

      if (this.enAjout) {
        if (this.$store.state.recettes.filtresEnMemoire.typeDeRechercheEnMemoire === "personnelles") {
          this.affecterRecette("etab")
        } else if (this.$store.state.recettes.filtresEnMemoire.typeDeRechercheEnMemoire === "etablissement") {
          this.affecterRecette("etab")
        } else if (this.isAdmin) {
          this.affecterRecette("univ")
        } else {
          this.affecterRecette("etab")
        }
        if (this.$store.state.recettes.lastRecette) {
          let recetteEnMemoire = this.$store.state.recettes.lastRecette
          this.recette["up"] = recetteEnMemoire.up ? recetteEnMemoire.up : null
          this.recette["categorieRecetteId"] = recetteEnMemoire.categorieRecetteId
            ? recetteEnMemoire.categorieRecetteId
            : null
          this.recette["quantitesParAssiette"] = []
          this.recette["lignes"] = []
          if (recetteEnMemoire.conviveTypeId) {
            // Reprendre le conviveTypeId de la recette en mémoire et ajouter la ligne de quantités correspondante
            this.recette.conviveTypeId = recetteEnMemoire.conviveTypeId
            this.addQuantitesParAssietteRow(this.recette.conviveTypeId)
          }
          this.recette["textureIds"] = []
          this.recette["regimeIds"] = []
          this.cacherAttente()
        }
      } else {
        const reponse = await obtenirPourEditionDelinee(this.$route.params.recetteId, this.etabCourantId);
        this.recette = reponse.data
        this.verifierAutorisations(this.recette)
        // Reconstruire recette.lignes
        this.recette.lignes = this.recetteLignesFromData(this.recette)
        // Reconstruire quantitesParAssiette avec la recette
        this.quantitesParAssietteFromData(this.recette, this.optionsConviveType, this.services)
        if (this.recette.conviveTypeId) {
          this.addQuantitesParAssietteRow(this.recette.conviveTypeId)
        }
        // Reconstruire l'affectation, UP et texturesIds
        this.reglerRecette()

        _.remove(this.recettes, el => {
          return el.id === this.recette.id
        })
        this.reordonner(this.recette.lignes)
        this.donneesInitiales = this.getDataRecette()
        this.cacherAttente()
      }
      this.corrigerLectureSeule()
      this.ingredientsEtRecettes = ingredients
      this.typeRecette = this.typeAffectationRecette(this.recette)
      this.donneesInitiales = this.getDataRecette()
      document.title = (this.enAjout) ? "Nouvelle recette" : this.recette.nom
      this.checkQuantitesParAssiette()
      this.ready = true
    },
    getTermeDeRechercheUtilisation(evt) {
      this.termeDeRechercheUtilisation = evt
      this.$emit("set-terme-de-recherche", evt)
    },
    effacerLaRecherche() {
      this.termeDeRechercheUtilisation = ""
      this.filteredUtilisationList = this.listeDesUtilisations
    },
    retourRecherche(results) {
      this.filteredUtilisationList = results
      this.keyUtilisations += 1
      this.currentItem = 0
    },
    checkQuantitesParAssiette() {
      if (!this.qteTypeforPersons || this.toutesLignesParPiece) {
        this.quantitesParAssietteValides = true
      } else {
        this.quantitesParAssietteValides = this.quantitesParAssietteAAfficher.filter(q => q.services.filter(s => s.quantite).length > 0).length > 0
      }
    },
    chargerSousRecettes() {
      return chargerParFiltre(null, { etablissementId: this.etabCourantId, propriete: "&filtre=0", unitesProduction: this.upSousRecettes.join(",") })
        .then(response => {
          this.recettes = response.data
          this.recettes.filter(x => x.up == "Personnes").forEach(x => {
            x.up = "PCE"
          })
          this.ingredientsEtRecettes.push(this.recettes)
          return
        })
    },
    modifUPSousRecettes(mod) {
      if (mod) {
        return this.chargerSousRecettes()
          .then(this.$refs.rechercheSousRecette.rechercher)
      }
    }
  },
  created() {
    this.recette = {
      nom: "",
      pourNombre: null,
      up: null,
      conviveTypeId: null,
      zonePreparationId: null,
      categorieRecetteId: null,
      quantitesParAssiette: [],
      lignes: []
    }
  },
  mounted() {
    this.afficherAttente()
    this.texturesPourExclusion = this.textures.filter(t => t.value !== constantesId.textureNormale)
    this.regimesPourExclusion = this.regimes.filter(t => t.value !== constantesId.regimeNormal)
    this.chargerSousRecettes()
    this.loadData()
    // this.$root.$emit("bv::show::tooltip") // supprimé pour erreur eslint, je ne suis pas sur que ca ouvrait vraiment tous les tooltips
    document.addEventListener("mousewheel", function () {
      if (document.activeElement.type === "number") {
        document.activeElement.blur()
      }
    })
    if (this.$route.params.recetteId) {
      return obtenirSurRecettes(this.$route.params.recetteId)
        .then(response => {
          this.listeDesUtilisations = response.data
          this.filteredUtilisationList = response.data
          return
        })
    }
  },
  beforeRouteLeave(to, from, next) {
    console.log('RecetteEdition:checkRoute')
    if (this.recette && this.recette.recetteOrigineId && !this.recette.estDeclinaisonPersonnalisee && !this.donneesModifiees && !this.enEnregistrement) {
      this.alerteConfirmation("Valider la recette", "Cette recette n'a jamais été modifiée.<br/>Vous convient-elle ?")
        .then((result) => {
          if (result) {
            this.enregistrer()
          }
        }).catch(()=> {
          console.error("erreur beforeRouteLeave Validation déclinaison de recette")
        })
    } else {
      // eslint-disable-next-line promise/no-callback-in-promise
      next()
    }
  }
}
</script>

<style lang="scss" scoped>
.usages {
  display: flex;
  flex-wrap: wrap;
}
.input-group {
  span {
    position: absolute;
    z-index: 1;
    right: 5px;
    top: 0.4rem;
    color: #4e6689;
    font-size: 1rem;
  }
  input {
    padding-right: 18px;
    border-radius: 0;
  }
}
.table-responsive {
  overflow: visible !important;
}
.table-grammages tbody tr:first-child td {
  input,
  textarea {
    border: 1px solid #7188cf;
  }
}
.close {
  float: none;
}
.table-header-rotated {
  border-collapse: collapse;
  @media (min-width: 2040px) {
    td {
      width: 90px;
      min-width: 90px;
      max-width: 90px;
    }
    th {
      padding: 5px 10px;
    }
  }
  @media (max-width: 2040px) {
    td {
      width: 80px;
      min-width: 80px;
      max-width: 80px;
    }
    th {
      padding: 5px 0px;
    }
  }
  @media (max-width: 1865px) {
    td {
      width: 70px;
      min-width: 70px;
      max-width: 70px;
    }
    th {
      padding: 5px 29px 5px 0;
    }
    input {
      font-size: 10px;
      padding-right: 12px;
    }
    span {
      font-size: 10px;
    }
  }
  @media (max-width: 1685px) {
    td {
      width: 60px;
      min-width: 70px;
      max-width: 70px;
    }
  }
  @media (max-width: 1675px) {
    td {
      width: 35px;
      min-width: 35px;
      max-width: 35px;
    }
  }

  td {
    text-align: center;
    padding: 10px 5px;
    border: 1px solid #ccc;
  }
  th.rotate {
    @media (min-width: 2130px) {
      > div {
        width: 76px;
        font-size: 1rem;
      }
    }
    @media (max-width: 2129px) {
      > div {
        width: 75px;
        font-size: 0.9rem;
      }
    }
    @media (max-width: 1865px) {
      > div {
        width: 43px;
        font-size: 0.6rem;
      }
    }
    @media (max-width: 1685px) {
      > div {
        width: 50px;
        font-size: 1rem;
      }
    }
    @media (max-width: 1675px) {
      > div {
        width: 35px;
        font-size: 0.5rem;
      }
    }
    @media (max-width: 1599px) {
      > div {
        width: 74px;
        font-size: 0.5rem;
      }
    }

    height: 50px;
    > div {
      white-space: normal;
    }
  }
  th.row-header {
    padding: 0 10px;
    border-bottom: 1px solid #ccc;
  }
}
.border-none {
  border: none;
}
.k-button li {
  display: none !important;
}
.k-multiselect-wrap ul {
  vertical-align: top;
  display: none !important;
}
.total-price {
  text-align: right !important;
  color: white;
}
.table td {
  border-bottom: none;
  text-align: left;
  vertical-align: middle;
}
.price {
  min-height: 1.45rem;
  font-size: 0.7rem;
  text-align: right;
  margin-left: 0.8rem;
  font-family: inherit;
  color: #3e587e;
  padding-top: 1rem;
  padding-bottom: 0;
  margin-top: 0;
  line-height: 0;
}
.border-classique {
  border: 1px solid #7188cf !important;
}
.border-base100 {
  border: 3px solid #4dcd9d !important;
}
.ligne-up-unique {
  margin-left: 1rem;
  width: 70px;
  padding-left: 0.7rem;
  color: #495057;
}
.btn-obtenir-besoins {
  position: absolute;
  z-index: 1;
  right: 30px;
  top: 72px;
}
.fas.fa-calculator.strike:after {
  position: absolute;
  content: "/";
  color: red;
  font-size: 2.7em;
  left: 209px;
  top: 4px;
}
</style>

<style lang="scss">
.tabs-lignes {
  .card-header {
    background: white !important;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #4d80d1 !important;
    box-shadow: 0px 0px 4px 0px #0000006e;
    border-radius: 1px;
  }
  .nav-link,
  .fake-nav-item {
    padding: 2px 10px;
    background: #4d6fd6;
    color: white;
    border-radius: 0;
  }
  .background-red {
    color: red;
  }
}
.selected {
  color: #4d80d1;
}
.fa-window-close {
  cursor: pointer;
  position: absolute;
  right: 1.2rem;
  font-size: 2rem;
  color: #d0d0d0;
}
.input__search {
  padding-left: 0px !important;
}
.v-select__selections .v-chip__content {
  z-index: unset !important;
}
</style>
