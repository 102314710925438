<template>
  <div class="form-body" :key="key">
    <div class="row">
      <div class="col-8">
        <div class="form-group">
          <label for="nom">Nom</label>
          <input @focus="$event.target.select()" class="form-control" id="nom" type="text" v-model="categorieGEMRCN.nom">
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="couleur">Couleur</label>
          <input type="color" id="couleur" class="form-control" :value="categorieGEMRCN.couleur" @input="categorieGEMRCN.couleur = $event.target.value">
        </div>
      </div>
    </div>
    <div v-if="isAdmin" class="row">
      <div class="col-4">
        <div class="form-group">
          <label for="debut">Date début</label>
          <v-date-picker v-model="categorieGEMRCN.dateDebut">
            <template v-slot="{ inputValue, inputEvents }">
              <div class="flex flex-col sm:flex-row justify-start items-center">
                <div class="relative flex-grow">
                  <input style="height:40px;" class="flex-grow pl-8 pr-5 py-1 bg-gray-100 border rounded w-full text-gray-900"
                    :value="inputValue ? inputValue : 'Choisir la date...'"
                    v-on="inputEvents"
                  />
                </div>
              </div>
            </template>
          </v-date-picker>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="fin">Date fin</label>
          <v-date-picker v-model="categorieGEMRCN.dateFin">
            <template v-slot="{ inputValue, inputEvents }">
              <div class="flex flex-col sm:flex-row justify-start items-center">
                <div class="relative flex-grow">
                  <input style="height:40px;" class="flex-grow pl-8 pr-5 py-1 bg-gray-100 border rounded w-full text-gray-900"
                    :value="inputValue ? inputValue : 'Choisir la date...'"
                    v-on="inputEvents"
                  />
                </div>
              </div>
            </template>
          </v-date-picker>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label>Composantes</label>
      <MultiSelect :value="categorieGEMRCN.composantesIds" :items="optionsComposantes" placeholder="Composantes..." emptyText="Toutes les composantes ont été ajoutées" @change="categorieGEMRCN.composantesIds = $event" />
    </div>

    <div class="form-actions mb-2">
      <b-button variant="outline-primary" alt="Ajouter une fréquence de consommation" @click="addFrequence()"><i class="fas fa-plus"></i> Ajouter une fréquence de consommation</b-button>
      <b-button v-if="categorieGEMRCN.frequences && categorieGEMRCN.frequences.length > 3" type="submit" variant="success" alt="Enregistrer" @click="enregistrerCategorie" style="float:right;">Enregistrer</b-button>
    </div>

    <div v-for="(frequence, iFrequence) in categorieGEMRCN.frequences" :key="iFrequence">
      <div class="card no-radius box-shadow-0 border-secondary bg-transparent">
        <div class="card-header no-radius">
          <h4 class="card-title">Fréquence</h4>
          <div class="heading-elements" style="right:7px !important; top:7px !important;">
            <ul class="list-inline mb-0">
              <li><button class="btn rougeAgap" style="transform:scale(0.8);" v-tooltip="{ content: 'Supprimer cette fréquence'}"  @click="deleteFrequence(iFrequence)" >
                <i style="transform: scale(1.5);" class="ft-x "></i></button></li>
            </ul>
          </div>
        </div>

        <div class="card-content">
          <div class="card-body">
            <div class="form-group">
              <label>Services</label>
              <MultiSelect :value="frequence.servicesIds" :items="optionsServices" placeholder="Services..." emptyText="Tous les services ont été ajoutés" @change="frequence.servicesIds = $event" />
            </div>
            <div class="form-group">
              <label>Convives impactés</label>
              <MultiSelect :value="frequence.typesConvivesIds" :items="optionsConvives" placeholder="Convives..." emptyText="Tous les convives ont été ajoutés" @change="frequence.typesConvivesIds = $event" />
            </div>
            <div class="row">
              <div class="col-4">
                <div class="form-group">
                  <label>Fréquence</label>
                  <InputNumber v-model="frequence.frequence" typeFiltre="quantiteEntiere" style="width:100%;" title="Fréquence" />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label>Période</label>
                  <b-form-select v-model="frequence.periodeId" :options="optionsPeriodes">
                  </b-form-select>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label>Limite</label>
                  <b-form-select v-model="frequence.limite" :options="optionsLimitesGEMRCN">
                  </b-form-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-actions flex-center-space-between">
      <b-button variant="outline-primary" alt="Ajouter une fréquence de consommation" @click="addFrequence()"><i class="fas fa-plus"></i> Ajouter une fréquence de consommation</b-button>
      <div>
        <b-button type="submit" :disabled="enregistrementEnCours" variant="success" alt="Enregistrer" @click="enregistrerCategorie" style="float:right;">Enregistrer</b-button>
        <b-button type="submit" variant="danger" alt="Annuler" style="float:right;margin-right:10px;" @click="annulerEdition">Annuler</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { connecteData } from "@/mixins/connecteData"
import { enregistrerCategorie } from '@/api/gpao/plansAlimentaires'
import { formatageDate } from '@/mixins/dateFormats'
import { showAlerts } from "@/mixins/alerts"
import InputNumber from "@/components/Inputs/InputNumber"
import MultiSelect from "@/components/Inputs/MultiSelect"

export default {
  name: "EditionCategorieGEMRCN",
  props: { "categorie": Object },
  components: { InputNumber, MultiSelect },
  mixins: [ connecteData, formatageDate, showAlerts ],
  data() {
    return {
      key: 1,
      categorieGEMRCN: {...this.categorie},
      optionsComposantes: this.$store.state.definitions.typesComposantes,
      optionsConvives: this.$store.state.definitions.typesConvives,
      optionsServices: this.$store.state.definitions.typesServices,
      optionsLimitesGEMRCN: this.$store.state.definitions.limitesGEMRCN,
      optionsPeriodes: this.$store.state.definitions.periodesPlansAlimentaires,
      enregistrementEnCours: false
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    saisieValide() {
      let message = ""
      if (!this.categorieGEMRCN.nom)
        message += "Le nom est obligatoire.<br>"
      if (this.isAdmin && !this.categorieGEMRCN.dateDebut)
        message += "La date de début est obligatoire.<br>"
      if (!this.categorieGEMRCN.composantesIds || !this.categorieGEMRCN.composantesIds.length)
        message += "Au moins une composante doit être sélectionnée parmi les catégories décomptées.<br>"
      if (this.categorieGEMRCN.frequences && this.categorieGEMRCN.frequences.find(f => !f.servicesIds.length || !f.typesConvivesIds.length || isNaN(f.frequence) || !f.periodeId || !f.limite))
        message += "Au moins une fréquence est mal configurée : les services, les types de convives, la fréquence, la période et la limite sont obligatoires.<br>"
      if (message) {
        this.alerteErreur(message)
        return false
      }
      return true
    },
    deleteFrequence(i) {
      this.categorieGEMRCN.frequences.splice(i, 1)
      this.key += 1
    },
    addFrequence() {
      this.categorieGEMRCN.frequences = this.categorieGEMRCN.frequences || []
      this.categorieGEMRCN.frequences.push({ servicesIds: [], typesConvivesIds: [], frequence: null, periodeId: null, limite: null })
      this.key += 1
    },
    async enregistrerCategorie() {
      this.enregistrementEnCours = true

      // Contrôle de la saisie
      if (!this.saisieValide()) {
        this.enregistrementEnCours = false
        return
      }

      if (this.categorieGEMRCN.dateDebut)
        this.categorieGEMRCN.dateDebut = new Date(this.formatDateAvecTirets(this.categorieGEMRCN.dateDebut))
      if (this.categorieGEMRCN.dateFin)
        this.categorieGEMRCN.dateFin = new Date(this.formatDateAvecTirets(this.categorieGEMRCN.dateFin))
      if (!this.categorieGEMRCN.couleur)
        this.categorieGEMRCN.couleur = "#000000"
      await enregistrerCategorie(this.categorieGEMRCN, this.etabCourantId)
       .then(() => this.$emit("categorie-enregistree"))
       .finally(() => this.enregistrementEnCours = false)
    },
    annulerEdition() {
      this.$emit("annuler-edition")
    },
  }
}
</script>

<style lang="scss" scoped>
.label-checkbox {
  margin: 0 5px;
}
</style>
