<template>
  <div>
    <div v-if="!etablissementId" class="mb-2">Choisissez l'établissement sur lequel vous allez travailler...</div>
  <b-form-select v-model="etablissementChoisi"  v-if="etablissementsCourants"
    :options="optionsEtablissements" 
  :value-field="'eId'" :text-field="'eRS'"  @change=setEtablissement($event)>
    <template slot="first"> <!--  class="selectpicker" -->
      <option :value="null" disabled>Établissements...</option>
    </template>
  </b-form-select>
  </div>
</template>

<script>
import {objetVide} from '@/mixins/index'
import { connecteData } from "@/mixins/connecteData"
export default {
  props: ['etablissementId'],
  data() {
    return {
      etablissementChoisi: null,
    }
  },
  mixins: [objetVide, connecteData],
  computed: {
    optionsEtablissements() {
      let result = []
      this.etablissementsCourants.map(el => {
        result.push({eId: el.eId, eRS: el.eRS})
      })
      result.sort(function(a,b){  return a.eRS.localeCompare(b.eRS)})
      return result;
    },

  },
  methods: {
    setEtablissement(event) {
      if (event) {
      //  this.$store.commit('etablissements/setEtablissement',
     //   {etablissementId: event, userId: this.$store.state.user.userInfo.id})
        this.$emit('etablissement-selectionne', event)
       /* if (this.$route.name === 'login') {
          this.$router.push('/')
        }*/
      }
    }
  },
  mounted() {

    if (this.etablissementId)
      this.etablissementChoisi = this.etablissementId
  }
}
</script>

<style>

</style>
