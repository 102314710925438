<template>

  <span class="flex-center-space-between">
    <i v-if="classAlert" :class="classAlert"></i>
    <i class="fas fa-info-circle" style="padding-top: 4px;" :id="'informationicon'+ligneId"></i>
    <b-popover :target="'informationicon'+ligneId" triggers="hover" placement="top">
      {{popoverText}}
    </b-popover>
    <InputNumber v-show="modifiable && enModification" ref="inputNumber" v-model="qteDemandee" typeFiltre="quantite" style="width:100%;display:none;color:black" zeroSiVide="1" :fixBlur="true" @input="handleInput" @save="handleSave" @blur="handleSave" />
    <span v-show="!modifiable || !enModification" style="width:80%;display:inline-block;padding-left:.3rem;padding-right:.3rem;text-align:right" @click="handleClick">{{ enlever0NonSignificatifs(qtePcb) }}</span>

    <span v-if="modifiable && !enModification"> <i @click="handleClick" class="fas fa-edit"> </i></span>
  </span>
</template>

<script>
import { fMod } from '@/helpers/utils'
import InputNumber from '@/components/Inputs/InputNumber'
import { arrondiMercuriale } from '@/helpers/utils'

export default {
  name: 'QuantiteDemandeeEtPcb',
  components: { InputNumber },
  data() {
    return {
      enModification: false,
      qteDemandee: 0,
    }
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
    modifiable: {
      type: Boolean,
      default: false
    },
    pcb: {
      type: Number,
      default: 1
    }
    , ligneId: {
      type: String
    }
    , uc: {
      type: String
    }
    , classAlert: {
      type: String
    }
  },
  mounted() {
    this.qteDemandee = this.value;
  },
  computed: {
    qtePcb() {
      let enPCB = Number(this.qteDemandee)
      if (!this.qPcbOk(this.qteDemandee, this.pcb)) {
        enPCB += this.pcb - fMod(this.qteDemandee, this.pcb)
      }

      if (isNaN(enPCB))
        return 0
      enPCB = arrondiMercuriale(enPCB)
      return enPCB;
    },
    popoverText() { return `Demande ${this.qteDemandee}, PCB ${this.pcb} = ${this.qtePcb} ${this.uc}` }
  },
  methods: {
    enlever0NonSignificatifs(chaineNombre) {
     // pour une chaine ( https://stackoverflow.com/a/53397618/130420)
      chaineNombre = String(chaineNombre)
      return chaineNombre.replace(/(\.[0-9]*[1-9])0+$|,0*$/,'$1')
    },
    qPcbOk: function (qDem, pcb) {
      if (fMod(qDem, pcb) === 0) {
        return true
      }
      return false
    },
    handleInput: function () {
      this.$emit('input', parseFloat(this.qtePcb.replace(",", ".")))
    },
    handleSave: function () {
      this.enModification = false
      this.$emit('save', this.qtePcb)
    },
    handleClick: function () {
      this.enModification = true;
      setTimeout(() => { this.$refs.inputNumber.$refs.input.focus() }, 100);
    }
  }
}
</script>