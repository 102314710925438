<template>
  <span>
    <b-button @click.stop="obtenirArticles(ingredient)" title="Substitution d'article" :style="small ? 'transform: scale(0.8);' : ''">
      <i class="fas fa-exchange-alt"></i>
    </b-button>
    <b-modal v-model="afficherModale" size="xl" hide-footer header-bg-variant="gradient-bleu" header-text-variant="light" @hide="surFermeture">
      <template slot="modal-title">
        <h3><i class="fas fa-exchange-alt" style="margin-right:1rem"></i>Substitution d'article</h3>
      </template>
      <template slot="default">
        <div class="container-fluid">
          <div class="row">
            <div class="col-2 small">Besoin</div>
            <div class="col-10 small">Article sélectionné pour substitution</div>
            <div class="col-2">
              <h4>{{ingredient.quantiteBesoinEnUC}} {{ingredient.uc}}</h4>
            </div>
            <div class="col-10">
              <h4>
                {{ingredient.designation.toUpperCase()}}
                {{ingredient.marque ? ' - ' + ingredient.marque : ''}} -
                {{fournisseurNom(ingredient.fournisseurId)}}<br />
                ({{ingredient.prixUnitaire}} € / {{ingredient.uc}}, prix total: {{(ingredient.quantiteBesoinEnUC * ingredient.prixUnitaire) | afficherPrix}})
              </h4>
            </div>
          </div>
        </div>
        <div class="container-fluid push-top-1rem">
          <div class="row" style="padding-left:18px;margin-bottom:4px;">
            <div class="col-5 small">Articles associés à l'ingrédient "{{nomIngredient}}"</div>
            <div class="col-3 retrait-a-gauche">Fournisseur</div>
            <div class="col-1 small retrait-a-gauche">PCB</div>
            <div class="col-1 small retrait-a-gauche">PU HT</div>
            <div class="col-1 retrait-a-gauche">Total HT</div>
            <div class="col-1"></div>
          </div>
        </div>
        <div class="container-fluid" v-if="articles.parProduit">
          <ArticlesParProduit :articles="articles.parProduit" :favoris="articles.favoris" :ingredient="ingredient" :gestion-favori-uniquement="gestionFavoriUniquement" :recette="recette" :recettes="recettes" :key="articlesParProduitKey" @selection-article="articleSelectionne" @toggle-favori="toggleFavori" />
        </div>
      </template>
    </b-modal>
  </span>
</template>

<script>
import ArticlesParProduit from '@/components/Stock/ArticlesParProduit'
import { connecteData } from '@/mixins/connecteData'
import { fournisseurs } from '@/mixins/fournisseurs'
import { tousArticlesPour } from '@/api/gpao/mercuriales'
import _ from "lodash"

export default {
  name: "SubstitutionArticle",
  props: {
    ingredient: Object,
    gestionFavoriUniquement: Boolean,
    small: Boolean,
    recette: Object,
    recettes: [],
    idsBloques: []//Id des fournisseurs non sélectionnables
  },
  components: { ArticlesParProduit, },
  mixins: [fournisseurs, connecteData],
  data() {
    return {
      afficherModale: false,
      articles: {},
      nomIngredient: '',
      articlesParProduitKey: 1,
      favorisModifies: false, // indique si on a modifié un favori
    }
  },
  mounted() {
    this.nomIngredient = this.$store.getters["definitions/obtenirIngredient"](this.ingredient.ingredientId).nom
  },
  methods: {
    afficherModal() {
      this.obtenirArticles(this.ingredient)
    },
    surFermeture() {
      this.$emit("sur-fermeture", { ingredient: this.ingredient, favorisModifies: this.favorisModifies })
    },
    async obtenirArticles(besoin) {
      const response = await tousArticlesPour(this.etabCourantId, besoin.ingredientId, besoin.familleId, besoin.grammage)
      let nbArticles = 0
      ++this.articlesParProduitKey
      this.ingredientChoisi = besoin
      this.articles = response.data
      for (const key in this.articles.parProduit) {
        let produits = this.articles.parProduit[key]
        const value = produits.find(el => el.tarifId === this.ingredientChoisi.tarifId)
        if (value) {
          this.$set(value, 'ouvrirAccordeon', true)
        }
        nbArticles += produits.length
        produits
          .forEach(p => {
            if (this.idsBloques.indexOf(p.fournisseurId) !== -1) {
              p.bloque = true
            }
          })
      }
      if (!this.articles.favoris) {
        this.articles.favoris = []
      }
      if (nbArticles > 0) {
        this.afficherModale = true
      } else {
        this.$toast('Aucune substitution trouvée avec ces paramètres', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          className: ['toast-error'],
          duration: 1500
        })
      }
      
    },
    articleSelectionne(event) {
      this.$emit('selection-article', event)
      this.afficherModale = false
    },
    toggleFavori(payload) {
      this.favorisModifies = true
      this.$emit("toggle-favori", payload)
    }
  }
}
</script>