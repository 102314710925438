import lscache from 'lscache'
import service from '@/helpers/serviceGPAO'
import { constantesId } from "@/helpers/constantes"

export function chargerToutes(etablissementId) {
  return service.get("recettes/liste?Propriete=0&EtablissementId=" + etablissementId)
}

export function chargerUniverselles() {
  return service.get("recettes/liste?Propriete=1")
}

export function chargerPersonnelles() {
  return service.get("recettes/liste?Propriete=2")
}

export function chargerParFiltre(menuId, filtres) {
  let etablissementId = ''
  let propriete = ''
  let categorieIds = ''
  let regimeIds = ''
  let textureIds = ''
  let composanteIds = ''
  let categoriesGEMRCNIds = ''
  let unitesProduction = ''

  if (filtres.etablissementId) {
    etablissementId = 'EtablissementId=' + filtres.etablissementId
  }
  if (filtres.propriete) {
    propriete = filtres.propriete
  }
  if (filtres.categorieIds && filtres.categorieIds.length) {
    categorieIds = '&CategorieIds=' + filtres.categorieIds
  }
  if (filtres.composanteIds && filtres.composanteIds.length) {
    composanteIds = '&ComposanteIds=' + filtres.composanteIds
  }
  if (filtres.regimeIds && filtres.regimeIds.length) {
    regimeIds = '&RegimeIds=' + filtres.regimeIds
  }
  if (filtres.textureIds && filtres.textureIds.length) {
    textureIds = '&TextureIds=' + filtres.textureIds
  }
  if (filtres.categoriesGEMRCNIds && filtres.categoriesGEMRCNIds.length) {
    categoriesGEMRCNIds = '&CategoriesGEMRCNIds=' + filtres.categoriesGEMRCNIds
  }
  if (filtres.unitesProduction && filtres.unitesProduction.length) {
    unitesProduction = '&UnitesProduction=' + filtres.unitesProduction
  }

  let baseUrl = menuId ? "recettes/listePourMenuSurPA/" + menuId + "?" : "recettes/liste?"
  const url = baseUrl + etablissementId + propriete + categorieIds + composanteIds + regimeIds + textureIds + categoriesGEMRCNIds + unitesProduction

  return service.get(url)
}

export function obtenirSurRecettes(recetteId) {
  return service.get('recettes/surRecettes/' + recetteId)
}


export function obtenirPourEdition(recetteId, etablissementId, typeConviveId, serviceId, effectif) {
  let url = "recettes/pouredition/" + etablissementId + '/' + recetteId

  if (typeConviveId) {
    url += "/" + typeConviveId + "/" + serviceId
  }
  if (effectif) {
    url += "/" + effectif
  }

  return service.get(url).then(response => {
    let recette = response.data
    recette.estUniverselle = Boolean((!recette.utilisateurId && !recette.etablissementId) || recette.recetteOrigineId)
    recette.upSingulier = recette.up
    if (recette.upSingulier === 'Personnes') {
      recette.upSingulier = 'Personne'
    }
    return response
  })
}

export function obtenirPourEditionDelinee(recetteId, etablissementId) {
  let url = "recettes/pourEditionDeclinee/" + etablissementId + '/' + recetteId

  return service.get(url).then(response => {
    let recette = response.data
    recette.estUniverselle = Boolean((!recette.utilisateurId && !recette.etablissementId) || recette.recetteOrigineId)
    recette.upSingulier = recette.up
    if (recette.upSingulier === 'Personnes') {
      recette.upSingulier = 'Personne'
    }
    return response
  })
}

export function prixUnitairePourComposante(etablissementId, composante) {
  let url = "recettes/prixUnitairePourComposante/" + etablissementId + '/' + composante.id + "/" + composante.recetteId
  return service.get(url)
}
export function prixUnitaire(etablissementId, recetteId, ingredientId, familleId, UniteProduction, grammage) {
  let url = "recettes/prixUnitaire/" + etablissementId + '/' + (recetteId || 0)

  if (ingredientId) {
    url += '/' + ingredientId
  }

  if (familleId && UniteProduction) {
    url += "/" + familleId + "/" + UniteProduction
    if (UniteProduction == constantesId.UPPiece && grammage) {
      url += "/" + grammage
    }
  }
  return service.get(url)
}

export function supprimerRecette(recetteId) {
  return service.delete("recettes/supprimer/" + recetteId).then(response => {
    lscache.remove("Recette-" + recetteId)
    return response
  })
}

export function ajouterRecette(recette, enDuplication) {
  nettoyerRecette(recette)

  if (enDuplication) {
    delete recette.recetteOrigineId
  }
  return service.post("recettes/ajouter", recette)
}

export function modifierRecette(recette) {
  nettoyerRecette(recette)

  return service.patch("recettes/modifier", recette).then(response => {
    lscache.remove("Recette-" + recette.id)
    return response
  })
}

export function modifierRecettesEnMasse(etablissementId, recettesIds, changesMulti) {
  changesMulti.recettesAModifierIds = recettesIds
  
  return service.post("recettes/modifier/" + etablissementId, changesMulti)
}

export function modifierRecetteTousEtabs(recette) {
  nettoyerRecette(recette)

  return service.patch("recettes/modifierTousEtabs", recette).then(response => {
    lscache.remove("Recette-" + recette.id)
    return response
  })
}

export function reinitialiserRecette(etablissementId, recetteId) {
  return service.post("recettes/reinitialiser/" + etablissementId + '/' + recetteId ).then(response => {
    lscache.remove("Recette-" + recetteId)
    return response
  })
}

export function obtenirBesoins(etablissementId, recette) {
  nettoyerRecette(recette)

  return service.post("recettes/obtenirBesoins" + "/" + etablissementId, recette)
}

// eviter d'envoyer des infos inutiles
function nettoyerRecette(recette) {
  delete recette["lignesCompletees"] // voir si a terme on enverra pas une copie plutot, pour ne pas changer le paramètre
  delete recette["estUniverselle"]
  delete recette["lectureSeule"]
  recette.lignes.forEach(ligne => { if (ligne.id < 0) { delete ligne.id } })
  recette.lignes.forEach(ligne => {
    delete ligne.UPs
    delete ligne.composant
    delete ligne.famille
    delete ligne.prixUnitaire
    delete ligne.details
    delete ligne.erreurs
    if (ligne.typeConviveId == -1) {
      ligne.typeConviveId = null
    }
    // if (ligne.nouvelleLigne) {
    //   delete ligne.nouvelleLigne
    // }
  })
}
