<template>
  
  <div v-if="ligne.utilisations && ligne.utilisations.length" class="utilisations table-responsive mb-0">
    <table class="table table-sm table-bordered">
      <thead>
        <tr>
          <th>Recette</th>
          <th>Structure</th>
          <th>Date consommation</th>
          <th>Service</th>
          <th>Régime</th>
          <th>Texture</th>
          <th ref="quantite">{{ "Qté (" + (Math.round(ligne.utilisations.reduce((t, u) => u.quantite + t, 0) * 100) / 100).toString().replace('.', ',') + " " + ligne.uc + ")" }}</th>
          <th>Effectifs</th>
          <th>Date livraison</th>
        </tr>
      </thead>
      <tbody key="keyUtilisations">
        <tr v-for="(utilisation, iUtilisation) in ligne.utilisations" :key="iUtilisation">
          <td v-if="utilisation.recette && utilisation.quantite">
            <span v-if="utilisation.recetteEnregistree == null"><i v-if="utilisation.recette.estUneDeclinaison && !utilisation.recette.estDeclinaisonPersonnalisee" class="fas fa-exclamation-triangle text-warning" v-tooltip="{ content: 'Configuration de recette non vérifiée', placement: 'top' }"></i> <RecettePopUpIngredients :estLien="true" :recetteId="utilisation.recette.id" :recetteNom="utilisation.recette.nom" /></span>
            <span v-else>
              <b><i v-if="utilisation.recette.estUneDeclinaison && !utilisation.recette.estDeclinaisonPersonnalisee" class="fas fa-exclamation-triangle text-warning" v-tooltip="{ content: 'Configuration de recette non vérifiée', placement: 'top' }"></i> <RecettePopUpIngredients :estLien="true" :recetteId="utilisation.recette.id" :recetteNom="utilisation.recette.nom" /></b>
              <s><i>(<i v-if="utilisation.recetteEnregistree.estUneDeclinaison && !utilisation.recetteEnregistree.estDeclinaisonPersonnalisee" class="fas fa-exclamation-triangle text-warning" v-tooltip="{ content: 'Configuration de recette non vérifiée', placement: 'top' }"></i> <RecettePopUpIngredients :estLien="true" :recetteId="utilisation.recetteEnregistree.id" :recetteNom="utilisation.recetteEnregistree.nom" />)</i></s>
            </span>
          </td>
          <td v-else><s><i><i v-if="utilisation.recette.estUneDeclinaison && !utilisation.recette.estDeclinaisonPersonnalisee" class="fas fa-exclamation-triangle text-warning" v-tooltip="{ content: 'Configuration de recette non vérifiée', placement: 'top' }"></i> <RecettePopUpIngredients :estLien="true" :recetteId="utilisation.recette.id" :recetteNom="utilisation.recette.nom" /></i></s></td>
          <td v-if="utilisation.recette && utilisation.quantite">{{ utilisation.structure }}</td>
          <td v-else><s><i>{{ utilisation.structure }}</i></s></td>
          <td v-if="utilisation.recette && utilisation.quantite">{{ formatHumanDateAvecSlashes(utilisation.dateConsommation) }}</td>
          <td v-else><s><i>{{ formatHumanDateAvecSlashes(utilisation.dateConsommation) }}</i></s></td>
          <td v-if="utilisation.recette && utilisation.quantite">{{ utilisation.service }}</td>
          <td v-else><s><i>{{ utilisation.service }}</i></s></td>
          <td v-if="utilisation.recette && utilisation.quantite">{{ (!utilisation.regime || utilisation.regime === "Normal"
            ? "-"
            : utilisation.regime) }}</td>
          <td v-else><s><i>{{ (!utilisation.regime || utilisation.regime === "Normal"
            ? "-"
            : utilisation.regime) }}</i></s></td>
          <td v-if="utilisation.recette && utilisation.quantite">{{ (!utilisation.texture || utilisation.texture === "Normal"
            ? "-"
            : utilisation.texture) }}</td>
          <td v-else><s><i>{{ (!utilisation.texture || utilisation.texture === "Normal"
            ? "-"
            : utilisation.texture) }}</i></s></td>
          <td v-if="utilisation.recette && utilisation.quantite">
            <span v-if="utilisation.quantiteEnregistree == null && utilisation.ucEnregistree == null">{{ utilisation.quantite.toString().replace('.', ',') + " " + utilisation.uc }}</span>
            <span v-else><b>{{ utilisation.quantite.toString().replace('.', ',') + " " + utilisation.uc }}</b> <s><i>({{ (utilisation.quantiteEnregistree ? utilisation.quantiteEnregistree.toString().replace('.', ',') : "") + " " + utilisation.ucEnregistree }})</i></s></span>
          </td>
          <td v-else>
            <s><i>{{ utilisation.quantite.toString().replace('.', ',') + " " + utilisation.uc }} ({{ (utilisation.quantiteEnregistree ? utilisation.quantiteEnregistree.toString().replace('.', ',') : "") + " " + utilisation.ucEnregistree }})</i></s>
          </td>
          <td v-if="utilisation.recette && utilisation.quantite">
            <span v-if="utilisation.effectifsEnregistres == null">{{ utilisation.effectifs }}</span>
            <span v-else><b>{{ utilisation.effectifs }}</b> <s><i>({{ utilisation.effectifsEnregistres }})</i></s></span>
          </td>
          <td v-else>
            <s><i>{{ utilisation.effectifs }} <span v-if="utilisation.effectifsEnregistres != null">({{ utilisation.effectifsEnregistres }})</span></i></s>
          </td>
          <td v-if="utilisation.recette && utilisation.quantite">{{ formatHumanDateAvecSlashes(utilisation.dateLivraisonSouhaitee) }}</td>
          <td v-else><s><i>{{ formatHumanDateAvecSlashes(utilisation.dateLivraisonSouhaitee) }}</i></s></td>
        </tr>
      </tbody>
    </table>
  </div>

</template>

<script>
import _ from "lodash"
import { formatHumanDateAvecSlashes } from '@/mixins/dateFormats'
import RecettePopUpIngredients from '@/components/Recettes/RecettePopUpIngredients'
export default {
  name: "UtilisationsLigneCommande",
  components: {RecettePopUpIngredients},
  mixins: [formatHumanDateAvecSlashes],
  props: {
    ligne: {type: Object, default: () => ({})},
  },
  data() {
    return {
      utilisations: [],
      keyUtilisations: 1,
    }
  },
  watch: {
    montrerBarquettes(visible) {
      setTimeout(() => {
        if (visible) {
          window.addEventListener("click", this.clickOutBarquettes)
        } else {
          window.removeEventListener("click", this.clickOutBarquettes)
        }
      }, 10)
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.utilisations = this.ligne.utilisations
    },

    async toggleBarquettes() {
      if (!this.montrerBarquettes) {//préparation des données
        this.barquettes = this.donnees && JSON.parse(this.donnees.replaceAll("'", "\""))
        this.barquettes = this.barquettes && this.barquettes.Barquettage || []
        this.barquettesAvant = JSON.stringify(this.barquettes.map(b => {
          return {
            Taille: b.Taille,
            Designation: b.Designation
          }
        }))
        this.checkBarquettes()
        this.keyBarquettes += 1
        this.montrerBarquettes = true
      } else {
        if (!this.erreurBarquettes) {//pas de fermeture en cas d'erreur
          if (this.modifBarquettes) {
            let result = await this.$swal({
              title: 'Modifications non enregistrées',
              html: "Les modifications effectuées sur le barquettage n'ont pas été appliquées.<br/>Voulez-vous les enregistrer ?",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Oui',
              cancelButtonText: 'Non',
              focusCancel: false,
              allowOutsideClick: false
            })
            if (result.value) {
              return this.enregistrerBarquettes()
            }
          }
          this.montrerBarquettes = false
        }
      }
    },
    inputBarquette() {
      this.checkBarquettes()
    },
    creerBarquette() {
      this.barquettes.push({Taille: "", Designation: ""})
      this.checkBarquettes()
      this.keyBarquettes += 1
    },
    supprimerBarquette(index) {
      _.remove(this.barquettes, (b, i) => i === index)
      this.checkBarquettes()
      this.keyBarquettes += 1
    },
    checkBarquette(barquette) {
      if (barquette.Designation.length > 20) {
        barquette.Designation = barquette.Designation.substr(0, 20)
      }
      barquette.tailleValide = Boolean(barquette.Taille)
      barquette.designationValide = Boolean(barquette.Designation)
    },
    checkBarquettes() {
      this.barquettes.forEach(this.checkBarquette)
      this.erreurBarquettes = this.barquettes.filter(b => !b.tailleValide || !b.designationValide).length > 0
      this.modifBarquettes = this.barquettesAvant !== JSON.stringify(this.barquettes.map(b => {
        return {
          Taille: b.Taille,
          Designation: b.Designation
        }
      }))
    },
    enregistrerBarquettes() {
      this.$emit("enregistrer", this.barquettes.map(b => {
        return {
          Taille: b.Taille,
          Designation: b.Designation
        }
      }))
      this.montrerBarquettes = false
    },
    clickOutBarquettes(e) {//fermeture du combo par clic en dehors
      if (this.montrerBarquettes && !e.target.closest("#barquettes") && !e.target.closest(".swal2-container")) {
        this.toggleBarquettes()
      }
    }
  },
  destroyed() {
    window.removeEventListener("click", this.clickOutBarquettes)
  }
}
</script>

<style>
/*.utilisations {
  background-color: #e0f2ff;
}*/
.utilisations > .table {
  background-color: transparent;
}
.ligne-commande.not-collapsed {
  background-color: #e0f2ff;
}
#barquettes td.erreur {
  border: 1px solid red;
}
</style>
