<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <!-- Panneau de gauche -->
        <transition name="fade">
          <div class="col-3">
            <div class="card-header bg-gradient-bleu text-white flex-center-space-between" style="height:60px">
              <h4 class="card-title">STOCK</h4>
              <div class="switch-container">
                <i v-tooltip="{ content: 'Toutes les DLC/DDM' }" class="fas fa-calendar-alt cursor-pointer" style="margin-left:2rem;font-size:1.8rem;opacity:.3" @click="filtreDlcDluo = constantesId.filtreToutesDlc;filtrer()" :class="filtreDlcDluo === constantesId.filtreToutesDlc ? 'filtre-selectionne' : ''"></i>
                <i v-tooltip="{ content: 'DLC/DDM proches' }" class="fas fa-stopwatch cursor-pointer" style="margin-left:2rem;font-size:1.8rem;opacity:.3" @click="filtreDlcDluo = constantesId.filtreDlcProches;filtrer()" :class="filtreDlcDluo === constantesId.filtreDlcProches ? 'filtre-selectionne' : ''"></i>
                <i v-tooltip="{ content: 'DLC/DDM dépassées' }" class="fas fa-calendar-times cursor-pointer" style="margin-left:2rem;font-size:1.8rem;opacity:.3" @click="filtreDlcDluo = constantesId.filtreDlcDepassees;filtrer()" :class="filtreDlcDluo === constantesId.filtreDlcDepassees ? 'filtre-selectionne' : ''"></i>
              </div>
            </div>
            <div class="card">
              <Calendrier :selectedDate="new Date()" @selectionner-date="setDateEtatAu($event)" />
              <div class="filtres-container" :key="key">
                <label v-tooltip="{ content: 'Affiche les lots qui ont été ajoutés en stock depuis le dernier inventaire et qui ont été retirés du stock'}">Afficher les lignes retirées du stock</label>
                <ToggleButton class="ml-2" v-model="afficherQuantitesAZero" :labels="{checked: 'Oui', unchecked: 'Non'}" :color="{checked: '#16D39A', unchecked: '#CCCCCC'}" :height="30" :width="80"
                  v-tooltip="{content: 'Affiche les lots qui ont été ajoutés en stock depuis le dernier inventaire et qui ont été retirés du stock', placement: 'top'}" @change="filtrerQuantitesAZero()" />
                <MultiSelect :value="filtreZonesDeStockage" :items="optionsZonesDeStockage" placeholder="Zones de stockage..." emptyText="Toutes les zones ont été ajoutées" @change="filtreZonesDeStockage = $event;filtrer()" style="margin-top:0!important;padding-top:0!important" />
                <div style="display:flex;justify-content:space-between;width:100%">
                  <input type="text" v-model="filtreNomProduit" style="width:90%" placeholder="Recherche type de produit" @input="filtrer()">
                  <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style='height:32px;' v-show="enCalculStock" />
                </div>
              </div>
            </div>
          </div>
        </transition>

        <!-- Panneau de droite -->
        <transition name="fade" mode="out-in">
          <div v-if="stock" class="col-9 pl-0">
            <div class="card card-defilante-pleine-page" id="card-details">
              <div class="card-header bg-gradient-bleu text-white card-links card-header-push-bottom flex-center-space-between" style="height:60px">
                <div class="col-6 flex-center-space-between">
                  <h4 class="card-title card-title-categories" v-if="stock && stock.date">{{formatHumanDate(new Date(stock.date))}}</h4>
                  <h4 class="card-title card-title-categories" v-if="stock && stock.valeurTotale">{{stock.valeurTotale | afficherPrix}}</h4>
                </div>
                <div class="col-6 switch-container">
                  <button class="btn btn-light push-right-1rem" v-tooltip="{ content: 'Réinitialiser les filtres', placement: 'top' }" v-if="filtreDlcDluo !== constantesId.filtreToutesDlc || filtreZonesDeStockage.length" @click="reinitialiser()">
                    <i class="fas fa-sync-alt"></i>
                  </button>
                  <button type="submit" class="btn btn-light push-right-1rem" alt="Exporter" v-tooltip="{ content: 'Exporter le stock', placement: 'top' }" @click="exporter(stock.date)">
                    <i :class="{ 'fa-spin ': enExportation }" class="fas fa-file-excel"></i>
                  </button>
                  <span v-tooltip="{ content: inventaireOuvert ? 'Ajout de produit non disponible tant qu\'un inventaire est ouvert.' : '' }">
                      <BtnAjoutMvtStock :disabled="inventaireOuvert" type="button" class="push-right-1rem" title="Ajouter un article au stock" btnImage="fas fa-plus" :dateEtatAu="dateEtatAu" :key="'ajoutArticle'+ajoutArticleKey" 
                                        action="in" mode="stock" :datesInvalides="datesInvalidesMvtStock" @envoyer-article="enregistrerEnStock($event)" />
                  </span>
                  <!--<button class="btn btn-light" v-tooltip="{ content: 'Enregistrer les modifications dans le stock', placement: 'top' }" @click="enregistrerEnStock()">
                  <i class="fas fa-save"></i>
                </button>-->
                </div>
              </div>
              <div class="card-body">
                <div v-if="enCalculStock" style="text-align:center">
                  <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style='height:160px;' />
                </div>
                <div v-else class="container-fluid" no-body v-for="(zone, i) in stock.zonesStockage" :key="i">
                  <div class="row card-header bg-gradient-bleu text-white">
                    <a block class="btn-card-header flex-center-space-between col-11" v-b-toggle="'zone'+i">
                      <span class="when-opened" style="float:left;"><i class="fa fa-chevron-down"></i></span>
                      <span class="when-closed" style="float:left;"><i class="fa fa-chevron-right"></i></span>
                      <div class="col-12 flex-center-space-between">
                        <span>{{zone.designation.toUpperCase()}}</span>
                        <span>{{zone.valeur | afficherPrix}}</span>
                        <span></span>
                      </div>
                    </a>
                    <div class="flex-end col-1" v-tooltip="{ content: inventaireOuvert ? 'Vider le stock non disponible tant qu\'un inventaire est ouvert.' : '' }">
                      <BtnAjoutMvtStock :disabled="inventaireOuvert" :id="'videZone'+i" type="button" title="Vider le stock pour cette zone de stockage" btnImage="fas fa-trash" :dateEtatAu="dateEtatAu"
                        action="out" mode="stock" :zoneStockage="zone" :datesInvalides="datesInvalidesMvtStock" @envoyer-article="enregistrerEnStock($event)" @vider-zone-stockage="viderZoneStockage($event)" />
                    </div>
                  </div>
                  <b-collapse class="row" :id="'zone'+i" :accordion="'zone'+i" role="tabpanel" visible>
                    <div class="container-fluid" no-body v-for="(ingredient, index) in zone.ingredients" :key="index">
                      <div class="row card-header">
                        <a block class="btn-card-header flex-center-space-between col-12">
                          <div v-b-toggle="'ingredient'+index+ingredient.id">
                            <span class="when-opened" style="float:left;"><i class="fa fa-chevron-down"></i></span>
                            <span class="when-closed" style="float:left;"><i class="fa fa-chevron-right"></i></span>
                          </div>
                          <div class="col-8 flex-start">
                            <BtnAffichageMvtsStock :id="ingredient.id" class="mr-1" :ingredient="ingredient" /> {{ingredient.nom.toUpperCase()}}
                          </div>
                          <div class="col-2 flex-end">
                            {{ingredient.quantite | afficherArrondi}} {{ingredient.uc}}
                          </div>
                          <div class="col-2 flex-end">
                            {{ingredient.valeur | afficherPrix}}
                          </div>
                        </a>
                      </div>
                      <b-collapse class="row" :id="'ingredient'+index+ingredient.id" :accordion="'ingredient'+index+ingredient.id" role="tabpanel" visible>
                        <div class="table-responsive">
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th style="width:120px">Référence</th>
                                <th>Désignation</th>
                                <th style="width:120px">N° Lot</th>
                                <th style="width:120px">DLC/DDM</th>
                                <th style="width:120px">Quantité</th>
                                <th style="width:120px">Total HT</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="datepicker-trigger" v-for="(lot, indexLot) in ingredient.lots" :key="indexLot">
                                <td style="vertical-align: middle" :style="lot.quantite == 0 ? 'color: lightgrey;font-style:italic' : ''">
                                  {{lot.reference}}
                                </td>
                                <td style="vertical-align: middle">
                                  <i v-if="lot.termineLe" class="fab fa-creative-commons-nc-eu mr-1" style="color:orange" v-tooltip="{ content: 'Tarif obsolète depuis le ' + formatHumanDate(lot.termineLe) }"></i>
                                  <BtnAffichageMvtsStock class="mr-1" :id="lot.id" :lot="lot" />
                                  <span :style="lot.quantite == 0 ? 'color: lightgrey;font-style:italic' : ''">{{lot.designationFournisseur}} <i>({{lot.valeurUnitaire | afficherPrix}} / {{lot.uc}})</i></span>
                                </td>
                                <td style="vertical-align: middle" :style="lot.quantite == 0 ? 'color: lightgrey;font-style:italic' : ''">
                                  {{lot.NumLot}}
                                </td>
                                <td style="vertical-align: middle" :style="lot.quantite == 0 ? 'color: lightgrey;font-style:italic' : ''">
                                  <i v-if="dluoProcheOuDepassee(lot) || dlcProche(lot) || dlcDepassee(lot)" class="fas fa-exclamation-triangle" :style="dlcDepassee(lot) ? 'color:red' : 'color:orange'"
                                    v-tooltip="{ content: (dluoProcheOuDepassee(lot) ? 'DDM proche ou dépassée : ' : dlcProche(lot) ? 'DLC proche : ' : dlcDepassee(lot) ? 'DLC dépassée : ' : '') + formatHumanDate(lot.dlcDluo), placement: 'bottom' }"></i>
                                  {{formatHumanDate(lot.dlcDluo)}}
                                </td>
                                <td style="vertical-align: middle;text-align:right">
                                  <span :style="lot.quantite == 0 ? 'color: lightgrey;font-style:italic' : ''">{{lot.quantite | afficherArrondi}} {{lot.uc}}</span>
                                  <span v-if="!inventaireOuvert">
                                    <BtnAjoutMvtStock :id="'sousQte'+indexLot+lot.id" btnImage="fas fa-minus-circle cursor-pointer" title="Retirer une quantité" style="margin-left:5px;" :dateEtatAu="dateEtatAu" :lot="lot" :key="'sousQte'+ajoutArticleKey" action="out" mode="stock" :datesInvalides="datesInvalidesMvtStock" @envoyer-article="retirerDuStock($event)" />
                                    <BtnAjoutMvtStock :id="'ajoutQte'+indexLot+lot.id" btnImage="fas fa-plus-circle cursor-pointer" title="Ajouter une quantité" style="margin-left:5px;" :dateEtatAu="dateEtatAu" :lot="lot" :key="'ajoutQte'+ajoutArticleKey" action="in" mode="stock" :datesInvalides="datesInvalidesMvtStock" @envoyer-article="enregistrerEnStock($event)" />
                                  </span>
                                </td>
                                <td style="vertical-align: middle;text-align:right" :style="lot.quantite == 0 ? 'color: lightgrey;font-style:italic' : ''">
                                  {{(lot.quantite * lot.valeurUnitaire) | afficherPrix}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-collapse>
                    </div>
                  </b-collapse>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { connecteData } from "@/mixins/connecteData"
import { closeModal } from "@/mixins/index"
import { showAlerts } from '@/mixins/alerts'
import { formatageDate } from '@/mixins/dateFormats'
import { listeInventaires, ajouterEnStock, obtenirStock, sortirDuStock, viderZoneStockage } from '@/api/gpao/stock'
import { exporterStock } from '@/api/gpao/documents'
import { isWithinRange, isBefore } from 'date-fns'
import { constantesId } from "@/helpers/constantes"
import { lancerTelechargementDeResponse } from "@/helpers/utils"
import Calendrier from '@/components/Stock/Calendrier'
import BtnAjoutMvtStock from '@/components/Stock/BtnAjoutMvtStock'
import BtnAffichageMvtsStock from '../../components/Stock/BtnAffichageMvtsStock.vue'
import MultiSelect from "@/components/Inputs/MultiSelect"
import { verifierInventaireOuvert } from "@/helpers/verifications"
import _ from 'lodash'

export default {
  name: "PageStock",
  mixins: [connecteData, closeModal, formatageDate, showAlerts],
  components: {
    Calendrier,
    BtnAjoutMvtStock,
    MultiSelect,
    BtnAffichageMvtsStock
  },
  data() {
    return {
      stock: {},
      dateEtatAu: '',
      filtreDlcDluo: constantesId.filtreToutesDlc,
      optionsZonesDeStockage: [],
      filtreZonesDeStockage: [],
      filtreNomProduit: '',
      zonesDeStockageEnMemoire: [],
      dateEtatAuPourApi: '',
      dlcDluoPourApi: '',
      ajoutArticleKey: 0,
      enCalculStock: false,
      datesInvalidesMvtStock: [],
      key: 1,
      enExportation:false,
      afficherQuantitesAZero: false,
      inventaireOuvert: true,
    }
  },
  computed: {
    constantesId() {
      return constantesId
    }
  },
  methods: {
    reinitialiser() {
      this.filtreDlcDluo = constantesId.filtreToutesDlc
      this.filtreZonesDeStockage = []
      this.stock.zonesStockage = _.cloneDeep(this.zonesDeStockageEnMemoire)
    },
    filtrer() {
      this.enCalculStock = true
      setTimeout(() => { // affiche l'attente avant de continuer
        this.stock.zonesStockage = _.cloneDeep(this.zonesDeStockageEnMemoire)//application des filtres
        this.filtrerZones()
        this.filtrerNomProduit()
        this.filtrerDlcDluo()

        this.stock.zonesStockage.forEach(zone => {//suppression des zones vides
          _.remove(zone.ingredients, ing => !ing.lots.length)
        })
        _.remove(this.stock.zonesStockage, zone => !zone.ingredients.length)

        this.enCalculStock = false
      }, 1)
    },
    filtrerZones() {
      if (this.filtreZonesDeStockage.length) {
        _.remove(this.stock.zonesStockage, zone => !this.filtreZonesDeStockage.includes(zone.designation))
      }
    },
    filtrerNomProduit() {
      if (this.filtreNomProduit) {
        const recherche = this.filtreNomProduit.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        //console.log(recherche)
        this.stock.zonesStockage.forEach(z => {
          z.ingredients = z.ingredients.filter(x => x.nomRecherche.includes(recherche));
        })
      }
    },
    filtrerDlcDluo() {
      const dateDebut = this.dateSeule(this.dateEtatAu)

      if (this.filtreDlcDluo === constantesId.filtreDlcProches) {
        const dateFin = this.dateSeule(dateDebut.setDate(dateDebut.getDate() + 7))

        this.stock.zonesStockage.forEach(zone =>
          zone.ingredients.forEach(ing =>
            ing.lots.forEach((lot, lotIndex) => {
              if (!isWithinRange(this.dateSeule(lot.dlcDluo), dateDebut, dateFin)) {
                this.$delete(ing.lots, lotIndex)
              }
            })
          )
        )
      }

      if (this.filtreDlcDluo === constantesId.filtreDlcDepassees) {
        this.stock.zonesStockage.forEach(zone =>
          zone.ingredients.forEach(ing =>
            ing.lots.forEach((lot, lotIndex) => {
              if (!isBefore(this.dateSeule(lot.dlcDluo), dateDebut)) {
                this.$delete(ing.lots, lotIndex)
              }
            })
          )
        )
      }
    },
    dluoProcheOuDepassee(lot) {
      if (lot.dlc || !lot.dlcDluo)
        return false
      let today = new Date()
      let dateLimite = this.formatDateAvecTirets(new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7))
      let dluoAvecTirets = this.formatDateAvecTirets(lot.dlcDluo)
      if (dluoAvecTirets <= dateLimite)
        return true
      return false
    },
    dlcProche(lot) {
      if (!lot.dlc || !lot.dlcDluo)
        return false
      let today = new Date()
      let dateDebut = this.formatDateAvecTirets(today)
      let dateFin = this.formatDateAvecTirets(new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7))
      let dlcAvecTirets = this.formatDateAvecTirets(lot.dlcDluo)
      if (dlcAvecTirets > dateDebut && dlcAvecTirets <= dateFin)
        return true
      return false
    },
    dlcDepassee(lot) {
      if (!lot.dlc || !lot.dlcDluo)
        return false
      let today = new Date()
      let dateLimite = this.formatDateAvecTirets(today)
      let dlcAvecTirets = this.formatDateAvecTirets(lot.dlcDluo)
      if (dlcAvecTirets <= dateLimite)
        return true
      return false
    },
    async retirerDuStock(event) {
      await sortirDuStock(event.date, event.article)

      this.alerteSucces('Quantité retirée !')

      return obtenirStock(this.etabCourantId, this.dateEtatAuPourApi, this.afficherQuantitesAZero)
        .then((response) => {
          this.stock = response.data
          return
        })

    },
    setDateEtatAu(event) {
      if (event && event !== this.dateEtatAu) {
        this.dateEtatAu = event
        this.dateEtatAuPourApi = this.formatDateAvecTirets(event)
        let date = null
        if (this.dateEtatAu === this.formatDateAvecTirets(new Date())) {
          date = this.formatDateAvecTirets(new Date())
        } else {
          date = this.dateEtatAuPourApi
        }
        this.enCalculStock = true
        return obtenirStock(this.etabCourantId, date, this.afficherQuantitesAZero)
          .then((response) => {
            this.stock = response.data
            this.optionsZonesDeStockage = response.data.zonesStockage.map(zone => zone.designation)
            this.zonesDeStockageEnMemoire = _.cloneDeep(response.data.zonesStockage)
            this.ajoutArticleKey++
            this.key += 1
            this.filtrer()
            return
          })
      }
    },
    filtrerQuantitesAZero() {
      this.enCalculStock = true
      return obtenirStock(this.etabCourantId, this.dateEtatAuPourApi, this.afficherQuantitesAZero)
        .then((response) => {
          this.stock = response.data
          this.optionsZonesDeStockage = response.data.zonesStockage.map(zone => zone.designation)
          this.zonesDeStockageEnMemoire = _.cloneDeep(response.data.zonesStockage)
          this.ajoutArticleKey++
          this.key += 1
          this.filtrer()
          return
        })
    },
    viderZoneStockage(event) {
      return viderZoneStockage(event.date, event.zoneStockage)
        .then(() => {
          this.alerteSucces('Zone de stockage vidée !')
          return obtenirStock(this.etabCourantId, event.date, this.afficherQuantitesAZero)
        })
        .then((response) => {
          this.stock = response.data
          return
        })
    },
    enregistrerEnStock(event) {
      return ajouterEnStock(event.date, event.article)
        .then(() => {
          this.alerteSucces('Article ajouté !')
          return obtenirStock(this.etabCourantId, event.date, this.afficherQuantitesAZero)
        })
        .then((response) => {
          this.stock = response.data
          return
        })
    },
    async exporter(date) {
      try {
        this.enExportation = true
        const response = await exporterStock(this.etabCourantId, date, this.afficherQuantitesAZero)
        lancerTelechargementDeResponse(response)
      } finally {
        this.enExportation = false
      }
    },
    async verifierInventaire() {
      this.inventaireOuvert = await verifierInventaireOuvert(this.etabCourantId)
    }
  },
  mounted() {
    this.verifierInventaire()
  },
  created() {
    this.dateDentree = this.formatDateAvecTirets(new Date())
    this.dateDentreePourApi = this.formatDateAvecTirets(new Date())
    this.setDateEtatAu(this.formatDateAvecTirets(new Date()))
    return listeInventaires(this.etabCourantId)
      .then((response) => {
        this.datesInvalidesMvtStock = response.data.map(el => this.formatDateAvecTirets(el.date))
        return
      })
  }
}
</script>

<style scoped>
.label-input {
  font-size: 1rem;
  color: #757575;
  margin-bottom: 0.5rem;
}
.push-top-item-header {
  margin-top: 1.9rem;
}
.refresh {
  font-size: 3rem;
  color: #757575;
}
</style>

<style>
label,
.v-input {
  font-size: 14px !important;
}
#datepicker-etat-au {
  width: 140px !important;
}
.v-input--selection-controls__input {
  margin-right: 0 !important;
}
</style>