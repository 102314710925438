<template>
  <nav class="header-navbar navbar-expand-md navbar navbar-with-menu fixed-top navbar-semi-dark navbar-shadow">
    <div class="navbar-wrapper">
      <div class="navbar-header">
        <ul class="nav navbar-nav flex-row position-relative">
          <li class="nav-item mobile-menu d-md-none mr-auto">
            <a class="nav-link nav-menu-main menu-toggle hidden-xs is-active" href="#"><i class="ft-menu font-large-1"></i></a>
          </li>
          <li class="nav-item mr-auto">
            <a class="navbar-brand" href="/">
              <!-- <img alt="Mercudyn 5" src="../assets/images/logo/logo-mercudyn5_blanc-x1.png"
              srcset="../assets/images/logo/logo-mercudyn5_blanc-x1.png 1x,
              ../assets/images/logo/logo-mercudyn5_blanc-x2.png 2x"
              style="width: 166px;margin-top: -1px;margin-left: 8px;"/> -->
              <img alt="M5" src="../assets/images/logo/MD5-acronyme-blanc.png" style="width: 50px!important;margin: 0!important;" />
            </a>
          </li>
          <li class="nav-item d-md-none">
            <a class="nav-link open-navbar-container" data-toggle="collapse" data-target="#navbar-mobile"><i class="fa fa-ellipsis-v"></i></a>
          </li>
        </ul>
      </div>
      <div class="navbar-container content">
        <div class="collapse navbar-collapse" id="navbar-mobile">
          <ul class="nav navbar-nav mr-auto float-left">
            <li class="nav-item">
              <h4 class="content-header-title">{{ currentRouteName }}</h4>
            </li>
          </ul>
          
          <ul class="nav navbar-nav float-right">
            <li v-if="utilisateurCourant && etablissementsCourants && etablissementsCourants.length > 1" class="nav-item">
              <SelectEtablissement :etablissement-id="etabCourantId" @etablissement-selectionne="changerEtablissement($event)" :key="userId" title="Etablissement en cours" />
            </li>
            <li v-else-if="utilisateurCourant && $route.name !== 'Profil' &&  (etablissementsCourants && etablissementsCourants.length == 1)" class="nav-item">
              {{userEtablissementUniqueERS}}
            </li>
            <li v-else class="nav-item">
            </li>
            <li class="nav-item disabled" style="position:relative;">
              <span id="headwayapp" class="nav-link" href="" style="padding: 0rem 0.5rem 0rem 1rem; font-size:1.5em;"><i class="ft-bell"></i></span>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name: 'Profil'}" style="padding: 0rem 0.5rem 0rem 0.5rem; font-size:1.5em;" :title="'Mon profil ' + utilisateurCourant.userName"><i class="ft-user"></i></router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name: 'Configuration'}" style="padding: 0rem 0.5rem 0rem 0.5rem; font-size:1.5em;"><i class="ft-settings" title="Configuration"></i></router-link>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="logout" style="padding: 0rem 0rem 0rem 0.5rem; font-size:1.5em;" title="Déconnexion"><i class="ft-log-out"></i></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { AUTH_LOGOUT } from "../store/actions/auth"
import SelectEtablissement from '@/components/Etablissements/SelectEtablissement'
import { connecteData } from "@/mixins/connecteData"
import lscache from 'lscache'
import { chargerMercuriales } from '@/helpers/mercuriales'
import { chargerDonneesEtablissement } from '@/helpers/definitions'
export default {
  name: "topbar",
  components: {
    SelectEtablissement
  },
  mixins: [ connecteData],
  methods: {
    goHome() {
      this.$router.push("/")
    },
    async logout() {
      await this.$store.dispatch("auth/" + AUTH_LOGOUT)
      this.$router.push({ name: "Home" })
    },
    changerEtablissement(etablissementId) {
      if (this.etabCourantId != etablissementId) {
        this.$store.commit('etablissements/setEtablissement', { etablissementId: etablissementId, userId: this.$store.state.user.userInfo.id })
        if (this.$route.params["etablissementId"]) {
          this.$route.params["etablissementId"] = etablissementId
          this.$router.replace({ name: this.$route.name, params: this.$route.params })
          lscache.flush()
        }
        return chargerDonneesEtablissement(this.etabCourantId)
          .then(function () {
            chargerMercuriales(this.etabCourantId, true)
          }.bind(this))
      }
    },
  },
  computed: {
    currentRouteName() {
      if (this.$route.name == "Home") {
        return this.$route.meta.message + " " + this.utilisateurCourant.userName + " !"
      } else {
        return this.$route.meta.title
      }
    },
    userName() {
      return this.utilisateurCourant && this.utilisateurCourant.userName
    },
    userEtablissementUniqueERS() {
      return this.etablissementsCourants[0].eRS
    }
  }
}
</script>
