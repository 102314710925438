<template>
  <div class="container-fluid">
    <div ref="filtres" class="card mb-2">
      <div class="card-body">
        <div class="row">
          <div class="col-9">
            <SelectDatesMenusPlansServices :key="keySelect" :dateDebut="dateDebut" :dateFin="dateFin" :serviceIds="serviceIds" :menuPlanIds="menuPlanIds" viderMenusPlans="Vider" @changer-dms="surChangementParametre" :disabled="enCalculBesoin ||enCoursGenerationCommande" />
          </div>
          <div class="col-3" v-if="besoins.length && afficherLivraisons">
            <button class="btn btn-primary" @click="afficherLivraisons=false" :disabled="enCoursGenerationCommande" title="Revenir aux calculs">
              <i class="fa fa-cog fa-spin fa-fw" v-show="enCoursGenerationCommande"></i>
              &lt;Calculs
            </button>
            <br />
            <button class="btn btn-primary" @click="genererCommande()" :disabled="enCoursGenerationCommande || erreursFournisseurs" style="margin-top:10px;">
              <i class="fa fa-cog fa-spin fa-fw" v-show="enCoursGenerationCommande"></i>
              Commander
            </button>
          </div>
          <div class="col-3" v-if="!afficherLivraisons">
            <button class="btn btn-primary" @click="checkFourn()" title="Déterminer les dates de livraisons" v-if="besoins.length && fournisseursBienConfigures">
              >Dates de livraison
            </button>
            <span class="error" v-if="besoins.length  && fournisseurs.length && !fournisseursBienConfigures">Des fournisseurs sont mal configurés</span>
            <br />
            <button class="btn btn-primary" @click="recalculer()" :disabled="enCalculBesoin" style="margin-top:10px;">
              <i class="fa fa-cog fa-spin fa-fw" v-show="enCalculBesoin"></i>
              Recalculer
            </button>
          </div>
          <div class="col-12" style="text-align:right;">
            <div v-if="erreursBesoins || (afficherLivraisons && erreursFournisseurs)" class="card-text badge badge-danger block mt-4" style="font-size:12px;width:300px;">
              Des erreurs apparaissent dans la liste
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="messageInfo">
      <div class="col-12" style="margin-top:250px;">
        <div class="card">
          <div class="card-text text-center">{{messageInfo}}</div>
        </div>
      </div>
    </div>
    <div class="card" v-if="besoins.length && afficherLivraisons">
      <div class="card-body">
        <label>Affichage :</label>
        <b-button :variant="currentSort.startsWith('parNom') ? 'primary' : 'secondary'" style="margin: 0 5px;" @click="changeSort('parNom')">
          Par noms
        </b-button>
        <b-button :variant="currentSort.startsWith('parDate') ? 'primary' : 'secondary'" style="margin: 0 5px;" @click="changeSort('parDate')">
          Par dates
        </b-button>
      </div>
      <div class="col-12" ref="datesLivraison">
        <DatesLivraisonParFournisseur :disabled="enCoursGenerationCommande" :fournisseurs='fournisseurs' :besoins='besoins' @date-modifiee="checkErreursFournisseurs" />
      </div>
    </div>
    <div class="row" v-if="besoins.length && !afficherLivraisons">
      <div class="col-8">
        <div class="card mb-0">
          <div class="card-header background-recherche-besoins text-white flex-center-space-between">
            <div class="card-title" style="width:80%">
              <VueFuse v-if="!premierChargementDeLaPage && besoins && besoins.length"
                :placeholder="compTermeDeRecherche"
                :list="besoins"
                :keys="['designation']"
                :search="termeDeRecherche"
                @fuse-input-changed="getTermeDeRecherche"
                @fuse-results-updated="retourRecherche" />
              <div style="text-align:right;position:relative">
                <span v-if="termeDeRecherche" v-tooltip="{ content: 'Effacer la recherche'}" @click="effacerLaRecherche()" class="fas fa-window-close"></span>
              </div>
            </div>
            <div class="prix-total text-white" v-if="besoins && besoins.length">
              Total : {{prixTotalLignes}}
            </div>
          </div>
          <div class="card-body flex-center-space-between">
            <div>
              <label>Affichage :</label>
              <b-button :variant="currentTab === 'general' ? 'primary' : 'secondary'" style="margin: 0 5px;" @click="changeTab('general')">
                General
              </b-button>
              <!--    <b-button :variant="currentTab === 'parDate' ? 'primary' : 'secondary'" style="margin: 0 5px;" @click="changeTab('parDate')">
                Par date
              </b-button> -->
              <b-button :variant="currentTab === 'parFournisseur' ? 'primary' : 'secondary'" style="margin: 0 5px;" @click="changeTab('parFournisseur')">
                Par fournisseur
              </b-button>
            </div>
            <b-button variant="secondary" style="margin: 0 5px;" @click="deduireStock()">
              Déduire le stock
            </b-button>
          </div>
        </div>
        <div class="mb-2">
          <div v-if="besoins.length" class="text-right" style="border-top: 1px solid #ececec;border-bottom: 1px solid #ececec;" :key="keyBesoins">
            <div v-if="currentTab === 'general'" :key="'1-' + tabKey">
              <!-- General -->
              <LigneProduit v-for="(ingredient, index) in filteredList" :ingredient="ingredient" :index="index" :key="'besoins'+index+ingredient.ingredientId+ingredient.prixUnitaire" :currentItem="currentItem" @apercu-recettes="apercuRecettes($event)" @quantite-modifiee="surQuantiteModifiee" @selection-article="surArticleSelectionne" @toggle-favori="toggleFavori" :recettes="recettesPresentes" />
            </div>
            <div v-else-if="currentTab === 'parDate'" :key="'2-' + tabKey">
              <!-- par date -->
              <div v-for="(date, iDate) in listeParDate" :key="date.livreLe">
                <div v-if="date">
                  <div class="container-fluid bg-gradient-bleu text-white text-left">
                    <h5>Livraison prévue le : {{new Date(date.livreLe).toLocaleDateString('fr-FR', {weekday: "long", year: "numeric", month: "long", day: "numeric"})}}</h5>
                  </div>
                  <div v-for="(fourn, iFourn) in date.fournisseurs" :key="iFourn">
                    <div v-if="fourn">
                      <div class="container-fluid bg-gradient-bleu text-white text-left">{{fourn.fournisseurNom}}</div>
                      <LigneProduit v-for="(ingredient, iIngredient) in fourn.produits" :ingredient="ingredient" :index="ingredient.index" :key="'besoins'+iDate+iIngredient+ingredient.ingredientId" @quantite-modifiee="surQuantiteModifiee" :currentItem="currentItem" @apercu-recettes="apercuRecettes($event)" @selection-article="surArticleSelectionne" @toggle-favori="toggleFavori" :recettes="recettesPresentes" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="currentTab === 'parFournisseur'" :key="'3-' + tabKey">
              <!-- par fournisseur -->
              <div v-for="(fourn, iFourn) in listeParFournisseurs" :key="iFourn">
                <div v-if="fourn">
                  <div class="container-fluid bg-gradient-bleu text-white text-left">{{fourn.fournisseurNom}}</div>
                  <LigneProduit v-for="(ingredient, iIngredient) in fourn.produits" :ingredient="ingredient" :index="ingredient.index" :key="'besoins'+iFourn+'-'+iIngredient" :currentItem="currentItem" @quantite-modifiee="surQuantiteModifiee" @apercu-recettes="apercuRecettes($event)" @selection-article="surArticleSelectionne" @toggle-favori="toggleFavori" :recettes="recettesPresentes" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref="colonneDroite" class="col-4 push-top" style="position:fixed;right:0px;width:33%;">
        <div v-if="besoins.length && (fournisseurs.length || (affichageUsage.utilisations && affichageUsage.utilisations.length))" class="utilisation">
          <div ref="cardDroite" style="overflow-y:auto;">
            <div v-if="besoins.length && affichageUsage.utilisations && affichageUsage.utilisations.length" class="card" style="position:static;">
              <div class="card-body">
                <ul>
                  <li v-for="(utilisation, index) in affichageUsage.utilisations" :key="'recette'+index" class="li-recettes">
                    {{utilisation.date}} - <i>{{utilisation.service}}</i> - {{utilisation.quantite}} {{utilisation.upBesoin}}<template v-if="utilisation.menu"> - {{utilisation.menu}}</template>
                    <br />
                    <RecettePopUpIngredients :estLien="true" :recetteId="utilisation.recetteId" :recetteNom="recetteNom(utilisation.recetteId)" :positionFixedRight="true" />
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="besoins.length && fournisseurs.length" :key="keyRecap" class="card" style="margin-bottom:0;">
              <div class="card-header background-recherche-besoins text-white" style="font-weight:bold;">
                Récapitulatif totaux par fournisseurs
              </div>
              <div class="card-body">
                <table class="table table-sm table-bordered recapFourn pb-0 mb-0">
                  <colgroup>
                    <col>
                    <col style="width: 60px">
                    <col style="width: 70px">
                    <col style="width: 70px">
                  </colgroup>
                  <thead>
                    <tr>
                      <th>Fournisseur</th>
                      <th>Lignes</th>
                      <th>Franco HT</th>
                      <th>Total HDT</th>
                    </tr>
                  </thead>
                  <tr v-for="(fourn, i) in fournisseurs" :key="'fournisseur' + i" @click="showFourn(fourn.foId)"
                  :title="fourn.valid ? 'Cliquez pour afficher les paramètres de ce fournisseur' : 'Erreur de paramétrage de ce fournisseur, cliquez pour corriger'" style="cursor:pointer;">

                    <b-modal :id="'modalDetailsFourn' + fourn.foId" :size="fourn.estLocal ? 'xl' : 'md'" hide-footer header-bg-variant="gradient-bleu" header-text-variant="light">
                      <template slot="modal-title">
                        <h3>{{fourn.nom}}</h3>
                      </template>
                      <template slot="default">

                        <ParametresDepot :fournisseurId="fourn.foId" @save-fourn="saveFourn($event)" @hide-fourn="hideFourn(fourn.foId)" />

                      </template>
                    </b-modal>

                    <td :style="fourn.valid ? '' : 'color:red;'">{{fourn.nom}}</td>
                    <td>{{fourn.nbLignes}}</td>
                    <td :class="fourn.pbFranco ? 'pbFranco' : ''">{{fourn.franco}}</td>
                    <td>{{fourn.total}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ajouterCommandeDepuisBesoin } from '@/api/gpao/commandes'
import DatesLivraisonParFournisseur from '@/components/Commandes/DatesLivraisonParFournisseur'
import { connecteData } from '@/mixins/connecteData'
import { fournisseurs } from '@/mixins/fournisseurs'
import { formatageDate } from '@/mixins/dateFormats'
import { showAlerts } from '@/mixins/alerts'
import { arrondiMercuriale, arrondiNombre } from '@/helpers/utils'
import RecettePopUpIngredients from '@/components/Recettes/RecettePopUpIngredients'
import LigneProduit from '@/components/Divers/LigneProduit'
import VueFuse from '@/components/Divers/VueFuse'
import ParametresDepot from '@/components/Divers/ParametresDepot'
import SelectDatesMenusPlansServices from '@/components/Divers/SelectDatesMenusPlansServices'
import { setInfosFournisseur } from '@/api/gpao/user'
import vm from "@/helpers/vm-toolkit-vue"

export default {
  name: "PageCalculDesBesoins",
  components: {
    RecettePopUpIngredients,
    VueFuse,
    LigneProduit,
    ParametresDepot,
    SelectDatesMenusPlansServices,
    DatesLivraisonParFournisseur
  },
  mixins: [showAlerts, connecteData, fournisseurs, formatageDate],
  data() {
    return {
      enCoursGenerationCommande: false,
      enCalculBesoin: false,
      afficherLivraisons: false,
      termeDeRecherche: '',
      dateDebut: '',
      apiDateDebut: '',
      dateFin: '',
      apiDateFin: '',
      menuPlanIds: [],
      optionsMenuPlans: [],
      serviceIds: [],
      optionsServices: [],
      besoins: [],
      recettesPresentes: [],
      menusPresents: [],
      affichageUsage: {},
      premierChargementDeLaPage: true,
      messageInfo: '',
      commande: {},
      prixTotal: 0,
      filteredList: [],
      currentItem: null,
      currentTab: 'general',
      currentSort: 'parNom-a',
      tabKey: 1,
      keyRecap: 1,
      fournisseurs: [],
      keySelect: 1,
      keyBesoins: 1,
      erreursFournisseurs: false
    }
  },
  watch: {
    filteredList: function () {
      this.initRecapFourn()
      this.keyBesoins += 1
    },
    afficherLivraisons: function (afficher) {
      this.$nextTick(() => {
        if (afficher && this.$refs.datesLivraison) {
          this.$refs.datesLivraison.style.top = "0"
          this.checkErreursFournisseurs()
        } else if (typeof window.onscroll === "function") {
          window.onscroll()
        }
      })
    }
  },
  computed: {
    ...mapGetters("auth", ["isAdmin"]),
    ...mapGetters("fournisseurs", ["nomFournisseur"]),
    prixTotalLignes() {
      return arrondiMercuriale(this.prixTotal) + ' €'
    },
    compTermeDeRecherche() {
      if (this.termeDeRecherche) {
        return this.termeDeRecherche + '...'
      } else { 
        return 'Rechercher dans ' + this.besoins.filter(x=>!x.nonCommandable).length + (this.besoins.length <= 1 ? ' ingrédient...' : ' ingrédients...')
      }
    },
    listeParDate() {
      var dates = []
      var liste = []
      this.filteredList.forEach(function (p, i) {
        p.utilisations.forEach(function (u) {
          var iFourn
          var prod
          var iDate = dates.indexOf(u.livreLe)
          if (iDate === -1) {
            dates.push(u.livreLe)
            iDate = dates.indexOf(u.livreLe)
          }
          if (!liste[iDate]) {
            liste[iDate] = {
              livreLe: u.livreLe,
              fournisseurs: [],
              iFourns: []
            }
          }
          iFourn = liste[iDate].iFourns.indexOf(p.fournisseurId)
          if (iFourn === -1) {
            liste[iDate].iFourns.push(p.fournisseurId)
            iFourn = liste[iDate].iFourns.indexOf(p.fournisseurId)
          }
          if (!liste[iDate].fournisseurs[iFourn]) {
            liste[iDate].fournisseurs[iFourn] = {
              fournisseurId: p.fournisseurId,
              fournisseurNom: this.fournisseurNom(p.fournisseurId),
              produits: []
            }
          }
          prod = JSON.parse(JSON.stringify(p))
          prod.index = i
          prod.quantiteBesoinEnUC = u.quantite
          prod.quantitePCB = u.quantite
          liste[iDate].fournisseurs[iFourn].produits.push(prod)
        }.bind(this))
      }.bind(this))
      liste = liste.sort((a, b) => {
        return a.livreLe < b.livreLe ? -1 : 1
      })
      liste.forEach(d => {
        d.fournisseurs = d.fournisseurs.sort((a, b) => {
          return a.fournisseurNom < b.fournisseurNom ? -1 : 1
        })
      })
      return liste
    },
    listeParFournisseurs() {
      var liste = []
      var fourns = []
      this.filteredList.forEach(function (p, i) {
        var iFourn = fourns.indexOf(p.fournisseurId)
        if (iFourn === -1) {
          fourns.push(p.fournisseurId)
          iFourn = fourns.indexOf(p.fournisseurId)
        }
        if (!liste[iFourn]) {
          liste[iFourn] = {
            fournisseurId: p.fournisseurId,
            fournisseurNom: this.fournisseurNom(p.fournisseurId),
            produits: []
          }
        }
        p.index = i
        liste[iFourn].produits.push(p)
      }.bind(this))
      liste.sort((a, b) => a.fournisseurNom < b.fournisseurNom ? -1 : 1)
      return liste
    },
    fournisseursBienConfigures() {
      return this.fournisseurs.filter(el => !el.Jours).length === 0 && this.fournisseurs.every(x => x.valid)
    },
    erreursBesoins() {
      return this.besoins.length > 0 && this.besoins.some(el => el.designation === 'NON REMPLI' && !el.nonCommandable)
    },
  },
  methods: {
    ...mapActions("uxHelpers", ["afficherAttente", "cacherAttente"]),
    surQuantiteModifiee() {
      this.sauverCalculEnCours()
      this.calculPrixTotal()
      this.initRecapFourn()
    },
    recetteNom(id) {
      const recette = this.recettesPresentes.find(recette => recette.id === id)
      if (!recette) {
        console.log('Nom non trouvé ' + id)
        return 'Nom non trouvé ' + id
      }
      return recette.nom
    },
    effacerLaRecherche() {
      this.termeDeRecherche = ''
    },
    getTermeDeRecherche(evt) {
      this.currentItem = null
      this.termeDeRecherche = evt
      this.$emit('set-terme-de-recherche', evt)
    },
    retourRecherche(results) {
      // prévoir un onglet pour afficher les non commandables

      this.filteredList = results.filter(x => !x.nonCommandable)
    },
    calculPrixTotal() {
      this.prixTotal = this.besoins
        .filter(el => el.designation !== 'NON REMPLI')
        .map(el => Number(el.prixTotal))
        .reduce((a, b) => a + b, 0)
      return arrondiMercuriale(this.prixTotal) + ' €'
    },
    async genererCommande() {
      this.enCoursGenerationCommande = true
      this.commande.dateDebut = this.dateDebut
      this.commande.dateFin = this.dateFin
      this.commande.menuIds = this.menuPlanIds
      this.commande.servicesFiltres = this.serviceIds
      this.commande.lignes = this.filteredList.filter(x => x.tarifId).map((el) => {
        return {
          quantiteDemandee: el.quantitePCB, // la quantité PCB est celle que l'utilisateur peut modifier
          foId: el.fournisseurId,
          pcb: el.pcb,
          tarifId: el.tarifId,
          prixUnitaire: el.prixUnitaire,
          dateLivraison: this.fournisseurs.find(f => f.foId == el.fournisseurId) && this.fournisseurs.find(f => f.foId == el.fournisseurId).dateLivraison || "",
          utilisations: el.utilisations.map((u) => {
            return {
              menuId: u.menuId,
              composanteId: u.composanteId,
              recetteId: u.recetteId,
              serviceId: u.serviceId,
              livreLe: u.livreLe,
              dateUsage: u.dateUsage,
              quantite: u.quantite,
              portions: u.portions,
              quantiteEnUc: u.quantiteEnUc,
              ucBesoin: u.ucBesoin
            }
          })
        }
      })

      if (!this.fournisseursBienConfigures) {
        this.alerteErreur("Création de commande impossible : une date de livraison doit être renseignée pour tous les fournisseurs")
        this.enCoursGenerationCommande = false
        return
      }

      if (this.erreursBesoins) {
        let continuer = await this.alerteConfirmation("Erreurs dans la liste", "Attention, tous les besoins sur la période indiquée n'ont pas pu être satisfaits, la commande calculée ne vous permettra donc pas en l'état de tout produire.<br/>N'oubliez pas de commander par ailleurs ce qui vous manque.", "J'ai bien noté", "J'annule")
        if (!continuer) {
          this.enCoursGenerationCommande = false
          return
        }
      }

      let recapLignesFournisseurs = []
      recapLignesFournisseurs = this.fournisseurs
        .map(f => {
          return { nom: f.nom, prixTotal: f.total, dateLivraison: f.dateLivraison }
        })
      const recap = '<table style="border-spacing:25px;border-collapse:separate">' +
        recapLignesFournisseurs.reduce((actuel, f) => actuel + '<tr><td style="text-align:left"->' + f.nom + '</td><td  style="text-align:right">' + f.prixTotal + '</td><td style="text-align:right">' + this.formatHumanDateAvecJour(f.dateLivraison) + '</td></tr>', '')
        + '</table>'

      let inputValue = ''
      let result = await this.$swal({
        title: 'Générer cette commande ?',
        input: 'text',
        inputValue: inputValue,
        inputPlaceholder: 'Entrez le nom de votre commande...',
        html: recap, width: '680px',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        focusCancel: true,
        inputValidator: (value) => {
          // eslint-disable-next-line promise/avoid-new
          return new Promise((resolve) => {
            if (value === '') {
              resolve('Vous devez indiquer un nom pour cette commande')
            } else {
              resolve()
            }
          })
        }
      })
      if (result.value) {
        this.commande.nom = result.value

        let responseAjout = await ajouterCommandeDepuisBesoin(this.etabCourantId, this.commande)
        this.alerteSucces('Commande créée !')
        this.$store.commit('commandes/setDateDebutEnMemoire', responseAjout.data.dateDebut)
        this.$store.commit('commandes/setDateFinEnMemoire', responseAjout.data.dateFin)
        this.$router.push({ name: 'ListeDesCommandes' })
      }

      this.enCoursGenerationCommande = false
    },
    apercuRecettes(e) {
      this.currentItem = e.index
      this.affichageUsage = {}
      this.affichageUsage.utilisations = e.ingredient.utilisations.map(el => {
        let d = new Date(el.dateUsage)
        const r = this.recettesPresentes.find(recette => recette.id === el.recetteId);
        let menu = undefined
        if (this.menusPresents && this.menusPresents.length > 1) { // on n'affichera pas le menu s'il n'y en a qu'un
          menu = this.menusPresents.find(menu => menu.id === el.menuId).nom
        }
        let nom = "Non trouve " + el.recetteId
        if (r)
          nom = r.nom
        return {
          date: d.toLocaleDateString('fr-FR', { weekday: "long", year: "2-digit", month: "short", day: "numeric" }),
          service: this.$store.state.definitions.services.find(service => service.value === el.serviceId).text,
          nom,
          menu,
          recetteId: el.recetteId,
          quantite: el.quantite,
          upBesoin: el.upBesoin
        }
      })
    },
    async recalculer() {
      await this.setListe()
    },
    async surChangementParametre(event) {
      this.afficherAttente()
      this.dateDebut = event.dateDebut
      this.apiDateDebut = event.dateDebut.split('-').join('')
      this.dateFin = event.dateFin
      this.apiDateFin = event.dateFin.split('-').join('')
      this.menuPlanIds = event.menuPlanIds
      this.serviceIds = event.serviceIds
      this.sauverCalculEnCours()

      await this.setListe()
      this.cacherAttente()
    },
    async setListe() {
      this.messageInfo = ''
      this.besoins = []
      this.recettes = []
      let donneesChargees = this.chargeDeLocalStorage
      this.enCalculBesoin = true
      if (!donneesChargees) {
        if (!this.apiDateFin) {
          this.dateFin = this.dateDebut
          this.apiDateFin = this.apiDateDebut
        }
        let url = "/" + this.etabCourantId + "/calcul-des-besoins" + (this.menuPlanIds.length ? '/' + this.menuPlanIds + '/' : '/') + this.apiDateDebut + '/' + this.apiDateFin + (this.serviceIds.length ? '/' + this.serviceIds : '/')
        if (url.endsWith("///")) {//évite l'erreur 404 au refresh de la page
          url = url.slice(0, -3)
        }
        history.pushState({}, '', url)
        if (this.menuPlanIds.length && this.apiDateDebut && this.apiDateFin) {
          try {
            const response = await this.$store.dispatch('menus/chargerMenuBesoins',
              { etablissementId: this.etabCourantId, menuPlanIds: this.menuPlanIds, apiDateDebut: this.apiDateDebut, apiDateFin: this.apiDateFin, serviceIds: this.serviceIds });
            donneesChargees = response.data
          } finally {
            this.enCalculBesoin = false
          }
        }
        else {
          if (!this.menuPlanIds.length && !this.besoins) {
            this.messageInfo = 'Choisissez une ou plusieurs structures de menus'
          } else {
            this.messageInfo = 'Aucun résultat...'
          }
          this.sauverCalculEnCours()
          this.enCalculBesoin = false
          return;
        }
      }
      else {
        this.chargeDeLocalStorage = undefined
        this.$toast('Chargement du dernier calcul trouvé', {
          horizontalPosition: 'center', verticalPosition: 'top',
          className: ['toast-success'], duration: 1500
        })
      }
      this.recettesPresentes = donneesChargees.recettes || donneesChargees.recettesPresentes
      this.menusPresents = donneesChargees.menus || donneesChargees.menusPresents
      this.besoins = donneesChargees.besoins.filter(b => b.estACommander);
      if (!this.chargeDeLocalStorage)
        this.sauverCalculEnCours()
      if (this.besoins.length) {
        this.besoins.sort((a, b) => {
          if (!a.designation)
            return true
          return a.designation.localeCompare(b.designation)
        })
        this.besoins.forEach(b => {
          b.utilisations = b.utilisations.filter(u => u.estACommander)
          b.quantiteDemandee = b.utilisations.reduce((s, u) => s + u.quantiteEnUc, 0)
          b.quantitePCB = (Math.ceil(((b.quantiteDemandee * 1000) / (b.pcb * 1000)).toFixed(3)) * (b.pcb * 1000)) / 1000
          b.quantiteBesoinEnUC = arrondiNombre(b.utilisations.reduce((s, u) => s + u.quantiteEnUc, 0))
        })
        this.besoins.map(ing => {
          this.$set(ing, 'prixTotal', arrondiNombre(ing.quantitePCB * ing.prixUnitaire))
        })
        this.calculPrixTotal()
        this.premierChargementDeLaPage = false
        this.messageInfo = ''
      } else {
        this.messageInfo = 'Aucun résultat...'
      }
      this.enCalculBesoin = false
    },
    changeTab(newTab) {
      this.currentTab = newTab
      this.tabKey += 1
    },
    changeSort(newSort) {
      if (this.currentSort.startsWith(newSort)) {
        if (this.currentSort === newSort + "-a") {//toggle tri ascendant / descendant
          this.currentSort = newSort + "-d"
        } else {
          this.currentSort = newSort + "-a"
        }
      } else {//nouveau critère
        this.currentSort = newSort + "-a"
      }

      const ascendant = this.currentSort.endsWith("-a")
      if (this.currentSort.startsWith("parNom")) {//tri par nom
        this.fournisseurs.sort((a, b) => (ascendant ? 1 : -1) * a.nom.localeCompare(b.nom))
      } else {
        this.fournisseurs.sort((a, b) => {//tri par date
          if (a.dateLivraison === b.dateLivraison || (!a.dateLivraison && !b.dateLivraison)) {
            return 0
          }
          if (!a.dateLivraison || new Date(a.dateLivraison) < new Date(b.dateLivraison)) {
            return ascendant ? -1 : 1
          }
          return ascendant ? 1 : -1
        })
      }
      this.tabKey += 1
    },
    surArticleSelectionne(e) {
      var besoin = this.besoins.find(b => b.produitId === e.produitId)
      vm.add(e, besoin)
      this.$emit('set-terme-de-recherche', this.termeDeRecherche)
      this.initRecapFourn()
      this.calculPrixTotal()
      this.sauverCalculEnCours()
      this.keyBesoins += 1
    },
    toggleFavori(event) {
      var besoin = this.filteredList.find(b => b.produitId === event.produitId && b.fournisseurId === event.fournisseurId)
      if (besoin) {
        besoin.estFavori = event.estFavori
        this.sauverCalculEnCours()
      }
    },
    initRecapFourn() {
      var liste = []
      var fourns = []
      this.filteredList.forEach(function (p) {
        if (p.fournisseurId) {
          var iFourn = fourns.indexOf(p.fournisseurId)
          if (iFourn === -1) {
            fourns.push(p.fournisseurId)
            iFourn = fourns.indexOf(p.fournisseurId)
          }
          if (!liste[iFourn]) {
            liste[iFourn] = this.parametresFournisseur(p.fournisseurId)
            liste[iFourn].franco = this.fournisseurFP(p.fournisseurId)
            liste[iFourn].nbLignes = 0
            liste[iFourn].total = 0
          }
          liste[iFourn].nbLignes += 1
          liste[iFourn].total += arrondiNombre(p.prixTotal)
        }
      }.bind(this))
      liste.forEach(f => {
        if (f.total < f.franco) {
          f.pbFranco = true
        }
        f.total = arrondiMercuriale(f.total) + " €"
        f.franco = arrondiMercuriale(f.franco, 2) + " €"
        f.valid = Boolean(f.dfId && f.Jours && (!f.Mail || vm.is.email(f.Mail)))
      })
      liste = liste.sort((a, b) => {
        return a.nom < b.nom ? -1 : 1
      })
      this.fournisseurs = liste
    },
    checkFourn() {
      var fourn = this.fournisseurs.find(f => !f.valid)
      if (fourn) {
        this.showFourn()
      } else {
        this.hideFourn()
        this.afficherLivraisons = true
      }
    },
    saveFournCheckNext(e) {
      return this.saveFourn(e)
        .then(this.checkFourn)
    },
    showFourn(id) {
      if (id) {
        this.$bvModal.show('modalDetailsFourn' + id)
      } else {
        this.$bvModal.show('modalValidationFourn')
      }
    },
    hideFourn(id) {
      if (id) {
        this.$bvModal.hide('modalDetailsFourn' + id)
      } else {
        this.$bvModal.hide('modalValidationFourn')
      }
    },
    saveFourn(fourn) {
      fourn.dfId = Number(fourn.dfId)
      this.hideFourn(fourn.foId)
      return setInfosFournisseur(fourn)
        .then(() => {
          this.$store.commit('user/setUserInfo', fourn)
          this.keyMain += 1
          this.$toast('Modifications enregistrées', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            className: ['toast-success'],
            duration: 1500
          })
          this.initRecapFourn()
          return
        })
        .catch(e => {
          console.error(e)
          this.initData()
          this.keyMain += 1
          this.alerteErreur("Echec de l'enregistrement");
        })
    },
    sauverCalculEnCours() {
      var payload = {
        dateDebut: this.apiDateDebut,
        dateFin: this.apiDateFin,
        planMenuIds: [],
        serviceIds: [],
        pourEtab: this.etabCourantId
      }
      if (this.menuPlanIds.length && this.dateDebut && this.dateFin) {
        payload.planMenuIds = this.menuPlanIds;
        payload.serviceIds = this.serviceIds;
      }
      payload.besoins = this.besoins;
      payload.recettesPresentes = this.recettesPresentes;
      payload.menusPresents = this.menusPresents;
      try {
        localStorage.setItem("calculBesoins", JSON.stringify(payload))
      } catch {
        console.log("Echec d'enregistrement du calcul en cours")
      }
    },
    chargerCalculDuLocalStorage() {
      let calculStocke
      try {
        var b = localStorage.getItem("calculBesoins");
        if (b) {
          calculStocke = JSON.parse(b)
        }
      } catch {
        console.error("PCB-001: Impossible de lire le calcul de besoin stocké")
        localStorage.removeItem("calculBesoins")
      }
      return calculStocke
    },
    deduireStock() {
      return this.$swal({
        title: 'Déduire le stock',
        html: "Le stock affiché est instantané et ne tient pas compte des mouvements éventuels futurs.<br/><br/>Êtes-vous certain de vouloir déduire votre stock ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        focusCancel: true
      })
      .then(result => {
        if (result.value) {
          this.filteredList.forEach(besoin => {
            if (besoin.quantiteStock > 0) {
              var quantite = besoin.quantiteBesoinEnUC - besoin.quantiteStock
              if (quantite <= 0) {
                quantite = 0
              } else {
                quantite = Math.ceil(quantite / besoin.pcb) * besoin.pcb
              }
              besoin.quantitePCB = quantite
              besoin.prixTotal = arrondiNombre(besoin.quantitePCB * besoin.prixUnitaire)
            }
          })
          this.surQuantiteModifiee()
          this.tabKey += 1
        }
        return
      })
      
    },
    checkErreursFournisseurs() {
      this.erreursFournisseurs = this.fournisseurs.filter(f => !f.dateLivraison.length).length > 0
    },
    fixColonneDroite() {
      var vue = this
      function resize() {
        if (vue.$refs.colonneDroite && vue.$refs.cardDroite && !this.afficherLivraisons) {
          var top = vue.$refs.filtres.getBoundingClientRect()
          top = top.top + top.height
          if (top < 40) {
            top = 40
          }
          top += 7
          var maxHeight = document.documentElement.clientHeight - top
          vue.$refs.colonneDroite.style.top = top + "px"
          vue.$refs.cardDroite.style.maxHeight = maxHeight + "px"
        }
      }

      window.onresize = resize
      window.onscroll = resize
    }
  },
  mounted() {
    let planMenuIds = [], services = []
    let dateDebut = '', dateFin = ''
    const calculStocke = this.chargerCalculDuLocalStorage()

    if ((calculStocke) && (calculStocke.dateDebut && calculStocke.dateFin
      && (!calculStocke.pourEtab || calculStocke.pourEtab == this.etabCourantId))) {
      this.chargeDeLocalStorage = calculStocke
      planMenuIds = calculStocke.planMenuIds
      dateDebut = calculStocke.dateDebut
      dateFin = calculStocke.dateFin
      services = calculStocke.serviceIds
    }

    if (this.$route.params.dateDebut && this.$route.params.dateFin) {
      if (this.$route.params.planMenuIds.length) {
        planMenuIds = this.$route.params.planMenuIds.split(',')
      }
      if (this.$route.params.services) {
        services = this.$route.params.services.split(',')
      }
      dateDebut = this.$route.params.dateDebut
      dateFin = this.$route.params.dateFin
    }

    const dateIso = (date) => {
      let annee = date.substring(0, 4)
      let mois = date.substring(4, 6)
      let jour = date.substring(6, 8)
      return annee + '-' + mois + '-' + jour
    }

    if (dateDebut && dateFin) {
      this.menuPlanIds = planMenuIds
      this.serviceIds = services
      this.apiDateDebut = dateDebut
      this.apiDateFin = dateFin
      this.dateDebut = dateIso(dateDebut)
      this.dateFin = dateIso(dateFin)
    }

    this.keySelect += 1

    this.setListe()

    this.fixColonneDroite()
  },
  beforeRouteLeave(to, from, next) {
    var payload = {
      dateDebut: this.apiDateDebut,
      dateFin: this.apiDateFin,
      planMenuIds: [],
      servicesId: [],
      pourEtab: this.etabCourantId
    }
    if (this.menuPlanIds.length && this.dateDebut && this.dateFin) // WEIRD : pourquoi pas apidateDebut ?
    {
      payload.planMenuIds = this.menuPlanIds;
      payload.serviceIds = this.serviceIds;
    }
    this.sauverCalculEnCours()
    window.onresize = undefined
    window.onscroll = undefined
    next()
  }
}
</script>

<style lang="scss" scoped>
h3 {
  font-size: 1.25rem;
}
.article-selectionne {
  color: #53d3a2;
  font-size: 1.5rem;
}
.fa-check {
  cursor: pointer;
}
.bordered {
  border: 1px solid rgb(207, 207, 207);
}

.fa-window-close {
  cursor: pointer;
  position: absolute;
  right: 2.2rem;
  top: -3rem;
  font-size: 2rem;
  color: #d0d0d0;
}
.prix-total {
  text-align: right;
  padding-right: 20px;
  font-size: 1.5rem;
  color: #6c757d;
}
.recapFourn td:nth-last-child(1),
.recapFourn td:nth-last-child(2) {
  text-align: right;
}
.recapFourn .pbFranco {
  background-color: #8a0100;
  color: white;
}

.besoin-selected input {
  color: black !important;
}
</style>

