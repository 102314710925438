import Vue from 'vue'
import { chargerTousLesPlans, chargerTousLesMenus, chargerMenuDateDebut, obtenirMenu, chargerMenuBesoins } from '@/api/gpao/menus'
const menus = {
  namespaced: true,
  state: {
    menus: [],
    date: '',
    menuId: '',
    dateEffectifs: '',
    filtreEffectifs: 0,
    filtreStrucActivesUniquement:true,
  },
  getters: {
    menuplans(state) {
      return state.plans
    },
    menusDate(state) {
      return state.menusDate
    }
  },
  actions: {
    async chargerTousLesPlans(context, id) {
      return chargerTousLesPlans(id)
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        console.error(error)
      })
    },
    async chargerTousLesMenus(context, id) {
      return chargerTousLesMenus(id)
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        console.error(error)
      })
    },
    async chargerMenuDateDebut(context, payload) {
      return chargerMenuDateDebut(payload)
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        console.error(error)
      })
    },
    async chargerMenuBesoins(context, payload) {
      return chargerMenuBesoins(payload)
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        console.error(error)
      })
    },
    async obtenirMenu(context, id) {
      return obtenirMenu(id)
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        console.error(error)
      })
    }
  },
  mutations: {
    setItems(state, items) {
      Vue.set(state, items.key, items["data"])
      // console.log('mutation setItems /' + items.key)
    },
    setRouteEnMemoire(state, payload) {
      Vue.set(state, 'date', payload.date)
      Vue.set(state, 'menuId', payload.menuId)
    },
    saveDateEffectifs(state, date) {
      Vue.set(state, 'dateEffectifs', date)
    },
    saveFiltreEffectifs(state, filtre) {
      Vue.set(state, 'filtreEffectifs', filtre)
    },
    cleanDuMenuId(state) {
      Vue.set(state, 'menuId', null)
    },
    saveFiltreStructActives(state, filtre) {
      Vue.set(state, 'filtreStrucActivesUniquement', filtre)
    }
  }
}

export default menus
