import { mapGetters } from 'vuex'

/* Data du connecté */
export const connecteData = {
  computed: {
    ...mapGetters("auth",["isAdmin"]),
    ...mapGetters({etabCourantId:"etablissements/etablissementId"}),
    ...mapGetters({utilisateurCourant:"user/utilisateurCourant"}),
    etabCourant() {
      if (!this.etablissementsCourants)
        return
      return this.$store.state.user.userInfo.etablissements.find(x => x.eId === this.etabCourantId);
    },
    etablissementsCourants() {
      return this.$store.state.user.userInfo.etablissements;
    },
    etablissementsCourantsTries() {
      const retour = [...this.$store.state.user.userInfo.etablissements];
      retour.sort((a, b) => a.eRS.localeCompare(b.eRS))
      return retour;
    },
    userId() {
      if (!this.utilisateurCourant)
        return undefined;
      return  this.utilisateurCourant.id;
    }
  }
}
