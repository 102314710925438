<template>
  <div>
    <div class="container-fluid">

      <div ref="filtres" class="card mb-2" style="position: sticky;top: 25px;z-index: 2;">
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <h5>Filtres sur statut de commande et date de livraison</h5>
              <div style="display: flex;">
                <div class="switch-container" style="align-items: flex-start;margin-top: -6px;">
                  <b-button :disabled="receptionEnCours()" v-tooltip="{ content: 'En création' }" :class="status.includes(constantesId.etatCmdEnCours) ? 'btn-primary' : 'btn-secondary'" style="margin:7px;" @click="chargerListeDesCommandes(constantesId.etatCmdEnCours)">
                    <i class="fas fa-clipboard-list cursor-pointer" style="font-size:1.8rem;"></i>
                  </b-button>
                  <b-button :disabled="receptionEnCours()" v-tooltip="{ content: 'Envoyée' }" :class="status.includes(constantesId.etatCmdEnvoyee) ? 'btn-primary' : 'btn-secondary'" style="margin:7px;" @click="chargerListeDesCommandes(constantesId.etatCmdEnvoyee)">
                    <i class="fas fa-shipping-fast cursor-pointer" style="font-size:1.7rem;margin-left:-5px;margin-right:-5px;margin-top:2px;"></i>
                  </b-button>
                  <b-button :disabled="receptionEnCours()" v-tooltip="{ content: 'Reçue' }" :class="status.includes(constantesId.etatCmdRecue) ? 'btn-primary' : 'btn-secondary'" style="margin:7px;" @click="chargerListeDesCommandes(constantesId.etatCmdRecue)">
                    <i class="fas fa-clipboard-check cursor-pointer" style="font-size:1.8rem;"></i>
                  </b-button>
                </div>

                <div class="datepicker-trigger" style="position:static;margin-right: 5px;">
                  <v-date-picker :disabled="receptionEnCours()" is-range :columns="2" :step="1" v-model="selectedDates" style="margin-right: 5px">
                    <template v-slot="{ inputValue, inputEvents }">
                      <div class="flex flex-col sm:flex-row justify-start items-center">
                        <div class="relative flex-grow">
                          <input style="height:40px;"
                            class="flex-grow pl-8 pr-5 py-1 bg-gray-100 border rounded w-full text-gray-900"
                            :value="inputValue.start || inputValue.end ? inputValue.start + ' - ' + inputValue.end : 'Choisir les dates...'"
                            v-on="inputEvents.start"
                          />
                        </div>
                      </div>
                    </template>
                  </v-date-picker>
                  <div>
                    <span v-if="dateDebut || dateFin" style="cursor:pointer;position:absolute;left:380px;top:34px;font-size:2rem;color:#d0d0d0;" v-tooltip="{ content: 'Effacer les dates'}" @click="effacerLesDates()" class="fas fa-window-close"></span>
                  </div>
                </div>

                <MultiSelect style="width: 33%;" :disabled="receptionEnCours()" :value="fournisseurIds" :items="fournisseursPourFiltre" itemText="nom" itemValue="id" placeholder="Fournisseurs..." emptyText="Tous les fournisseurs ont été ajoutés" @blur="filtreFournChanged" />
              </div>
            </div>
            <div class="col-6">
              <h5>Filtres sur menus</h5>
              <SelectDatesMenusPlansServices :key="keySelect" :dateDebut="dataDMS.dateDebut" :dateFin="dataDMS.dateFin" :serviceIds="dataDMS.serviceIds" :menuPlanIds="dataDMS.menuPlanIds" viderMenusPlans="Vider" @changer-dms="dmsChanged" />
            </div>
          </div>
        </div>
      </div>

      <div class="row">

        <!-- Panneau de droite - Commandes -->
        <transition name="fade" mode="out-in">
          <div class="col-12" style="z-index:1;">
            <div class="card">

              <div class="card-header bg-gradient-bleu text-white flex-space-between" style="padding-top:6px;padding-bottom:7px;">
                <h4 class="card-title" style="padding-top:5px;text-transform:none;font-weight:bold;font-size:18px;"><b>Commandes</b></h4>
                <div>
                  <div style="display:inline;position:relative;margin-right:8px;">
                    <input type="text" placeholder="Filtrer par" v-model="filtreCommandes">
                    <span v-if="filtreCommandes" class="fas fa-window-close" title="Effacer la recherche" style="right:4px;top:-4px;" @click="filtreCommandes = ''"></span>
                  </div>
                  <button class="btn" style="background-color:white;font-weight:bold;font-size:12px;" :disabled="receptionEnCours()" @click="showAjoutCommande()">Nouvelle commande</button>
                </div>

                <b-modal ref="modalNouvelleCommande" size="lg" hide-footer
                header-bg-variant="gradient-bleu"
                header-text-variant="light">
                  <template slot="modal-title">
                    <h3>Créer une commande</h3>
                  </template>
                  <template slot="default">
                    <div class="form-group">
                      <label for="nomCommande">Nom de votre commande</label>
                      <input @focus="$event.target.select()" class="form-control" id="nomCommande" type="text" v-model="nouvelleCommande.nom">
                      <div class="error" v-if="!nouvelleCommande.nom">Vous devez indiquer un nom pour cette commande</div>
                    </div>
                    <div class="form-group mt-4">
                      <label class="mt-3"><i>Calculer à partir des menus de la période :</i></label>
                    </div>
                    <div class="parametres-besoins">
                      <SelectDatesMenusPlansServices :dateDebut="ncDateDebut" :dateFin="ncDateFin" :menuPlanIds="ncMenuPlanIds" :serviceIds="ncServiceIds" viderMenusPlans="Vider" @changer-dms="ncChangementParametre" />
                    </div>
                    <div style="margin: 2rem 0; text-align:right">
                      <button class="btn btn-success" @click="ajoutCommande" :disabled="!nouvelleCommande.nom">Créer</button>
                      <button class="btn btn-danger" style="margin-left:1rem;" @click="$refs.modalNouvelleCommande.hide()">Annuler</button>
                    </div>
                  </template>
                </b-modal>

              </div>

              <div class="card-body bg-white" style="margin-top:15px;border-radius:10px;" :key="keyCommandes">
                <div v-for="(commande, iCommande) in commandesFiltrees" :key="iCommande" class="card commande" :style="commande.afficherDetails ? 'border-bottom-left-radius:0px!important;border-bottom-right-radius:0px!important;' : ''">

                  <a block :class="'accordeon btn-card-header' + (commande.locked ? ' locked' : '')" :disabled="receptionEnCours()" v-b-toggle="'commande-' + commande.id"><!--titre commande-->
                    <div class="card-header flex-center-space-between" :class="receptionEnCours() && !receptionEnCours(commande) ? 'card-list' : 'bg-gradient-bleu text-white'" style="padding:0.65rem 1.25rem;">
                      <div><b>{{commande.nom}}</b></div>
                      <div>
                        <i v-if="commandeTitreIncident(commande.commandesFournisseurs)" :class="commandeTitreIncidentTraitee(commande.commandesFournisseurs) ? 'fa-vert' : 'fa-rose'" class="fas fa-exclamation-triangle" style="margin-left:0.8rem;font-size:1.7rem;"></i>
                        <i v-if="commandeTitreEnCreation(commande.commandesFournisseurs)" v-tooltip="{ content: 'En création' }" class="fas fa-clipboard-list" style="margin-left:0.8rem;font-size:1.7rem;"></i>
                        <i v-if="commandeTitreEnvoyee(commande.commandesFournisseurs)" v-tooltip="{ content: commandeTitreEnErreur(commande.commandesFournisseurs) ? 'Une commande est en erreur de mail' : 'Envoyé' }" class="fas fa-shipping-fast" :class="commandeTitreEnErreur(commande.commandesFournisseurs) ? 'text-danger' : ''" style="margin-left:0.8rem;font-size:1.6rem;"></i>
                        <i v-if="commandeTitreRecue(commande.commandesFournisseurs)" v-tooltip="{ content: 'Reçue' }" class="fas fa-clipboard-check" style="margin-left:0.8rem;font-size:1.7rem;"></i>
                        <b style="margin-left:0.8rem">Total HDT : {{totalCommande(commande) || commande.totalHDT}}&nbsp;€</b>
                        <span style="margin-left:5px;"><i class="fas fa-chevron-up"></i></span>
                      </div>
                    </div>
                  </a>

                  <b-collapse v-model="commande.afficherDetails" :id="'commande-' + commande.id" :key="keyCommande" accordion="commande" role="tabpanel" :class="{locked: commande.locked}" @show="chargerCommande(commande)" style="margin-top:10px;margin-bottom:0px;">

                    <div v-if="commande.enChargement" style="text-align:center;padding-bottom:5px;">
                      <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style="margin-left:-8px;margin-bottom:5px;" />
                    </div>

                    <div v-else>

                      <div v-if="!receptionEnCours() && !commande.locked" style="text-align:right;margin-bottom:10px;"><!--actions commande-->

                        <span style="float:left">
                          <button v-tooltip="{ content: 'Ouvrir les commandes fournisseurs' }" class="btn btn-light push-right-1rem" style="margin: 0 10px;" @click="ouvrirSousCommandes(commande)">
                            <i class="fas ft-chevrons-down"></i>
                          </button>
                          <button v-tooltip="{ content: 'Fermer les commandes fournisseurs' }" class="btn btn-light push-right-1rem" style="margin: 0 10px;" @click="fermerSousCommandes(commande)">
                            <i class="fas ft-chevrons-up"></i>
                          </button>
                        </span>

                        <span v-if="commandeEnCreation(commande)">
                          <b-button v-tooltip="{ content: 'Enregistrer les modifications sur la commande en base de données', placement: 'top' }" class="btn btn-success push-right-1rem" :disabled="!donneesModifiees" @click="modificationCommande(commande)">
                            <i class="fas fa-save"></i>
                          </b-button>
                        </span>

                        <span v-if="toutesSousCommandesEnCreation(commande) && commandePorteSurMenus(commande)">
                          <b-button v-tooltip="{ content: 'Recalculer à partir des menus', placement: 'top' }" class="btn btn-light push-right-1rem" @click="recalculer(commande)">
                            <i :class="'fas fa-calculator' + (calculEnCours ? ' icon-spin' : '')"></i>
                          </b-button>
                        </span>

                        <span v-if="commandeEnCreation(commande) && sousCommandesEnvoyees(commande).length === 0 && sousCommandesRecues(commande).length === 0">

                          <BoutonDuplicationCommande :disabled="donneesModifiees" :commandeGlobale="commande" @commande-dupliquee="rafraichirCommande(commande)" />

                          <b-button class="btn btn-light push-right-1rem" v-b-modal="'modal-renommer-commande-' + commande.id" v-tooltip="{ content: 'Renommer la commande', placement: 'top' }" @click="commande.nouveauNom = commande.nom">
                            <i class="fas fa-edit"></i>
                          </b-button>
                          <b-modal :id="'modal-renommer-commande-' + commande.id" size="md" hide-footer header-bg-variant="gradient-bleu" header-text-variant="light">
                            <template slot="modal-header">
                              <h3>Nom de la commande ?</h3>
                            </template>
                            <template slot="default">
                              <input type="text" class="container-fluid" v-model.trim="commande.nouveauNom" maxlength="80">
                              <div style="margin: 2rem 0; text-align:right">
                                <button class="btn btn-success" :disabled="!commande.nouveauNom || commande.nouveauNom.length < 3 || commande.nouveauNom.length > 80 " @click="renommerCommande(commande)">Renommer</button>
                                <button class="btn btn-danger" style="margin-left:3rem;background:#3085d6!important;border-color:#3085d6!important;" @click="closeModal('modal-renommer-commande-' + commande.id)">Annuler</button>
                              </div>
                            </template>
                          </b-modal>

                        </span>

                        <span v-if="!commandeTitreEnvoyee(commande.commandesFournisseurs) && !commandeTitreRecue(commande.commandesFournisseurs)">

                          <BoutonAjoutProduit :title="'Ajouter un article à la commande ' + commande.nom.toUpperCase()" @ajouter-produit="ajouterProduit(commande, $event)" />

                        </span>

                        <BoutonImpressionCommande :ref="'btnEnvoi-' + iCommande" :disabled="donneesModifiees" :commande="commande" :pasDEnvoi="sousCommandesEnCreation(commande).length === 0 && sousCommandesEnErreurMail(commande).length === 0" @commande-envoyee="rafraichirCommande(commande, sCommande)" />

                        <BoutonImpressionCommande1Tableau :disabled="donneesModifiees" :commande="commande" />

                        <span v-if="sousCommandesEnCreation(commande).length > 0">
                          <BoutonEnvoiCommande :disabled="donneesModifiees" :commande="commande" :sCommandes="sousCommandesEnCreation(commande)" :fournisseurIds="sousCommandesEnCreation(commande).map(el => el.fournisseurId)" @commande-envoyee="rafraichirCommande(commande, sCommande)" @afficher-modal-envoi="afficherModalEnvoi('btnEnvoi-' + iCommande)" />
                        </span>

                        <span v-if="commandeEnCreation(commande) && sousCommandesEnvoyees(commande).length === 0 && sousCommandesRecues(commande).length === 0">
                          <b-button v-tooltip="{ content: 'Supprimer la commande', placement: 'top' }" class="btn btn-danger push-right-1rem" style="width:35px;" @click="suppressionCommande(commande)">
                            <i class="fas fa-times"></i>
                          </b-button>
                        </span>
                      </div>

                      <div v-for="(sCommande, iSCommande) in commande.commandesFournisseurs" :key="iSCommande"><!--détails commande-->

                        <div :ref="'sCommande' + sCommande.fournisseurId" v-if="estModifiee(commande, sCommande) || (sCommande.lignes && sCommande.lignes.some(l => l.qDem))" class="accordeon flex-center-space-between text-white bg-gradient-bleu-clair" :disabled="receptionEnCours()" v-b-toggle="'commande-' + commande.id + '-' + sCommande.fournisseurId" style="outline:none!important;padding:5px 10px;margin:1px 0;"><!--titre sous-commande-->
                          <div>
                              <i v-if="commandeTitreIncident(commande.commandesFournisseurs, sCommande)" :class="commandeTitreIncidentTraitee(commande.commandesFournisseurs, sCommande) ? 'fa-vert' : 'fa-rose'" class="fas fa-exclamation-triangle" style="margin-right:0.5rem"></i>
                              <i v-if="sousCommandeEnCreation(commande, sCommande)" v-tooltip="{ content: 'En création' }" class="fas fa-clipboard-list" style="margin-right:0.5rem"></i>
                              <i v-if="sousCommandeEnvoyee(commande, sCommande)" v-tooltip="{ content: sCommande.etatDuMail && sCommande.etatDuMail.estErreur ? 'Erreur d\'envoi du mail' : 'Envoyée' }" class="fas fa-shipping-fast" :class="sCommande.etatDuMail && sCommande.etatDuMail.estErreur ? 'text-danger' : ''" style="margin-right:0.5rem"></i>
                              <i v-if="sousCommandeRecue(commande, sCommande)" v-tooltip="{ content: 'Reçue' }" class="fas fa-clipboard-check" style="margin-right:0.5rem"></i>
                              
                              <i v-if="sCommande.lignes && sCommande.lignes.some(l => l.utilisations && l.utilisations.some(u => u.estModifiee))" class="fas fa-exclamation-triangle ml-1 mr-2 text-danger"></i>
                                <b v-if="sCommande.lignes && sCommande.lignes.reduce((a, b) => a + b.qDem, 0) == 0"><s>{{nomFournisseur(sCommande.fournisseurId)}}</s></b>
                                <b v-else>{{nomFournisseur(sCommande.fournisseurId)}}</b>
                              <em v-if="sousCommandeEnvoyeeManuellement(commande, sCommande)">
                                <span v-if="sCommande.etatDuMail && sCommande.etatDuMail.estErreur">&nbsp;(Erreur de mail, traitée manuellement le {{dateEnvoiManuel(sCommande)}})</span>
                                <span v-else>&nbsp;(Envoyée manuellement le {{dateEnvoiManuel(sCommande)}})</span>
                              </em>
                          </div>
                          <div>
                            <b>Liv. : {{dateLivraison(sCommande)}}</b>
                            <b style="display:inline-block;width:200px;text-align:right;" :style="sCommande.francoHT > (totalSCommande(commande, sCommande, false) || (sCommande.lignes && sCommande.lignes.some(l => l.qDem) ? sCommande.totalHDT : 0)) ? 'color:orange' : ''">Franco : {{sCommande.francoHT}}&nbsp;€</b>
                            <b style="display:inline-block;width:200px;text-align:right;">Total HDT : {{totalSCommande(commande, sCommande, true) || (sCommande.lignes && sCommande.lignes.some(l => l.qDem) ? sCommande.totalHDT : 0)}}&nbsp;€</b>
                            <span style="margin-left:5px;"><i class="fas fa-chevron-up"></i></span>
                          </div>
                        </div>

                        <b-collapse v-if="estModifiee(commande, sCommande) || (sCommande.lignes && sCommande.lignes.some(l => l.qDem))" :id="'commande-' + commande.id + '-' + sCommande.fournisseurId" v-model="sCommande.afficherDetails" :key="keySCommande" role="tabpanel" style="margin-bottom:10px;">

                          <div v-if="!commande.locked" style="text-align:right;margin:10px 0;"><!--actions sous-commande-->

                            <span v-if="receptionEnCours(commande, sCommande)">

                              <BoutonAjoutProduit :disabled="!sCommande.numeroBL || !sCommande.dateLivraison" :fournisseurId="sCommande.fournisseurId" :title="'Ajouter un article à la commande ' + commande.nom.toUpperCase()" @ajouter-produit="ajouterArticle(sCommande, $event)" />

                              <BoutonAjoutIncident :disabled="!sCommande.numeroBL || !sCommande.dateLivraison" class="push-right-1rem" idModal="Global" :donneesCommande="sCommande" :optionsIncident="optionsIncidentGlobal" :optionsTypesDeReclamations="optionsTypesDeReclamations" @incident="ajouterIncident($event, sCommande)" />

                              <button :disabled="!sCommande.numeroBL || !sCommande.dateLivraison" v-tooltip="{ content: 'Enregistrer en stock' }" class="btn btn-success push-right-1rem" @click="enregistrerEnStock(commande, sCommande)">
                                <i class="fas fa-check"></i>
                              </button>

                              <button v-tooltip="{ content: 'Annuler le contrôle à réception' }" class="btn btn-light push-right-1rem" @click="annulerReception(commande, sCommande)">
                                <i class="fas fa-ban"></i>
                              </button>

                            </span>

                            <span v-else-if="sousCommandeEnvoyee(commande, sCommande)" v-tooltip="{ content: inventaireOuvert ? 'Réception non disponible tant qu\'un inventaire est ouvert.' : 'Réceptionner la commande' }">

                              <button :disabled="inventaireOuvert" class="btn btn-light push-right-1rem" @click="demarrerReception(commande, sCommande)">
                                <i class="fas fa-box-open"></i>
                              </button>

                            </span>

                            <span v-else-if="sousCommandeEnCreation(commande, sCommande)">

                              <BoutonAjoutProduit :fournisseurId="sCommande.fournisseurId" :title="'Ajouter un article à la commande ' + commande.nom.toUpperCase()" @ajouter-produit="ajouterProduit(commande, $event)" />

                              <v-date-picker v-model="sCommande.dateLivraisonSouhaitee"
                                :attributes="attributsDPsCommande(commande, sCommande)"
                                v-tooltip="{ content: 'Changer la date de livraison souhaitée', placement: 'top' }"
                                @input="setDateLivraisonSouhaiteeSCommande(commande, sCommande, $event)"
                                style="display:inline-block;">
                                <template v-slot="{ inputValue, togglePopover }">
                                  <button type="button" class="btn btn-light push-right-1rem btn-secondary" v-on:click="togglePopover">
                                    <i class="far fa-calendar-alt" :data-title="inputValue"></i>
                                  </button>
                                </template>
                              </v-date-picker>

                            </span>

                            <BoutonImpressionCommande :disabled="donneesModifiees" :ref="'btnEnvoi-' + iCommande + '-' + iSCommande" :commande="commande" :fournisseurId="sCommande.fournisseurId" :pasDEnvoi="!sousCommandeEnCreation(commande, sCommande) && !sousCommandeEnErreurMail(commande, sCommande)" @commande-envoyee="rafraichirCommande(commande, sCommande)" />

                            <span v-if="(sousCommandeEnCreation(commande, sCommande) && sCommande.lignes && sCommande.lignes.some(l => l.qDem)) || (sousCommandeEnErreurMail(commande, sCommande) && !sousCommandeEnvoyeeManuellement(commande, sCommande))">

                              <BoutonEnvoiCommande :disabled="donneesModifiees" :commande="commande" :sCommandes="sousCommandesEnCreationOuEnErreurMail(commande)" :fournisseurIds="[sCommande.fournisseurId]" @commande-envoyee="rafraichirCommande(commande, sCommande)" @afficher-modal-envoi="afficherModalEnvoi('btnEnvoi-' + iCommande + '-' + iSCommande)" />

                            </span>

                          </div>

                          <div v-if="receptionEnCours(commande, sCommande) || sousCommandeRecue(commande, sCommande)">
                            <!-- Incident global -->
                            <div v-if="sCommande.raisonIncidentId" class="container-fluid">
                              <div class="row alert alert-danger alert-dismissible mb-2" role="alert" style="padding:2px;margin-bottom:0px!important">
                                <button v-if="sousCommandeEnvoyee(commande, sCommande)" type="button" class="close" data-dismiss="alert" aria-label="Close" @click="enleverIncident(sCommande)" style="padding-top:0px;height:100%">
                                  <span aria-hidden="true" style="vertical-align:middle">&times;</span>
                                </button>
                                <span style="padding-left:10px">
                                  {{raisonIncident(sCommande)}}
                                </span>
                              </div>
                            </div>

                            <!-- Entête de commande : numéro BL et date de livraison -->
                            <div class="filtres-container" :key="keyBL">
                              <div v-if="!sCommande.numeroBL || !sCommande.dateLivraison" class="alert alert-danger mb-2" role="alert">
                                Indiquez une date de livraison et un bon de livraison
                              </div>

                              <div class="flex-center-space-between">
                                <div class="col-4 flex-start p-0" style="margin-right:19px">
                                  Num.&nbsp;BL&nbsp;:&nbsp;
                                  <div class="li-commande"><input type="text" v-model="sCommande.numeroBL" @blur="if (sCommande.dateLivraison) {keyBL += 1}" :disabled="!sousCommandeEnvoyee(commande, sCommande)" style="box-shadow:none"></div>
                                </div>
                                <div class="col-6 flex-start p-0">
                                  Date&nbsp;de&nbsp;livraison&nbsp;:&nbsp;
                                  <div class="li-commande">
                                    <div v-if="sousCommandeEnvoyee(commande, sCommande)" class="datepicker-trigger">
                                      <input type="text" autocomplete="off" :id="'datepicker-livraison-' + commande.id + '-' + sCommande.fournisseurId" class="datepicker" :disabled="!sousCommandeEnvoyee(commande, sCommande)" placeholder="Date..." :value="formatHumanDate(sCommande.dateLivraison)">
                                      <AirbnbStyleDatepicker :trigger-element-id="'datepicker-livraison-' + commande.id + '-' + sCommande.fournisseurId" mode="single" :fullscreen-mobile="true" :showShortcutsMenuTrigger="false" :monthsToShow="1" @date-one-selected="setDateLivraisonSCommande(sCommande, $event)" :date-one="sCommande.dateLivraison" />
                                    </div>
                                    <div v-else style="width:100%"><input type="text" :value="formatHumanDate(sCommande.dateLivraison)" disabled style="width:100%;box-shadow:none"></div>
                                  </div>
                                </div>
                                <!-- <div class="col-2">//donnée déjà affichée dans le titre de la sous-commande
                                  <span class="p-2 bg-gradient-bleu text-white">Total livré : {{totalSCommande(sCommande, true)}} €</span>
                                </div> -->
                              </div>
                            </div>

                            <div class="container-fluid">
                              <div class="row bg-gradient-bleu text-white" v-for="(ligne, iLigne) in sCommande.lignes" :key="iLigne" style="margin-bottom:5px;padding:2px">
                                <div class="col-9 flex-start">
                                  <!--<i v-if="ligne.incident" class="fas fa-exclamation" :class="!ligne.reclamation || (ligne.reclamation && ligne.reclamation.estTraitee) ? 'fa-vert' : 'fa-rouge' " style="font-size:2rem;margin-right:1rem"></i>-->
                                  <span v-if="ligne.ancienTarifId">
                                    <del>{{ligne.ancienneDesignation}} (Réf. {{ligne.ancienneReference}})</del>
                                    {{designation(ligne.foId, ligne.dAgap, ligne.dFou)}} (Réf. {{ligne.reference}})
                                  </span>
                                  <span v-else-if="ligne.tIdLivre">
                                    <del v-if="ligne.tIdLivre !== ligne.tId && (ligne.refLivree !== ligne.reference || ligne.designationLivree !== designation(ligne.foId, ligne.dAgap, ligne.dFou))">
                                      {{designation(ligne.foId, ligne.dAgap, ligne.dFou)}} (Réf. {{ligne.reference}})
                                    </del>
                                    {{ligne.designationLivree}} (Réf. {{ligne.refLivree}})
                                  </span>
                                  <span v-else>
                                    {{designation(ligne.foId, ligne.dAgap, ligne.dFou)}} (Réf. {{ligne.reference}})
                                  </span>
                                  <!-- Suppression de ligne -->
                                  <div v-if="!ligne.estLivree">
                                    <!--<input type="text" class="li-commande" :id="'reference'+ligne.foId" v-model="ligne.reference" style="width:8rem;background:white">-->
                                    <span v-if="!ligne.id" style="margin-left:3rem;cursor:pointer" @click="retirerArticle(sCommande, iLigne)"><i class="fas fa-minus-circle" style="margin-right:.5rem"></i> Retirer</span>
                                  </div>
                                </div>
                                <div class="col-3 flex-end" v-if="sousCommandeEnvoyee(commande, sCommande)">
                                  <!-- Substitution d'article -->
                                  <RechercheAjoutProduit v-if="ligne.id" :disabled="!sCommande.numeroBL || !sCommande.dateLivraison" :fournisseurId="sCommande.fournisseurId" @substituer-article="substituerArticle(ligne, $event)" class="push-right-1rem" />

                                  <BoutonAjoutIncident size="sm" :idModal="ligne.id" :disabled="!sCommande.numeroBL || !sCommande.dateLivraison" :donneesCommande="ligne" :optionsIncident="optionsIncidentLigne" :optionsTypesDeReclamations="optionsTypesDeReclamations" @incident="ajouterIncident($event, ligne)" />
                                </div>
                                <div class="container-fluid card-selected">
                                  <div class="row li-commande">
                                    <!-- Incident sur la ligne -->
                                    <div class="container-fluid">
                                      <!-- Lecture si commande reçue -->
                                      <div v-if="ligne.raisonIncidentId" class="row alert alert-dismissible mb-2" :class="ligne.estTraitee ? 'alert-success' : 'alert-danger'" role="alert" style="padding:2px">
                                        <button v-if="sousCommandeEnvoyee(commande, sCommande)" type="button" class="close" data-dismiss="alert" aria-label="Close" @click="enleverIncident(ligne)" style="padding-top:0px">
                                          <span aria-hidden="true" style="vertical-align:middle">&times;</span>
                                        </button>
                                        <span style="padding-left:10px">
                                          {{raisonIncident(ligne)}}
                                        </span>
                                      </div>
                                    </div>

                                    <!-- Détail de la ligne -->
                                    <div class="col-3">
                                      <span v-if="ligne.uc">Quantité ({{ligne.uc}})</span><br />
                                      <div class="flex-start">
                                        <div>Cmd : {{ligne.qDem}}</div>
                                        <div v-if="ligne.estLivree">&nbsp;- Livré : <span :class="ligne.quantite !== ligne.qDem ? 'alert-danger' : ''">{{ligne.quantite}}</span></div>
                                        <div v-else>
                                          &nbsp;- Livré :
                                          <InputNumber :key="'quantite'+ligne.foId" v-model="ligne.quantite" step="0.001" typeFiltre="quantite" style="width:7rem" :disabled="!sCommande.numeroBL || !sCommande.dateLivraison" @blur="keyCommande += 1" />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-3">
                                      Prix ({{ligne.uc}})<br />
                                      <div class="flex-start">
                                        <div>Cmd : {{ligne.puInitial | afficherPrix}}</div>
                                        <div v-if="ligne.estLivree" :class="{'alert-danger': ligne.puLivre > ligne.puInitial, 'alert-success': ligne.puLivre < ligne.puInitial}">&nbsp;- Livré : {{ligne.puLivre | afficherPrix}}</div>
                                        <div v-else>
                                          &nbsp;- Livré :
                                          <InputNumber :key="'pu'+ligne.foId" :value="parseFloat(ligne.pu.toFixed(3))" typeFiltre="quantite" style="width:7rem"
                                            :disabled="!sCommande.numeroBL || !sCommande.dateLivraison" step="0.001" @input="ligne.pu = $event;ligne.puLivre = $event" @blur="keyCommande += 1" /> €
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-3">
                                      Total ligne
                                      <div class="flex-start">
                                        <div>Cmd : {{totalLigne(ligne.qDem, ligne.puInitial, true)}} €</div>
                                        <div>
                                          &nbsp;- Livré :
                                          <span :class="ligne.quantite < ligne.qDem || ligne.puLivre > ligne.puInitial ? 'alert-danger' : (ligne.puInitial > ligne.puLivre ? 'alert-success' : '')">{{totalLigne(ligne.quantite, ligne.puLivre, true)}} €</span>
                                        </div>
                                      </div>
                                    </div>
                                    <!-- Ajout de lot -->
                                    <div class="col-3 flex-end">
                                      <button v-if="sousCommandeEnvoyee(commande, sCommande)" :disabled="!sCommande.numeroBL || !sCommande.dateLivraison" class="btn btn-primary" size="sm" @click="ajouterLot(ligne)">+ N° de Lot/DLC/DDM</button>
                                    </div>
                                  </div>
                                  <div class="row" v-for="(lot, iLot) in ligne.lots" :key="iLot">
                                    <div class="col-5"></div>
                                    <div class="col-7 li-commande flex-center-space-between">
                                      <div v-if="ligne.estLivree">Qté : {{lot.quantite}}</div>
                                      <div v-if="ligne.estLivree">Lot n° : {{lot.numero}}</div>
                                      <div class="flex-center-space-between" v-else>
                                        Qté :&nbsp;
                                        <InputNumber v-model="lot.quantite" typeFiltre="quantite" style="width:7rem" />
                                      </div>
                                      <div class="flex-center-space-between" v-if="!ligne.estLivree">Lot n° :&nbsp;<input type="text" v-model="lot.numero" style="width:7rem"></div>
                                      <div class="flex-center-space-between" v-if="ligne.estLivree">
                                        <div :title="!lot.dlc && 'Date de Durabilité Minimale (ex. DLUO)'">{{lot.dlc ? "DLC" : "DDM"}} : {{formatHumanDate(lot.dlcDluo)}}</div>
                                      </div>
                                      <div class="flex-center-space-between" v-else>
                                        <div class="flex-center-space-between">
                                          <div title="Date de Durabilité Minimale (ex. DLUO)">DLC/DDM :&nbsp;</div>
                                          <ManualDatepicker input-style="width:135px;" :value="lot.dlcDluo" @saved="lot.dlcDluo = $event" />
                                        </div>
                                      </div>
                                      <div v-if="!ligne.estLivree">
                                        <button type="button" class="close" aria-label="Close" @click='supprimerLigneLot(ligne.lots, iLot)'>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div v-else-if="sousCommandeEnCreation(commande, sCommande) || sousCommandeEnvoyee(commande, sCommande)">
                            <div class="table-responsive mb-0">
                              <table class="table table-sm table-bordered">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>Famille</th>
                                    <th>Désignation</th>
                                    <th>Fournisseur</th>
                                    <th>Ref</th>
                                    <th>Marque</th>
                                    <th>UC</th>
                                    <th>PCB</th>
                                    <th>PU HDT</th>
                                    <th>Qté demandée</th>
                                    <th>Qté PCB</th>
                                    <th>Total HDT</th>
                                    <th v-if="sousCommandeEnCreation(commande, sCommande)"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <template v-for="(ligne, iLigne) in sCommande.lignes">
                                    <tr v-if="ligne.qDem || estModifiee(commande, sCommande, ligne)" :key="iLigne + '-produit-' + key" :id="'ligneCmd' + ligne.id" :ref="'ligneCmd' + ligne.id"
                                      v-b-toggle="'commande-' + commande.id + '-' + sCommande.fournisseurId + '-' + iLigne"
                                      :class="'ligne-commande accordeon ' + (sousCommandeRecue(commande, sCommande) || sousCommandeEnvoyee(commande, sCommande) ? 'element-disabled' : '') + (ligne.locked ? ' locked' : '')"
                                      :style="(sousCommandeRecue(commande, sCommande) ? 'font-style:italic ' : '') + (ligne.qDem === 0 ? 'text-decoration: line-through;' : '')">
                                      <td style="vertical-align:middle;text-align:center">
                                        <span><i class="fas fa-chevron-up"></i></span>
                                      </td>
                                      <td style="vertical-align:middle;text-align:center">
                                        <IconeFamilleIngredient :familleId="ligne.faId" />
                                      </td>
                                      <td style="vertical-align:middle">
                                        <b v-if="estModifiee(commande, sCommande, ligne)">{{designation(ligne.foId, ligne.dAgap, ligne.dFou)}}</b>
                                        <span v-else>{{designation(ligne.foId, ligne.dAgap, ligne.dFou)}}</span>
                                      </td>
                                      <td style="vertical-align:middle">
                                        <b v-if="estModifiee(commande, sCommande, ligne)">{{nomFournisseur(ligne.foId)}}</b>
                                        <span v-else>{{nomFournisseur(ligne.foId)}}</span>
                                      </td>
                                      <td style="vertical-align:middle">
                                        <b v-if="estModifiee(commande, sCommande, ligne)">{{ligne.reference}}</b>
                                        <span v-else>{{ligne.reference}}</span>
                                      </td>
                                      <td style="vertical-align:middle">
                                        <b v-if="estModifiee(commande, sCommande, ligne)">{{ligne.marque}}</b>
                                        <span v-else>{{ligne.marque}}</span>
                                      </td>
                                      <td style="vertical-align:middle">
                                        <b v-if="estModifiee(commande, sCommande, ligne)">{{ligne.uc}}</b>
                                        <span v-else>{{ligne.uc}}</span>
                                      </td>
                                      <td style="vertical-align:middle" :style="ligne.qDem !== qtePcb(ligne.qDem, ligne.pcb) ? 'color:orange' : ''">
                                        <b v-if="estModifiee(commande, sCommande, ligne)">{{ligne.pcb && ligne.pcb.toString().replace('.', ',')}}</b>
                                        <span v-else>{{ligne.pcb && ligne.pcb.toString().replace('.', ',')}}</span>
                                      </td>
                                      <td style="vertical-align:middle">
                                        <span v-if="estModifiee(commande, sCommande, ligne)" :title="ligne.pu|afficherPrix6"><b>{{ligne.pu|afficherPrix}}</b></span>
                                        <span v-else :title="ligne.pu|afficherPrix6">{{ligne.pu|afficherPrix}}</span>
                                      </td>
                                      <td style="vertical-align:middle;width:150px">
                                        <div class="flex-center-space-between">
                                          <div v-if="ligne.utilisations.some(u => u.estModifiee)" class="flex-center-space-between">
                                            <i class="fas fa-exclamation-triangle ml-1 mr-2 text-danger"></i>
                                            <button v-if="ligne.utilisations.some(u => u.estModifiee)" type="button" class="btn btn-light mr-2" style="width:35px;transform:scale(0.8);" @click.stop="effacerAlerteRecalcul(ligne)">
                                              <i class="fas fa-check"></i>
                                            </button>
                                          </div>
                                          <InputNumber v-if="!commande.locked && !ligne.locked && (!ligne.foId || sousCommandeEnCreation(commande, sCommande))"
                                            ref="inputNumber" v-model="ligne.qDem" typeFiltre="quantite" style="width:100%" zeroSiVide="1" :fixBlur="false" @save="setQteDem(sCommande, ligne, $event)" @blur="setQteDem(sCommande, ligne, $event)" />
                                          <div v-else>
                                            {{ ligne.qDem }}
                                          </div>
                                        </div>
                                      </td>
                                      <td style="vertical-align:middle" :style="ligne.qDem !== qtePcb(ligne.qDem, ligne.pcb) ? 'color:orange' : ''">
                                        <b v-if="estModifiee(commande, sCommande, ligne)">{{ qtePcb(ligne.qDem, ligne.pcb).toString().replace('.', ',') }}</b>
                                        <span v-else>{{ qtePcb(ligne.qDem, ligne.pcb).toString().replace('.', ',') }}</span>
                                      </td>
                                      <td style="vertical-align:middle" :style="ligne.qDem !== qtePcb(ligne.qDem, ligne.pcb) ? 'color:orange' : ''">
                                        <b v-if="estModifiee(commande, sCommande, ligne)">{{(ligne.pu * qtePcb(ligne.qDem, ligne.pcb)) | afficherPrix}}</b>
                                        <span v-else>{{(ligne.pu * qtePcb(ligne.qDem, ligne.pcb)) | afficherPrix}}</span>
                                      </td>
                                      <td v-if="sousCommandeEnCreation(commande, sCommande) && !commande.locked && !ligne.locked"
                                        style="vertical-align:middle;width:82px;">
                                        <SubstitutionArticle :small="true"
                                          :ingredient='preparerIngredient(ligne)'
                                          :idsBloques="commande.commandesFournisseurs.filter(c => c.etat !== 'EC1').map(c => c.fournisseurId)"
                                          @selection-article="substitutionProduit(commande, ligne, $event)" /><!-- @toggle-favori="toggleFavori"-->
                                        <button type="button" v-tooltip="{ content: 'Supprimer le produit', placement: 'top' }" class="btn btn-danger" style="width:35px;transform:scale(0.8);" @click.stop="supprimerProduit(sCommande, ligne)">
                                          <i class="fas fa-times"></i>
                                        </button>
                                      </td>
                                    </tr>
                                    <b-collapse v-if="(ligne.qDem || estModifiee(commande, sCommande, ligne)) && !commande.locked && !ligne.locked"
                                      tag="tr" colspan="13" v-model="ligne.afficherDetails"
                                      :key="iLigne + '-utilisations'"
                                      :id="'commande-' + commande.id + '-' + sCommande.fournisseurId + '-' + iLigne"
                                      :accordion="'commande-' + commande.id + '-' + sCommande.fournisseurId">
                                      <td colspan="13" style="background-color: #e0f2ff;">
                                        <UtilisationsLigneCommande :ligne="ligne" />
                                      </td>
                                    </b-collapse>
                                  </template>
                                </tbody>
                              </table>
                            </div>
                          </div>

                        </b-collapse>
                      </div>

                    </div>

                  </b-collapse>
                </div>
              </div>
            </div>
          </div>
        </transition>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { chargerCommandes, detailsCommande, receptionnerCommande, renommerCommande, supprimerCommande, ajouterCommande, modifierCommande, ajouterLigneCommande, substituerLigneCommande, recalculerCommande } from '@/api/gpao/commandes'
import { chargerTousLesMenus } from '@/api/gpao/menus'
import { showAlerts } from '@/mixins/alerts'
import { closeModal, getLundiDeLaSemaine, getDimancheDeLaSemaine } from "@/mixins/index"
import { connecteData } from "@/mixins/connecteData"
import { nomFamille, iconeFamille } from '@/mixins/recette'
import { formatageDate, livreLe, formatHumanDateAvecSlashes } from '@/mixins/dateFormats'
import { arrondiMercuriale, arrondiNombre } from '@/helpers/utils'
import { constantesId } from "@/helpers/constantes"
import InputNumber from '@/components/Inputs/InputNumber'
import MultiSelect from '@/components/Inputs/MultiSelect'
import ManualDatepicker from '@/components/Inputs/ManualDatepicker'
import BoutonImpressionCommande from '@/components/SuiviCommandes/BoutonImpressionCommande'
import BoutonImpressionCommande1Tableau from '@/components/SuiviCommandes/BoutonImpressionCommande1Tableau'
import BoutonEnvoiCommande from '@/components/SuiviCommandes/BoutonEnvoiCommande'
import BoutonAjoutProduit from '@/components/SuiviCommandes/BoutonAjoutProduit'
import BoutonAjoutIncident from '@/components/SuiviCommandes/BoutonAjoutIncident'
import IconeFamilleIngredient from '@/components/Divers/IconeFamilleIngredient'
import RechercheAjoutProduit from '@/components/Divers/RechercheAjoutProduit'
import BoutonDuplicationCommande from '@/components/SuiviCommandes/BoutonDuplicationCommande'
import SelectDatesMenusPlansServices from '@/components/Divers/SelectDatesMenusPlansServices'
import SubstitutionArticle from '@/components/Divers/SubstitutionArticle'
import UtilisationsLigneCommande from '@/components/Commandes/UtilisationsLigneCommande'
import vm from "@/helpers/vm-toolkit-vue"
import { verifierInventaireOuvert } from "@/helpers/verifications"

export default {
  name: "ListeDesCommandes",
  mixins: [connecteData, closeModal, getLundiDeLaSemaine, getDimancheDeLaSemaine, nomFamille, iconeFamille, showAlerts, formatageDate, formatHumanDateAvecSlashes, livreLe],
  components: {
    InputNumber,
    MultiSelect,
    ManualDatepicker,
    IconeFamilleIngredient,
    BoutonImpressionCommande,
    BoutonImpressionCommande1Tableau,
    BoutonAjoutIncident,
    BoutonAjoutProduit,
    RechercheAjoutProduit,
    BoutonDuplicationCommande,
    BoutonEnvoiCommande,
    SelectDatesMenusPlansServices,
    SubstitutionArticle,
    UtilisationsLigneCommande,
  },
  props: {
    fournisseurs: {
      type: String,
      default: 'tous-les-fournisseurs'
    }
  },
  data() {
    return {
      commandes: [],
      filtreCommandes: "",
      commandesFiltrees: [],
      nouvelleCommande: {},
      commandeEnReception: false,
      commandeActive: "",
      fournisseurIds: [],
      aujourdhui: null,
      dateDebut: '',
      apiDateDebut: '',
      dateFin: '',
      apiDateFin: '',
      status: [constantesId.etatCmdEnCours, constantesId.etatCmdEnvoyee, constantesId.etatCmdRecue],
      optionsStatus: [],
      optionsIncident: [],
      optionsIncidentGlobal: [],
      optionsIncidentLigne: [],
      optionsTypesDeReclamations: [],
      key: 1,
      keyBL: 1,
      keyCommandes: 1,
      keyCommande: 1,
      keySCommande: 1,
      menus: [],
      keySelect: 1,
      ncDepuisBesoins: false,
      ncDateDebut: "",
      ncDateFin: "",
      ncMenuPlanIds: [],
      ncServiceIds: [],
      inventaireOuvert: true,
      selectedDates: {
        start: new Date(this.dateDebut),
        end: new Date(this.dateFin)
      },
      dataDMS: {
        dateDebut: "",
        dateFin: "",
        menuPlanIds: [],
        serviceIds: []
      },
      donneesModifiees: false,
      calculEnCours: false
    }
  },
  watch: {
    filtreCommandes() {
      this.filtrerCommandes()
    },
    selectedDates() {
      this.datesChanged()
    },
  },
  computed: {
    ...mapGetters("fournisseurs", ["fournisseursCourants", "nomFournisseur"]),
    ...mapGetters("definitions", ["trouverIngredient", "services"]),
    ...mapGetters("recettes", ["obtenirRecette"]),
    constantesId() {
      return constantesId
    },
    fournisseursPourFiltre() {
      return this.fournisseursCourants
        .filter(f => f.commandable && this.etabCourant.choixFournisseurs.filter(cf => cf.actif && cf.foId == f.id).length > 0)
        .sort(function (a, b) { return a.nom < b.nom ? -1 : 1 })
    }
  },
  methods: {
    checkDonneesModifiees() {
      if (this.commandeEnReception) {
        let commande = this.commandes.find(c => c.id === this.commandeEnReception.commandeId)
        let sCommande = commande.commandesFournisseurs.find(s => s.fournisseurId === this.commandeEnReception.fournisseurId)
        this.donneesModifiees = JSON.stringify(this.donneesSCommande(commande, sCommande)) !== this.commandeEnReception.avantModif
      } else if (this.commandeActive) {
        let commande = this.commandes.find(c => c.id === this.commandeActive.commandeId)
        let test = this.donneesCommande(commande)
        this.donneesModifiees = JSON.stringify(test) !== JSON.stringify(this.commandeActive.avantModifs)
      } else {
        this.donneesModifiees = false
      }
      this.key += 1
    },
    estModifiee(commande, sCommande, ligne) {
      if (!commande || !sCommande || !this.commandeActive || !this.commandeActive.avantModifs) {
        return false
      }
      let sCmdEnCours = this.donneesSCommande(commande, sCommande) || {}
      let sCmdAvantModif = this.commandeActive.avantModifs[sCommande.fournisseurId] || {}
      if (ligne) {
        let ligneEnCours = sCmdEnCours.lignes ? sCmdEnCours.lignes.find(l => l.id === ligne.id) : {}
        let ligneAvantModif = sCmdAvantModif.lignes ? sCmdAvantModif.lignes.find(l => l.id === ligne.id) : {}
        return (JSON.stringify(ligneEnCours) !== JSON.stringify(ligneAvantModif))
      }
      return (JSON.stringify(sCmdEnCours) !== JSON.stringify(sCmdAvantModif))
    },
    async recalculer(commande) {
      this.calculEnCours = true
      commande.locked = true
      let response = await recalculerCommande(commande)
      this.majCommande(commande, response.data)
      this.calculEnCours = false
      commande.locked = false
    },
    attributsDPsCommande(commande, sCommande) {
      let details = commande.detailFournisseurs ? commande.detailFournisseurs.find(el => el.fournisseurId === sCommande.fournisseurId) : null
      if (!details || !details.joursLivraison) {
        return []
      }
      return [{
        dates: {weekdays: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"].map((j, n) => {
          if (details.joursLivraison.indexOf(j) > -1) {
            return n + 1
          }
        })},
        highlight: "green",
      }]
    },
    preparerIngredient(ligne) {
      var ingredient = this.trouverIngredient(ligne.pId)
      return {
        ingredientId: ingredient.id,
        familleId: ligne.faId,
        quantiteBesoinEnUC: ligne.qDem,
        designation: ligne.dFou,
        marque: ligne.marque,
        fournisseurId: ligne.foId,
        prixUnitaire: ligne.pu,
        uc: ligne.uc,
        tarifId: ligne.tId,
      }
    },
    async substitutionProduit(commande, ligne, event) {
      ligne.locked = true
      this.key += 1
      await this.$nextTick()
      let response = await substituerLigneCommande(commande, ligne.id, event.produit.tarifId)
      this.majCommande(commande, response.data)
      this.selectionnerLigne(commande, ligne)
      ligne.locked = false
      this.key += 1
    },
    selectionnerLigne(commande, ligne) {
      commande.commandesFournisseurs.forEach(cf => cf.afficherDetails = false)
      let l = commande.lignes.find(l => l.id === ligne.id)
      l.afficherDetails = true
      let cf = commande.commandesFournisseurs.find(cf => cf.fournisseurId === l.foId)
      cf.afficherDetails = true
      let cfOrig = commande.commandesFournisseurs.find(cf => cf.fournisseurId === ligne.foId)
      cfOrig.afficherDetails = true
      /*let elt = document.getElementById("ligneCmd" + ligne.id)
      if (elt) {
        elt.scrollIntoView({left: 0, top: 0, behavior:'smooth'})
      }*/
      //window.HTMLElement.prototype.scrollIntoView = function() {}
      /*let refSCommande = this.$refs['sCommande' + l.foId][0]
      if (refSCommande) {
        //let pos = ref.getBoudingClientRect().top + window.pageYOffset
        //window.scrollTo({top: pos, behavior: 'smooth'})
        refSCommande.scrollIntoView({block:'start', behavior:'smooth'})
      }
      let refLigne = this.$refs['ligneCmd' + l.id][0]
      if (refLigne) {
        //let pos = ref.getBoudingClientRect().top + window.pageYOffset
        //window.scrollTo({top: pos, behavior: 'smooth'})
        //window.scrollTo(0, 0)
        refLigne.scrollIntoView({block:'start', behavior:'smooth'})
      }*/
    },
    ouvrirSousCommandes(commande) {
      commande.commandesFournisseurs.forEach(s => s.afficherDetails = true)
      this.key += 1
    },
    fermerSousCommandes(commande) {
      commande.commandesFournisseurs.forEach(s => s.afficherDetails = false)
      this.key += 1
    },
    nomUtilisation(utilisation) {
      //console.log(utilisation, ligne, commande)
      var menu = (utilisation.structure + " - ") || ""
      var date = utilisation.dateConsommation
      date = this.formatHumanDateAvecSlashes((date && date.dateLivraison) || "")
      date = (date && date.dateLivraison + " - ") || ""
      var service = utilisation.service || ""
      var recette = (utilisation.recette && utilisation.recette.nom) || ""
      return menu + date + service + recette
    },
    filtrerCommandes() {
      if (!this.filtreCommandes) {
        this.commandesFiltrees = this.commandes
      } else {
        this.commandesFiltrees = this.commandes.filter(commande => commande.nom.toLowerCase().includes(this.filtreCommandes.toLowerCase()))
      }
    },
    donneesSCommande(commande, sCommande) {
      return {
        commandeId: commande.id,
        fournisseurId: sCommande.fournisseurId,
        dateLivraisonSouhaitee: this.formatDateAvecTirets(sCommande.dateLivraisonSouhaitee),
        numeroBL: sCommande.numeroBL,
        dateLivraison: sCommande.dateLivraison,
        raisonIncidentId: sCommande.raisonIncidentId,
        detailIncident: sCommande.detailIncident,
        typeReclamation: sCommande.typeReclamation,
        montantAvoir: sCommande.montantAvoir,
        dateRelivraisonSouhaitee: sCommande.dateRelivraisonSouhaitee,
        lignes: sCommande.lignes ? sCommande.lignes.map(el => {
          return {
            id: el.id || "",
            tarifId: el.tId,
            reference: el.reference,
            qDem: el.qDem,
            quantite: el.quantite,
            pu: el.pu,
            raisonIncidentId: el.raisonIncidentId,
            detailIncident: el.detailIncident,
            typeReclamation: el.typeReclamation,
            dateRelivraisonSouhaitee: el.typeReclamation === "TR1" ? el.dateRelivraisonSouhaitee : null,
            montantAvoir: el.typeReclamation === "TR2" ? el.montantAvoir : null,
            lots: el.lots || []
          }
        }) : []
      }
    },
    donneesCommande(commande) {
      let donnees = {}
      if (commande) {
        commande.commandesFournisseurs.forEach(sCommande => {
          donnees[sCommande.fournisseurId] = this.donneesSCommande(commande, sCommande)
        })
      }
      return donnees
    },
    showAjoutCommande() {
      this.nouvelleCommande = {
        nom: ''
      }
      this.$refs.modalNouvelleCommande.show()
    },
    async ajoutCommande() {
      this.$refs.modalNouvelleCommande.hide()

      let parametres = {
        nom: this.nouvelleCommande.nom.trim(),
        dateDebut: this.ncDateDebut,
        dateFin: this.ncDateFin,
        menusPlansIds: this.ncMenuPlanIds,
        servicesIds: this.ncServiceIds
      }

      await ajouterCommande(this.etabCourantId, parametres)
      this.alerteSucces('Commande créée !')

      this.chargerListeDesCommandes()
    },
    async modificationCommande(commande) {
      this.$store.commit('uxHelpers/showSpinner')
      await modifierCommande(commande.id, commande)
      this.alerteSucces('Commande modifiée !')
      this.chargerListeDesCommandes()
      this.checkDonneesModifiees()
    },
    nomIncident(raisonIncidentId) {
      return this.optionsIncident.find(el => raisonIncidentId === el.code).designation
    },
    nomTypeReclamation(typeReclamationId) {
      var reclamation = this.optionsTypesDeReclamations.find(el => typeReclamationId === el.code)
      return reclamation && reclamation.designation || typeReclamationId
    },
    detailReclamation(elt) {
      var reclamation = elt.montantAvoir ? elt.montantAvoir + " € HT." : ""
      reclamation += elt.dateRelivraisonSouhaitee ? this.formatHumanDate(elt.dateRelivraisonSouhaitee) + "." : ""
      return this.nomTypeReclamation(elt.typeReclamation) + " : " + reclamation
    },
    raisonIncident(elt) {
      var detailIncident = this.nomIncident(elt.raisonIncidentId) + "."
      detailIncident += elt.typeReclamation ? " " + this.detailReclamation(elt) : ""
      detailIncident += elt.detailIncident ? " \"" + elt.detailIncident + "\"" : ""
      return detailIncident
    },
    renommerCommande(commande, sCommande) {
      return renommerCommande(commande.id, commande.nouveauNom)
        .then(() => {
          this.rafraichirCommande(commande, sCommande)
          this.alerteSucces('Commande renommée !')
          return
        })
    },
    suppressionCommande(commande) {
      return this.$swal({
        title: 'Supprimer la commande ?',
        html: "Souhaitez-vous supprimer définitivement la commande '" + commande.nom + "' ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Supprimer',
        cancelButtonText: 'Annuler',
        focusCancel: true
      })
        .then((result) => {
          if (result.value) {
            // eslint-disable-next-line promise/no-nesting
            return supprimerCommande(commande.id)
              .then(() => {
                this.chargerListeDesCommandes()
                this.alerteSucces('Commande supprimée !')
                return
              })
          }
          return
        })
    },
    commandeEnCreation(commande) {
      return commande.etat === constantesId.etatCmdEnCours
    },
    sousCommandeDansEtat(commande, sCommande, etat) {
      return etat === sCommande.etat
    },
    sousCommandeRecue(commande, sCommande) {
      return this.sousCommandeDansEtat(commande, sCommande, constantesId.etatCmdRecue)
    },
    sousCommandeEnvoyee(commande, sCommande) {
      return this.sousCommandeDansEtat(commande, sCommande, constantesId.etatCmdEnvoyee)
    },
    sousCommandeEnCreation(commande, sCommande) {
      return this.sousCommandeDansEtat(commande, sCommande, constantesId.etatCmdEnCours) && (sCommande.totalHDT || this.estModifiee(commande, sCommande))
    },
    sousCommandeEnErreurMail(commande, sCommande) {
      return this.sousCommandeEnvoyee(commande, sCommande) && sCommande.etatDuMail && sCommande.etatDuMail.estErreur
    },
    sousCommandeEnvoyeeManuellement(commande, sCommande) {
      return this.sousCommandeEnvoyee(commande, sCommande) && sCommande.dateEnvoiManuel != null
    },
    commandeTitreRecue(commandesFournisseurs) {
      return commandesFournisseurs.some(sCommande => sCommande.etat === constantesId.etatCmdRecue)
    },
    commandeTitreEnvoyee(commandesFournisseurs) {
      return commandesFournisseurs.some(sCommande => sCommande.etat === constantesId.etatCmdEnvoyee)
    },
    commandeTitreEnvoyeeManuellement(commandesFournisseurs) {
      return commandesFournisseurs.some(sCommande => sCommande.etat === constantesId.etatCmdEnvoyee && sCommande.dateEnvoiManuel != null)
    },
    commandeTitreEnErreur(commandesFournisseurs) {
      return commandesFournisseurs.some(sCommande => sCommande.etat === constantesId.etatCmdEnvoyee && sCommande.etatDuMail && sCommande.etatDuMail.estErreur)
    },
    commandeTitreEnCreation(commandesFournisseurs) {
      return commandesFournisseurs.some(sCommande => sCommande.etat === constantesId.etatCmdEnCours && sCommande.totalHDT)
    },
    sousCommandesDansEtat(commande, etat) {
      if (!commande.commandesFournisseurs) {
        return []
      }
      return commande.commandesFournisseurs.filter(sCommande => sCommande.etat === etat && sCommande.totalHDT)
    },
    sousCommandesRecues(commande) {
      return this.sousCommandesDansEtat(commande, constantesId.etatCmdRecue)
    },
    sousCommandesEnvoyees(commande) {
      return this.sousCommandesDansEtat(commande, constantesId.etatCmdEnvoyee)
    },
    sousCommandesEnCreation(commande) {
      return this.sousCommandesDansEtat(commande, constantesId.etatCmdEnCours)
    },
    sousCommandesEnErreurMail(commande) {
      return this.sousCommandesEnvoyees(commande).filter(f => f.etatDuMail && f.etatDuMail.estErreur)
    },
    sousCommandesEnCreationOuEnErreurMail(commande) {
      return this.sousCommandesEnCreation(commande).concat(this.sousCommandesEnErreurMail(commande)) 
    },
    toutesSousCommandesEnCreation(commande) {
      if (commande.commandesFournisseurs.some(x => x.etatCommande === constantesId.etatCmdEnvoyee || x.etatCommande === constantesId.etatCmdRecue))
        return false;
      return true;
    },
    commandePorteSurMenus(commande) {
      return commande.commandesFournisseurs && commande.commandesFournisseurs.some(cf => cf.lignes && cf.lignes.some(l => l.utilisations && l.utilisations.length))
    },
    commandeTitreIncident(commandesFournisseurs, sCommande) {
      if (sCommande) {
        return commandesFournisseurs.find(s => s.fournisseurId === sCommande.fournisseurId).livraisonOk === false
      } else {
        return commandesFournisseurs.some(s => s.livraisonOk === false)
      }
    },
    commandeTitreIncidentTraitee(commandesFournisseurs, sCommande) {
      if (sCommande) {
        return commandesFournisseurs.find(s => s.fournisseurId === sCommande.fournisseurId).reclamationsTraitees === true
      }
    },
    async ajouterProduit(commande, e) {
      let response = await ajouterLigneCommande(commande, e.tarifId, e.quantite)
      this.majCommande(commande, response.data)
      this.selectionnerLigne(commande, commande.lignes.find(l => l.tId === e.tarifId))
    },
    majCommande(commande, data) {
      vm.add(data, commande)
      commande.detailFournisseurs.forEach(function (dCommande) {
        let sCommande = commande.commandesFournisseurs.find(el => el.fournisseurId === dCommande.fournisseurId)
        if (!sCommande) {
          commande.commandesFournisseurs.unshift({ fournisseurId: dCommande.fournisseurId })
        }
      })
      commande.commandesFournisseurs.forEach(function (sCommande) {
        this.chargerCommandeFournisseur(commande, sCommande)
      }.bind(this))
      this.checkDonneesModifiees()
    },
    supprimerProduit(sCommande, ligne) {
      ligne.qDem = 0
      this.setQteDem(sCommande, ligne)
    },
    rafraichirCommande(commande, sCommande) {
      return this.chargerListeDesCommandes()
        .then(() => this.chargerCommande(commande))
        .then(() => {
          if (sCommande) {
            return this.chargerCommandeFournisseur(commande, sCommande)
          }
          return
        })
    },
    ajouterArticle(sCommande, event) {
      sCommande.lignes.unshift({
        tId: event.tarifId,
        foId: event.article.fournisseurId,
        dAgap: event.article.designation,
        dFou: event.article.designation,
        reference: event.article.reference,
        uc: event.article.uc,
        qDem: 0,
        quantite: event.quantite,
        puInitial: 0,
        pu: event.article.prixUnitaire,
        lots: []
      })
      this.keySCommande += 1
    },
    substituerArticle(ligne, event) {
      ligne.ancienneReference = ligne.reference
      ligne.ancienneDesignation = this.designation(ligne.foId, ligne.dAgap, ligne.dFou)
      ligne.ancienTarifId = ligne.tId
      ligne.tId = event.article.tarifId
      ligne.foId = event.article.fournisseurId
      ligne.dAgap = event.article.designation
      ligne.dFou = event.article.designation
      ligne.reference = event.article.reference
      if (event.article.pu < ligne.pu) {
        ligne.pu = event.article.pu
      }
      this.keySCommande += 1
    },
    retirerArticle(sCommande, index) {
      sCommande.lignes.splice(index, 1)
      this.keySCommande += 1
    },
    afficherNouvelArticle() {
      this.nouvelArticleVisible = !this.nouvelArticleVisible
    },
    supprimerLigneLot(lots, iLot) {
      lots.splice(iLot, 1)
      this.keySCommande += 1
    },
    ajouterLot(ligne) {
      let qteDem = 0
      if (ligne.lots.length === 0) {
        qteDem = ligne.quantite
      }
      ligne.lots.push({
        numero: "",
        quantite: qteDem,
        dlc: true,
        dlcDluo: ""
      })
      this.keySCommande += 1
    },
    effacerLesDates() {
      this.dateDebut = ''
      this.dateFin = ''
      this.selectedDates = {
        start: "",
        end: ""
      }
    },
    filtreFournChanged(e) {
      if (e) {
        this.fournisseurIds = e
        this.chargerListeDesCommandes()
      }
    },
    chargerListeDesMenus() {
      return chargerTousLesMenus(this.etabCourantId)
        .then(function (response) {
          this.menus = response.data
          return
        }.bind(this))
    },
    async chargerListeDesCommandes(status) {
      if (this.receptionEnCours()) {
        return
      }
      this.commandes = []
      this.$store.commit('uxHelpers/showSpinner')
      if (status) {
        this.status.includes(status) ? this.status.splice(this.status.indexOf(status), 1) : this.status.push(status)
      }

      const response = await chargerCommandes(this.etabCourantId, {
        Statuts: this.status || this.optionsStatus.map(el => el.value),
        DateDebut: this.dateDebut,
        DateFin: this.dateFin,
        FournisseurIds: this.fournisseurIds,
        DateDebutMenu: this.dataDMS.dateDebut,
        DateFinMenu: this.dataDMS.dateFin,
        StructuresIds: this.dataDMS.menuPlanIds,
        ServicesIds: this.dataDMS.serviceIds,
      })

      if (!response) {
        this.$store.commit('uxHelpers/hideSpinner')
        return
      }

      if (response.data.length) {//ouvrir la première commande
        let commande = response.data[0]
        commande.afficherDetails = true
      }

      response.data.forEach(function (commande) {//état en création par défaut pour les commandes et les sous-commandes
        if (!commande.etat) {
          commande.etat = constantesId.etatCmdEnCours
        }
        commande.commandesFournisseurs.forEach(function (sCommande) {
          if (!sCommande.etat) {
            sCommande.etat = constantesId.etatCmdEnCours
          }
        })
      })

      this.commandes = response.data
      this.filtrerCommandes()
      if (this.commandes.length) {
        this.chargerCommande(this.commandes[0], true)
      } else {
        this.$store.commit('uxHelpers/hideSpinner')
      }
    },
    async chargerCommande(commande, forcerMaj) {
      if (this.receptionEnCours()) {
        this.$store.commit('uxHelpers/hideSpinner')
        return
      }
      if (commande.id === this.commandeActive.commandeId && !forcerMaj) {
        this.$store.commit('uxHelpers/hideSpinner')
        return
      }
      if (this.donneesModifiees && commande.id !== this.commandeActive.commandeId) {
        let result = await this.alerteConfirmationEnregistrement()
        if (result) {
          this.commandesFiltrees.forEach(commande => {
            commande.afficherDetails = commande.id === this.commandeActive.commandeId
          })
          this.$store.commit('uxHelpers/hideSpinner')
          return
        }
      }
      this.$store.commit('uxHelpers/showSpinner')
      const response = await detailsCommande(commande.id)
      vm.add(response.data, commande)
      commande.commandesFournisseurs.forEach(function (sCommande) {
        this.chargerCommandeFournisseur(commande, sCommande)
      }.bind(this))
      this.commandes.forEach(c => {
        if (c.id !== commande.id) {
          c.commandesFournisseurs.forEach(sc => sc.afficherDetails = false)
        }
      })
      this.commandeActive = {
        commandeId: commande.id,
        avantModifs: this.donneesCommande(commande)
      }
      this.keyCommandes += 1
      this.checkDonneesModifiees()
      this.$store.commit('uxHelpers/hideSpinner')
    },
    chargerCommandeFournisseur(commande, sCommande) {
      sCommande.lignes = commande.lignes.filter(el => el.foId === sCommande.fournisseurId)
      if (sCommande.lignes) {
        sCommande.lignes.forEach(ligne => {
          ligne.quantite = ligne.estLivree ? ligne.qteLivree : ligne.qPCB
          ligne.puInitial = ligne.pu
          ligne.reference = ligne.Ref
          ligne.lots = ligne.lots || []
          // Set à vide pour le PUT commandes/receptionner si déclaration d'incident
          ligne.raisonIncidentId = ligne.incident ? ligne.incident.raisonIncidentId : ''
          ligne.detailIncident = ligne.incident ? ligne.incident.detail : ''
          ligne.typeReclamation = ligne.reclamation ? ligne.reclamation.type : ''
          ligne.montantAvoir = ligne.reclamation ? ligne.reclamation.montantAvoir : null
          ligne.dateRelivraisonSouhaitee = ligne.reclamation ? ligne.reclamation.dateRelivraisonSouhaitee : ''
        })
      } else {
        console.log("sous commande vide : " + commande.nom + ", " + sCommande.raisonSocialeFournisseur)
      }
      let details = commande.detailFournisseurs.find(el => el.fournisseurId === sCommande.fournisseurId)
      if (!details) {
        commande.commandesFournisseurs = commande.commandesFournisseurs.filter(el => el.fournisseurId !== sCommande.fournisseurId)
        return
      }
      sCommande.totalHDT = details.totalHDT
      sCommande.francoHT = details.francoHT
      sCommande.dateLivraison = details.dateLivraison || ""
      sCommande.dateLivraisonSouhaitee = details.dateLivraisonSouhaitee || ""
      sCommande.numeroBL = details.numeroBL
      sCommande.etatCommande = details.etatCommande
      sCommande.etat = details.etatCommande
      // Idem pour le PUT commandes/receptionner dans le cadre d'une commande globale
      sCommande.raisonIncidentId = details.incident ? details.incident.raisonIncidentId : ''
      sCommande.detailIncident = details.incident ? details.incident.detail : ''
      sCommande.typeReclamation = details.reclamation ? details.reclamation.type : ''
      sCommande.montantAvoir = details.reclamation ? details.reclamation.montantAvoir : null
      sCommande.dateRelivraisonSouhaitee = details.reclamation ? details.reclamation.dateRelivraisonSouhaitee : ''
    },
    demarrerReception(commande, sCommande) {
      commande.commandesFournisseurs.forEach(sC => {
        sC.afficherDetails = sC.fournisseurId === sCommande.fournisseurId
      })
      this.commandeEnReception = {
        commandeId: commande.id,
        fournisseurId: sCommande.fournisseurId,
        avantModif: JSON.stringify(this.donneesSCommande(commande, sCommande))
      }
      this.checkDonneesModifiees()
    },
    annulerReception() {
      this.commandeEnReception = false
      this.checkDonneesModifiees()
      if (this.donneesModifiees) {
        this.chargerListeDesCommandes()
      }
    },
    receptionnerCommande(commande, sCommande) {
      return receptionnerCommande(commande.id, this.donneesSCommande(commande, sCommande))
        .then(() => {
          this.commandeEnReception = false
          this.chargerListeDesCommandes()
          this.toastSucces('Modification enregistrée !')
          return
        })
    },
    enregistrerEnStock(commande, sCommande) {
      return this.$swal({
        title: 'Enregistrer en stock ?',
        html: "Souhaitez vous enregistrer cette commande en stock ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Enregistrer',
        cancelButtonText: 'Continuer la saisie',
        focusCancel: true
      })
        .then((result) => {
          if (result.value) {
            return this.receptionnerCommande(commande, sCommande)
          }
          return
        })
    },
    ajouterIncident(event, el) {
      this.$set(el, 'raisonIncidentId', event.raisonIncidentId)
      this.$set(el, 'typeReclamation', event.typeReclamation)
      this.$set(el, 'dateRelivraisonSouhaitee', event.dateRelivraisonSouhaitee)
      this.$set(el, 'montantAvoir', event.montantAvoir)
      this.$set(el, 'detailIncident', event.detailIncident)
      this.keySCommande += 1
    },
    enleverIncident(el) {
      this.$set(el, 'raisonIncidentId', "")
      this.$set(el, 'typeReclamation', "")
      this.$set(el, 'dateRelivraisonSouhaitee', "")
      this.$set(el, 'montantAvoir', "")
      this.$set(el, 'detailIncident', "")
      this.keySCommande += 1
    },
    setLigneDateDeRelivraisonSouhaitee(event, ligne) {
      this.$set(ligne, 'dateRelivraisonSouhaitee', event)
    },
    setLotDlcDluo(lot, date) {
      if (lot.dlcDluo !== date) {
        lot.dlcDluo = date
        this.keySCommande += 1
      }
    },
    setDateLivraisonSCommande(sCommande, date) {
      if (sCommande.dateLivraison !== date) {
        sCommande.dateLivraison = date
      }
    },
    setDateLivraisonSouhaiteeSCommande(commande, sCommande, date) {
      if (date) {
        let dt = new Date(this.formatDateAvecTirets(date))
        sCommande.dateLivraisonSouhaitee = dt
        let detail = commande.detailFournisseurs.find(el => el.fournisseurId === sCommande.fournisseurId)
        if (detail) {
          detail.dateLivraisonSouhaitee = dt
        }
        this.checkDonneesModifiees()
      }
    },
    changeUrl(event) {
      this.$route.params.dateDebut = event
    },
    totalLigne(quantite, pu, arrondi) {
      var total = 0
      if (quantite && pu) {
        total = arrondiNombre(quantite * pu)
        if (arrondi) {
          total = arrondiMercuriale(total)
        }
      }
      return total
    },
    totalSCommande(commande, sCommande, arrondi) {
      let total = 0
      if (sCommande && sCommande.lignes && sCommande.lignes.some(l => l.qDem)) {
        sCommande.lignes.forEach(function (ligne) {
          let qte = this.receptionEnCours(commande, sCommande) ? ligne.quantite : this.qtePcb(ligne.qDem, ligne.pcb)
          total += this.totalLigne(qte, Number(ligne.pu), false)
        }.bind(this))
      }
      if (total && arrondi) {
        return arrondiMercuriale(total)
      }
      return total
    },
    totalCommande(commande) {
      let total = 0
      if (commande && commande.commandesFournisseurs) {
        commande.commandesFournisseurs.forEach(function (sCommande) {
          total += this.totalSCommande(commande, sCommande, false)
        }.bind(this))
      }
      return total && arrondiMercuriale(total)
    },
    designation(foId, dAgap, dFou) {
      if (foId) {
        return dFou
      } else {
        return dAgap
      }
    },
    qtePcb(qteDem, pcb) {
      // Au bûcher Javascript !!! il est incapable de réaliser des calculs sur des virgules flottantes
      return (Math.ceil(((qteDem * 1000) / (pcb * 1000)).toFixed(3)) * (pcb * 1000)) / 1000
    },
    setQteDem(sCommande, ligne, ancienneValeur) {
      if (ligne.qDem != ancienneValeur)
        this.effacerAlerteRecalcul(ligne)
      else
        this.checkDonneesModifiees()
    },
    effacerAlerteRecalcul(ligne) {
      ligne.utilisations.forEach(u => u.estModifiee = false)
      this.checkDonneesModifiees()
    },
    datesChanged() {
      if (this.selectedDates && this.selectedDates.start) {
        this.dateDebut = this.formatDateAvecTirets(this.selectedDates.start)
        this.$store.commit('commandes/setDateDebutEnMemoire', this.dateDebut)
        this.apiDateDebut = this.formatDatePourApiSansSeparation(this.selectedDates.start)
      }
      if (this.selectedDates && this.selectedDates.end) {
        this.dateFin = this.formatDateAvecTirets(this.selectedDates.end)
        this.$store.commit('commandes/setDateFinEnMemoire', this.dateFin)
        this.apiDateFin = this.formatDatePourApiSansSeparation(this.selectedDates.end)
      }
      this.chargerListeDesCommandes()
    },
    dmsChanged(event) {
      this.dataDMS = event
      this.chargerListeDesCommandes()
    },
    dateDuJour() {
      const d = new Date()
      return d.getFullYear() + '-' + (d.getUTCMonth() + 1) + '-' + d.getDate()
    },
    dateLivraison(sCommande) {
      return this.formatHumanDateAvecSlashes(sCommande.dateLivraison || sCommande.dateLivraisonSouhaitee)
    },
    dateEnvoiManuel(sCommande) {
      return this.formatHumanDateAvecSlashes(sCommande.dateEnvoiManuel)
    },
    receptionEnCours(commande, sCommande) {
      if (!commande && !sCommande) {
        return Boolean(this.commandeEnReception)
      }
      if (!sCommande) {
        return Boolean(this.commandeEnReception && this.commandeEnReception.commandeId === commande.id)
      }
      return Boolean(this.commandeEnReception && this.commandeEnReception.commandeId === commande.id && this.commandeEnReception.fournisseurId === sCommande.fournisseurId)
    },
    toggleChargement(commande, enChargement) {
      commande.enChargement = enChargement
      this.keyCommande += 1
    },
    afficherModalEnvoi(ref) {
      var btn = this.$refs[ref]
      if (btn.length) {
        btn[0].show()
      }
    },
    ncChangementParametre(e) {
      this.ncDateDebut = e.dateDebut
      this.ncDateFin = e.dateFin
      this.ncMenuPlanIds = e.menuPlanIds
      this.ncServiceIds = e.serviceIds
      this.ncDepuisBesoins = this.ncDateDebut && this.ncDateFin && this.ncMenuPlanIds.length
    },
    async verifierInventaire() {
      this.inventaireOuvert = await verifierInventaireOuvert(this.etabCourantId)
    }
  },
  created() {
    let debut = this.$store.state.commandes.dateDebutEnMemoire
    let fin = this.$store.state.commandes.dateFinEnMemoire
    let moisdernier=     new Date()
    moisdernier.setMonth(moisdernier.getMonth()-1)
    let trimestreProchain=new Date()
     trimestreProchain.setMonth(trimestreProchain.getMonth()+3)
    this.dateDebut = debut ? debut : this.formatDateAvecTirets(this.getLundiDeLaSemaine(moisdernier))
    this.dateFin = fin ? fin : this.formatDateAvecTirets(this.getDimancheDeLaSemaine(trimestreProchain))
    this.selectedDates = {
      start: new Date(this.dateDebut),
      end: new Date(this.dateFin)
    }
    this.status = this.$store.state.definitions.etatsCommandes.map(el => el.value) 

    this.status = ["EC1", "EC2"]
    this.optionsStatus = this.$store.state.definitions.etatsCommandes
    this.fournisseurIds = this.$store.state.commandes.fournisseurIds || []
  },
  mounted() {
    this.optionsIncident = this.$store.state.definitions.raisonsIncidents || []
    this.optionsIncidentGlobal = this.optionsIncident.filter(el => el.porteSur === 'Commande')
    this.optionsIncidentLigne = this.optionsIncident.filter(el => el.porteSur === 'Ligne')
    this.optionsTypesDeReclamations = this.$store.state.definitions.typesReclamations
    this.aujourdhui = new Date()
    //this.chargerListeDesCommandes()
    this.chargerListeDesMenus(),
    this.verifierInventaire()
  },
  beforeRouteLeave(to, from, next) {
    if (this.commandeEnReception) {
      // Interruption de la navigation (enregistrement manuel obligatoire)
      this.alerteErreur("Vous devez terminer ou annuler le contrôle à réception en cours pour pouvoir réaliser cette action.")
      // eslint-disable-next-line promise/no-callback-in-promise
      next(false)
      return
    }
    var that = this
    return (function () {
      if (that.donneesModifiees) {
        return that.alerteConfirmationEnregistrement()
      }
      return Promise.resolve(false)
    }())
      .then(change => {
        if (change) {
          try {
            let commande = this.commandes.find(c => c.id === this.commandeActive.commandeId)
            this.modificationCommande(commande)
          } finally {
            this.$store.commit('uxHelpers/hideSpinner')
          }
        }
        // eslint-disable-next-line promise/no-callback-in-promise
        next()
        return
      })
    
  }
}
</script>

<style lang="scss" scoped>
.locked, .locked * {
  cursor: not-allowed !important;
}

.accordeon.not-collapsed .fa-chevron-up {
  transform: rotate(180deg);
}

.card.commande > .collapse.show {
  margin-bottom: -10px!important;
}

.numero-et-date-container {
  font-size: 0.93rem;
  display: flex;
  align-items: center;
  background: #4d72d5;
  color: white;
  padding-left: 19px;
  width: 100%;
  justify-content: left;
}

.alerte-pcb {
  background: orange !important;
  color: white;
  font-weight: 600;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
// .datepicker {
//   box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.06);
//   border: 1px solid black;
//   padding: 3px 9px;
//   border-color: rgba(0,0,0,0.16);
//   color: #5f5f5f;
//   background-color: #fff;
//   border-style: solid;
//   border-radius: 2px;
//   font-size: 1rem;
// }
::placeholder {
  color: #a7a7a7;
}
.li-commande {
  background: #f7f7f7;
  border: 1px solid #dadada;
  color: #000000;
  &:hover {
    background: #eeeeee;
    cursor: pointer;
  }
}

.input-group {
  width: auto;
  span {
    position: absolute;
    z-index: 1;
    right: 5px;
    top: 8px;
    color: #000000;
    font-size: 0.8rem;
  }
  input {
    padding-right: 14px;
    border-radius: 0;
  }
}
.container-ligne-incident-lots {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.container-incident-sur-la-ligne-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.close {
  cursor: pointer;
}
.incident {
  background: #d33;
  color: white;
  margin-bottom: 1rem;
}
.table-suivi-commandes tbody tr:nth-of-type(odd) {
  background-color: rgba(214, 224, 255, 0.44) !important;
}
.table-suivi-commandes thead {
  background: #7286ce !important;
  color: white !important;
}
.bg-gradient-bleu text-white {
  background: rgba(77, 113, 214, 0.09019607843137255);
  font-weight: 600;
  padding: 7px 15px;
  margin-bottom: 0.4rem;
}
.btn-card-header {
  color: white;
  &:hover {
    text-decoration: none;
  }
}
.container-titre-commande {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}
.selected {
  background: #7087cf;
  color: white;
  box-shadow: 0px 0px 1px 1px #616bc461 !important;
  border: none !important;
}
.container-annuler {
  margin-left: 3rem;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
}
.datepicker-suivi-des-commandes {
  height: 43px;
  width: 282px;
  text-align: center;
  letter-spacing: 0.1px;
  font-size: 1.2rem;
  color: #757575;
}
.fa-rose {
  color: #ff7e7e;
}
.fa-vert {
  color: green;
}
.fournisseurs:not(.v-input--is-label-active):not(.v-input--is-focused) {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

#card-commandes .card-body {
  overflow-x: hidden;
}
#card-commande .card-body {
  padding: 0 !important;
}
</style>
