<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header bg-gradient-bleu text-white">
            <h4 v-if="archive" class="card-title" style="display:inline;margin-right:10px;">Productions archivées</h4>
            <h4 v-else class="card-title" style="display:inline;margin-right:10px;">Productions en cours</h4>
            <input v-if="productions.length" type="text" v-model="termeDeRecherche" placeholder="Recherche..." class="input-search" />
            <span v-if="termeDeRecherche" class="fas fa-window-close" title="Effacer la recherche" style="position:relative;right:31px;top:4px;" @click="termeDeRecherche = ''"></span>
          </div>
          <div class="card-content" aria-expanded="true">
            <div class="card-body">
              <b-pagination v-if="showPagination" class="flex-center-center" v-model="currentPage" :total-rows="totalRows" :per-page="perPage" />
              <div class="col-12">
                <b-table v-if='!enTravail' ref="tableSuivi" :items="productionsAffichees" :fields="champsTableSuivi" :busy="enCreation || enRecalcul" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" @row-selected="surSelectionLigne" striped hover sort-icon-left selectable select-mode="single">
                  <template v-slot:cell(urlFichesProdPdf)="data">
                    <a v-if="data.value" class="btn btn-primary" :href="data.value" target="_blank">Voir</a>
                  </template>
                  <template v-slot:cell(urlBonLivraisonPdf)="data">
                    <a v-if="data.value" class="btn btn-primary" :href="data.value" target="_blank">Voir</a>
                  </template>
                     <template v-slot:cell(dateProduction)="data">
                       <span v-if="data.value.indexOf('0001')==-1"> {{formatHumanDate(data.value)}}  </span>
                  </template>
                  <template v-slot:cell(urlFichesSortiePdf)="data">
                    <a v-if="data.value" class="btn btn-primary" :href="data.value" target="_blank">
                      Voir
                      <!-- <i class="fas fa-exclamation-triangle ml-2" v-show="data.item.articlesManquants" title="Articles manquants"></i> -->
                    </a>
                  </template>
                </b-table>
                <div v-else style="width:100%;height:100%">
                  <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style="display: block; margin-left: auto; margin-right: auto" />
                </div>
              </div>
              <div v-if="!archive" class="col-12">
                <button v-if='!enCreation && !enTravail' class="btn btn-primary mr-2" @click="creerProduction" :disabled='enTravail'>Créer une mise en production</button>
                <button v-if='enCreation' class="btn btn-primary mr-2" @click="enCreation=false">Annuler la mise en production</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="enCreation">
      <div class="col-12">
        <div class="card">
          <div class="card-header bg-gradient-bleu text-white">
            <h4 class="card-title">Création d'une production</h4>
          </div>
          <div class="card-content" aria-expanded="true">
            <div class="card-body">
              <div style="display:flex;flex-direction:row">
                <div class="mr-2" style="flex:auto">
                  <SelectDatesMenusPlansServices @changer-dms="changementPeriode" titre="Période de consommation..." viderMenusPlans="Désélectionner toutes les structures de menus" dropdownPosition="bottom-top" />
                  <!--     :dateDebut="dateDebut" :dateFin="dateFin" :serviceIds="serviceIds" :menuPlanIds="menuPlanIds" -->
                </div>
                <div style="flex:initial">
                  <button class="btn btn-primary mr-2" @click="afficherCreation()">Créer</button>
                  <button class="btn btn-primary" @click="enCreation=false">Annuler</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="productionEnCours && !enCreation">
      <div class="col-12">
        <div class="card">
          <div class="card-header bg-gradient-bleu text-white">
            <div class="card-title flex-center-space-between">
              {{productionEnCours.nom}} - {{productionEnCours.statutTexte}}
              <span title="Date de production">{{formatHumanDateAvecJour(productionEnCours.dateProduction)}} </span>
              <button v-if="productionEnCours && productionEnCours.statut<=2" class="btn btn-danger mr-2" @click="supprimerSuiviProduction">
                <i class="fas fa-eraser" style="margin-right:10px;" ref="supprprod"></i>
                Supprimer
              </button>
            </div>
          </div>
          <div class="card-body">
            <div v-if="productionEnCours.statut<=3" class="card-text mb-1">
              <span class="mr-2"> Etapes : </span>
              <button v-if="productionEnCours" class="btn btn-primary mr-2" @click="miseEnProduction" :disabled="enTravail || !(productionEnCours.statut==1)">1. Mettre en production</button>
              <i class="fa fa-chevron-right"></i>
              <span v-tooltip="{ content: inventaireOuvert ? 'Confirmation non disponible tant qu\'un inventaire est ouvert.' : '' }">
                <button v-if="productionEnCours" class="btn btn-primary mr-2" @click="atterrir" :disabled="enTravail || !(productionEnCours.statut==2) || inventaireOuvert">2. Confirmer la sortie de stock</button>
              </span>
              <i class="fa fa-chevron-right"></i>
              <button v-if="productionEnCours" class="btn btn-primary mr-2" @click="terminer" :disabled="!(productionEnCours.statut==3)">3. Terminer (archiver)</button>
            </div>
            <div v-if="productionEnCours.statut > 1" class="card-texte mb-2" style="margin-top: 8px;">
              <span class="mr-2"> Exports : </span>
              <ToggleButton class="mr-2" v-model="fichesEnPdf" :labels="{checked: 'PDF', unchecked: 'Excel'}" :color="{checked: '#D30943', unchecked: '#1D6F42'}" :height="30" :width="80" v-tooltip="{content: 'Modifier le type d\'export', placement: 'top'}" :disabled="productionEnCours.statut <= 1" />
              <b-btn variant="outline-primary" class="mr-2" @click="lancerRecalcul" :disabled="enRecalcul || !(productionEnCours.statut == 2)">Recalculer les documents <i :class="[{'fa-spin': enRecalcul},'fa' ,'fa-sync-alt' ,'ml-2']"></i></b-btn>
              <div class="btn-group mr-2">
                <b-btn variant="primary" class="mr-1" @click="telechargerFichesSortie" :disabled='enRecalcul||docManquant("urlFichesSortie")'>
                  Fiche de sortie<i class="fas fa-exclamation-triangle ml-2" v-show="productionEnCours.articlesManquants" title="Articles potentiellement manquants en stock."></i>
                </b-btn>
              </div>
              <div class="btn-group mr-2">
                <b-btn variant="primary" class="mr-1" @click="telechargerFichesProd" :disabled='enRecalcul||docManquant("urlFichesProd")'>Fiche de prod.</b-btn>
                <b-btn variant="primary" class="mr-1" @click="telechargerDocProdReleveTemperature" :disabled='enRecalcul||docManquant("urlFichesProd")'>Relevé de temp.</b-btn>
                <b-btn variant="primary" class="mr-1" @click="telechargerDocProdRefroidissement" :disabled='enRecalcul||docManquant("urlFichesProd")'>Refroidissement</b-btn>
              </div>
              <div class="btn-group mr-2">
                <b-btn variant="primary" class="mr-1" @click="telechargerBonDeLivraison" :disabled="enRecalcul || (!(productionEnCours.atterrissage && productionEnCours.atterrissage.avecPortage))">Bon de livraison</b-btn>
                <b-btn variant="primary" class="mr-1" @click="telechargerDocProdAllotissement" :disabled="enRecalcul  || (!(productionEnCours.atterrissage && productionEnCours.atterrissage.avecPortage))">Récap. d'allotissement</b-btn>
              </div>
              <span v-if="productionEnCours.dateGenerationDoc">Générés : {{formatHumanDateHeure(productionEnCours.dateGenerationDoc)}}</span>
            </div>
            <div v-if="(productionEnCours.atterrissage && productionEnCours.atterrissage.composantes) || !enTravailEffectifs" class="card-text">
              <b-table striped hover :items="productionEnCours.atterrissage.composantes" :fields="this.productionEnCours.statut <= 2 ? champsTableEffectif : champsTableEffectifComplet">
                <template v-slot:cell(menu)="data">
                  <router-link :to="{ name: 'Menus', params: { etablissementId: etabCourantId, date: formatDateAvecTirets(getLundiDeLaSemaine(productionEnCours.atterrissage.composantes[data.index].dateUsage)), menuId: 'm' + productionEnCours.atterrissage.composantes[data.index].menuId } }" target="_blank" title="Afficher le menu" style="color:#212529;">
                    <i class="fas fa-external-link-alt"></i>&nbsp;{{data.value}}
                  </router-link>
                </template>
                <template v-slot:cell(dateUsage)="data">
                  <AffichageDate :date="data.value" anneeSur2Chiffres />
                </template>
                <template v-slot:cell(effectif)="data">
                  <span v-if="archive || productionEnCours.statut>1 ||productionEnCours.atterrissage.avecPortage">{{ data.value }}</span>
                  <input v-else type="number" v-model="productionEnCours.atterrissage.composantes[data.index].effectif" @focus="$event.target.select()" @blur="sauverEffectifs" />
                </template>
                <template v-slot:cell(effectifServi)="data">
                  <span v-if="archive || productionEnCours.atterrissage.avecPortage">{{ data.value }}</span>
                  <input v-else type="number" v-model="productionEnCours.atterrissage.composantes[data.index].effectifServi" @focus="$event.target.select()" @blur="sauverEffectifs" />
                </template>
              </b-table>
            </div>
            <div v-if="enTravailEffectifs" style="width:100%;height:100%">
              <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style="display: block; margin-left: auto; margin-right: auto" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal size="lg" hide-footer v-model="nouvelleProduction.modalAffichee" header-bg-variant="gradient-bleu" header-text-variant="light">
      <template slot="modal-title">
        <h3>Créer une production</h3>
      </template>
      <template slot="default">
        <div class="form-group">
          <label for="nomCommande">Nom de votre production</label>
          <input @focus="$event.target.select()" class="form-control" id="nomCommande" type="text" v-model.trim="nouvelleProduction.nom">
          <div class="error" v-if="!nouvelleProduction.nom">Vous devez indiquer un nom pour cette production</div>
        </div>
        <div class="datepicker-trigger form-group">
          <label>Date de Production</label><br />
            <ManualDatepicker input-style="width:150px;" :value="nouvelleProduction.date" @saved="nouvelleProduction.date = $event" />         
          <div class="error" v-if="!nouvelleProduction.date">La date de production doit être indiquée</div>
        </div>
        <div style="margin-bottom:15px;" class="form-group">
          <label>DLC / DDM</label><br />
          J + <InputNumber v-model="nouvelleProduction.dlc" :key="nouvelleProduction.dlc" typeFiltre="quantiteEntiere" style="width:70px" />
          <div class="error" v-if="nouvelleProduction.dlc <= 0 || !nouvelleProduction.dlc">Une DLC doit être supérieure à 0</div>
        </div>
        <div style="margin: 2rem 0; text-align:right">
          <button class="btn btn-success" @click="declencherProduction" :disabled="!nouvelleProduction.nom || !nouvelleProduction.date || !nouvelleProduction.dlc">Enregistrer</button>
          <button class="btn btn-danger" style="margin-left:1rem;" @click="nouvelleProduction.modalAffichee=false">Annuler</button>
        </div>
      </template>
    </b-modal>
  </div>

</template>

<script>
import { mapActions } from "vuex"
import SelectDatesMenusPlansServices from '@/components/Divers/SelectDatesMenusPlansServices'
import AffichageDate from '@/components/Divers/AffichageDate'
import { showAlerts } from "@/mixins/alerts"
import { connecteData } from "@/mixins/connecteData"
import { formatageDate } from '@/mixins/dateFormats'
import { getLundiDeLaSemaine } from '@/mixins/index'
import {
  listerProductionEnCours, creerProduction, mettreEnProduction, obtenirSuiviAJour, downloadBonDeLivraison,
  atterrir, obtenirAtterrissage, terminer, indiquerEffectifs, telechargerFichesProdPdf, telechargerFichesProdXlsx, telechargerFichesSortiePdf, telechargerFichesSortieXlsx, 
  telechargerProdReleveTemperature, telechargerProdRefroidissement, telechargerProdAllotissement,
  listerProductionArchive, supprimerProduction
} from "@/api/gpao/production"
import { lancerTelechargementDeResponse } from '@/helpers/utils'
import ManualDatepicker from '@/components/Inputs/ManualDatepicker'
import InputNumber from '@/components/Inputs/InputNumber'
import { verifierInventaireOuvert } from "@/helpers/verifications"
export default {
  components: { SelectDatesMenusPlansServices, AffichageDate,ManualDatepicker,InputNumber },
  mixins: [showAlerts, connecteData, formatageDate, getLundiDeLaSemaine],
  name: "PageProduction",
  data() {
    return {
      nouvelleProduction: { date: undefined, nom: undefined, modalAffichee: false, dlc: undefined },
      productions: [],
      productionsFiltrees: [],
      productionsTriees: [],
      productionsAffichees: [],
      enTravail: false,
      enTravailEffectifs: false,
      enCreation: false,
      enRecalcul: false,
      champsTableSuivi: [
        { key: 'nom', sortable: true },
        { key: 'statutTexte', label: 'Statut', sortable: true },
        { key: 'dateProduction', label:'Produit Le', sortable: true },
        { key: "urlFichesProdPdf", label: 'Fiche de prod.', tdClass: "text-center" },
        { key: 'urlFichesSortiePdf', label: 'Fiche de sortie', tdClass: "text-center" },
        { key: 'urlBonLivraisonPdf', label: 'Bon de livraison', tdClass: "text-center" }
      ],
      champsTableEffectif: ['menu', 'dateUsage', 'service', 'typeComposante', 'convive', 'recette', { key: 'effectif', label: 'Effectif prévu' }],
      periodeCreation: {},
      productionEnCours: null,
      fichesEnPdf: true,
      optionsTypesFichiers: [{ text: "PDF", value: true }, { text: "Excel", value: false }],
      perPage: 10,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      termeDeRecherche: "",
      inventaireOuvert: true,
    }
  },
  props: {
    archive: { type: Boolean, default: false }
  },
  watch: {
    currentPage() {
      this.updateProductionsAffichees()
    },
    sortBy() {
      this.updateProductionsTriees()
    },
    sortDesc() {
      this.updateProductionsTriees()
    },
    termeDeRecherche() {
      this.updateProductionsFiltrees()
    }
  },
  computed: {
    champsTableEffectifComplet() {
      var tab = this.champsTableEffectif.slice(0, this.champsTableEffectif.length);
      tab.push('effectifServi');
      return tab;
    },
    totalRows() {
      return this.productionsFiltrees.length
    },
    showPagination() {
      return this.totalRows && (this.totalRows > this.perPage)
    }
  },
  methods: {
    ...mapActions("uxHelpers", ["afficherAttente", "cacherAttente"]),
    docManquant(typeDoc) {
      if (!this.productionEnCours)
        return true
      if (this.fichesEnPdf)
        typeDoc += "Pdf"
      else
        typeDoc += "Xlsx"
      return !this.productionEnCours[typeDoc]
    },
    async surSelectionLigne(items) {
      this.productionEnCours = (items.length == 0) ? undefined : items[0];
      if (this.productionEnCours) {
        await this.obtenirAtterrissage();
      }
    },
    changementPeriode(event) {
      this.periodeCreation = {
        menuStructureIds: event.menuPlanIds.join(","), dateDebut: event.dateDebut.replace(/-/g, ''), dateFin: event.dateFin.replace(/-/g, ''), serviceIds: event.serviceIds.join(","),
        etablissementId: this.etabCourantId
      }
    },
    creerProduction() {
      this.enCreation = true
      this.$nextTick(() => window.scrollTo(0, document.body.scrollHeight))
    },
    declencherProduction() {
      this.nouvelleProduction.modalAffichee = false
      this.enCreation = false
      this.enTravail = true
      this.periodeCreation["titre"] = this.nouvelleProduction.nom
      this.periodeCreation["dateProduction"] = this.formatDatePourApiSansSeparation(this.nouvelleProduction.date)
      this.periodeCreation["joursDLC"] = this.nouvelleProduction.dlc
      return creerProduction(this.periodeCreation)
        .then((response) => {
          this.productions.push(response.data)
          this.updateProductionsFiltrees()
          setTimeout(() => { this.$refs.tableSuivi.selectRow(this.productions.length - 1); }, 1000)
          this.alerteSucces('Mise en production créée !')
          this.enTravail = false;
          return
        })
    },
    afficherCreation() {
      this.nouvelleProduction.nom = undefined
      this.nouvelleProduction.modalAffichee = true
      var date = new Date(this.periodeCreation.dateDebut.substring(0,4), (this.periodeCreation.dateDebut.substring(4,6) - 1), this.periodeCreation.dateDebut.substring(6,8))
      this.nouvelleProduction.date = String(new Date(date))
      let objDs = JSON.parse(this.etabCourant.donneesSupplementaires)
      if (objDs.EtablissementDlcPortage)
        this.nouvelleProduction.dlc = objDs.EtablissementDlcPortage
      else
        this.nouvelleProduction.dlc = 3
    },
    async miseEnProduction() {
      if (!this.productionEnCours)
        return;
      this.enTravail = true
      this.enRecalcul = true
      this.afficherAttente()
      const reponse = await mettreEnProduction(this.productionEnCours.suiviId)
      Object.assign(this.productionEnCours, reponse.data)
      this.enTravail = false
      this.enRecalcul = false
      this.cacherAttente()
    },
    async atterrir() {
      if (!this.productionEnCours)
        return;
      let atterrirOk = await this.alerteConfirmation("Confirmer la sortie de stock", "Etes-vous certain de vouloir confirmer la sortie de stock ?");
      if (atterrirOk) {
        this.enTravail = true
        this.enRecalcul = true
        this.afficherAttente()
        const reponse = await atterrir(this.productionEnCours.suiviId)
        reponse.data.atterrissage = this.hydraterAtterrissage(reponse.data.atterrissage, true);
        Object.assign(this.productionEnCours, reponse.data)
        this.enTravail = false
        this.enRecalcul = false
        this.cacherAttente()
      }
    },
    async lancerRecalcul() {
      this.enRecalcul = true
      try {
        const response = await obtenirSuiviAJour(this.productionEnCours.suiviId)
        Object.assign(this.productionEnCours, response.data)
        this.toastSucces("Documents recalculés !")
      } finally {
        this.enRecalcul = false
      }
    },
    telechargerFichesProd() {
      if (this.fichesEnPdf) {
        return telechargerFichesProdPdf(this.productionEnCours.suiviId).then(response => {
          return lancerTelechargementDeResponse(response)
        })
      } else {
        return telechargerFichesProdXlsx(this.productionEnCours.suiviId).then(response => {
          return lancerTelechargementDeResponse(response)
        })
      }
    },
    telechargerBonDeLivraison() {
      return downloadBonDeLivraison((this.fichesEnPdf ? "pdf" : "xlsx"), this.productionEnCours.suiviId).then(response => {
        return lancerTelechargementDeResponse(response)
      })
    },
    telechargerDocProdReleveTemperature() {
      return telechargerProdReleveTemperature((this.fichesEnPdf ? "pdf" : "xlsx"), this.productionEnCours.suiviId).then(response => {
        return lancerTelechargementDeResponse(response)
      })
    },
    telechargerDocProdRefroidissement() {
      return telechargerProdRefroidissement((this.fichesEnPdf ? "pdf" : "xlsx"), this.productionEnCours.suiviId).then(response => {
        return lancerTelechargementDeResponse(response)
      })
    },
    telechargerDocProdAllotissement() {
      return telechargerProdAllotissement((this.fichesEnPdf ? "pdf" : "xlsx"), this.productionEnCours.suiviId).then(response => {
        return lancerTelechargementDeResponse(response)
      })
    },
    telechargerFichesSortie() {
      if (this.fichesEnPdf) {
        return telechargerFichesSortiePdf(this.productionEnCours.suiviId).then(response => {
          return lancerTelechargementDeResponse(response)
        })
      } else {
        return telechargerFichesSortieXlsx(this.productionEnCours.suiviId).then(response => {
          return lancerTelechargementDeResponse(response)
        })
      }
    },
    async obtenirAtterrissage() {
      this.enTravailEffectifs = true
      const reponse = await obtenirAtterrissage(this.productionEnCours.suiviId);
      let atterrissage = this.hydraterAtterrissage(reponse.data, this.productionEnCours.statut == 3);
      this.$set(this.productionEnCours, 'atterrissage', atterrissage);
      this.enTravailEffectifs = false
    },
    hydraterAtterrissage(atterrissage, serviParDefaut) {
      for (let i = 0; i < atterrissage.composantes.length; ++i) {
        atterrissage.composantes[i].recette = atterrissage.recettes.find((element) => element.id === atterrissage.composantes[i].recetteId).nom;
        atterrissage.composantes[i].typeComposante = this.$store.state.definitions.composantesRepas.find((element) => element.value === atterrissage.composantes[i].typeComposanteId).text
        var convive = this.$store.state.definitions.convivesTypes.find((element) => element.value === atterrissage.composantes[i].typeConviveIdDemande)
        atterrissage.composantes[i].convive = convive.text
        atterrissage.composantes[i].texture = this.$store.state.definitions.textures.find((element) => element.value === atterrissage.composantes[i].textureId).text
        atterrissage.composantes[i].service = this.$store.state.definitions.services.find((element) => element.value === atterrissage.composantes[i].serviceId).text
        atterrissage.composantes[i].regime = this.$store.state.definitions.regimes.find((element) => element.value === atterrissage.composantes[i].regimeId).text
        atterrissage.composantes[i].menu = atterrissage.menus.find((element) => element.id === atterrissage.composantes[i].menuId).nom;
        if (serviParDefaut && !atterrissage.composantes[i].effectifServi) {
          atterrissage.composantes[i].effectifServi = atterrissage.composantes[i].effectif
        }
      }
      return atterrissage;
    },
    async terminer() {
      if (!this.productionEnCours)
        return;
      this.enTravail = true
      this.enRecalcul = true
      this.afficherAttente()
      await this.sauverEffectifs(this.productionEnCours)
      const reponse = await terminer(this.productionEnCours.suiviId)
      if (reponse.status === 200) {
        // eslint-disable-next-line promise/catch-or-return
        listerProductionEnCours(this.etabCourantId)
          .then((response) => {
            this.productions = response.data
            this.updateProductionsFiltrees()
            return
          })
      }
      this.productionEnCours = undefined
      this.enTravail = false
      this.enRecalcul = false
      this.cacherAttente()
    },
    async sauverEffectifs() {
      let production = this.productionEnCours
      let effectifs = production.atterrissage.composantes.map(({ composanteId, effectif, effectifServi }) => ({ composanteId, 'effectifPrevu': effectif, effectifServi }));
      effectifs.forEach(element => production.statut === 1 ? element.effectifServi = null : element.effectifPrevu = null)
      const reponse = await indiquerEffectifs(production.suiviId, effectifs);
      reponse.data.atterrissage = this.hydraterAtterrissage(reponse.data.atterrissage);
      Object.assign(production, reponse.data)
      this.toastSucces("Effectifs sauvés")
    },
    async supprimerSuiviProduction() {
      if (!this.productionEnCours)
        return
      let suppressionOk = await this.alerteConfirmation("Supprimer une production", "Etes-vous certain de vouloir supprimer cette production ?");
      if (suppressionOk) {
        this.enCreation = true
        this.enTravail = true
        this.enRecalcul = true
        this.$refs.supprprod.classList.add("fa-spin")
        this.$refs.supprprod.parentNode.disabled = true
        let response = await supprimerProduction(this.productionEnCours.suiviId)
        if (response.status === 200) {
          let res = await listerProductionEnCours(this.etabCourantId)
          this.productions = res.data
          this.updateProductionsFiltrees()
        }
        this.productionEnCours = undefined
        this.enTravail = false
        this.enRecalcul = false
        this.enCreation = false
        if (this.$refs.supprprod) {
          this.$refs.supprprod.classList.remove("fa-spin")
          this.$refs.supprprod.parentNode.disabled = false
        }
      }
    },
    updateProductionsFiltrees() {
      if (this.termeDeRecherche) {
        this.productionsFiltrees = this.productions.filter(p => p.nom.toLocaleLowerCase().includes(this.termeDeRecherche.toLocaleLowerCase()))
      } else {
        this.productionsFiltrees = this.productions
      }
      this.updateProductionsTriees()
    },
    updateProductionsTriees() {
      if (!this.sortBy) {
        this.productionsTriees = this.productionsFiltrees
      } else {
        this.productionsTriees = this.productionsFiltrees.sort((a, b) => {
          return (this.sortDesc ? -1 : 1) * (a[this.sortBy] > b[this.sortBy] ? 1 : -1)
        })
      }
      this.updateProductionsAffichees()
    },
    updateProductionsAffichees() {
      if (this.productionsTriees.length) {
        this.productionsAffichees = this.productionsTriees.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
        )
      } else {
        this.productionsAffichees = []
      }
    },
    async verifierInventaire() {
      this.inventaireOuvert = await verifierInventaireOuvert(this.etabCourantId)
    }
  },
  mounted() {
    if (this.archive) {
      return listerProductionArchive(this.etabCourantId)
        .then((response) => {
          this.productions = response.data
          this.updateProductionsFiltrees()
          return
        })
    }
    return listerProductionEnCours(this.etabCourantId)
      .then((response) => {
        this.productions = response.data
        this.updateProductionsFiltrees()
        return
      }),
    this.verifierInventaire()
  }
}
</script>
