<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <!-- Panneau de gauche -->
        <transition name="fade">
          <div class="col-3">
            <div class="card-header bg-gradient-bleu text-white flex-center-space-between" style="height:60px">
              <h4 class="card-title">INVENTAIRE</h4>
              <div class="switch-container">
                <button class="btn btn-light push-right-1rem" v-tooltip="{ content: 'Ajouter un inventaire', placement: 'top' }" :disabled="!dateCreationValide" @click="creerStockInventaire()">
                  <i class="fas fa-plus"></i>
                </button>
              </div>
            </div>
            <div class="card">
              <Calendrier :markedDates="calendrierDatesInventaires" :selectedDate="inventaire ? inventaire.date : new Date()" :verrouAvantMarkedDates="true" :verrouEntreMarkedDates="true" :verrouApresMarkedDates="!tousLesInventairesValides" :dateMax="new Date()" :key="calendrierKey" @selectionner-date="selectionnerDate($event)"></Calendrier>
              <div v-if="inventaire" class="filtres-container">
                <MultiSelect :value="filtreZonesDeStockage" :items="inventaire.zonesStockage" itemText="designation" itemValue="designation" placeholder="Zones de stockage..." emptyText="Toutes les zones ont été ajoutées" @change="filtreZonesDeStockage = $event" style="margin-top:0!important;padding-top:0!important" />
                <div style="display:flex;justify-content:space-between;width:100%">
                  <input type="text" v-model="filtreNomProduit" style="width:90%" placeholder="Recherche type de produit" @input="filtrerNomProduit">
                  <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style='height:32px;' v-show="enCalcul" />
                </div>
              </div>
            </div>
          </div>
        </transition>

        <!-- Panneau de droite -->
        <transition name="fade" mode="out-in">
          <div class="col-9 pl-0">
            <div v-if="inventaire" class="alert alert-warning mb-2" role="alert">
              <strong>Inventaire en cours.</strong> Les fonctionnalités suivantes sont désactivées afin de ne pas fausser le stock.<br />
              Mouvements de stock, réception de commande, sortie de stock d'une production.
            </div>
            <!-- Affichage des lignes de l'inventaire -->
            <div class="card card-defilante-pleine-page">
              <div v-if="inventaire" class="card-header bg-gradient-bleu text-white card-links card-header-push-bottom flex-center-space-between" style="height:60px">
                <h4 class="card-title card-title-categories">{{formatHumanDate(new Date(inventaire.date))}}</h4>
                <div class="links-container">
                  <button v-if="filtreZonesDeStockage.length || filtreNomProduit" class="btn btn-light push-right-1rem" v-tooltip="{ content: 'Réinitialiser les filtres', placement: 'top' }" @click="reinitialiser()">
                    <i class="fas fa-sync-alt"></i>
                  </button>

                  <button type="submit" class="btn btn-light push-right-1rem" alt="Editer" v-tooltip="{ content: 'Exporter l\'inventaire', placement: 'top' }" @click="exporter(inventaire)">
                    <i :class="{ 'fa-spin ': enExportation }" class="fas fa-file-excel"></i>
                  </button>

                  <BtnAjoutMvtStock type="button" class="push-left-1rem push-right-1rem" title="Ajouter un article à l'inventaire" btnImage="fas fa-plus" :disabled="!inventaireSelectionneNonValide" action="in" mode="inventaire" @envoyer-article="ajouterArticle($event)" />
                  <button class="btn btn-light push-right-1rem" v-tooltip="{ content: 'Enregistrer les modifications', placement: 'top' }" :disabled="!inventaireSelectionneNonValide || !donneesModifiees" @click="enregistrerInventaire(inventaire.id, lignesInventaire)" :style="inventaireSelectionneNonValide && donneesModifiees ? 'border: 1px solid red' : ''">
                    <i class="fas fa-save"></i>
                  </button>

                  <button class="btn btn-success push-left-1rem push-right-1rem" v-tooltip="{ content: 'Valider l\'inventaire', placement: 'top' }" :disabled="!inventaireSelectionneNonValide || donneesModifiees" @click="validerInventaire(inventaire.id)">
                    <i class="fas fa-check"></i>
                  </button>

                  <button class="btn btn-danger push-right-1rem" v-tooltip="{ content: 'Annuler l\'inventaire', placement: 'top' }" :disabled="!inventaireSelectionneNonValide" @click="annulerInventaire(inventaire.id)">
                    <i class="fas fa-ban"></i>
                  </button>
                </div>
              </div>
              <div v-else class="card-body flex-center-space-between m-0 p-0">
                <h4 class="card-title card-title-categories flex-center-space-between m-0 p-0">
                  <div class="card ml-0 mt-0 mr-2 mb-0">
                    <span class="m-4">Pour commencer un nouvel inventaire à partir du stock à la date correspondante, merci de sélectionner une date et de cliquer sur le bouton
                      <button class="btn btn-primary push-right-1rem" :disabled="!dateCreationValide" @click="creerStockInventaire()">
                        <i class="fas fa-plus"></i>
                      </button>
                    </span>
                  </div>
                  <div class="card ml-2 mt-0 mr-0 mb-0">
                    <span class="m-4">Pour commencer un nouvel inventaire à partir d'un fichier, merci de sélectionner une date et de cliquer sur le bouton
                      <ImportInventaire :disabled="!dateCreationValide" :date="dateInventaireACreer" :inventaireId="inventaire ? inventaire.id : null"
                        @importer="importerStockInventaire($event)"/>
                    </span>
                  </div>
                </h4>
              </div>
              <div v-if="inventaire" class="card-body">
                <div v-for="(zone, iZone) in inventaire.zonesStockage" :key="iZone" class="container-fluid" no-body>
                  <div v-if="!filtreZonesDeStockage.length || filtreZonesDeStockage.includes(zone.designation)">
                    <div class="row card-header bg-gradient-bleu text-white">
                      <a block class="btn-card-header flex-center-space-between col-12" v-b-toggle="'zone' + iZone">
                        <span class="when-opened" style="float:left;"><i class="fa fa-chevron-down"></i></span>
                        <span class="when-closed" style="float:left;"><i class="fa fa-chevron-right"></i></span>
                        <div class="col-8 flex-start">
                          {{zone.designation.toUpperCase()}}
                        </div>
                        <div class="col-3 flex-end">
                          {{zone.valeur | afficherPrix}}
                        </div>
                        <div v-if="inventaireSelectionneNonValide" class="col-1 flex-end">
                          <ToggleButton v-model="zone.verifiee" :labels="{checked: 'Vu', unchecked: 'A voir'}" :color="{checked: '#16D39A', unchecked: '#CCCCCC'}" :height="30" :width="80" :key="keyToggle" @change="verifierQtesLots(zone)" />
                        </div>
                      </a>
                    </div>

                    <b-collapse :id="'zone' + iZone" accordion="zone" class="row" role="tabpanel" visible>
                      <div class="table-responsive">
                        <table class="table table-sm table-bordered">
                          <thead>
                            <tr>
                              <th style="width:120px">Référence</th>
                              <th>Désignation</th>
                              <th style="width:120px">N° Lot</th>
                              <th style="width:120px">DLC/DDM</th>
                              <th v-if="inventaireSelectionneNonValide" style="width:120px">Stock</th>
                              <th style="width:120px">Quantité</th>
                              <th v-if="inventaireSelectionneNonValide" style="width:50px">Vérification</th>
                              <th style="width:120px">Total HT</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(lot, iLot) in obtenirLots(zone)" :key="iZone + '-' + iLot" class="datepicker-trigger">
                              <td style="vertical-align: middle" :style="lot.quantite == 0 ? 'text-decoration: line-through' : ''">
                                {{lot.reference}}
                              </td>
                              <td style="vertical-align: middle" :style="lot.quantite == 0 ? 'text-decoration: line-through' : ''">
                                <i v-if="lot.termineLe" class="fab fa-creative-commons-nc-eu mr-1" style="color:orange" v-tooltip="{ content: 'Tarif obsolète depuis le ' + formatHumanDate(lot.termineLe) }"></i>
                                <btn-affichage-mvts-stock class="mr-1" :id="lot.id" :lot="lot" />
                                <span>{{lot.designationFournisseur}} <i>({{lot.valeurUnitaire | afficherPrix}} / {{lot.uc}})</i></span>
                              </td>
                              <td style="vertical-align: middle;max-width:50px">
                                <span v-if="inventaireSelectionneNonValide">
                                  <input type="text" autocomplete="off" v-model="lot.NumLot" style="width:100%" placeholder="N° lot" />
                                </span>
                                <span v-else>{{lot.NumLot}}</span>
                              </td>
                              <td style="vertical-align: middle;max-width:50px">
                                <span v-if="inventaireSelectionneNonValide">
                                  <b-form-datepicker right v-model="lot.dlcDluo" placeholder="" :date-format-options="{ year: '2-digit', month: 'numeric', day: 'numeric' }" locale='fr' size="sm" />
                                  <!--<datepicker monday-first :language="fr" v-model="lot.dlcDluo" class="date-picker-semaine" style="width:110px"></datepicker>-->
                                </span>
                                <span v-else>{{formatHumanDate(lot.dlcDluo)}}</span>
                              </td>
                              <td v-if="inventaireSelectionneNonValide" style="vertical-align: middle;max-width:50px">
                                {{obtenirQteLotEnStock(lot.id)}} {{lot.uc}}
                              </td>
                              <td style="vertical-align: middle;max-width:50px">
                                <span v-if="inventaireSelectionneNonValide">
                                  <InputNumber v-model="lot.quantite" style="width:100%" typeFiltre="quantite" placeholder="Quantité" @input="setQuantiteLot(lot, $event)" @move="moveLot($event)" />
                                </span>
                                <span v-else>
                                  {{lot.quantite}} {{lot.uc}}
                                </span>
                              </td>
                              <td v-if="inventaireSelectionneNonValide" style="vertical-align: middle">
                                <ToggleButton v-model="lot.quantiteVerifiee" :labels="{checked: 'Vu', unchecked: 'A voir'}" :color="{checked: '#16D39A', unchecked: '#CCCCCC'}"
                                  :height="30" :width="80" :key="keyToggle" @change="QteLotEstVerifiee(zone)" />
                              </td>
                              <td style="vertical-align: middle">
                                {{(lot.quantite * lot.valeurUnitaire) | afficherPrix}}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-collapse>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
   
  </div>
      
</template>

<script>
import { obtenirStock, listeInventaires, detailInventaire, creerInventaire, modifierInventaire, validerInventaire, annulerInventaire } from '@/api/gpao/stock'
import { obtenirProduitMinimum } from '@/api/gpao/produits'
import { connecteData } from '@/mixins/connecteData'
import { showAlerts } from '@/mixins/alerts'
import { formatageDate } from '@/mixins/dateFormats'
import { exporterInventaire } from '@/api/gpao/documents'
import { lancerTelechargementDeResponse } from "@/helpers/utils"
import { designationZoneStockage } from "@/helpers/familles"
import { alerteDonneesModifiees } from '@/mixins/index'
import Calendrier from '@/components/Stock/Calendrier'
import InputNumber from '@/components/Inputs/InputNumber'
import MultiSelect from "@/components/Inputs/MultiSelect"
import BtnAjoutMvtStock from '@/components/Stock/BtnAjoutMvtStock'
import ImportInventaire from '@/components/Stock/ImportInventaire.vue'
import BtnAffichageMvtsStock from '../../components/Stock/BtnAffichageMvtsStock.vue'
import _ from 'lodash'

export default {
  name: "PageInventaire",
  components: {
    BtnAjoutMvtStock,
    Calendrier,
    InputNumber,
    ImportInventaire,
    MultiSelect,
    BtnAffichageMvtsStock
    //Datepicker
  },
  mixins: [connecteData, showAlerts, formatageDate, alerteDonneesModifiees],
  data() {
    return {
      inventaires: [],
      inventaire: null,
      stock: null,
      dateInventaireACreer: '',
      calendrierDatesInventaires: [],
      enExportation: false,
      calendrierKey: 0,
      enCalcul: false,
      optionsZonesDeStockage: [],
      filtreZonesDeStockage: [],
      filtreNomProduit: '',
      keyToggle: 1,
      lignesStock: []
    }
  },
  computed: {
    tousLesInventairesValides() {
      return !this.inventaires.some(el => el.valide === false)
    },
    inventairesValides() {
      return this.inventaires.filter(el => el.valide === true)
    },
    inventaireSelectionneNonValide() {
      return this.inventaire && !this.inventaire.valide
    },
    dateCreationValide() {
      if (!this.dateInventaireACreer || !this.tousLesInventairesValides) {
        return false
      }
      if (this.inventaires.length === 0) {
        return true
      }
      return (this.formatDateAvecTirets(this.dateInventaireACreer) > this.formatDateAvecTirets(new Date(Math.max.apply(null, this.inventaires.map(el => new Date(el.date))))))
    },
    lignesInventaire() {
      if (!this.inventaire)
        return { lignes: [] }
      return { lignes: this.obtenirLotsInventaire(this.inventaire) }
    },
    donneesModifiees() {
      if (!this.donneesInitiales || !this.donneesInitiales.lignes)
        return false
      if (this.donneesInitiales.lignes.length !== this.lignesInventaire.lignes.length)
        return true

      var modifie = false
      this.donneesInitiales.lignes.forEach(el => {
        let li = this.lignesInventaire.lignes.find(l => l.id === el.id)
        if (!li)
          modifie = true
        if (li.reference !== el.reference || li.designationFournisseur !== el.designationFournisseur || li.NumLot !== el.NumLot || li.dlc !== el.dlc
          || this.formatDateAvecTirets(li.dlcDluo) !== this.formatDateAvecTirets(el.dlcDluo) || li.quantite !== el.quantite
          || li.valeurUnitaire !== el.valeurUnitaire || li.tarifId !== el.tarifId || li.quantiteVerifiee !== el.quantiteVerifiee)
          modifie = true
      })
      return modifie
    },
    enregistrerDonneesModifiees() {
      this.enregistrerInventaire(this.inventaire.id, this.lignesInventaire)
      return true
    }
  },
  methods: {
    reinitialiser() {
      this.filtreZonesDeStockage = []
      this.filtreNomProduit = ""
      return this.filtrerNomProduit()
    },
    filtrerNomProduit() {
      var vue = this
      function p(r) {
        vue.enCalcul = true
        setTimeout(() => { // affiche l'attente avant de continuer
          if (vue.filtreNomProduit) {
            const recherche = vue.filtreNomProduit.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            console.log(recherche)
            vue.inventaire.zonesStockage.forEach(z => z.ingredients = z.tousIngredients.filter(x => x.nomRecherche.includes(recherche)))
          } else {
            vue.inventaire.zonesStockage.forEach(z => z.ingredients = _.cloneDeep(z.tousIngredients))
          }
          vue.enCalcul = false
          r()
        }, 0)
      }
      // eslint-disable-next-line promise/avoid-new
      return new Promise(p)
    },
    moveLot(event) {
      var lotId = Number(event.id.replace("lotQte", ""))
      var index = this.lignesInventaire.lignes.findIndex(x => x.id === lotId)
      if (event.direction === "up" && index > 0) {
        lotId = this.lignesInventaire.lignes[index - 1].id
      } else if (event.direction === "down" && index < this.lignesInventaire.lignes.length - 1) {
        lotId = this.lignesInventaire.lignes[index + 1].id
      }
      document.getElementById("lotQte" + lotId).focus()
    },
    setQuantiteLot(lot, quantite) {
      lot.quantite = quantite
      if (!lot.quantiteVerifiee)
        lot.quantiteVerifiee = true
      ++this.keyToggle
    },
    verifierQtesLots(zone) {
      zone.ingredients.forEach(x => x.lots.forEach(y => {
        if (!y.quantiteVerifiee)
          y.quantiteVerifiee = true
      }))
      zone.verifiee = true
      ++this.keyToggle
    },
    QteLotEstVerifiee(zone) {
      if (zone.ingredients.find(x => x.lots.find(y => !y.quantiteVerifiee))) {
        zone.verifiee = false
      } else {
        zone.verifiee = true
      }
      ++this.keyToggle
    },
    setdateInventaireACreer(event) {
      this.dateInventaireACreer = event
    },
    setDlcDluo(event, elt) {
      this.$set(elt, 'dlcDluo', event)
    },
    setInventaire(id) {
      return detailInventaire(id)
        .then((response) => {
          response.data.zonesStockage.forEach(zone => {
            zone.tousIngredients = _.cloneDeep(zone.ingredients)
            this.QteLotEstVerifiee(zone)
          })
          this.inventaire = response.data
          this.donneesInitiales = _.cloneDeep(this.lignesInventaire)
          if (!this.inventaire.valide) {
            this.getStock(this.inventaire.date)
          }
          return
        })
    },
    getStock(date) {
      return obtenirStock(this.etabCourantId, date, false)  // Ne récupère que les lignes présentes en stock (avec quantité différente de 0)
        .then((response) => {
          this.stock = response.data
          this.lignesStock = this.obtenirLotsInventaire(this.stock)
          return
        })
    },
    obtenirLots(zoneStockage) {
      var lots = []
      if (zoneStockage) {
        zoneStockage.ingredients.forEach(ingredient => {
          lots = lots.concat(ingredient.lots)
        })
      }
      return lots
    },
    obtenirLotsInventaire(inventaire) {
      if (!inventaire)
        return []
      if (inventaire.zonesStockage.length === 0)
        return []
      return inventaire.zonesStockage.map(z => this.obtenirLots(z)).reduce(function (a, b) { return a.concat(b) })
    },
    obtenirQteLotEnStock(lotId) {
      let lot = this.lignesStock.find(l => l.id === lotId)
      if (!lot)
        return 0
      return lot.quantite
    },
    ajouterArticle(elt) {
      return obtenirProduitMinimum(elt.article.produitId)
        .then((response) => {
          // Contrôle de doublons
          let lotsExistants = this.obtenirLotsInventaire(this.inventaire)
          let lotExistant = lotsExistants.find(x => x.tarifId === elt.article.tarifId && x.NumLot === elt.article.NumLot
            && this.formatDateAvecTirets(x.dlcDluo) === this.formatDateAvecTirets(elt.article.dlcDluo))
          if (lotExistant) {
            // eslint-disable-next-line promise/no-nesting
            return this.alerteConfirmation("Lot déjà présent dans l'inventaire",
              "Un lot identique a été détecté dans l'inventaire (quantité : " +
              lotExistant.quantite + lotExistant.uc + "). Voulez-vous affecter la quantité que vous avez saisie (" + elt.article.quantite + elt.article.uc + ") ?")
            .then((result) => {
              if (result) {
                lotExistant.quantite = elt.article.quantite
              }
              return
            })
          }
          let famille = this.$store.state.definitions.familles.find(f => f.id === response.data.familleId)
          let dzs = designationZoneStockage(famille.zoneStockage)
          let zs = this.inventaire.zonesStockage.find(z => z.designation === dzs)
          if (!zs) {
            zs = { designation: dzs, valeur: 0, ingredients: [] }
            this.inventaire.zonesStockage.push(zs)
          }
          if (!elt.article.designationFournisseur)
            elt.article.designationFournisseur = elt.article.designation || response.data.designation
          zs.ingredients.push({ lots: [elt.article] })
          zs.valeur += elt.article.quantite * elt.article.valeurUnitaire
          return
        })
    },
    enregistrerInventaire(id, lignes) {
      lignes.lignes = lignes.lignes.filter(x => x.quantite !== 0)
      var doublons = []
      let uniques = lignes.lignes.filter((t, i, s) => {
        if (i === s.findIndex((elt) =>
          (elt.tarifId === t.tarifId && elt.NumLot === t.NumLot && this.formatDateAvecTirets(elt.dlcDluo) === this.formatDateAvecTirets(t.dlcDluo)))) {
            return t
        } else {
          doublons.push(t)
        }
      })
      if (uniques.length !== lignes.lignes.length) {
        let messageDoublons = "Impossible d'enregistrer l'inventaire car les doublons suivants ont été détectés :<br/>"
        doublons.forEach(function(doublon) {
          messageDoublons += doublon.designationFournisseur + "<br/>"
        })
        this.alerteErreur(messageDoublons)
        return
      }
      return modifierInventaire(id, lignes)
        .then(() => {
          this.listerInventaires(this.etabCourantId)
          this.toastSucces('Modification enregistrée !')
          return
        })
    },
    async validerInventaire(id) {
      let result = await this.alerteConfirmation("Clôturer l'inventaire", "Souhaitez-vous clôturer l'inventaire en cours ? Il ne sera plus modifiable ensuite, et le stock sera mis à jour.")
      if (result) {
        await validerInventaire(id)
        this.toastSucces("Inventaire validé !")
        this.listerInventaires(this.etabCourantId)
      }
    },
    async annulerInventaire(id) {
      var result = await this.alerteConfirmation("Annuler l'inventaire", "Souhaitez-vous annuler l'inventaire en cours ? Cette action supprime l'inventaire définitivement, et le stock n'est pas mis à jour.")
      if (result) {
        await annulerInventaire(id)
        this.listerInventaires(this.etabCourantId)
      }
    },
    creerStockInventaire() {
      if (!this.dateCreationValide) {
        return false;
      }
      return obtenirStock(this.etabCourantId, this.formatDateAvecTirets(this.dateInventaireACreer), false)  // Ne récupère que les lignes présentes en stock (avec quantité différente de 0)
        .then((response) => {
          response.data.zonesStockage.forEach(zone => {
            zone.tousIngredients = _.cloneDeep(zone.ingredients)
          })
          this.inventaire = response.data
          // eslint-disable-next-line promise/no-nesting
          return creerInventaire(this.etabCourantId, this.formatDateAvecTirets(this.dateInventaireACreer), this.lignesInventaire)
            .then(() => {
              this.listerInventaires(this.etabCourantId)
              this.alerteSucces('OK !')
              return
            })
        })
    },
    importerStockInventaire(inventaireId) {
      if (inventaireId) {
        this.toastSucces("Inventaire importé !")
        this.listerInventaires(this.etabCourantId)
        this.setInventaire(inventaireId)
      }
    },
    obtenirInventaireADate(inventaires, date) {
      return inventaires.find(el => this.formatDateAvecTirets(new Date(el.date)) === this.formatDateAvecTirets(new Date(date)))
    },
    marquerInventairesDansCalendrier() {
      this.calendrierDatesInventaires = []
      this.inventaires.forEach(el => {
        let classDate = el.valide ? "calendar-date-default" : "calendar-inventaire-en-cours"
        this.calendrierDatesInventaires.push({ date: el.date, class: classDate })
      })
      this.calendrierKey++
    },
    selectionnerDate(date) {
      this.setdateInventaireACreer(date)
      let inv = this.obtenirInventaireADate(this.inventaires, date)
      if (inv) {
        console.time("setInventaire")
        this.setInventaire(inv.id)
        console.timeEnd("setInventaire")
      } else {
        this.inventaire = null
      }
    },
    listerInventaires(etablissementId) {
      return listeInventaires(etablissementId)
        .then((response) => {
          _.remove(response.data, el => el.date === '0001-01-01T00:00:00')
          this.inventaires = response.data
          return this.marquerInventairesDansCalendrier()
        })
        .then(() => {
          const inventaire = this.inventaires.find(inventaire => inventaire.valide === false)
          this.selectionnerDate(inventaire ? inventaire.date : new Date())
          return
        })
    },
    async exporter(inventaire) {
      try {
        this.enExportation = true
        const response = await exporterInventaire(inventaire.id)
        lancerTelechargementDeResponse(response)
      } finally {
        this.enExportation = false
      }
    }
  },
  created() {
    this.listerInventaires(this.etabCourantId)
  }
}
</script>
