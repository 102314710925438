<template>
  <div style="display: inline">

    <b-button class="btn btn-light push-right-1rem" :disabled="disabled" @click="show()" v-tooltip="{ content: 'Imprimer/envoyer le bon de commande', placement: 'top' }">
      <i class="fas fa-print"></i>
    </b-button>

    <b-modal v-if="commande" ref="modal" size="lg" content-class="md-impression" hide-footer header-bg-variant="gradient-bleu" header-text-variant="light"
    @hide="nettoyer">

      <template slot="modal-header">
        <h3>Configuration de l'impression de {{commande.nom}}</h3>
      </template>

      <template slot="default">

        <div style="margin-bottom:15px;" class="datepicker-trigger">
          <label>Date de commande :</label>
          <input type="text" autocomplete="off" id="datepicker-trigger-commande" class="datepicker" placeholder="Cliquer pour choisir la date..." :disabled="pasDEnvoi" :value="formatHumanDate(options.DateImpression)" />
          <AirbnbStyleDatepicker key="1" trigger-element-id="datepicker-trigger-commande" mode="single" :fullscreen-mobile="true" :showShortcutsMenuTrigger="false" :months-to-show="1" :date-one="options.DateImpression" @date-one-selected="options.DateImpression = $event" />
        </div>

        <div class="row">
          <div class="col-6">
            <label style="display: block;"><input type="checkbox" v-model="options.ImprimerTitreCommande"><span> Intégrer le nom de la commande</span></label>
            <label style="display: block;"><input type="checkbox" v-model="options.ImprimerControleAReception"><span> Intégrer le contrôle à réception</span></label>
            <label style="display: block;"><input type="checkbox" v-model="options.A3"><span> Générer au format A3</span></label>
          </div>
          <div class="col-6">
            <label style="display: block;"><input type="checkbox" v-model="options.SortieXlsx"><span> Sortie en fichier Excel</span></label>
            <label style="display: block;"><input type="checkbox" v-model="options.TrierAlpha"><span> Trier les lignes par ordre alphabétique</span></label>
            <label style="display: block;"><input type="checkbox" v-model="options.ImprimerTVA"><span> Intégrer le total TTC</span></label>
            <label style="display: block;"><input type="checkbox" v-model="options.ImprimerDatesConso"><span> Intégrer les dates de consommation</span></label>
          </div>
        </div>

        <div class="row" style="margin:15px 0;">

          <div :class="pasDEnvoi ? 'col-12' : 'col-6'">
            <div class="card">
              <div class="card-header bg-gradient-bleu text-white flex-center-flex-start">
                <input type="checkbox" v-model="options.ImprimeTout" @change="checkAllImprime()">
                &nbsp;<h4 class="card-title">IMPRESSION</h4>
              </div>
              <div aria-expanded="true" class="card-content">
                <div class="card-body">
                  <label v-for="(fournisseur, index) in fournisseurs" :key="index" style="display: block;">
                    <input type="checkbox" v-model="fournisseur.impression" @change="update()">
                    {{fournisseur.nom}}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!pasDEnvoi" class="col-6">
            <div class="card">
              <div class="card-header bg-gradient-bleu text-white flex-center-flex-start">
                <input type="checkbox" v-model="options.EnvoiTout" @change="checkAllEnvoi()">
                &nbsp;<h4 class="card-title">ENVOI</h4>
              </div>
              <div aria-expanded="true" class="card-content">
                <div class="card-body">
                  <label v-for="(fournisseur, index) in fournisseursPourEnvoi" :key="index" style="display: block;">
                    <input type="checkbox" v-model="fournisseur.envoi" @change="update()">
                    {{fournisseur.nom}}
                  </label>
                </div>
              </div>
              <div style="font-size:12px;">
                <div style="padding:1px 2px 0 2px;display:inline-block;">Votre Email :</div>
                <div style="display: inline-block;"><input type="text" v-model="emailEnvoiBC" ref="inEmail" style="height:17px;"></div>
                <div style="padding-left:2px;margin-top:4px;">Votre Message : </div>
              </div>
              <textarea v-model="txtMailBC" style="font-size:12px;height:170px;width:100%;padding:2px;" />
              <a class="btn" @click="resetTxtMailBC" style="width:100%;background:#3085d6;border-color:#3085d6;color:#fff;">Réinitialiser le message</a>
            </div>
          </div>

        </div>

        <div style="margin: 2rem 0; text-align:right">
          <button ref="btnImprimer" class="btn btn-success" @click="print">Imprimer</button>
          <button class="btn btn-danger" style="margin-left:3rem;background:#3085d6;border-color:#3085d6;color:#fff;" @click="hide">Annuler</button>
        </div>

      </template>
    </b-modal>

  </div>
</template>

<script>
import { formatageDate } from '@/mixins/dateFormats'
import { obtenirBC } from '@/api/gpao/documents'
import { envoyerBC } from '@/api/gpao/commandes'
import { saveAs } from 'file-saver'
import { connecteData } from '@/mixins/connecteData'
import { showAlerts } from '@/mixins/alerts'
import { constantesId } from "@/helpers/constantes"

export default {
  name: "BoutonImpressionCommande",
  mixins: [formatageDate, connecteData, showAlerts],
  props: {
    commande: {type: Object, required: true},
    fournisseurId: {type: Number},
    pasDEnvoi: {type: Boolean, default: () => false},
    disabled: {type: Boolean}
  },
  data() {
    return {
      fournisseurs: [],
      txtMailBC: "",
      emailEnvoiBC: "",
      options: {
        CommandeId: null,
        DateImpression: new Date(),
        SortieXlsx: true,
        A3: false,
        ImprimerControleAReception: true,
        ImprimerTitreCommande: true,
        TrierAlpha: false,
        ImprimerTVA: false,
        ImprimerDatesConso: false,
        EnvoiTout: false,
        ImprimeTout: true
      }
    }
  },
  mounted() {
    var options = this.$store.state.commandes.optionsImpression || this.options
    this.options.SortieXlsx = options.SortieXlsx
    this.options.A3 = options.A3
    this.options.ImprimerControleAReception = options.ImprimerControleAReception
    this.options.ImprimerTitreCommande = options.ImprimerTitreCommande
    this.options.TrierAlpha = options.TrierAlpha
    this.options.ImprimerTVA = options.ImprimerTVA
    this.options.ImprimerDatesConso = options.ImprimerDatesConso
    this.options.EnvoiTout = options.EnvoiTout
    this.options.ImprimeTout = options.ImprimeTout
  },
  updated() {
    if (this.$refs.inEmail) {
      this.update()
    }
  },
  watch: {
    txtMailBC(newVal) {
      localStorage.txtMailBC = newVal
    },
    emailEnvoiBC(newVal) {
      localStorage.emailEnvoiBC = newVal || ""
    }
  },
  computed: {
    fournisseursPourEnvoi() {
      return this.fournisseurs.filter(f => f.etatCommande === constantesId.etatCmdEnCours || (f.etatDuMail && f.etatDuMail.estErreur) )
    }
  },
  methods: {
    show() {
      this.options.CommandeId = this.commande.id
      this.commande.commandesFournisseurs.filter(f => f.totalHDT).forEach(fournisseur => {
        this.fournisseurs.push({
          fournisseurId: fournisseur.fournisseurId,
          nom: fournisseur.raisonSocialeFournisseur,
          impression: this.fournisseurId ? fournisseur.fournisseurId === this.fournisseurId : true,
          envoi: false,
          etatCommande: fournisseur.etatCommande,
          etatDuMail:  fournisseur.etatDuMail
        })
      })
      this.emailEnvoiBC = localStorage.emailEnvoiBC || ""
      if (!this.emailEnvoiBC) {
        let etab = this.utilisateurCourant.etablissements.find(x => x.eId === this.etabCourantId)
        if (etab) {
          this.emailEnvoiBC = etab.email
        }
      }
      this.txtMailBC = localStorage.txtMailBC

      if (!this.txtMailBC) {
        this.resetTxtMailBC()
      }
      this.$refs.modal.show()
    },
    hide() {
      this.nettoyer()
      this.$refs.modal.hide()
    },
    nettoyer() {
      this.fournisseurs.length = 0; //https://stackoverflow.com/a/1232046/130420

      this.$store.commit('commandes/setOptionsImpression', {
        SortieXlsx: this.options.SortieXlsx,
        A3: this.options.A3,
        ImprimerControleAReception: this.options.ImprimerControleAReception,
        ImprimerTitreCommande: this.options.ImprimerTitreCommande,
        TrierAlpha: this.options.TrierAlpha,
        ImprimerTVA: this.options.ImprimerTVA,
        ImprimerDatesConso: this.options.ImprimerDatesConso
      })
    },
    print() {
      this.options.SortieEnPDF = !this.options.SortieXlsx
      var fournisseursImpression = []
      var fournisseurEnvoi = []
      this.fournisseurs.forEach(f => {
        if (f.impression) {
          fournisseursImpression.push(f.fournisseurId)
        }
        if (f.envoi) {
          fournisseurEnvoi.push(f.fournisseurId)
        }
      })
      if (fournisseursImpression.length !== 0) {
        this.options.FournisseurIds = fournisseursImpression
        // eslint-disable-next-line promise/catch-or-return
        obtenirBC(this.options.CommandeId, this.options)
          .then(response => saveAs(new File([response.data], this.commande.nom + (this.options.SortieXlsx ? ".xlsx" : ".pdf"), { type: "text/plain;charset=utf-8" })))
      }
      if (fournisseurEnvoi.length !== 0) {
        this.options.FournisseurIds = fournisseurEnvoi
        this.options.emetteur = this.emailEnvoiBC
        this.options.message = this.txtMailBC
        return this.alerteConfirmation("Envoyer les commandes par email", "Les bons de commande vont être envoyés par email au" + (fournisseurEnvoi.length > 1 ? "x" : "") + " fournisseur" + (fournisseurEnvoi.length > 1 ? "s" : "") + " sélectionné" + (fournisseurEnvoi.length > 1 ? "s" : "") + ".", "Envoyer", "Annuler")
          .then(result => {
            if (!result) {
              return
            }
            // eslint-disable-next-line promise/no-nesting
            return envoyerBC(this.options.CommandeId, this.options)
              .then(() => {
                this.$emit("commande-envoyee")
                this.alerteSucces("Bon de commande envoyé !")
                this.hide()
              })
          })
      }
      this.hide()
    },
    
    checkAllEnvoi() {
      this.fournisseurs.forEach(f => {
        f.envoi = this.options.EnvoiTout
      })
    },

    checkAllImprime() {
      this.fournisseurs.forEach(f => {
        f.impression = this.options.ImprimeTout
      })
    },

    update() {
      const btnImprimer = this.$refs.btnImprimer
      const inEmail = this.$refs.inEmail

      var disabled = false
      var impression = 0
      var envoi = 0

      this.fournisseurs.forEach(f => {
        if (f.impression) {
          impression += 1
        }
        if (f.envoi) {
          envoi += 1
        }
      })

      this.options.EnvoiTout = (envoi == this.fournisseurs.length)
      this.options.ImprimeTout = (impression == this.fournisseurs.length)

      if (envoi) {
        if (inEmail.value && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(inEmail.value)) {
          inEmail.classList.remove("error")
        } else {
          inEmail.classList.add("error")
          disabled = true
        }
      } else if (inEmail) {
        inEmail.classList.remove("error")
      }

      btnImprimer.disabled = disabled || !(impression + envoi)

      if (envoi) {
        if (impression) {
          btnImprimer.textContent = "Imp./Env."
        } else {
          btnImprimer.textContent = "Envoyer"
        }
      } else {
        btnImprimer.textContent = "Imprimer"
      }
    },
    resetTxtMailBC() {
      localStorage.txtMailBC = "Bonjour,\n\n" +

        "Pouvez-vous accuser réception de la commande ci-jointe, je vous prie ?\n" +
        "Merci de nous contacter en cas de rupture ou de substitution de produits.\n\n" +

        "Cordialement,\n"

      this.txtMailBC = localStorage.txtMailBC
    }
  }
}
</script>

<style scoped>
input {
  height: auto;
}
label {
  font-size: 12px;
}
</style>
