<template>
  <div style="display:inline">
    <button v-if="type && type==='button'" class="btn btn-light" :disabled="disabled" v-tooltip="{ content: title, placement: 'top' }" @click="initArticle()">
      <i :class="iClass"></i>
    </button>
    <i v-else :class="iClass + ' cursor-pointer'" :title="title" @click="initArticle()"></i>
    <b-modal :id="idModal" size="xl" header-bg-variant="gradient-bleu" header-text-variant="light" hide-footer no-close-on-backdrop>
      <template slot="modal-title">
        <h3>{{titreModal}}</h3>
      </template>
      <template slot="default">

        <div v-if="lot">
          <h4 class="push-top-1rem">Article : {{lot.designationFournisseur}}</h4>
        </div>

        <div v-else-if="zoneStockage">
          <h4 class="push-top-1rem">Zone de stockage à vider : {{zoneStockage.designation}}</h4>
        </div>

        <div v-else>
          <RechercheArticles :masquerVide="true" typeParDefaut="parFamille" @change-ingredient="changeIngredient($event)" @change-state="changeEtatRecherche($event)" />

          <h4 class="push-top-1rem" :key="stateSearch" v-if="stateSearch">{{stateSearch}}</h4>

          <div class="container-fluid" style="margin-top:5px;">
            <ArticlesParProduit v-if="ingredient && ingredient.parProduit" :articles="ingredient.parProduit" :favoris="ingredient.favoris" :ingredient="ingredient" :index="0" :key="keyArticles" @selection-article="setArticle($event)" />
          </div>

          <div v-if="article && article.designation">
            <h4 class="push-top-1rem">Article : {{article.designation}}</h4>
          </div>
        </div>

        <div class="card-body" v-if="article.tarifId || zoneStockage">
          <div class="card row" style="padding-bottom:6em">
            <div v-if="article.tarifId" class="flex-center push-top-1rem">
              <div class="col-4 flex-start">
                <div class="label-input push-right-1rem">N° Lot : </div>
                <input type="text" :disabled="action === 'out'" v-model="NumLot">
              </div>
              <div class="col-4 flex-start">
                <div class="input-group datepicker-trigger">
                  <b-input-group>
                    <button type="button" class="btn btn-primary" :disabled="action === 'out'" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :title="!dlc && 'Date de Durabilité Minimale (ex. DLUO)'">
                      {{dlc ? 'DLC' : 'DDM'}}
                    </button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" href="#" @click="dlc=true">DLC</a>
                      <a class="dropdown-item" href="#" @click="dlc=false">DDM</a>
                    </div>
                    <b-form-input type="text" v-model="dlcDluo" placeholder="Date (AAAA-MM-JJ)" autocomplete="off"></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker v-model="dlcDluo" locale="fr-FR" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" :disabled="action === 'out'" button-only right :start-weekday="1" hide-header button-variant="outline-primary" @context="setDateDlcDluo()" dropleft></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>
              <div class="col-4 flex-end">
                <div class="label-input push-right-1rem">PU HT :</div>
                <InputNumber :disabled="action === 'out'" typeFiltre="montant" v-model="valeurUnitaire" v-tooltip="{ content: 'Prix Unitaire', placement: 'top' }" style="width:100px"/>
                <div class="label-input ml-1">€</div>
              </div>
            </div>
            <div class="flex-center push-top-2rem">
              <div v-if="mode === 'stock'" class="col-4 flex-start">
                <div v-if="action === 'in'" class="label-input push-right-1rem">Entrée au : </div>
                <div v-if="action === 'out'" class="label-input push-right-1rem">Sortie au : </div>
                <div class="datepicker-trigger group-row">
                  <input type="text" autocomplete="off" :id="'datepicker-entree-au'" class="datepicker" placeholder="Date..." :value="formatHumanDate(dateMouvement)">
                  <AirbnbStyleDatepicker trigger-element-id="datepicker-entree-au" mode="single" :fullscreen-mobile="true" :showShortcutsMenuTrigger="false" :monthsToShow="1" @date-one-selected="setDateMouvement($event)" :date-one="dateMouvement" :disabledDates="datesInvalides" />
                  <div class="error" v-if="!$v.dateMouvement.required">
                    Ce champ est requis
                  </div>
                </div>
              </div>
              <div v-if="mode === 'stock'" class="col-4 flex-start push-top-1rem">
                <div class="group-row">
                  <!-- En attendant le end-point ou j'ai pas bien vu -->
                  <b-form-radio-group v-model="typeMouvement" :options="optionsTypeMouvement" @change="typeMouvementChange" />
                  <div class="error" v-if="!$v.typeMouvement.required">
                    Ce champ est requis
                  </div>
                </div>
              </div>
              <div v-if="!zoneStockage" class="col-4" :class="mode === 'stock' ? ' flex-end' : ' flex-start'">
                <div class="label-input push-right-1rem">Quantité&nbsp;:</div>
                <div class="group-row">
                  <div class="flex-center">
                    <InputNumber id="quantite" typeFiltre="quantite" v-model.trim="$v.quantite.$model" style="max-width:130px" :disabled="this.razQuantite" :key="keyQuantite" />
                    <div class="push-left-1rem">
                      <span v-if="ingredient.uc"> {{ingredient.uc}} </span>
                    </div>
                    <div class="flex-end ml-2" v-if="action === 'out'">
                      (
                      <div class="custom-control custom-checkbox ml-2" v-tooltip="{ content: 'Vide le stock pour cet article', placement: 'top' }">
                        <input class="custom-control-input cursor-pointer" id="raz" type="checkbox" v-model="razQuantite" @click="razQte()">
                        <label for="raz" class="custom-control-label cursor-pointer mr-1">RàZ</label>
                      </div>
                      )
                    </div>
                  </div>
                  <div class="error" v-if="!$v.quantite.required">
                    Ce champ est requis
                  </div>
                </div>
              </div>
            </div>
            <div v-if="action === 'out' && article.tarifId" class="flex-center push-top-2rem">
              <div class="col-4 flex-start">
                <div class="datepicker-trigger">
                  <input type="text" autocomplete="off" id="datepicker-trigger" class="datepicker" :class="!sortieJournee ? 'element-disabled' : ''" placeholder="Période de consommation..." :value="formatHumanDate(dateConsommation)" :disabled="!sortieJournee">
                  <AirbnbStyleDatepicker :trigger-element-id="'datepicker-trigger'" :mode="'single'" :fullscreen-mobile="true" :showShortcutsMenuTrigger="false" :date-one="dateConsommation" @date-one-selected="setDateConsommation($event)" />
                  <div class="error" v-if="!$v.dateConsommation.required">
                    Ce champ est requis
                  </div>
                </div>
              </div>
              <div class="col-4">
                <v-select id="menuPlans" v-model="menuPlanId" :options="optionsMenuPlans" label='nom' placeholder="Structures de menus à inclure..." :reduce="m => m.id" style="height:55px" :disabled="!sortieJournee" />
                <div class="error" v-if="!$v.menuPlanId.required">
                  Ce champ est requis
                </div>
              </div>
              <div class="col-4">
                <v-select id="services" v-model="serviceId" :options="optionsServices" label='nom' placeholder="Services..." :reduce="s => s.id" style="height:55px" :disabled="!sortieJournee" />
                <div class="error" v-if="!$v.serviceId.required">
                  Ce champ est requis
                </div>
              </div>
            </div>
            <div class="flex-center push-top-2rem">
              <div v-if="mode === 'stock'" class="col-12">
                <div class="group-row">
                  <!--       <div class="label-input push-right-1rem">Commentaire : </div> -->
                  <b-form-textarea v-model="commentaire" placeholder="Commentaire..." rows="4" max-rows="4"></b-form-textarea>
                  <div class="error" v-if="!$v.commentaire.required">
                    Ce champ est requis
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-12 flex-end">
              <button class="btn btn-danger" :disabled="!$v.typeMouvement.required || !$v.quantite.required || !$v.dateMouvement.required || !$v.article.required || !$v.dateConsommation.required || !$v.menuPlanId.required || !$v.serviceId.required || !$v.commentaire.required" @click="envoyer()">Enregistrer</button>
              <button class="btn btn-primary push-left-1rem" @click="hide()">Annuler</button>
            </div>
          </div>
        </div>

      </template>
    </b-modal>
  </div>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'
import { formatageDate } from '@/mixins/dateFormats'
import { connecteData } from "@/mixins/connecteData"
import { constantesId } from "@/helpers/constantes"
import { chargerMenuPlansAvecRecetteDeuxDates } from '@/api/gpao/menus'
import { arrondiNombre } from '@/helpers/utils'
import InputNumber from '@/components/Inputs/InputNumber'
import RechercheArticles from "@/components/Divers/RechercheArticles"
import ArticlesParProduit from '@/components/Stock/ArticlesParProduit'
import vm from "@/helpers/vm-toolkit-vue"

export default {
  props: ['id', 'dateEtatAu', 'lot', 'type', 'btnImage', 'title', 'action', 'disabled', 'mode', 'datesInvalides', 'zoneStockage'],
  mixins: [formatageDate, connecteData],
  components: {
    InputNumber,
    RechercheArticles,
    ArticlesParProduit
  },
  data() {
    return {
      idModal: this.id ? this.id : "nouvelArticle",
      titreModal: this.title,
      iClass: this.btnImage,
      article: {},
      dateMouvement: '',
      dateMouvementPourApi: null,
      typeMouvement: null,
      quantite: '',
      NumLot: '',
      dlc: true,
      dlcDluo: '',
      dlcDluoPourApi: null,
      valeurUnitaire: null,
      dateConsommation: '',
      dateConsommationPourApi: null,
      menuPlanId: null,
      optionsMenuPlans: [],
      serviceId: null,
      optionsServices: [],
      commentaire: '',
      ingredient: {},
      keyArticles: 1,
      stateSearch: "",
      razQuantite: false,
      keyQuantite: 1
    }
  },
  validations: {
    typeMouvement: {
      required: requiredIf(function () {
        return this.mode === "stock"
      })
    },
    quantite: {
      required: requiredIf(function() {
        return !this.razQuantite && !this.zoneStockage
      })
    },
    dateMouvement: {
      required: requiredIf(function () {
        return this.mode === "stock"
      })
    },
    article: {
      required: requiredIf(function () {
        return !this.lot && !this.zoneStockage
      })
    },
    dateConsommation: {
      required: requiredIf(function () {
        return this.sortieJournee
      })
    },
    menuPlanId: {
      required: requiredIf(function () {
        return this.sortieJournee
      })
    },
    serviceId: {
      required: requiredIf(function () {
        return this.sortieJournee
      })
    },
    commentaire: {
      required: requiredIf(function() {
        return this.razQuantite || this.zoneStockage
      })
    }
  },
  computed: {
    afficherBouton() {
      return this.modeBouton
    },
    sortieJournee() {
      return this.typeMouvement === constantesId.tMvtStockSortieJournee
    },
    optionsTypeMouvement() {
      var options = []
      if (this.action === 'in') {
        options.push({ text: "Entrée de Marchandise", value: constantesId.tMvtStockEntreeMarchandise })
      }
      if (this.action === 'out' && !this.zoneStockage) {
        options.push({ text: "Journée", value: constantesId.tMvtStockSortieJournee })
      }
      options.push({ text: "Régularisation", value: constantesId.tMvtStockRegularisation })
      if (this.action === 'out') {
        options.push({ text: "Autre", value: constantesId.tMvtStockSortieAutre })
      }
      return options
    }
  },
  methods: {
    initArticle() {
      if (this.lot) {
        this.setArticle({ produit: this.lot })
        this.NumLot = this.lot.NumLot
        this.dlc = this.lot.dlc
        this.ingredient.uc = this.lot.uc
        this.valeurUnitaire = arrondiNombre(this.lot.valeurUnitaire)
        this.setDateDlcDluo(this.lot.dlcDluo)
        this.titreModal = this.title + " de " + this.lot.designationFournisseur
      } else {
        this.article = {}
        this.NumLot = ''
        this.dlc = true
        this.dlcDluo = ''
        this.dlcDluoPourApi = null
        this.valeurUnitaire = null
        this.changeIngredient({})
      }
      this.quantite = ''
      this.typeMouvement = null
      this.dateConsommation = ''
      this.dateConsommationPourApi = null
      this.commentaire = ''
      this.setDateMouvement(this.dateEtatAu)
      this.viderOptionsStructuresDeMenusEtServices()
      this.show()
    },
    changeEtatRecherche(etat) {
      this.stateSearch = etat
    },
    changeIngredient(ingredient) {
      this.ingredient = ingredient
      this.article = {}
      this.stateSearch = ""
      this.keyArticles += 1
    },
    setArticle(elt) {
      let article = elt.produit
      if (this.ingredient.parProduit) {//ouvre l'article sélectionné
        vm.forEach(this.ingredient.parProduit, a => {
          a.forEach(r => {
            r.ouvrirAccordeon = r.tarifId === article.tarifId
          })
        })
      }
      this.article = article
      this.ingredient.tarifId = article.tarifId
      this.ingredient.uc = article.uc
      if (article.id) {
        this.$set(this.article, 'id', article.id)
      }
      this.$set(this.article, 'tarifId', article.tarifId)
      this.$set(this.article, 'designationFournisseur', article.designationFournisseur || article.designation)
      this.$set(this.article, 'uc', article.uc)
      this.$set(this.article, 'valeurUnitaire', article.valeurUnitaire || article.prixUnitaire)
      this.$set(this.article, 'reference', article.reference)
      this.$set(this.article, 'marque', article.marque)
      this.$set(this.article, 'fournisseurId', article.fournisseurId)
      if (elt.produitId) {
        this.$set(this.article, 'produitId', elt.produitId)
      }
      this.valeurUnitaire = arrondiNombre(this.article.valeurUnitaire)
      this.keyArticles += 1
    },
    setDateDlcDluo(date) {
      if (date)
        this.dlcDluo = this.formatDateAvecTirets(date)
      if (this.dlcDluo)
        this.dlcDluoPourApi = this.formatDateAvecTirets(this.dlcDluo)
    },
    setDateMouvement(event) {
      if (event && this.dateMouvementValide(event)) {
        this.dateMouvement = event
        this.dateMouvementPourApi = this.formatDateAvecTirets(event)
      } else {
        this.dateMouvement = ''
        this.dateMouvementPourApi = null
      }
    },
    dateMouvementValide(date) {
      return !this.datesInvalides.includes(date)
    },
    setDateConsommation(event) {
      if (event) {
        this.dateConsommation = event
        this.dateConsommationPourApi = event.split('-').join('')
      } else {
        this.dateConsommation = ''
        this.dateConsommationPourApi = null
      }
      this.viderEtRequeteStructuresDeMenusEtServices()
    },
    viderEtRequeteStructuresDeMenusEtServices() {
      this.viderOptionsStructuresDeMenusEtServices()
      this.requeteApiStructuresDeMenusEtServices()
    },
    viderOptionsStructuresDeMenusEtServices() {
      this.optionsMenuPlans = []
      this.optionsServices = []
      this.menuPlanId = null
      this.serviceId = null
    },
    requeteApiStructuresDeMenusEtServices() {
      if (this.dateConsommation) {
        return chargerMenuPlansAvecRecetteDeuxDates({ etablissementId: this.etabCourantId, apiDateDebut: this.dateConsommationPourApi, apiDateFin: this.dateConsommationPourApi })
          .then(function (response) {
            if (response.data.menuPlans.length) {
              this.optionsMenuPlans = response.data.menuPlans.map(el => {
                return { id: el.id.toString(), nom: el.nom }
              })
              this.optionsServices = response.data.servicesPresents.map(function (el) {
                return { id: el, nom: this.$store.state.definitions.services.find(service => service.value === el).text }
              }.bind(this))
            } else {
              this.messageInfo = 'Aucun résultat...'
            }
          }.bind(this))
      }
    },
    typeMouvementChange() {
      if (!this.sortieJournee) {
        this.setDateConsommation(null)
      }
    },
    razQte() {
      if (!this.razQuantite) {
        this.quantite = ""
        ++this.keyQuantite
      }
    },
    envoyer() {
      if (this.zoneStockage) {
        this.viderZoneStockage()
      } else {
        this.envoyerArticle()
      }
    },
    viderZoneStockage() {
      let zs = {}
      this.$set(zs, 'etablissementId', this.etabCourantId)
      this.$set(zs, 'code', this.zoneStockage.code)
      this.$set(zs, 'typeMouvement', this.typeMouvement)
      this.$set(zs, 'commentaire', this.commentaire)
      this.$emit('vider-zone-stockage', { date: this.dateMouvement, zoneStockage: zs })
      this.hide()
    },
    envoyerArticle() {
      this.$set(this.article, 'etablissementId', this.etabCourantId)
      this.$set(this.article, 'quantite', this.razQuantite ? 0 : this.quantite)
      this.$set(this.article, 'razQuantite', this.razQuantite)
      this.$set(this.article, 'NumLot', this.NumLot)
      this.$set(this.article, 'dlc', this.dlc)
      this.$set(this.article, 'dlcDluo', this.dlcDluoPourApi)
      this.$set(this.article, 'typeMouvement', this.typeMouvement)
      this.$set(this.article, 'valeurUnitaire', this.valeurUnitaire)
      this.$set(this.article, 'commentaire', this.commentaire)
      if (this.sortieJournee) {
        this.$set(this.article, 'dateConsommation', this.dateConsommation)
        this.$set(this.article, 'menuPlanId', this.menuPlanId)
        this.$set(this.article, 'serviceId', this.serviceId)
      }
      this.$emit('envoyer-article', { date: this.dateMouvement, article: this.article })
      this.hide()
    },
    show() {
      this.$bvModal.show(this.idModal)
    },
    hide() {
      this.$bvModal.hide(this.idModal)
    }
  },
  mounted() {
    this.setDateMouvement(this.dateEtatAu)
  }
}
</script>

<style>
textarea {
  box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.06);
  border: none !important;
  border-radius: 0 !important;
}
