<template>
  <div>

    <div class="col-6 push-top-1rem">
      Type de recherche :
      <div class="flex-center">
        <b-form-radio-group v-model="typeActif" :options="optionsType" name="type-de-recherche" ref="radio" @input="reset()" />
      </div>
    </div>

    <div v-if="typeActif === 'parIngredient'">
      <RechercheIngredients ref="recherche" :liste="ingredients" placeHolder="Rechercher un ingrédient..." :masquerVide="masquerVide" @selection-ingredient="selectionIngredient($event)" @reset-ingredient="reset()" style="margin:10px 0;" />
    </div>

    <div v-else-if="typeActif === 'parFamille'">
      <div class="row">
        <div class="col-6">
          <dropDownChoixFamille id="selectFamilleRechercheArticles" :key="keyFamille" :familleSelectionnee="familleCode" :familles="optionsFamilles" @select-famille="selectionFamille" :afficherTexte="true" />
        </div>
        <div class="col-6">
          <v-select v-if="familleCode" id="selectProduit" v-model="produitId" :options="optionsProduits" @input="selectionProduit()" label='designation' placeholder="Produit..." :reduce="p => p.id" :filterBy="rechercheSansAccents" />
        </div>
      </div>
    </div>

    <div v-else-if="typeActif === 'parReference'" style="margin-top:5px">
      <div class="row">
        <div class="col-3">
          <input id="txtReference" type="text" v-model.trim="reference" @keydown.enter.prevent="pressEnterReference()" placeholder="Référence à rechercher" />
        </div>
        <button class="btn btn-primary" v-if="reference && reference.length>2" @click="pressEnterReference">Rechercher</button>
      </div>
    </div>
  </div>
</template>

<script>
import { connecteData } from "@/mixins/connecteData"
import RechercheIngredients from "@/components/Recettes/RechercheIngredients"
import { mapGetters } from "vuex"
import { tousArticlesFamilles, tousArticlesParReference, tousArticlesPour } from '@/api/gpao/mercuriales'
import dropDownChoixFamille from "@/components/Recettes/dropDownChoixFamille"
import vm from "@/helpers/vm-toolkit-vue"

export default {
  props: ['masquerVide', 'fournisseurId', 'typeParDefaut'],
  mixins: [connecteData],
  components: {
    RechercheIngredients,
    dropDownChoixFamille
  },
  data() {
    return {
      optionsType: [
        { text: "par ingrédient", value: "parIngredient" },
        { text: "par famille et produit", value: "parFamille" },
        { text: "par référence", value: "parReference" }
      ],
      typeActif: this.typeParDefaut || "parIngredient",
      familleCode: "",
      keyFamille: 1,
      produitId: false,
      optionsProduits: [],
      reference: ""
    }
  },
  computed: {
    ...mapGetters("definitions", ["ingredients"]),
    ...mapGetters("definitions", ["famillesActives"]),
    type() {
      return this.typeActif && this.optionsType.find(o => o.value === this.typeActif)
    },
    optionsFamilles() {
      var familles = []
      this.famillesActives.forEach(f => {
        familles.push(f.code)
      })
      return familles
    }
  },
  mounted() {
    setTimeout(() => {     this.focusRecherche()    }, 10)
  },
  methods: {
    rechercheSansAccents(option, label, search) {
      let actuel = option.designationRecherche
      const recherche = search.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
      if (!actuel)
        actuel=label
      if (!actuel)
        return false
      return actuel.indexOf(recherche) > -1;
    },
    pressEnterReference() {
      return tousArticlesParReference(this.etabCourantId, this.reference)
        .then(r => {
          this.chargerArticles(r)
          return
        })
    },
    focusRadio() {
      if (this.$refs.radio) {
        this.$refs.radio.$vnode.elm.querySelector("input:checked").focus()
      }
    },
    focusRecherche() {
      if (this.$refs.recherche) {
        this.$refs.recherche.$vnode.elm.querySelector("input").focus()
      }
    },

    reset(optionalState) {
      this.familleCode = ""
      this.produitId = false
      this.$emit("change-ingredient", {})
      this.$emit("change-state", (optionalState)?optionalState:"")
    },

    selectionIngredient(e) {
      this.$emit("change-state", "Recherche en cours...")
      this.focusRadio()//masque les résultats de recherche
      return tousArticlesFamilles(this.etabCourantId, e.id, this.fournisseurId)
        .then(r => {
          this.chargerArticles(r)
          return
        })
    },

    chargerArticles(reponseApi) {
      var data = {}//préparation des données
      reponseApi.data.forEach(d => {
        data.ingredientId = d.ingredientId
        data.up = d.up
        data.parProduit = data.parProduit || {}
        vm.forEach(d.parProduit, (p, i) => {
          data.parProduit[i] = p
        })
        data.favoris = data.favoris || []
        if (d.favoris) {
          vm.forEach(d.favoris, (f, i) => {
            data.favoris[i] = f
          })
        }
      })
      this.updateArticles(data)
      return
    },

    selectionFamille(code) {
      this.familleCode = code.famille
      var familleId = this.famillesActives.find(f => f.code === this.familleCode).id
      this.optionsProduits = this.$store.state.produits.produitsUtilisateur.filter(el => el.familleId === familleId)
        .sort((p1, p2) => p1.designation.localeCompare(p2.designation))
      if (!this.familleCode) {
        this.reset()
      }
      this.keyFamille += 1
    },

    selectionProduit() {
      if (this.produitId) {
        this.$emit("change-state", "Recherche en cours...")
        return tousArticlesPour(this.etabCourantId, this.produitId, this.fournisseurId)
          .then(r => {
            this.updateArticles(r.data || {})
            return
          })
      } else {
        let familleCode = this.familleCode
        this.reset()
        this.familleCode = familleCode
      }
    },

    updateArticles(data) {
      var state
      if (data.parProduit && Object.values(data.parProduit).length) {
        state = "Veuillez choisir l'article à ajouter"
        Object.values(data.parProduit)[0][0].ouvrirAccordeon = true//ouvre le premier résultat
              this.$emit("change-state", state)
      this.$emit("change-ingredient", data)
      } else {
        this.reset("Aucun article ne correspond à ces critères de recherche")
      }

    }
  }
}
</script>

<style>
#selectFamilleRechercheArticles > div > div.dropdown {
  width: 100%;
  box-shadow: none;
}

#selectFamilleRechercheArticles > div > div.dropdown > button {
  text-align: left;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 41px;
}

#selectFamilleRechercheArticles > div > div.dropdown > button > span {
  vertical-align: sub;
}

#selectFamilleRechercheArticles > div > div.dropdown > button::after {
  float: right;
  transform: scale(1.5);
  color: rgba(60, 60, 60, 0.5);
  margin-right: 8px;
}
</style>
