<template>
  <span>
    <b-button class="btn btn-default" :size="size" @click="show" title='Fiche technique'>
      <i class="fas fa-barcode"></i>
    </b-button>
    <b-modal  v-model="afficherModale" :size="sizeModal" hide-footer
      :content-class="contentClass"
      header-bg-variant="gradient-bleu"
      header-text-variant="light">
      <template slot="modal-title">
        <h3 :title="urlFT"><i class="fas fa-barcode" style="margin-right:1rem" ></i>Fiche technique</h3>
      </template>
      <template slot="default">
        <div v-if="https" class="container-fluid">
          <object :data="url" type="application/pdf" />
        </div>
        <div v-else class="container-fluid">
          <p>Pour des raisons dépendantes du site du fournisseur, nous ne pouvons afficher directement la fiche technique.</p>
          <p>Vous pouvez cliquer sur le lien suivant pour la télécharger :</p>
          <a :href="url" target="_blank" rel="noopener noreferrer" @click="hide" @click.right="hide" @click.middle="hide">{{url}}</a>
          <p></p>
        </div>
      </template>
    </b-modal>
  </span>
</template>

<script>
import { obtenirUrlFt } from "@/api/gpao/fournisseurs"
export default {
  name: "boutonFicheTechnique",
  props: ["fournisseurId", "reference", "urlFT", "size"],
  data() {
    return {
      afficherModale: false,
      https: false,
      sizeModal: "lg",
      contentClass:"",
      id: "",
      url: ""
    }
  },
  methods: {
    show() {
      var me = this
      !(function() {
        if (me.urlFT && me.urlFT.startsWith("http")) {
          return Promise.resolve(me.urlFT)
        }
        if (me.urlFT) {
          return Promise.resolve("https://admin.mercudyn.com" + me.urlFT)
        }
        return obtenirUrlFt(me.fournisseurId, me.reference).then(result => {
          return result.data
        })
      })()
        .then(url => {
          me.url = url
          this.afficherModale = true
          this.https = url.startsWith("https")
          this.sizeModal = this.https ? "xl" : "lg"
          this.contentClass = this.https ? "md-ft" : ""
          return
        })
    },
    hide() {
      this.afficherModale = false
    }  
  },
  mounted() {
    this.id = this.fournisseurId + "_" + this.reference
  }
}
</script>

<style>
.md-ft {
  height: 94vh;
}
.md-ft .container-fluid,
.md-ft object {
  height: 100%;
  width: 100%;
}
.modal-xxl {
  max-width: 94%;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 899px) {
  .modal-xxl {
    max-width: 800px;
  }
}
@media (max-width: 1600px) {
  .modal-xxl {
    max-width: 90vw;
  }
}
@media (min-width: 1600px) {
  .modal-xxl {
    max-width: 1500px;
  }
}
</style>
