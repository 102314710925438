<template>
  <div style="display: inline">

    <b-button :disabled="disabled" v-tooltip="{ content: 'Passer la commande au statut \'Envoyé\'', placement: 'top' }" class="btn btn-light push-right-1rem" @click="show">
      <i class="fas fa-check"></i>
    </b-button>

    <b-modal ref="modal" size="xl" hide-footer header-bg-variant="gradient-bleu" header-text-variant="light">

      <template slot="modal-header">
        <h3>{{commande.nom}}</h3>
      </template>

      <template slot="default">

        <p>Le statut des commandes sélectionnées sera modifié en "Envoyé".</p>

        <p>Cette action indique à MercuDyn 5 que vous avez passé la commande par vos propres moyens (télévente, email manuel, ...).</p>

        <p>Si vous souhaitez envoyer automatiquement les commandes, veuillez utiliser la fonction :
          <b-button class="btn btn-light push-right-1rem" @click="afficherModalEnvoi()" v-tooltip="{ content: 'Imprimer/envoyer le bon de commande', placement: 'top' }">
            <i class="fas fa-print"></i>
          </b-button>
        </p>

        <div v-for="(fournisseur, index) in fournisseurs" :key="index">
          <div :class="'fournisseur-ligne flex-space-between' + (fournisseur.envoi ? ' bg-gradient-bleu text-white' : '')" @click="toggle(fournisseur)">
            <div>{{fournisseur.nom}}</div>
            <div>{{fournisseur.dateLivraisonSouhaitee}}</div>
          </div>
        </div>

        <div style="margin:2rem 0;text-align:right;">
          <button ref="btnEnvoyer" class="btn btn-success" @click="send">Modifier le statut</button>
          <button class="btn btn-danger" style="margin-left:3rem;background:#3085d6!important;border-color:#3085d6!important;" @click="hide">Annuler</button>
        </div>

      </template>

    </b-modal>

  </div>
</template>

<script>
import { envoyerCommandeFournisseurs } from '@/api/gpao/commandes'
import { formatageDate } from '@/mixins/dateFormats'
import { showAlerts } from '@/mixins/alerts'

export default {
  name: "BoutonEnvoiCommande",
  mixins: [formatageDate, showAlerts],
  props: ['commande', 'sCommandes', 'fournisseurIds', 'disabled'],
  data() {
    return {
      fournisseurs: []
    }
  },
  methods: {
    show() {
      var fournisseurs = []
      this.sCommandes.forEach(function (sCommande) {
        fournisseurs.push({
          fournisseurId: sCommande.fournisseurId,
          nom: this.$store.state.fournisseurs.fournisseursCourants.find(el => el.id === sCommande.fournisseurId).nom,
          dateLivraisonSouhaitee: this.formatHumanDate(sCommande.dateLivraisonSouhaitee),
          envoi: this.fournisseurIds.includes(sCommande.fournisseurId)
        })
      }.bind(this))
      this.fournisseurs = fournisseurs
      this.$refs.modal.show()
    },
    hide() {
      this.$refs.modal.hide()
    },
    toggle(fournisseur) {
      fournisseur.envoi = !fournisseur.envoi
      var envoi = 0
      this.fournisseurs.forEach(f => {
        if (f.envoi) {
          envoi += 1
        }
      })
      this.$refs.btnEnvoyer.disabled = !envoi
    },
    send() {
      var fournisseursEnvoi = []
      this.fournisseurs.forEach(f => {
        if (f.envoi) {
          fournisseursEnvoi.push(f.fournisseurId)
        }
      })
      if (fournisseursEnvoi.length !== 0) {
        this.hide()
        return envoyerCommandeFournisseurs(this.commande.id, fournisseursEnvoi)
          .then(() => {
            this.$emit("commande-envoyee")
            this.alerteSucces("Commande envoyée !")
            return
          })
      }
    },
    afficherModalEnvoi() {
      this.hide()
      this.$emit("afficher-modal-envoi")
    }
  }
}
</script>

<style scoped>
.fournisseur-ligne {
  margin: 6px 0;
  padding: 1rem;
  border: 1px solid white;
  box-shadow: 2px 2px 0px #ededed inset;
  cursor: pointer;
}
</style>
