<template>
  <b-modal v-if="tarif" v-model="afficherModal" size="lg" hide-footer header-bg-variant="gradient-bleu" header-text-variant="light">
    <template slot="modal-title">
      <h6 :key="key" style="font-weight:bold;margin-bottom:0;">{{title}}</h6>
    </template>
    <template slot="default">
      <div class="container-fluid" :key="key">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="codeProduit">Code produit</label>
              <input id="codeProduit" class="form-control" type="text" v-model="tarif.Reference" readonly>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="designation">Désignation produit du fournisseur</label>
              <input id="designation" class="form-control" type="text" v-model="tarif.Designation" readonly>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="marque">Marque</label>
              <input id="marque" class="form-control" type="text" v-model="tarif.Marque" readonly>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="ingredient">Ingrédient</label>
              <input id="ingredient" class="form-control" type="text" v-model="ingredient.nom" readonly>
            </div>
          </div>

          <div class="col-12">
            <div class="flex-start">
              <div class="label-input">PU HT :</div>
              <InputNumber v-model="tarif.PU" typeFiltre="montant" style="width:70px" readonly />
              <div class="input-group-append">
                <span class="input-group-text">€</span>
              </div>
              <div class="label-input" style="margin-left:15px;">PCB :</div>
              <InputNumber v-model="tarif.PCB" typeFiltre="quantite" style="width:50px" readonly />
              <div class="input-group-append">
                <span class="input-group-text">{{prod.UC}}</span>
              </div>
              <div class="label-input" style="margin-left:15px;">Montant HT :</div>
              <InputNumber v-model="tarif.PHT" typeFiltre="montant" style="width:90px" readonly />
              <div class="input-group-append">
                <span class="input-group-text">€</span>
              </div>
            </div>
          </div>

          <div class="col-12"><!-- v-if="tarif && (tarif.parPCE || tarif.parKg || tarif.parL)" -->
            <div class="flex-end" style="margin-top:15px;">
              <div class="input-group-prepend">
                <span class="input-group-text ">1 {{prod.UC}} =</span>
              </div>
              <InputNumber v-model="tarif.parPCE" typeFiltre="quantite" class="saisieRepartition" style="width:60px;" readonly />
              <div class="input-group-append">
                <span class="input-group-text">PCE, </span>
              </div>
              <InputNumber v-model="tarif.parKg" typeFiltre="quantite" class="saisieRepartition" style="width:60px;" readonly />
              <div class="input-group-append">
                <span class="input-group-text"><i v-if="estInvalidePour('KG',tarif.parKg)" class="error fas fa-exclamation-circle" v-b-tooltip.hover title="Cette valeur devrait être 1"></i>KG, </span>
              </div>
              <InputNumber v-model="tarif.parL" typeFiltre="quantite" class="saisieRepartition" style="width:60px;" readonly />
              <div class="input-group-append">
                <span class="input-group-text"><i v-if="estInvalidePour('L',tarif.parL)" class="error fas fa-exclamation-circle" v-b-tooltip.hover title="Cette valeur devrait être 1"></i>L.</span>
              </div>
            </div>
            <div class="error" v-if="!tarif.parPCE && !tarif.parKg && !tarif.parL">Attention, les répartitions doivent être renseignées pour être prises en compte dans les calculs automatiques</div>
            <div class="flex-end" style="margin-top:3px;">
              <p>{{phraseConversion(false)}}</p>
            </div>
            <div class="flex-end">
              <p>{{phraseConversion(true)}}</p>
            </div>
          </div>

        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import InputNumber from '@/components/Inputs/InputNumber'
import { arrondiMercuriale, arrondiNombre } from '@/helpers/utils'
import { obtenirTarif } from "@/api/gpao/fournisseurs"
import { connecteData } from '@/mixins/connecteData'
import { mapGetters } from "vuex"

export default {
  name: "DetailsTarif",
  mixins: [ connecteData ],
  components: {InputNumber},
  data() {
    return {
      afficherModal: false,
      title: "",
      idProd: "",
      key: 1,
      prod: {},
      tarif: {},
      ingredient: {}
    }
  },
  computed: {
    ...mapGetters("definitions", ["trouverIngredient"]),
    ...mapGetters("fournisseurs", ["getFournisseur"]),
    ...mapGetters("produits", ["getProduit"])
  },
  methods: {
    afficher(data) {//appel externe
      this.prod = data.prod
      this.tarif = data.tarif
      if (!this.prod || !this.tarif) {
        return
      }
      this.tarif.PU = arrondiMercuriale(this.tarif.PrixAchat)
      this.tarif.PHT = arrondiMercuriale(this.tarif.PrixAchat * this.tarif.PCB)
      this.title = this.getFournisseur(data.idFourn).nomCourt + " : " + this.prod.Designation
      this.ingredient = this.trouverIngredient(data.idProd) || {}
      this.key += 1
      this.afficherModal = true
      return obtenirTarif(this.tarif.idTarif)
        .then(r => {
          this.tarif.parKg = r.data.item2.parKg
          this.tarif.parPCE = r.data.item2.parPCE
          this.tarif.parL = r.data.item2.parL
          this.key += 1
          return
        })
      
    },
    phraseConversion(auPcb) {
      var phrase = ""
      if (this.tarif.PCB > 0) {
        phrase += auPcb ? "Au PCB, si je commande " + this.tarif.PCB : "Si je commande 1"
        phrase += " " + this.prod.UC + " je recevrai "
        if (this.tarif.parKg > 0) {
          if (auPcb)
            phrase += arrondiNombre((this.tarif.parKg * this.tarif.PCB), 2) + " kg"
          else
            phrase += arrondiNombre(this.tarif.parKg, 2) + " kg"

          if (this.tarif.parPCE > 0 || this.tarif.parL > 0)
            if (auPcb)
              phrase += " (soit " + (this.tarif.parPCE > 0 ? arrondiNombre(this.tarif.parPCE * this.tarif.PCB, 2) + " unité" + ((arrondiNombre(this.tarif.parPCE * this.tarif.PCB, 2)) > 1 ? "s" : "") : "") + (this.tarif.parPCE > 0 && this.tarif.parL > 0 ? " ou " : "") + (this.tarif.parL > 0 ? arrondiNombre(this.tarif.parL * this.tarif.PCB, 2) + " litre" + ((arrondiNombre(this.tarif.parL * this.tarif.PCB, 2)) > 1 ? "s" : "") : "") + ")."
            else
              phrase += " (soit " + (this.tarif.parPCE > 0 ? arrondiNombre(this.tarif.parPCE, 2) + " unité" + (this.tarif.parPCE > 1 ? "s" : "") : "") + (this.tarif.parPCE > 0 && this.tarif.parL > 0 ? " ou " : "") + (this.tarif.parL > 0 ? arrondiNombre(this.tarif.parL, 2) + " litre" + (this.tarif.parL > 1 ? "s" : "") : "") + ")."

          else
            phrase += "."
        }
        else if (this.tarif.parPCE > 0 || this.tarif.parL > 0)
          if (auPcb)
            phrase += (this.tarif.parPCE > 0 ? arrondiNombre(this.tarif.parPCE * this.tarif.PCB, 2) + " unité" + ((arrondiNombre(this.tarif.parPCE * this.tarif.PCB, 2)) > 1 ? "s" : "") : "") + (this.tarif.parPCE > 0 && this.tarif.parL > 0 ? " ou " : "") + (this.tarif.parL > 0 ? arrondiNombre(this.tarif.parL * this.tarif.PCB, 2) + " litre" + ((arrondiNombre(this.tarif.parL * this.tarif.PCB, 2))  > 1 ? "s." : ".") : ".")
          else
            phrase += (this.tarif.parPCE > 0 ? arrondiNombre(this.tarif.parPCE, 2) + " unité" + (this.tarif.parPCE > 1 ? "s" : "") : "") + (this.tarif.parPCE > 0 && this.tarif.parL > 0 ? " ou " : "") + (this.tarif.parL > 0 ? arrondiNombre(this.tarif.parL, 2) + " litre" + (this.tarif.parL > 1 ? "s." : ".") : ".")
        
        else
          phrase = "Aucune conversion n'est paramétrée."
      }
      else
        phrase = "Aucun PCB n'est paramétré."

      return phrase
    },
    estInvalidePour(up, repartition) {
      if (!this.prod.UC)
        return false
      if (up !== this.prod.UC.toUpperCase()) {
        return false;
      }
      return repartition != 1;
    }
  }
}
</script>

<style scoped>
.form-control {
  box-shadow: 0 2px 2px 1px rgb(0 0 0 / 6%);
  border: none !important;
  border-radius: 0 !important;
  height: 31px;
  padding-left: 1rem;
  color: #4f565c;
  background-color: white;
}
.form-control:focus-visible {
  outline-offset: 0px;
  outline: -webkit-focus-ring-color auto 1px;
}
</style>
