<template>
  <div class="bg-white page-categories-gemrcn" style="margin-top:-15px;">
    <b-modal id="modalEditionCategorie" size="lg" hide-footer header-bg-variant="gradient-bleu" header-text-variant="light">
      <template slot="modal-title">
        <h3>Édition de catégorie GEMRCN</h3>
      </template>
      <template slot="default">
        <EditionCategorieGEMRCN :categorie="currentCategorie" @categorie-enregistree="categorieEnregistree" @annuler-edition="fermerModalCategorie" />
      </template>
    </b-modal>

    <div v-if="isAdmin" class="container-fluid bg-gradient-bleu" style="min-height:60px;padding:10px">
      <button class="btn" style="background-color: white; font-weight: bold; font-size: 12px;" @click="ajouterCategorie">Ajouter</button>
    </div>

    <div :key="key" class="container-fluid no-padding" style="min-height:65vh;background-color:inherit;">
      <table class="table table-sm table-bordered">
        <colgroup>
          <col style="width:60px;">
          <col style="width:40px;">
          <col>
          <col style="width: 40px;text-align: center;">
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th>Actif</th>
            <th>Nom</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(categorie, iCategorie) in categories" :key="iCategorie" style="cursor: pointer;" title="Editer la catégorie" @click="editerCategorie(categorie)">
            <td><div :style="'height: 20px;width: 20px;margin: 0 auto;background-color:' + categorie.couleur"></div></td>
            <td style="text-align: center;">
              <i v-if="categorie.actif" class="fas fa-check vertAgapInverse" title="Catégorie active" style="margin: 0 auto;"></i>
              <i v-else-if="isAdmin" class="fas fa-trash rougeAgapInverse" style="margin: 0 auto;" title="Supprimer la catégorie" @click.stop="supprimerCategorie(categorie)"></i>
              <i v-else class="fas fa-times rougeAgapInverse" style="margin: 0 auto;" title="Catégorie inactive"></i>
            </td>
            <td>
              <span v-if="categorie.actif">{{ categorie.nom }}</span>
              <span v-else style="color:gray"><s><i>{{ categorie.nom }}</i></s></span>
            </td>
            <td>
              <i v-if="categorie.couleur" class="fas fa-trash rougeAgapInverse" title="Supprimer la configuration" @click.stop="supprimerConfiguration(categorie)" style="margin: 0 auto;"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { connecteData } from "@/mixins/connecteData"
import { chargerCategories, supprimerConfiguration, supprimerCategorie } from '@/api/gpao/plansAlimentaires'
import { chargerDefinitions, chargerDonneesEtablissement } from '@/helpers/definitions'
import { showAlerts } from "@/mixins/alerts"
import { formatageDate } from '@/mixins/dateFormats'
import EditionCategorieGEMRCN from '@/components/Divers/EditionCategorieGEMRCN'

export default {
  name: "PageCategoriesGEMRCN",
  mixins: [ connecteData, showAlerts, formatageDate ],
  components: { EditionCategorieGEMRCN },
  data() {
    return {
      key: 1,
      categories: [],
      currentCategorie: {},
    }
  },
  computed: {
    
  },
  async mounted() {
    await this.rechargerCategories()
  },
  methods: {
    async rechargerDefinitions() {
      await chargerDefinitions()
        .then(() => {
          if (this.etabCourantId) {
            chargerDonneesEtablissement(this.etabCourantId)
          }
          return
        })
    },
    async rechargerCategories() {
      let resultat = await chargerCategories(this.etabCourantId)
      this.categories = resultat.data
      this.key += 1
    },
    ajouterCategorie() {
      this.currentCategorie = {}
      this.afficherModalCategorie()
    },
    editerCategorie(categorie) {
      if (this.formatDateAvecTirets(categorie.dateDebut) === "1-01-01")
        categorie.dateDebut = null
      this.currentCategorie = categorie
      this.afficherModalCategorie()
    },
    async supprimerConfiguration(categorie) {
      let result = await this.alerteConfirmation("Supprimer la configuration", "Souhaitez-vous supprimer votre configuration pour cette catégorie GEMRCN ?<br>La catégorie GEMRCN ne sera pas supprimée si elle est active.")
      if (result) {
        await supprimerConfiguration(categorie, this.etabCourantId)
        await this.rechargerCategories()
      }
    },
    async supprimerCategorie(categorie) {
      let result = await this.alerteConfirmation("Supprimer la catégorie GEMRCN", "Souhaitez-vous supprimer cette catégorie GEMRCN ?")
      if (result) {
        await supprimerCategorie(categorie)
        await this.rechargerCategories()
        await this.rechargerDefinitions()
      }
    },
    async categorieEnregistree() {
      this.fermerModalCategorie()
      await this.rechargerCategories()
      await this.rechargerDefinitions()
    },
    afficherModalCategorie() {
      this.$bvModal.show('modalEditionCategorie')
    },
    fermerModalCategorie() {
      this.$bvModal.hide('modalEditionCategorie')
    },
  }
}
</script>

<style>

</style>
