<template>
  <div>
    <div v-for="(article, iArticle) in articlesParProduit(articles)" :key="'article' + iArticle" :id="'accordion' + iArticle" :style="verrouille ? 'opacity:.7' : ''">
      <div class="card no-margin-bottom">
        <div class="flex">
          <div class="card-header cursor-pointer" style="width:80%" :id="'headingOne' + iArticle" data-toggle="collapse" :data-target="'#collapseOne' + iArticle" aria-expanded="true" :aria-controls="'collapseOne' + iArticle">
            <h5>
              {{nomArticle(article.nom)}}
            </h5>
          </div>
          <div class="card-header cursor-pointer">
            <span :v-tooltip="'Mieux-disant'" :class="{mieuxDisant:prixMinimal(article.produits)==mieuxDisantGeneral}" style="padding: 0 5px">{{monArrondiMercuriale(prixMinimal(article.produits))}}&euro;</span>
          </div>
          <div v-if="ingredient" class="card-header" style="width:20%">
            <div class="flex-center-space-between">
              <span :style="!favoriModifiable(article.produitId) ? 'opacity:.5' : ''">
                <i class="fas fa-user" v-tooltip="{ content: 'Vos produits favoris', placement: 'top' }"></i> : <i class="fa fa-star" :style="favoriModifiable(article.produitId) ? 'cursor:pointer' : ''"
                  :class="articleGlobalEnFavori(article.produitId) ? 'selected' : ''" @click.prevent="setArticleFavori(article)"></i>
              </span>
              <span :style="!recette || !favoriModifiable(article.produitId, null, recette.id) ? 'opacity:.5' :''">
                <i class="icone-recettes" v-tooltip="{ content: 'Produits favoris de la recette (attention, ce favori est prioritaire sur tous les autres)', placement: 'top' }"></i> : <i class="fa fa-star" :style="recette && favoriModifiable(article.produitId, null, recette.id) ? 'cursor:pointer' : ''"
                  :class="articleEnFavoriRecette(article.produitId, recette ? [{ id: recette.id }] : recettes) ? 'couleur-rouge-agap' : ''" @click.prevent="setArticleFavoriRecette(article, recette)"></i>
              </span>
            </div>
          </div>
          <div v-else class="card-header" style="width:20%"></div>
        </div>
        <div :id="'collapseOne' + iArticle" :class="article.produits.some(el => el.ouvrirAccordeon) ? 'collapse.show' : 'collapse'" :aria-labelledby="'headingOne' + iArticle" :data-parent="'#accordion' + iArticle">
          <div class="card-body">

            <table style="table-layout: fixed;border-spacing:2px;border-collapse:separate">
              <tr style="text-align:center">
                <th style="width:450px"><b>Désignation</b></th>
                <th style="width:160px"><b>Réf.</b></th>

                <th style="width:250px"><b>Fournisseur</b></th>
                <th style="width:50px"><i class="fas fa-user" v-tooltip="{ content: 'Vos articles favoris', placement: 'top' }"></i></th>
                <th style="width:50px"><i class="icone-recettes" :style="!recette ? 'opacity:.5' : ''" v-tooltip="{ content: recette ? 'Articles favoris de la recette (attention, ces favoris sont prioritaires sur les vôtres)' : 'Articles favoris de recettes (attention, ces favoris sont prioritaires sur les vôtres)', placement: 'top' }"></i></th>
                <th style="width:50px;"><b>PCB</b></th>
                <th style="width:100px"><b>PU HT</b></th>
                <th style="width:100px"><b>Total HT</b></th>
                <th style="width:40px" v-if="!gestionFavoriUniquement"></th>
              </tr>
              <tr v-for="(ligneArticle, iLigne) in article.produits" :key="'article' + iLigne" :class="{bloque: ligneArticle.bloque}">
                <td style="word-wrap: break-word">
                  {{ligneArticle.designation}} - {{ligneArticle.marque}}
                </td>
                <td>
                  <BoutonFicheTechnique v-if="ligneArticle.urlFT" size="sm" :fournisseurId="ligneArticle.fournisseurId" :reference="ligneArticle.reference" :urlFT="ligneArticle.urlFT" />
                  <span style="margin-right:5px"> {{ligneArticle.reference}}</span>
                </td>
                <td>
                  {{fournisseurNom(ligneArticle.fournisseurId)}}
                </td>
                <td>
                  <i v-if="ingredient" class="fa fa-star" :class="articleEnFavori(article.produitId, ligneArticle.fournisseurId) ? 'selected' : ''" style="margin-left:1rem"
                    :style="(favoriModifiable(article.produitId, ligneArticle.fournisseurId) && !ligneArticle.bloque) ? 'cursor:pointer' : 'opacity:.5'" @click="setArticleFavori(article, ligneArticle)">
                  </i>
                </td>
                <td>
                  <i v-if="ingredient" class="fa fa-star" :class="articleEnFavoriRecette(article.produitId, recette ? [{ id: recette.id }] : recettes, ligneArticle.fournisseurId) ? 'couleur-rouge-agap' : ''"
                    style="margin-left:1rem" :style="(recette && favoriModifiable(article.produitId, ligneArticle.fournisseurId, recette.id) && !ligneArticle.bloque) ? 'cursor:pointer' : 'opacity:.5'" @click="!ligneArticle.bloque && setArticleFavoriRecette(article, recette, ligneArticle)"></i>
                </td>
                <td style="text-align:right">{{ligneArticle.pcb}}</td>
                <td style="text-align:right" ><span :class="{mieuxDisantInterne:prixMinimal(article.produits)==ligneArticle.prixUnitaire}" style="padding: 0 5px">{{ligneArticle.prixUnitaire | afficherPrix}}</span>
                </td>
                <td style="text-align:right">{{((ingredient.quantiteBesoinEnUC || ligneArticle.pcb) * ligneArticle.prixUnitaire) | afficherPrix}}</td>
                <template v-if="!gestionFavoriUniquement">
                  <td v-if="ingredient" style="text-align:center">
                    <div @click="!ligneArticle.bloque && selectionArticle(article, ligneArticle)">
                      <i v-if="ingredient.tarifId === ligneArticle.tarifId" class="fas fa-check-circle" :class="(!verrouille && !ligneArticle.bloque) ? 'cursor-pointer' : ''"></i>
                      <i v-else class="fas fa-circle" :class="(!verrouille && !ligneArticle.bloque) ? 'cursor-pointer' : ''" style="color:green"></i>
                    </div>
                  </td>
                  <td v-else style="text-align:center">
                    <div @click="!ligneArticle.bloque && ajoutArticle(ligneArticle)">
                      <i v-if="articleSelectionne.tarifId && articleSelectionne.tarifId === ligneArticle.tarifId" class="fas fa-check-circle" :class="(!verrouille && !ligneArticle.bloque) ? 'cursor-pointer' : ''"></i>
                      <i v-else class="fas fa-circle" :class="(!verrouille && !ligneArticle.bloque) ? 'cursor-pointer' : ''" style="color:green"></i>
                    </div>
                  </td>
                </template>
              </tr>
            </table>
            <!--         <div class="container-fluid">
              <div class="row">
                <div class="col-4 bordered flex-center"><b>Désignation</b></div>
                <div class="col-1"><b>Réf.</b></div>

                <div class="col-3"><b>Fournisseur</b></div>
                <div class="col-1 bordered flex-center"><b>PCB</b></div>
                <div class="col-1 bordered flex-center" style="white-space: nowrap;"><b>PU HT</b></div>
                <div class="col-1 bordered flex-center" style="white-space: nowrap;"><b>Total HT</b></div>
                <div class="col-1"></div>
              </div> -->
            <!--        <div class="row" v-for="(ligneArticle, i) in article.produits" :key="'article'+i">
                <div class="col-4 bordered flex-center">{{ligneArticle.designation}} - {{ligneArticle.marque}}</div>
                <div class="col-1 bordered flex-center"><BoutonFicheTechnique v-if="ligneArticle.urlFT"
          :fournisseurId="ligneArticle.fournisseurId"          :reference="ligneArticle.reference"          :urlFT="ligneArticle.urlFT"  style="margin-right:5px"/>
     <span  style="margin-right:5px">    {{ligneArticle.reference}}</span></div>
                <div v-if="ingredient" class="col-3 bordered flex-center-space-between">
                  {{fournisseurNom(ligneArticle.fournisseurId)}}
                  <i class="fa fa-star" :class="articleEnFavori(article.produitId, ligneArticle.fournisseurId) ? 'selected' : ''"
                    style="cursor:pointer;margin-left:1rem" @click="setArticleFavori(article, ligneArticle)">
                  </i>
                </div>
                <div v-else class="col-3">{{fournisseurNom(ligneArticle.fournisseurId)}}</div>
                <div class="col-1 bordered flex-center">{{ligneArticle.pcb}}</div>
                <div class="col-1 bordered flex-center" style="white-space: nowrap;">{{ligneArticle.prixUnitaire | afficherPrix}}</div>
                <div class="col-1 bordered flex-center" style="white-space: nowrap;">{{(ligneArticle.pcb * ligneArticle.prixUnitaire) | afficherPrix}}</div>
                <div v-if="ingredient" class="col-1 bordered flex-center">
                  <div @click="articleSelectionnePourIngredient(article, ligneArticle)">
                    <i v-if="ingredient.tarifId === ligneArticle.tarifId" class="fas fa-check-circle" :class="!verrouille ? 'cursor-pointer' : ''"></i>
                    <i v-else class="fas fa-circle" :class="!verrouille ? 'cursor-pointer' : ''" style="color:green"></i>
                  </div>
                </div>
                <div v-else class="col-1">
                  <div @click="ajoutArticle(ligneArticle)">
                    <i v-if="articleSelectionne.tarifId && articleSelectionne.tarifId === ligneArticle.tarifId" class="fas fa-check-circle" :class="!verrouille ? 'cursor-pointer' : ''"></i>
                    <i v-else class="fas fa-circle" :class="!verrouille ? 'cursor-pointer' : ''" style="color:green"></i>
                  </div>
                </div>
              </div> 
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { articleFavori, articleFavoriRecette } from '@/api/gpao/commandes'
import { fournisseurs } from '@/mixins/fournisseurs'
import { showAlerts } from '@/mixins/alerts'
import { mapGetters } from "vuex";
import { arrondiMercuriale } from '@/helpers/utils'
import BoutonFicheTechnique from '@/components/Divers/BoutonFicheTechnique'
import _ from 'lodash'

export default {
  name: "ArticlesParProduit",
  props: {
    articles: Object,
    favoris: Array,
    articleSelectionne: Object,
    ingredient: Object,
    index: Number,
    disabled: Boolean,
    gestionFavoriUniquement: Boolean,
    recette: Object,
    recettes: []
  },
  mixins: [fournisseurs, showAlerts],
  components: { BoutonFicheTechnique },
  data() {
    return {
      mieuxDisantGeneral: 2.263158,
      articleChoisi: {
        produitId: '',
        fournisseurId: ''
      },
      articlesEnFavori: []
    }
  },
  computed: {
    ...mapGetters("auth", ["isAdmin"]),
    verrouille() {
      return this.disabled;
    }
  },
  methods: {
    prixMinimal(produits) {
      return (produits.reduce((min, p) => p.prixUnitaire < min ? p.prixUnitaire : min, produits[0].prixUnitaire));
    },
    articleGlobalEnFavori(produitId) {
      return Boolean(this.articlesEnFavori.find(el => el.produitId === produitId && !el.fournisseurId && !el.recetteId))
    },
    articleEnFavori(produitId, fournisseurId) {
      return Boolean(this.articlesEnFavori.find(el => el.produitId === produitId && el.fournisseurId === fournisseurId && !el.recetteId))
    },
    articleEnFavoriRecette(produitId, recettes, fournisseurId) {
      if (recettes && recettes.length === 0)
        return false
      let favoris = this.articlesEnFavori.filter(el => el.produitId === produitId)
      if (fournisseurId)
        favoris = favoris.filter(el => el.fournisseurId === fournisseurId)
      else
        favoris = favoris.filter(el => !el.fournisseurId)
      return Boolean(favoris.find(el => (!recettes && el.recetteId) || (recettes && recettes.find(r => r.id === el.recetteId))))
    },
    articleChoisiEnFavori() {
      // L'article choisi est considéré comme favori si l'article est favori ou si le produit correspondant est favori
      return this.articleGlobalEnFavori(this.articleChoisi.produitId) || this.articleEnFavori(this.articleChoisi.produitId, this.articleChoisi.fournisseurId)
        || this.articleEnFavoriRecette(this.articleChoisi.produitId, null, this.articleChoisi.fournisseurId)
    },
    favoriModifiable(produitId, fournisseurId, recetteId) {
      let favoris = this.articlesEnFavori.filter(el => el.produitId === produitId)
      if (fournisseurId)
        favoris = favoris.filter(el => el.fournisseurId === fournisseurId)
      else
        favoris = favoris.filter(el => !el.fournisseurId)
      if (recetteId)
        favoris = favoris.filter(el => el.recetteId === recetteId)
      else
        favoris = favoris.filter(el => !el.recetteId)
      return Boolean(!favoris.find(el => el.lectureSeule))
    },
    nomArticle(id) {
      var retour = this.$store.state.produits.produitsUtilisateur.find(el => el.id === id).designation
      if (this.isAdmin) {
        retour = retour + "   - " + id
      }
      return retour;
    },
    articlesParProduit(articles) {
      var retour = articles && Object.entries(articles).map(el => { return { nom: el[0], produits: el[1], produitId: el[0] } })
      return retour || [];
    },
    async setArticleFavori(article, ligneArticle) {
      const fournisseurId = ligneArticle && ligneArticle.fournisseurId || null
      if (this.verrouille || (ligneArticle && ligneArticle.bloque) || !this.favoriModifiable(article.produitId, fournisseurId))
        return

      this.$set(article, 'loading', true)
      let newState = await articleFavori(article.produitId, fournisseurId)

      if (newState.data) {
        this.articlesEnFavori.push({ produitId: article.produitId, fournisseurId: fournisseurId })
      } else {
        this.articlesEnFavori = this.articlesEnFavori.filter(el => el.produitId !== article.produitId || el.fournisseurId !== fournisseurId || el.recetteId)
      }
      this.$emit("toggle-favori", { produitId: article.produitId, fournisseurId: fournisseurId, estFavori: Boolean(newState.data), produit: ligneArticle })

      this.$toast('Favori modifié !', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        className: ['toast-success'],
        duration: 1500
      })
    },
    async setArticleFavoriRecette(article, recette, ligneArticle) {
      const fournisseurId = ligneArticle && ligneArticle.fournisseurId || null
      if (this.verrouille || !recette || !this.favoriModifiable(article.produitId, fournisseurId, recette.id))
        return

      this.$set(article, 'loading', true)
      try {
        let newState = await articleFavoriRecette(article.produitId, recette.id, fournisseurId)
        if (newState.data) {
          this.articlesEnFavori.push({ produitId: article.produitId, recetteId: recette.id, fournisseurId: fournisseurId })
        } else {
          this.articlesEnFavori = this.articlesEnFavori.filter(el => el.produitId !== article.produitId || el.recetteId !== recette.id || el.fournisseurId !== fournisseurId)
        }
        this.$emit("toggle-favori", { produitId: article.produitId, recetteId: recette.id, fournisseurId: fournisseurId, estFavoriRecette: Boolean(newState.data), produit: ligneArticle })

        this.$toast('Favori recette modifié !', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          className: ['toast-success'],
          duration: 1500
        })
      } catch (error) {
        this.alerteErreur("Impossible d'enregistrer l'article en favori de recette " + (error && error.message ? " : " + error.message : ""))
      }
    },
    selectionArticle(article, produit) {
      if (this.verrouille || produit.bloque)
        return
      this.articleChoisi.produitId = article.produitId
      this.articleChoisi.fournisseurId = produit.fournisseurId

      this.$emit('selection-article', { produitId: article.produitId, produit: produit, estFavori: this.articleChoisiEnFavori(), index: this.index })
    },
    monArrondiMercuriale(prix) {
      return arrondiMercuriale(prix)
    },
    ajoutArticle(article) {
      if (this.verrouille)
        return
      this.$emit('ajout-article', article)
    }
  },
  mounted() {
    if (this.favoris && this.favoris.length) {
      this.articlesEnFavori = _.cloneDeep(this.favoris)
    }
    this.articleChoisi.produitId = this.ingredient.produitId
    this.articleChoisi.fournisseurId = this.ingredient.fournisseurId
    if (!this.ingredient.tarifId) { // Selection automatique si pas de tarif fourni et choix unique
      let produits = this.articlesParProduit(this.ingredient.parProduit)
      if (produits.length == 1) {
        let articles = produits[0].produits
        if (articles.length == 1 && !this.ingredient.tarifId) {
          this.selectionArticle(produits[0], articles[0])
        }
      }
    }
    let plusBas = 99999
    Object.keys(this.articles).forEach(idProduit => {
      let article = this.articles[idProduit]
      let minimal = article.reduce((min, p) => p.prixUnitaire < min ? p.prixUnitaire : min, article[0].prixUnitaire)
      if (minimal < plusBas) {
        plusBas = minimal
      }
    });
    this.mieuxDisantGeneral = (plusBas)
  }
}
</script>

<style lang="css" scoped>
.bloque, .bloque * {
  color:gray!important;
}
.selected-star {
  background: black;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.mieuxDisantInterne {
  background-color: green;
  color: white;
}
.mieuxDisant {
  font-weight: 600;
  background-color: green;
  color: white;
}
.rotate {
  animation: rotate 2s linear infinite;
}
</style>
