import Vue from 'vue'
import { chargerFournisseurs } from '@/api/gpao/fournisseurs'

export default  {
  namespaced: true,
  state: {
    fournisseursCourants: [],
  },
  actions: {
    async listeFournisseurs({ commit },etabCourantId) {
      return chargerFournisseurs(etabCourantId)
      .then(function (response) {
        commit('setItems', {key: 'fournisseursCourants', data:response.data})
        return response
      })
      .catch(function (error) {
        console.log(error)
      })
    },
  /*   async listeCompleteFournisseurs({ commit }) {
      return listeCompleteFournisseurs()
      .then(function (response) {
        const data = response.data
        commit('setItems', {key: 'listeComplete', data})
        return response
      })
      .catch(function (error) {
        console.log(error)
      })
    } */
  },
  getters: {
    fournisseursCourants: state => { return state.fournisseursCourants },
    nomFournisseur: state => id => {
      if (id) {
        const f = state.fournisseursCourants.find(el => el.id === id);
        if (!f)
          return 'Non trouvé ' + id
        return f.nom
      }
    },
    nomCourtFournisseur: state => id => {
      if (id) {
        const f = state.fournisseursCourants.find(el => el.id === id);
        if (!f)
          return ""
        return f.nomCourt
      }
    },
    getFournisseur: state => id => {
      return state.fournisseursCourants.find(f => f.id === id)
    }
  },
  mutations: {
    setItems(state, items) {
      Vue.set(state, items.key, items["data"])
    },
    setFournisseurLocal(state, item) {
      Vue.set(state, 'fournisseursCourants', item)
    }
  }
}

