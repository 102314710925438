import Vue from 'vue'

const commandes = {
  namespaced: true,
  state: {
    dateDebutEnMemoire: '',
    dateFinEnMemoire: '',
    optionsImpression: null
  },
  mutations: {
    setDateDebutEnMemoire(state, dateDebut) {
      Vue.set(state, 'dateDebutEnMemoire', dateDebut)
    },
    setDateFinEnMemoire(state, dateFin) {
      Vue.set(state, 'dateFinEnMemoire', dateFin)
    },
    setOptionsImpression(state, options) {
      Vue.set(state, 'optionsImpression', options)
    }
  }
}
export default commandes
