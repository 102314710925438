<template>
  <div class="card" style="min-height:300px;">
    <div class="card-content">
      <div class="card-body">
        <div class="flex-center-space-between" v-tooltip="gotMenus && { content: menus[indexMenu] && menus[indexMenu].nom, placement: 'top' }">
          <span class="widgetTitle">Menus du jour</span>
           
          <span v-if="gotMenus && menus.length">{{(indexMenu + 1) + "/" + menus.length}}</span>
        </div>
        <div v-if="gotMenus" style="margin-top:-5px;"  class="flex-center-space-between">
          <div style="font-size:13px;font-weight:bold;">{{menus[indexMenu] && menus[indexMenu].texteDate}}</div>
          <div>{{menus[indexMenu] && menus[indexMenu].nom}} </div>
        </div>
        <div v-if="!gotMenus" style="text-align:center;margin-top:50px;">
          <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style='height:100px;margin-left:-8px;' />
        </div>
        <div v-else-if="menus.length" :style="menus[indexMenu].services.length === 1 ? 'margin-top:20px;' : ''">

          <div v-if="indexMenu > 0" style="position:absolute;top:0;left:0;height:100%;width:20px;" @click="indexMenu -= 1">
            <div style="color:grey;cursor:pointer;height:100%;display:flex;align-items:center;flex-direction:row-reverse;" v-tooltip="{ content: 'Menu précédent', placement: 'left' }">
              <i class="fas fa-chevron-left" style="font-size:20px;"></i>
            </div>
          </div>

          <div v-for="(service, iService) in menus[indexMenu].services" :key="iService" style="text-align:center;">
            <div :style="'font-weight:bold;margin-bottom:5px;' + (iService > 0 ? 'margin-top:15px;' : '')">{{service.nom}}</div>
            <div v-for="(composante, iComposante) in service.composantes" :key="iComposante" style="font-size:13px;margin-bottom:8px;">
              <RecettePopUpIngredients v-if="composante.recetteId" :estLien=true :recetteId="composante.recetteId" :recetteNom="composante.recetteNom" :serviceId="service.id" :typeConviveId="menus[indexMenu].typeConviveId" :effectif="composante.effectif" />
            </div>
          </div>

          <div v-if="indexMenu < menus.length - 1" style="position:absolute;top:0;right:0;height:100%;width:20px;" @click="indexMenu += 1">
            <div style="color:grey;cursor:pointer;height:100%;display:flex;align-items:center;" v-tooltip="{ content: 'Menu suivant', placement: 'right' }">
              <i class="fas fa-chevron-right" style="font-size:20px;"></i>
            </div>
          </div>

        </div>
        <div v-else style="font-style:italic;margin-top:20px;text-align:center;">Aucun menu enregistré pour aujourd'hui</div>
      </div>
    </div>
  </div>

</template>

<script>
import { obtenirMenusJour } from '@/api/gpao/menus'
import { connecteData } from "@/mixins/connecteData"
import { formatageDate, formatHumanDateAvecSlashes } from '@/mixins/dateFormats'
import { mapGetters } from "vuex"
import RecettePopUpIngredients from '@/components/Recettes/RecettePopUpIngredients'

export default {
  name: "WidgetMenus",
  props: [],
  mixins: [formatageDate, formatHumanDateAvecSlashes, connecteData],
  components: { RecettePopUpIngredients },
  data() {
    return {
      menus: [],
      gotMenus: false,
      indexMenu: 0
    }
  },
  mounted() {
    this.chargerMenus()
  },
  computed: {
    ...mapGetters("definitions", ["obtenirService"]),
  },
  methods: {
    recetteNom(id) {
      return id && this.recettes && this.recettes.find(recette => recette.id === id).nom || ""
    },
    async chargerMenus() {
      const reponse = await obtenirMenusJour(this.etabCourantId)
      this.menus = reponse.data
      this.menus.forEach(m => {
        m.texteDate = this.formatHumanDateAvecSlashes(m.dateMenu)
        if (m.services) {
          m.services.forEach(s => {
            s.nom = s.id ? this.obtenirService(s.typeId).text : ""
          })
        }
      })
      this.gotMenus = true
      return;

    },
  }
}
</script>

<style scoped>
</style>
