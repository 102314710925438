<template>
  <div class="menu-container">
    <div class="main-menu menu-fixed menu-dark menu-accordion menu-shadow" style="padding-top:10px" data-scroll-to-active="true" v-if="etabCourantId">

      <div class="main-menu-content">
        <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
          <li :class="'nav-item '" v-for="entree in entreesFiltrees(entrees)" :key="entree.icon" :title="entree.aide">
            <template v-if="entree.url">
              <router-link :to="entree.url">
                <i :class="[entree.icon, {'estSelectionne':estSelectionne(entree)}]" aria-hidden="true"></i>
              </router-link>
            </template>
            <template v-else>
              <b-popover id="popover-menu" target="imagePanneau" triggers="click" placement="right" :show.sync="panneauVisible">
                <div class="card" style="width:340px;background-color:rgb(64,78,103);">
                  <div class="card-header" style="border-color:rgb(64,78,103);">
                    <div class="card-title" style="text-align:center;font-weight:bold;color:#dcdcdc;">
                      Autres fonctionnalités
                    </div>
                  </div>
                  <div class="card-body">
                    <div style="display:flex;flex-basis: auto;flex-flow: row wrap;align-content:space-around">
                      <div v-for="entree in entreesSecondaires" :key="entree.icone" style="text-align:center;width:100px;padding-bottom:10px" v-tooltip="enChargement(entree) && { content: 'En cours de chargement...', placement: 'right' }">
                        <router-link :to="entree.url" :class="{'disabled': enChargement(entree)}">
                          <i :class="[entree.icon, 'menu-link', {'estSelectionne': estSelectionne(entree)}, {'fa-spin': enChargement(entree)}]" aria-hidden="true" style="color:#dcdcdc;"></i>
                        </router-link>
                        <br />
                        <router-link :to="entree.url" style="color:#dcdcdc;" :class="['menu-link', {'disabled': enChargement(entree)}]">
                          {{entree.titre}}
                        </router-link>

                      </div>
                    </div>
                  </div>
                </div>
              </b-popover>
              <a href="javascript:void(0);" id="imagePanneau" triggers="hover">
                <i id="imagePanneau" :class="entree.icon" aria-hidden="true" triggers="hover"></i> <!--  @click="ouverturePanneau"-->
              </a>

            </template>
          </li>
        </ul>
      </div>
      <div class="main-menu-footer footer-close" style="padding-bottom:2px;max-height: 20px;text-align:center" :title="buildToolTip">
        {{environnement}}
      </div>
    </div>

  </div>

</template>

<script>
import { connecteData } from "@/mixins/connecteData"
import { chargerInfosApp } from '@/api/gpao/definitions'
import { mapGetters } from "vuex"

export default {
  name: "menuApp",
  mixins: [connecteData],
  data() {
    return {
      environnement: process.env.NODE_ENV,
      buildToolTip: 'inconnu',
      entrees: [],
      entreesSecondaires: [],
      panneauVisible: false,
    }
  },
  computed: {
    ...mapGetters("uxHelpers", ["mercurialesChargees"]),
  },
  mounted: function () {
    this.buildToolTip = this.versionFront()
    // eslint-disable-next-line promise/always-return
    chargerInfosApp().then(response => {
      this.environnement = `${response.data.environnement}`
      this.buildToolTip = `API: ${response.data.dateBuild.substring(2)} \n` + this.versionFront()
    })
      .catch(() => {
        console.error("Erreur de récupération infosApp")
      })

    this.entrees = [
      {
        icon: "icone-recettes plusGrand",
        aide: "Recettes",
        url: { name: 'Recettes', params: { etablissementId: this.etabCourantId } }
      },
      {
        icon: "icone-menu plusGrand",
        aide: "Menus & Structures de menus",
        url: { name: 'Menus', params: { etablissementId: this.etabCourantId } }
      },
      {
        icon: "icone-effectifs-portages plusGrand", aide: "Saisie des effectifs",
        url: { name: 'EffectifsPortage', params: { etablissementId: this.etabCourantId } }
      },
      {
        icon: "icone-calcul-des-besoins plusGrand",
        aide: "Calcul des besoins",
        url: { name: 'CalculDesBesoins', params: { etablissementId: this.etabCourantId } }
      },
      {
        icon: "icone-suivi-des-commandes plusGrand",
        aide: "Suivi des commandes",
        url: { name: 'ListeDesCommandes', params: { etablissementId: this.etabCourantId } }
      },
      {
        icon: "icone-gestion-des-stocks plusGrand",
        aide: "Stock",
        url: { name: 'Stock', params: { etablissementId: this.etabCourantId } }
      },
      {
        icon: "icone-mise-en-production plusGrand",
        aide: "Production",
        url: { name: 'SuiviProduction', params: { etablissementId: this.etabCourantId } }
      },
      { icon: "icone-menu-plus plusGrand" },
    ]
    this.entreesSecondaires = [
      {
        icon: "icone-mercuriale iconePanneau",
        titre: "Mercuriale",
        url: { name: 'Mercuriales', params: { etablissementId: this.etabCourantId } }
      },
      {
        icon: "icone-mon-gre-a-gre iconePanneau",
        titre: "Mon gré à gré",
        url: { name: 'MonGreAGre', params: { etablissementId: this.etabCourantId } }
      },
      {
        icon: "icone-reclamations-et-avoirs iconePanneau",
        titre: "Réclamations & avoir",
        url: { name: 'Reclamations', params: { etablissementId: this.etabCourantId } }
      },
      {
        icon: "icone-inventaire iconePanneau",
        titre: "Inventaire",
        url: { name: 'Inventaire', params: { etablissementId: this.etabCourantId } }
      },
      {
        icon: "icone-productions-archivees iconePanneau",
        titre: "Productions archivées",
        url: { name: 'SuiviProductionArchive', params: { etablissementId: this.etabCourantId } }
      },
      {
        icon: "icone-fournisseurs iconePanneau",
        titre: "Mes fournisseurs",
        url: { name: 'Fournisseurs', params: { etablissementId: this.etabCourantId } }
      },
      {
        icon: "icone-gestion-des-stocks iconePanneau",
        titre: "Mouvements de stock",
        url: { name: 'MouvementsStock', params: { etablissementId: this.etabCourantId } }
      }
    ]
    if (this.isAdmin) {
      this.entreesSecondaires.push(
        {
          icon: "fas fa-seedling fix-bottom iconePanneau",
          titre: "Catégories GEMRCN",
          url: { name: 'CategoriesGEMRCN', params: { etablissementId: this.etabCourantId } }
        },
        {
          icon: "fas fa-table fix-bottom iconePanneau",
          titre: "Plans alimentaires",
          url: { name: 'PlansAlimentaires', params: { etablissementId: this.etabCourantId } }
        },
        {
          icon: "icone-configuration iconePanneau",
          titre: "Configuration",
          url: { name: 'Configurations' }
        },
        {
          icon: "icone-donnees-produits iconePanneau",
          titre: "Données produits",
          url: { name: 'FichesTechniques' }
        },
        {
          icon: "icone-allergenes iconePanneau",
          titre: "Imports des allergènes",
          url: { name: 'Allergenes' }
        }
      )
    }
  },
  watch: {
    '$route'() {
      this.panneauVisible = false
    },
    panneauVisible(visible) {
      if (visible) {
        window.addEventListener("click", this.clickListener)
      } else {
        window.removeEventListener("click", this.clickListener)
      }
    }
  },
  methods: {
    versionFront() {
      return `FRONT: ${document.documentElement.dataset.buildTimestamp} (ici => +${new Date().getTimezoneOffset() / (-60)}H)`
    },
    estSelectionne(entree) {
      if (entree.url.name) {
        return this.$route.name === entree.url.name
      }
      return this.$route.path === entree.url
    },
    enChargement(entree) {
      if (entree.url.name === "Mercuriales") {
        return !this.mercurialesChargees
      }
      return false
    },
    entreesFiltrees: function (entrees) {
      if (this.etabCourant && this.etabCourant.aLieuPortageActif) {
        return entrees;
      }
      else {
        return entrees.filter(function (e) {
          if (e.url) {
            return e.url.name != "EffectifsPortage";
          }
          else {
            return e;
          }
        })
      }
    },
    clickListener() {
      this.panneauVisible = false
      window.removeEventListener("click", this.clickListener)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/couleurs.scss";
.plusGrand {
  transform: scale(1.75, 1.75);
}
.iconePanneau {
  font-size: 48px;
  color: black;
}
.estSelectionne {
  color: $rouge-agap !important;
}
a.disabled {
  pointer-events: none;
  opacity: 0.6;
}
i.fa-spin::before {
  animation: fa-spin 2s infinite linear;
}
.fix-bottom {
  margin-bottom: 20px;
}
</style>

<style>
#popover-menu {
  background-color: transparent;
  box-shadow: none;
}
#popover-menu > .arrow {
  left: -2px;
}
#popover-menu > .arrow:after, #popover-menu > .arrow:before {
  border-right-color: rgb(64, 78, 103);
  border-bottom-width: 10px;
  border-top-width: 10px;
  border-left-width: 0px;
  border-right-width: 13px;
}
#popover-menu .icone-mon-gre-a-gre:before, #popover-menu .icone-reclamations-et-avoirs:before, #popover-menu .icone-fournisseurs:before, #popover-menu .icone-configuration:before {
  transform: scale(1.2);
}
</style>
