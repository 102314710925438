<template>
  <div class="card" style="height:300px;overflow-y:auto;">
    <div class="card-content">
      <div class="card-body">
        <div class="widgetTitle">Actualités</div>
        <div v-if="!gotActualites" style="text-align:center;margin-top:50px;">
          <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style='height:100px;margin-left:-8px;' />
        </div>
        <div v-else-if="actualites.length">
          <a v v-for="(actualite, i) in actualites" :key="i" :href="actualite.lien" target="_blank" v-tooltip="{content: actualite.description, placement: 'bottom'}" style="display:block;">
            <div style="display:flex;align-items:center;">
              <img :src="actualite.image" style="height:60px;" />
              <b style="font-size:12px;color:black;margin-left:10px;">{{actualite.titre}}</b>
            </div>
            <hr v-if="i < actualites.length - 1" />
          </a>
        </div>
        <div v-else>
          <span style="font-size:12px;">Echec de la requête</span>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import { connecteData } from "@/mixins/connecteData"
import { formatageDate, formatHumanDateAvecSlashes } from '@/mixins/dateFormats'

export default {
  name: "WidgetActualites",
  props: [  ],
  mixins: [ formatageDate, formatHumanDateAvecSlashes, connecteData ],
  components: {  },
  data() {
    return {
      actualites: [],
      gotActualites: false,
    }
  },
  mounted() {
    this.chargerActualites()
  },
  computed: {
    
  },
  methods: {
    chargerActualites() {
      return fetch("https://agap-pro.com/feed/")
        .then(r => r.text())
        .then(r => new window.DOMParser().parseFromString(r, "text/xml"))
        .then(function (r) {
          var articles = r.querySelectorAll("item")
          if (!articles.length) {
            throw "Pas d'actualités, la requête a échoué"
          }
          articles.forEach(function (article) {
            var description = new window.DOMParser().parseFromString(article.querySelector("description").textContent, "text/html")
            var image = description.querySelector("img").srcset.split(", ")
            image = image[image.length - 1].split(" ")[0]
            this.actualites.push({
              titre: article.querySelector("title").textContent,
              lien: article.querySelector("link").textContent,
              description: description.querySelector("p").textContent,
              image: image,
              date: new Date([...article.children].find(c => c.tagName === "pubDate").textContent)
            })
          }.bind(this))
          this.$nextTick(() => this.gotActualites = true)
          return
        }.bind(this))
        .catch(e => {
          console.log(e)
          this.actualites = []
          this.gotActualites = true
        })
    },
  }
}
</script>

<style scoped>

</style>
