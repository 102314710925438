<template>
  <div style="display: inline">
    <b-button class="btn btn-danger" :size="this.size" v-tooltip="{ content: 'Déclarer un incident' }" :disabled="disabled" @click="ouvrirModal" v-b-modal="'ModalIncident'+idModal">
      <i class="fas fa-exclamation-triangle"></i>
    </b-button>

    <b-modal :id="'ModalIncident'+idModal" size="xl" hide-footer header-bg-variant="gradient-bleu" header-text-variant="light">
      <template slot="modal-title">
        <h3>Déclaration d'incident à la réception</h3>
      </template>
      <template slot="default">
        <v-select v-model="incident.raisonIncidentId" :options="optionsIncident" label='designation' placeholder="Incident..." :reduce="i => i.code" />
        <b-form-radio-group v-model="incident.typeReclamation" :options="optionsTypeDeReclamation" />
        <div v-if="incident.typeReclamation === 'TR1'" style="margin: 1rem 0">
          Date de relivraison souhaitée :
          <div class="datepicker-trigger">
            <input type="text" autocomplete="off" :id="'datepicker-trigger4'+idModal" class="datepicker" placeholder="Date..." :value="formatHumanDate(incident.dateRelivraisonSouhaitee)">
            <AirbnbStyleDatepicker :trigger-element-id="'datepicker-trigger4'+idModal" :mode="'single'" :fullscreen-mobile="true" :showShortcutsMenuTrigger="false" :monthsToShow="1" @date-one-selected="setDateDeRelivraisonSouhaitee($event)" :date-one="incident.dateRelivraisonSouhaitee" />
          </div>
        </div>
        <div v-if="incident.typeReclamation === 'TR2'" style="margin: 1rem 0">
          Montant de l'avoir :
          <div class="flex" style="align-items:center">
            <InputNumber v-model="incident.montantAvoir" typeFiltre="quantite" style="width:8rem" />
            <div style="margin-left: .5rem;font-size: 1.5rem;">€</div>
          </div>
        </div>
        <v-textarea style="margin: 1rem 0" label="Commentaire" v-model="incident.detailIncident" hint="Détails de l'incident">
        </v-textarea>
        <div style="margin: 2rem 0; text-align:right">
          <button class="btn btn-success" style="background: #d33 !important;border-color: #d33 !important;" @click="envoyerIncident(incident);closeModal('ModalIncident'+idModal)">
            Ajouter
          </button>
          <button class="btn btn-danger" style="margin-left:3rem; background: #3085d6 !important;border-color: #3085d6 !important;" @click="closeModal('ModalIncident'+idModal)">
            Annuler
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { formatageDate } from '@/mixins/dateFormats'
import { closeModal } from "@/mixins/index"
import InputNumber from '@/components/Inputs/InputNumber'
export default {
  name: "BoutonAjoutIncident",
  mixins: [formatageDate, closeModal],
  components: {
    InputNumber
  },
  props: ['idModal', 'disabled', 'donneesCommande', 'optionsIncident', 'optionsTypesDeReclamations', 'size'],
  data() {
    return {
      incident: {
        raisonIncidentId: null,
        typeReclamation: null,
        dateRelivraisonSouhaitee: null,
        montantAvoir: null,
        detailIncident: null
      }
    }
  },
  computed: {
    optionsTypeDeReclamation() {
      var options = []
      this.optionsTypesDeReclamations.forEach(option => {
        options.push({
          text: option.designation,
          value: option.code
        })
      })
      return options
    }
  },
  methods: {
    setDateDeRelivraisonSouhaitee(event) {
      this.$set(this.incident, 'dateRelivraisonSouhaitee', event)
    },
    envoyerIncident(event) {
      if (event) {
        if (event.typeReclamation !== "TR1") {
          event.dateRelivraisonSouhaitee = null
        }
        if (event.typeReclamation !== "TR2") {
          event.montantAvoir = null
        }
        this.$emit('incident', event)
      }
    },
    ouvrirModal() {
      let montantCmd = ((this.donneesCommande.lignes)
        ? this.donneesCommande.lignes.map(e => e.qDem * e.puInitial).reduce((accumultor, current) => accumultor + current)
        : this.donneesCommande.qDem * this.donneesCommande.puInitial).toFixed(3)
      let montantLivre = ((this.donneesCommande.lignes)
        ? this.donneesCommande.lignes.map(e => e.quantite * e.pu).reduce((accumultor, current) => accumultor + current)
        : this.donneesCommande.quantite * this.donneesCommande.pu).toFixed(3)
      this.incident.montantAvoir = (montantLivre > montantCmd ? montantLivre - montantCmd : 0).toFixed(3)
      this.$bvModal.show('ModalIncident' + this.idModal)
    }
  },
  mounted() {
    if (this.donneesCommande) {
      this.incident.raisonIncidentId = this.donneesCommande.raisonIncidentId
      this.incident.typeReclamation = this.donneesCommande.typeReclamation
      this.incident.dateRelivraisonSouhaitee = this.donneesCommande.dateRelivraisonSouhaitee
      this.incident.detailIncident = this.donneesCommande.detailIncident
    }
  }
}
</script>
