import Vue from "vue"
import VueSweetalert2 from 'vue-sweetalert2'
import Bugsnag from '@bugsnag/js'
Vue.use(VueSweetalert2)

export const showAlerts = {
  methods: {
    alerteSucces(message) {
      // Use sweetalert2
      this.$swal({
        title: message,
        icon: "success",
        timer: 2000,
        // showCancelButton: false, // exemple gardé pour le cancel
        showConfirmButton: false
      })
    },
    logEtMontrerAlerte(message, exception) {
      if (exception)
        Bugsnag.notify(exception)
      this.alerteErreur(message, exception)
    },
    alerteErreur(message, exception) {
      let consolemsg = message
      if (exception) {
        if (exception.erreurDejaAffichee)
          return
        consolemsg += "\n" + exception.stack
      }
      console.error(consolemsg)
      this.$swal.fire({
        icon: "error",
        //  timer: 2500,
        title: "Opération impossible",
        html: message,
        showConfirmButton: true
      })
    },
    async alerteConfirmationEnregistrement()
    {
      var result = await this.$swal.fire({
        title: "Enregistrer les modifications",
        html: "Souhaitez-vous enregistrer vos modifications en cours ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        focusCancel: true
      })
      return result.value
    },
    async alerteConfirmation(titre, message, confirm, cancel)
    {
      if (!confirm)
        confirm = "Oui"
      if (!cancel)
        cancel = "Non"
      var result = await this.$swal.fire({
        title: titre,
        html: message,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: confirm,
        cancelButtonText: cancel,
        focusCancel: true
      })
      return result.value
    },
    toastSucces(message) {
      this.$toast(message, {
        horizontalPosition: "center",
        verticalPosition: "top",
        className: ["toast-success"],
        duration: 1500
      })
    },
    toastErreur(message) {
      this.$toast(message, {
        horizontalPosition: "center",
        verticalPosition: "top",
        className: ["toast-error"],
        duration: 5000
      })
    }
  }
}