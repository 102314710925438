<template>
  <div id="app" data-app>
    <Spinner message="Traitement en cours" v-if="spinnerShown" />
    <topbar v-if="estAuthentifie && etabCourantId" />
    <menuBar v-show="estAuthentifie && etabCourantId " :key="etabCourantId" />
    <div v-if="estAuthentifie && etabCourantId" class="app-content content"><!--TODO: activer pour poc scrolling style="overflow-x:hidden;overflow-y:scroll;"-->
      <div class="content-wrapper">
        <div class="content-body">
          <router-view :key="$route.fullPath" />
        </div>
      </div>
    </div>
    <div v-else>
      <b-modal v-model ="afficherLogin"  @hide="disalowClickOutside($event)" size="xl" hide-footer hide-header :modal-class="transparentBg">
        <Login />
      </b-modal>
    </div>
  </div>
</template>
<script>
import topbar from "@/components/TopBar";
import menuBar from "@/components/MenuBar";
import { mapGetters } from "vuex";
import Spinner from '../src/components/Spinner'
import Login from '@/views/Login'
import { connecteData } from "@/mixins/connecteData"
import { objetVide } from '@/mixins/index'
import { chargerMercuriales } from '@/helpers/mercuriales'

export default {
  name: "app",
  components: { topbar, menuBar, Spinner, Login },
  mixins: [objetVide, connecteData],
  data() {
    return {

      transparentBg: ['transparent-bg'],
      notificationSystem: {
        options: {
          error: {
            position: "topRight",
            timeout: 0
          }
        }
      }
    };
  },
  watch: {
    '$route'(to /*, from*/) {
      document.title = to.meta.title || 'MercuDyn 5'
    }
  },
  mounted() {
    this.initMercuriales()
  },
  updated() {
   
  },
  computed: {
    ...mapGetters("auth", ["estAuthentifie"]),
          afficherLogin() {
            return !(this.etabCourantId && this.estAuthentifie)
          },
    
    spinnerShown() {
      return this.$store.getters['uxHelpers/spinnerShown']
    },
   
  },
  methods: {
    disalowClickOutside(e) {
      e.preventDefault()
    },
    initMercuriales() {
      if (this.estAuthentifie && this.etabCourantId) {
        chargerMercuriales(this.etabCourantId)
      }
    }
  }
}
</script>

<style lang="scss">
@import "./assets/css/style.scss";
@import "./assets/css/font-awesome5.css";
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.transparent-bg > .modal-dialog > .modal-content {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
/*html {TODO: activer pour poc scrolling
  overflow: hidden;
}*/
</style>
