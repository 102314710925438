<template>
  <div class="card" style="height:300px;">

    <b-modal id="modalInformations" size="xl" hide-footer
      header-bg-variant="gradient-bleu"
      header-text-variant="light">
      <template slot="modal-title">
        <h3>Informations</h3>
      </template>
      <template slot="default">
        <div class="row">
          <div class="col">
            <div v-for="(info, i) in informations" :key="i">
              <div class="card">
                <div class="card-header bg-gradient-accueil text-white" :style="info.couleur ? ('background-image: linear-gradient(to right, ' + info.couleur + ', ' + info.couleur + ');') : ''">
                  <div class="card-title">
                    {{info.titre}}
                  </div>
                </div>
                <div class="card-content">
                  <div class="card-body">
                    <span v-html="info.message"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>

    <div class="card-content">
      <div class="card-body">
        <div class="flex-center-space-between">
          <div class="widgetTitle">Informations</div>
          <b-button v-b-modal.modalInformations class="btn bleuAgap" style="font-size:13px;font-weight:bold;padding:2px 10px;">Voir plus</b-button>
        </div>
        <div v-if="!gotInformations" style="text-align:center;margin-top:50px;">
          <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style='height:100px;margin-left:-8px;' />
        </div>
        <div v-else style="flex-flow: column nowrap">
          <span v-for="(info, i) in resumeInformations" :key="i">
            <h6>{{info.titre}}</h6>
            <p class="info">{{info.resume}}</p>
            <hr v-if="i < 2" />
          </span>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import _ from 'lodash'
import { mapGetters } from "vuex"

export default {
  name: "WidgetInformations",
  props: [  ],
  components: {  },
  data() {
    return {
      informations: [],
      resumeInformations: [],
      gotInformations: false,
    }
  },
  mounted() {
    this.chargerInformations()
  },
  computed: {
        ...mapGetters("definitions", ["informationsAccueil"]),
  },
  methods: {
    chargerInformations() {
      this.informations = _.cloneDeep(this.informationsAccueil.messages)
      if (this.informations) {
        this.resumeInformations = _.cloneDeep(this.informationsAccueil.messages).splice(0, 3)
        this.resumeInformations.forEach((info) => {//suppression des balises html
          var div = document.createElement("div")
          div.innerHTML = info.message
          info.resume = div.textContent || div.innerText
          info.resume = info.resume.substring(0, 200)
        })
      }
      this.$nextTick(() => this.gotInformations = true)
    }
  }
}
</script>

<style scoped>
.info {
  font-size: 12px;
  width: 99%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
