import service from '@/helpers/serviceGPAO'

export function ajouterProduit(data) {
  return service.post('produits/produitsHorsMarche/ajouter/', data)
}
export function modifierProduit(produitId, data) {
  return service.patch('produits/modifier/' + produitId, data)
}
export function supprimerProduit(produitId) {
  return service.delete('produits/produitsGreAGre/supprimer/' + produitId)
}
export function obtenirProduit(produitId) {
  return service.get('produits/' + produitId)
}

export function reordonnerFamille(familleId) {
  return service.get('produits/reordonnerfamille/' + familleId)
}
export function obtenirProduitMinimum(produitId) {
  return service.get('produits/produitMinimum/' + produitId)
}
export function produitsUtilisateurCourant() {
  return service.get('produits/usercourant/').then(reponse => {
    reponse.data.forEach(x => x.designationRecherche = x.designation.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())
    return reponse;
  })
}

export function changerAffectationProduit(produitId) {
  return service.get('produits/changerAffectation/' + produitId)
}

