<template>
<div class="container-fluid">
  <div class="row match-height">
    <div class="col-lg-4 col-md-12 d-flex align-self-stretch">
			<div class="card">
				<div class="card-content">
					<div class="card-body d-flex flex-column">
						<h4 class="card-title info">Recharger les définitions</h4>
						<p class="card-text">
              Permet de récupérer les définitions suite à une modification ou un ajout.<br />
              Exemple : <em>catégorie de recette, composante menu...</em>
            </p>
            <p class="card-text">
              Vous ne serez pas déconnecté de MercuDyn 5.
            </p>
						<button class="btn mt-auto btn-primary btn-block" @click="rechargerDefinitions()">
              <i class="fas fa-eraser" style="margin-right:10px;" ref="defs"></i>
              Recharger les définitions</button>
					</div>
				</div>
			</div>
		</div>

    <div class="col-lg-4 col-md-12">
			<div class="card">
				<div class="card-content">
					<div class="card-body d-flex flex-column">
						<h4 class="card-title info">Recharger les mercuriales</h4>
						<p class="card-text">
              Permet d'actualiser les grilles tarifaires ainsi que les liens avec les produits et ingrédients de MercuDyn 5.
            </p>
            <p class="card-text">
              Vous ne serez pas déconnecté de MercuDyn 5.
            </p>
						<button class="btn mt-auto btn-primary btn-block" @click="rechargerMercuriales()">
              <i class="fas fa-eraser" style="margin-right:10px;" ref="mercs"></i>
              Recharger les mercuriales</button>
					</div>
				</div>
			</div>
		</div>

    <div class="col-lg-4 col-md-12">
			<div class="card">
				<div class="card-content">
					<div class="card-body d-flex flex-column">
						<h4 class="card-title info">Vider le cache</h4>
						<p class="card-text">
              Vider le cache du navigateur permet de rafraichir l'intégralité des données.<br />
              Action généralement réalisée sur demande du support technique.
            </p>
            <p class="card-text">
              Vous ne serez pas déconnecté de MercuDyn 5.
            </p>
						<button class="btn mt-auto btn-primary btn-block" @click="nettoyerCache()">
              <i class="fas fa-eraser" style="margin-right:10px;" ref="cach"></i>
              Vider le cache</button>
					</div>
				</div>
			</div>
		</div>
  </div>
</div>
</template>

<script>
import lscache from 'lscache'
import { connecteData } from "@/mixins/connecteData"
import { viderMercuriales } from '@/helpers/mercuriales'
import { chargerDefinitions, chargerDonneesEtablissement } from '@/helpers/definitions'
import { chargerMercuriales } from '@/helpers/mercuriales'

export default {
  name: "Configuration",
  mixins: [connecteData],
  data() {
    return {
    }
  },
  computed: {
  
  },
  methods: {
    nettoyerCache() {
      this.$refs.cach.classList.add("fa-spin")
      this.$refs.cach.parentNode.disabled = true
      viderMercuriales()
      lscache.flush()
      this.$toast("Le cache est vidé", {
        horizontalPosition: "center",
        verticalPosition: "top",
        className: ["toast-success"],
        duration: 2000
      })
      this.$refs.cach.classList.remove("fa-spin")
      this.$refs.cach.parentNode.disabled = false
    },
    rechargerDefinitions() {
      this.$refs.defs.classList.add("fa-spin")
      this.$refs.defs.parentNode.disabled = true
      return chargerDefinitions()
        .then(() => {
          this.$toast("Définitions rechargées", {
            horizontalPosition: "center",
            verticalPosition: "top",
            className: ["toast-success"],
            duration: 2000
          })
          this.$refs.defs.classList.remove("fa-spin")
          this.$refs.defs.parentNode.disabled = false
          if (this.etabCourantId) {
            chargerDonneesEtablissement(this.etabCourantId)
          }
          return
        })
    },
    rechargerMercuriales() {
      this.$refs.mercs.classList.add("fa-spin")
      this.$refs.mercs.parentNode.disabled = true
      return chargerMercuriales(this.etabCourantId, true)
        .then(() => {
          this.$toast("Mercuriales rechargées", {
            horizontalPosition: "center",
            verticalPosition: "top",
            className: ["toast-success"],
            duration: 2000
          })
          if (this.$refs.mercs) {
            this.$refs.mercs.classList.remove("fa-spin")
            this.$refs.mercs.parentNode.disabled = false
          }
          return
        })
    }
  }
}
</script>
