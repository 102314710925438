<template>
  <div class="row">
    <div class="col-4">
      <v-date-picker is-range :columns="2" :step="1" v-model="selectedDates" style="margin-right: 5px">
        <template v-slot="{ inputValue, inputEvents }">
          <div class="flex flex-col sm:flex-row justify-start items-center">
            <div class="relative flex-grow">
              <input style="height:40px;"
                class="flex-grow pl-8 pr-5 py-1 bg-gray-100 border rounded w-full text-gray-900"
                :value="inputValue.start || inputValue.end ? inputValue.start + ' - ' + inputValue.end : 'Choisir les dates...'"
                v-on="inputEvents.start"
              />
            </div>
          </div>
        </template>
      </v-date-picker>
      <span v-if="debut || fin" style="cursor:pointer;position:absolute;left:210px;top:6px;font-size:2rem;color:#d0d0d0;" v-tooltip="{ content: 'Effacer les dates'}" @click="effacerLesDates()" class="fas fa-window-close"></span>
    </div>
    <div class="col-5">
      <MultiSelect v-if="optionsMenusPlans.length" :disabled="estDesactive" :value="menusPlans" :items="optionsMenusPlans" itemText="nom" itemValue="id" placeholder="Structures de menus à inclure..." emptyText="Toutes les structures ont été ajoutées" :clearText="viderMenusPlans" :maxDisplay="3" :dropdownPosition="dropdownPosition" @blur="menusPlansChanged" />
      <!--<label v-else style="margin-left:5px"><i>Indiquer une date valide pour continuer</i></label>-->
    </div>
    <div class="col-3">
      <MultiSelect v-if="optionsServices.length" :disabled="estDesactive" :value="services" :items="optionsServices" itemText="nom" itemValue="id" placeholder="Services..." emptyText="Tous les services ont été ajoutés" :dropdownPosition="dropdownPosition" @blur="servicesChanged" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { formatageDate } from '@/mixins/dateFormats'
import { chargerMenuPlansAvecRecetteDeuxDates } from '@/api/gpao/menus'
import { connecteData } from '@/mixins/connecteData'
import MultiSelect from "@/components/Inputs/MultiSelect"

export default {
  name: "SelectDatesMenusPlansServices",
  props: {
    dateDebut: String,
    dateFin: String,
    menuPlanIds: Array,
    serviceIds: Array,
    titre: {type: String, default: 'Période de consommation...'},
    viderMenusPlans: {type: String, default: ""},
    disabled: {type: Boolean, default: false},
    dropdownPosition: {type: String, default: undefined}
  },
  components: {MultiSelect},
  mixins: [formatageDate, connecteData],
  data() {
    return {
      debut: '',
      fin: '',
      menusPlans: [],
      optionsMenusPlans: [],
      services: [],
      optionsServices: [],
      selectedDates: {
        start: new Date(this.dateDebut),
        end: new Date(this.dateFin)
      }
    }
  },
  watch: {
    selectedDates() {
      this.datesChanged()
    }
  },
  methods: {
    ...mapActions("uxHelpers", ["afficherAttente", "cacherAttente"]),
    datesChanged() {
      this.debut = this.formatDateAvecTirets(this.selectedDates.start)
      this.fin = this.formatDateAvecTirets(this.selectedDates.end)
      this.resetMenusPlansEtServices()
    },
    effacerLesDates() {
      this.dateDebut = ''
      this.dateFin = ''
      this.selectedDates = {
        start: "",
        end: ""
      }
      this.menusPlans = []
      this.optionsMenusPlans = []
      this.services = []
      this.optionsServices = []
    },
    resetMenusPlansEtServices(init) {
      if (this.debut && !this.fin) {
        this.fin = this.debut
      }
      this.optionsMenusPlans = []
      this.optionsServices = []
      this.menusPlans = []
      this.services = []
      this.afficherAttente()
      if (this.debut && this.fin) {
        chargerMenuPlansAvecRecetteDeuxDates({ etablissementId: this.etabCourantId, apiDateDebut: this.apiDate(this.debut), apiDateFin: this.apiDate(this.fin) })
          .then(function (response) {
            this.affichageUsage = {}
            if (response.data.menuPlans.length) {
              this.optionsMenusPlans = response.data.menuPlans.map(el => {
                return { id: el.id.toString(), nom: el.nom }
              })
              this.menusPlans = response.data.menuPlans.map(el => {
                return el.id.toString()
              })
              this.optionsServices = response.data.servicesPresents.map(function (el) {
                return { id: el, nom: this.$store.state.definitions.services.find(service => service.value === el).text }
              }.bind(this))
            }
            if (init) {
              this.menusPlans = this.menuPlanIds
            } else {
              this.emitChange()
            }
          }.bind(this))
          .catch(e => console.log(e))
      }
      this.cacherAttente()
    },
    menusPlansChanged(e) {
      if (e && e !== this.menusPlans) {
        this.menusPlans = e
        this.emitChange()
      }
    },
    servicesChanged(e) {
      if (e && e !== this.services) {
        this.services = e
        this.emitChange()
      }
    },
    emitChange() {
      this.$emit("changer-dms", { dateDebut: this.debut, dateFin: this.fin, menuPlanIds: this.menusPlans, serviceIds: this.services })
    }
  },
  computed: {
    estDesactive() {
      return this.disabled
    }
  },
  mounted() {
    this.debut = this.dateDebut
    this.fin = this.dateFin
    if (this.debut && this.fin) {
      this.resetMenusPlansEtServices(true)
    }
    this.menusPlans = this.menuPlanIds
    this.services = this.serviceIds
  }
}
</script>

<style lang="scss" scoped>
.datepicker {
  box-shadow: none;
  border-bottom: 1px solid #949494 !important;
  color: #5f5f5f;
  background-color: #fff;
  font-size: 1rem;
  width: 100%;
  font-size: 16px;
  height: 59.2px;
  padding: 6px 0 0 0;
  ::placeholder {
    color: rgba(0, 0, 0, 0.87);
  }
}
</style>
