<template>
  <input :id="id" ref="input"
  type="text" autocomplete="off"
  :class="['number-input', {'bordure-rouge': invalideParcequeVide}]"
  :style="disabled === true ? 'border-color:transparent!important;' : ''"
  :value="domValue"
  :required="invalideSiVide"
  :disabled="disabled === true"
  @click.stop
  @input="handleInput"
  @focus="handleFocus"
  @blur="handleBlur"
  @keyup="handleKey" 
  @keyup.enter.prevent="handleSave">
</template>

<script>
import { arrondiNombre } from '@/helpers/utils'
export default {
  name: 'InputNumber',
  // invalideSiVide : affiche une bordure si il est vide
  props: ['value', 'typeFiltre', 'id', 'zeroSiVide','invalideSiVide', 'decimales', 'disabled', 'fixBlur'],
  data() {
    return {
      domValue: this.value ? this.value.toString().replace('.', ',') : this.value,
      savedValue: this.value ? this.value.toString().replace(',', '.') : this.value,
      tmpValue: this.value ? this.value.toString().replace('.', ',') : this.value,
    }
  },
  computed: {
    invalideParcequeVide() {
      return this.invalideSiVide && !this.value && this.value !== 0
    },
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          //console.log(newValue)
          this.domValue = newValue.toString().replace('.', ',')
        })
      }
      else if (this.zeroSiVide) {
        this.$nextTick(() => this.domValue = 0)
      }/* else {
        this.$nextTick(() => this.domValue = newValue)
      }*/
    }
  },
  methods: {
    handleInput(e) {
      let value = e.target.value.replace(',', '.')
      if (this.zeroSiVide && (value === '' || isNaN(value))) {
        value = 0
      }
      if (value !== e.target.value) {
        e.target.value = value
      }
      if (this.fixBlur) {
        this.tmpValue = arrondiNombre(value, this.decimales || 3)
      } else {
        this.$emit('input', arrondiNombre(value, this.decimales || 3))
      }
    },
    handleFocus(e) {
      if (this.fixBlur) {
        this.tmpValue = arrondiNombre(e.target.value.replace(',', '.'), this.decimales || 3)
      }
      e.target.select()
    },
    handleBlur() {
      if (this.fixBlur) {
        this.$emit('input', this.tmpValue)
      }
      this.handleSave()
    },
    handleSave() {
      if (Number(this.value) !== Number(this.savedValue)) {
        this.savedValue = this.value
        this.$emit('save', this.value)
      } else {
        this.$emit('blur', this.value)
      }
    },
    handleKey(e) {
      if (e.key === "ArrowUp") {
        this.$emit("move", { direction: "up", id: this.id })
      } else if (e.key === "ArrowDown") {
        this.$emit("move", { direction: "down", id: this.id })
      } else if (e.key === "ArrowLeft" && e.target.selectionStart === 0) {
        this.$emit("move", { direction: "left", id: this.id })
      } else if ((e.key === "ArrowRight" && e.target.selectionEnd === e.target.value.length) || e.key === "Enter") {
        this.$emit("move", { direction: "right", id: this.id })
      }
    },
    filtre(typeFiltre) {
      switch (typeFiltre) {
        case 'montant':
          return function (value) {
            return /^\d*[.,]?\d{0,3}$/.test(value)
          }
        case 'quantite':
          return function (value) {
            return /^\d*[.,]?\d{0,3}$/.test(value)
          }
        case 'quantiteEntiere':
          return function (value) {
            return /^\d*$/.test(value)
          }
        default:
          throw "Filtre " + typeFiltre + " inconnu"
      }
    }
  },
  mounted() {
    let input = document.getElementById(this.id)
    if (input) {
      ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach((event) => {
        let filtreActuel = this.filtre(this.typeFiltre)
        input.filtreActuel = filtreActuel
        input.addEventListener(event, function (event) {
          let filtre = event.target.filtreActuel
          if (filtre(this.value)) {
            this.oldValue = this.value
            this.oldSelectionStart = this.selectionStart
            this.oldSelectionEnd = this.selectionEnd
          } else if ('oldValue' in this) {
            // eslint-disable-next-line vue/no-mutating-props
            this.value = this.oldValue
            this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd)
          }
        })
      }, this)
      let inputValue = document.getElementById(this.id).value
      let replacedValue = inputValue.replace('.', ',')
      document.getElementById(this.id).value = replacedValue
    }
  }
}
</script>

<style scoped>
.bordure-rouge {
 border: 1px solid red !important;
}
.number-input {
  -moz-appearance: textfield;
  width: inherit;
  text-align: right;
  padding-right: 4px;
  background: white;
  padding-left:2px;
}
.number-input:disabled {
  color: lightgrey;
  font-style: italic;
}
.number-input:read-only {
  cursor: not-allowed
}
.number-input::-webkit-inner-spin-button {
  display: none;
}
.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
