<template>
  <div class="about">
    <h1>Configurations</h1>
    <span> API : {{ baseURL }}</span>
    <div>
      <button class="btn btn-primary btn-lg btn-block btn-round" @click="nettoyerCache()">Nettoyer le cache</button>
      <button class="btn btn-primary btn-lg btn-block btn-round" @click="genererErreur()">Générer une erreur</button>
    </div>
    <h1> Dernières actions</h1>
    <div class="col-md-4">
      <b-table striped hover :items="dernieresActions">
      </b-table>
    </div>
  </div>
</template>
<script>
//import { setUserBugsnag } from "@/helpers/bugsnagM5"
import service from '@/helpers/serviceGPAO'
import { connecteData } from "@/mixins/connecteData"
import { getDernieresActions } from '@/api/gpao/user'
import lscache from 'lscache'
import { viderMercuriales } from '@/helpers/mercuriales'
import Bugsnag from '@bugsnag/js'

export default {
  name: "Configurations",
  mixins: [connecteData],
  data() {
    return {
      dernieresActions: [],
      baseURL: service.defaults.baseURL
    }
  },
  computed: {

  },
  methods: {
    genererErreur() {
      //   setUserBugsnag(this.utilisateurCourant)
      Bugsnag.notify(new Error('Test error'))
      this.$toast("Erreur générée", {
        horizontalPosition: "center",
        verticalPosition: "top",
        className: ["toast-success"],
        duration: 2000
      })

    },
    nettoyerCache() {
      viderMercuriales()
      lscache.flush()
      this.$toast("Le cache est vidé", {
        horizontalPosition: "center",
        verticalPosition: "top",
        className: ["toast-success"],
        duration: 2000
      })
    }
  },
  mounted() {
    return getDernieresActions()
      .then(response => {
        this.dernieresActions = response.data
        return
      })
  },
  destroyed() {

  }
}
</script>