
/**
 * ViewModel helper for vue.js
 */

import jQuery from 'jquery'
// eslint-disable-next-line
import pop from "@/helpers/popover"

function vm() {}


/**
Copies all properties from src to dest, overwritting on conflict
*/
vm.add = function (src, dest, readOnly) {

    var result;

    dest = dest || {};

    result = vm.clone(dest);

    if (dest && !result) {

        if (readOnly) {

            throw {
                name: "InvalidParameterException",
                message: "could not clone dest, readOnly impossible!"
            };

        }

        result = dest;

    }

    if (src) {

        vm.forEach(src, function (val, key) {

            result[key] = val;

            if (!readOnly) {

                dest[key] = val;

            }

        });

    }

    return result;

};

vm.bind = (function () {

    var params;

    function details() {

        var hasDetails;
        var isDetails;
        var popover;

        function findTargets(e) {

            var target = jQuery(e.currentTarget);

            if (target.hasClass("hasDetails")) {

                hasDetails = target;

                popover = target.siblings(".popover").first();

            } else {

                popover = target;

                hasDetails = target.siblings(".hasDetails").first();

            }

            isDetails = target.siblings(".details").first();

        }

        function bind() {

            var after;

            vm.bind();

            after = hasDetails.data("after");

            if (after) {

                vm.exec(after, hasDetails);

            }

            if (typeof params.details.onShow === "function") {

                params.details.onShow(popover);

            }

        }

        function getContent() {

            var content;

            function selfDetails() {

                var dest;

                function copy(el) {

                    var result = window.document.createElement(el.tagName);

                    var cl = el.getAttribute("class");

                    if (cl) {

                        result.setAttribute("class", cl);

                    }

                    cl = el.href;

                    if (cl) {

                        result.href = cl;

                    }

                    cl = el.getAttribute("data-title");

                    if (cl) {

                        result.setAttribute("title", cl);

                    }

                    cl = el.getAttribute("data-url");

                    if (cl) {

                        result.setAttribute("data-url", cl);

                    }

                    cl = el.getAttribute("data-after");

                    if (cl) {

                        result.setAttribute("data-after", cl);

                    }

                    if (el.children.length) {

                        vm.forEach(el.children, function (ch) {

                            result.appendChild(copy(ch));

                        });

                    } else {

                        result.innerText = el.getAttribute("data-details") || el.innerText;

                    }

                    return result;

                }

                dest = window.document.createElement(hasDetails.prop("tagName"));

                dest.setAttribute("class", hasDetails.data("selfdetails"));

                dest.innerHTML = copy(hasDetails[0]).innerHTML;

                return dest;

            }

            if (isDetails.length) {

                content = isDetails.find(".popover-content");

                if (content.length) {

                    content = content.first().html();

                } else {

                    content = isDetails.html();

                }

            } else if (hasDetails.data("selfdetails")) {

                content = selfDetails();

                content = vm.html.toStr(content);

            } else if (hasDetails.data("details")) {

                content = hasDetails.attr("data-details");

            } else {

                content = hasDetails.attr("title");

            }

            return content;

        }

        function show() {

            var content;
            var oldContent;

            content = getContent();

            oldContent = popover.find(".popover-content");

            hasDetails.popover({
                content: content,
                html: true,
                placement: hasDetails.data("placement") || "auto bottom",
                template: "<div class=\"popover show\" role=\"tooltip\"><div class=\"popover-content\"></div></div>",
                trigger: "manual"
            });

            if (oldContent.html() !== content) {

                oldContent.html(content);

                bind();

            }

            if (!hasDetails.siblings(".popover").length) {

                hasDetails.popover("show");

                bind();

            }

        }

        function hide() {

            hasDetails.popover("hide");

        }

        function onLeave(e) {

            var target;

            if (!params.details.noHover) {

                target = jQuery(e.currentTarget);

                target.removeClass("showDetails");

                findTargets(e);

                setTimeout(function () {

                    if (!hasDetails.hasClass("showDetails") && !popover.hasClass("showDetails")) {

                        hide();

                    }

                }, 10);

            }

        }

        function onEnter(e) {

            if (!params.details.noHover) {

                jQuery(".showDetails").removeClass("showDetails");

                jQuery(e.currentTarget).addClass("showDetails");

                jQuery(".popover.in").each(function (ignore, pop) {

                    pop = jQuery(pop);

                    if (!pop.siblings().addBack().hasClass("showDetails")) {

                        pop.popover("hide");

                    }

                });

                findTargets(e);

                show();

            }

        }

        function onClick(e) {

            var targets;

            var target = jQuery(e.currentTarget);

            if (target.hasClass("hasDetails")) {

                targets = target.siblings().addBack();

                findTargets(e);

                if (targets.hasClass("showDetails")) {

                    targets.removeClass("showDetails");

                    hide();

                } else {

                    jQuery(".showDetails").removeClass("showDetails");

                    target.addClass("showDetails");

                    jQuery(".popover.in").each(function (ignore, pop) {

                        pop = jQuery(pop);

                        pop.popover("hide");

                    });

                    show();

                }

            }

        }

        params.details = params.details || {};

        jQuery(".hasDetails, .popover").off("mouseleave");
        jQuery(".hasDetails, .popover").on("mouseleave", onLeave);

        jQuery(".hasDetails, .popover").off("mouseenter");
        jQuery(".hasDetails, .popover").on("mouseenter", onEnter);

        jQuery(".hasDetails").off("click");
        jQuery(".hasDetails").on("click", onClick);

    }

    function bind(params0) {

        params = params0 || params || {};

        details();

    }

    return bind;

}());

/**
Returns a clone of obj
*/
vm.clone = function (obj) {

    if (!obj) {
        return obj;
    }

    return JSON.parse(JSON.stringify(obj));

};

/**
Returns the number of properties on obj
*/
vm.count = function (obj) {

    var count = 0;

    if (obj) {

        vm.forEach(obj, function () {

            count += 1;

        });

    }

    return count;

};


/**
loops on collection

executes action on each member of collection, sorted if called with 3 parameters
*/
vm.forEach = function (collection, sort, action) {

    var data;

    function loop(keys, vals) {

        var index;
        var key;
        var val;

        var length = keys.length;

        for (index = 0; index < length; index += 1) {

            if (vals) {

                key = keys[index];

                val = vals[key];

            } else {

                key = index;

                val = keys[key];

            }

            action(val, key);

        }

    }

    if (typeof sort !== "function") {

        throw {
            name: "InvalidParameterException",
            message: "an action must be specified!"
        };

    }

    if (typeof action !== "function") {

        action = sort;

        sort = false;

    }

    if (collection === undefined) {

        throw {
            name: "InvalidParameterException",
            message: "collection is undefined!"
        };

    }

    if (typeof collection === "number") {

        data = vm.random(collection);

    } else if (typeof collection === "string") {

        data = collection.split("");

    } else {

        data = collection;

    }

    if (sort) {

        loop(vm.sort(data, sort));

    } else {

        if (typeof data.length === "number") {//array-like

            loop(data);

        } else {

            loop(Object.keys(data), data);

        }

    }

};

vm.is = (function () {

    function is(obj, type) {

        return Object.prototype.toString.call(obj) === "[object " + type + "]";

    }

    is.array = function (obj) {

        return vm.is(obj, "Array");

    };

    is.email = function (str) {

        var reg = new RegExp("^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]{2,}[.][a-z]{2,4}$");

        str = String(str);

        if (str && reg.test(str)) {

            return true;

        }

        return false;

    };

    return is;

}());

/**
tasks can be an array or an object

startTask is a Promise resolved when each task is started, with the task as its param

opt can be empty or an object containing:
    pCount: the number of tasks to be executed in parallel (defaults to 4)
    timeout: time before ending a task, in milliseconds (defaults to 10000)
    endTask: a function executed when each startTask completes, with the task and the result from startTask as its params

returns a Promise resolved when all tasks are completed
*/
vm.schedule = function (tasks, startTask, opt) {

    function pr_Schedule(r_Schedule) {

        var _tasks;

        function initTask(task) {

            _tasks.push({
                "state": 1,
                "task": task
            });

        }

        function startAll() {

            var cCount;

            function endOne(task, result) {

                task.state = 3;

                if (typeof opt.endTask === "function") {

                    opt.endTask(task.task, result);

                }

                if (startAll() === 0) {

                    r_Schedule();

                }

            }

            function startOne(task) {

                if (task.state === 1 && cCount < opt.pCount) {

                    task.state = 2;

                    if (opt.timeout) {

                        task.timeout = setTimeout(function () {

                            if (!task.finished) {

                                task.timedOut = true;

                                endOne(task, {error: true, name: "TimeoutException (" + (opt.timeout / 1000) + "s)"});

                            }

                        }, opt.timeout);

                    }

                    // eslint-disable-next-line promise/catch-or-return
                    (function () {

                        if (typeof startTask === "function") {

                            return startTask(task.task);

                        }

                        if (typeof task.task === "function") {

                            return task.task();

                        }

                        return task.task;

                    }())
                        .then(function (result) {

                            if (!task.timedOut) {

                                task.finished = true;

                                clearTimeout(task.timeout);

                                endOne(task, result);

                            }

                            return;

                        });

                    cCount += 1;

                } else if (task.state === 2) {

                    cCount += 1;

                }

            }

            cCount = 0;

            vm.forEach(_tasks, startOne);

            return cCount;

        }

        opt = opt || {};

        opt.pCount = Number(opt.pCount) || 4;

        opt.timeout = Number(opt.timeout) || 60000;

        _tasks = [];

        if (!vm.count(tasks)) {

            r_Schedule();

        } else {

            vm.forEach(tasks, initTask);

            startAll();

        }

    }

    // eslint-disable-next-line promise/avoid-new
    return new Promise(pr_Schedule);

};

/**
turns collection into an array, apply sort if provided
*/
vm.sort = function (collection, sort) {

    var data = [];

    if (collection) {

        vm.forEach(collection, function (i) {

            data.push(i);

        });

        if (typeof sort === "function") {

            data.sort(sort);

        }
        
    }

    return data;

};

vm.st = (function () {

    var st = async function (params) {
        var html;
        var opt = {
            rowHeight: params.rowHeight || 22,
            body: params.body,
            bodyHeight: params.bodyHeight,
            cols: params.cols,
            head: params.head,
            rows: params.rows,
            class: " class=\"" + (params.class ? (params.class + " ") : "") + "scroll-table\"",
            id: params.id && (" id=\"" + params.id + "\"") || "",
        };

        if (params.style) {
            opt.style = " style=\"" + params.style + "\"";
        } else {
            if (params.height) {
                opt.style = "height:" + params.height + "px;";
            } else {
                opt.style = "height:100%;";
            }
            if (params.width) {
                opt.style += "width:" + params.width + "px;";
            }
            opt.style = " style=\"" + opt.style + "\"";
        }

        if (params.drawTo) {
            st.getBody(opt);
            html = st.drawFull(opt);
//console.log(jQuery(params.drawTo).html())
//console.log(html)
            jQuery(params.drawTo).html(html);
//console.log(jQuery(params.drawTo).html())
/*await setTimeout(() => {}, 1)
console.log(jQuery(params.drawTo).html())*/
        } else {
            st.getBody(opt);
            html = st.drawSmall(opt);
        }

        return html;

    };

    st.getBody = function (opt) {
        var addRow;

        function concatRow(row) {
            opt.body += row;
        }
        function calcRow(row) {
            opt.body += opt.drawRow(row);
        }

        if (opt.rows) {
            opt.body = "";
            if (typeof opt.drawRow === "function") {
                addRow = calcRow;
            } else {
                addRow = concatRow;
            }
            vm.forEach(opt.rows, addRow);
        }

        return opt.body;
    };

    st.bind = function (params) {
        var _current;
        var els;

        function trigger() {
            var current;

            if (_current.key) {
                current = {
                    key: _current.key,
                    locked: _current.locked || false,
                    target: _current.target
                };
            } else {
                current = {};
            }
            if (params.noLock) {
                if (!_current.locked) {
                    return;
                }
                _current.locked = false;
                els.removeClass("locked");
            }
            if (typeof params.onChange === "function") {
                params.onChange(current);
            }
        }

        function enter(e) {
            var target = jQuery(e.currentTarget);
            var targets = target.parent().find("[data-key=\"" + target.data("key") + "\"]");

            if (!params.noTitle) {
                targets.attr("title", (target.hasClass("locked")
                    ? params.titleLocked
                    : params.title));
            }
            if (!_current.locked && _current.key !== target.data("key") && !params.multiLock) {
                if (_current.target) {
                    els.removeClass("watching");
                }
                targets.addClass("watching");
                _current = {
                    key: target.data("key"),
                    target: target
                };
                trigger();
            }
        }

        function click(e) {
            var target = jQuery(e.currentTarget);
            var targets = target.parent().find("[data-key=\"" + target.data("key") + "\"]");

            if (target.hasClass("locked")) {
                if (!params.noTitle) {
                    targets.attr("title", params.title);
                }
                if (params.multiLock) {
                    targets.removeClass("locked");
                } else {
                    els.removeClass("locked");
                }
                _current.locked = false;
                trigger();
            } else {
                if (!params.multiLock) {
                    els.removeClass("watching");
                    targets.addClass("watching");
                }
                if (!params.noLock) {
                    if (!params.multiLock) {
                        els.removeClass("locked");
                    }
                    targets.addClass("locked");
                    if (!params.noTitle) {
                        targets.attr("title", params.titleLocked);
                    }
                }
                _current.locked = true;
                _current.key = target.data("key");
                _current.target = target;
                trigger();
            }
        }

        _current = {};

        params = params || {};
        params.title = "Cliquez pour " + (params.title || "commander");
        params.titleLocked = "Cliquez pour " + (params.titleLocked || "débloquer");

        els = (params.selector
            ? jQuery(params.selector).find(".watchMe")
            : jQuery(".watchMe"));
        els.off("mouseenter");
        els.on("mouseenter", enter);
        els.off("click");
        els.on("click", click);
    };

    st.drawFull = function (config) {
        var html = "<div" + (config.id || "") + (config.class || "") + (config.style || "") + ">";
        html += "<div style=\"overflow-y:hidden;position:fixed;z-index:1;\">" +
            "<table class=\"table table-bordered\">" +
                "<colgroup>" + config.cols + "</colgroup>" +
                "<thead>" + config.head + "</thead>" +
                "<tbody>" + config.body + "</tbody>" +
            "</table>" +
        "</div>";
        html += "<div style=\"height:" + config.bodyHeight + "px;width:1px;z-index:0;\"></div>";
        html += "</div>";
        return html;
    };

    st.drawSmall = function (config) {
        var html = "<div" + config.id + config.class + config.style + ">";
        html += "<div>" +
            "<table class=\"table table-bordered\">" +
                "<colgroup>" + config.cols + "</colgroup>" +
                "<thead>" + config.head + "</thead>" +
            "</table>" +
        "</div>";
        html += "<div>" +
            "<table class=\"table table-bordered table-striped\">" +
                "<colgroup>" + config.cols + "</colgroup>" +
                "<tbody>" + config.body + "</tbody>" +
            "</table>" +
        "</div>";
        html += "</div>";
        return html;
    };

    return st;

}());

vm.to = (function () {

    var to = {};

    var searchMap;

    var caseMap;

    function initSearchMap() {

        var map = {
            "a": "\u00E0\u00E1\u00E2\u00E3\u00E4\u00E5",
            "ae": "\u00E6",
            "c": "\u00E7",
            "e": "\u00E8\u00E9\u00EA\u00EB",
            "i": "\u00EC\u00ED\u00EE\u00EF",
            "n": "\u00F1",
            "o": "\u00F2\u00F3\u00F4\u00F5\u00F6\u00F8",
            "oe": "\u0153",
            "u": "\u00F9\u00FA\u00FB\u00FC",
            "y": "\u00FD\u00FF"
        };

        searchMap = {};

        function extractSearch(search, find) {

            function extractLetter(letter) {

                searchMap[letter] = find;

            }

            vm.forEach(search, extractLetter);

        }

        vm.forEach(map, extractSearch);

    }

    function initCaseMap() {

        caseMap = caseMap || {

            "à": {lower: "à", upper: "À"},
            "À": {lower: "à", upper: "À"},

            "æ": {lower: "æ", upper: "Æ"},
            "Æ": {lower: "æ", upper: "Æ"},

            "ç": {lower: "ç", upper: "Ç"},
            "Ç": {lower: "ç", upper: "Ç"},

            "è": {lower: "è", upper: "È"},
            "È": {lower: "è", upper: "È"},

            "é": {lower: "é", upper: "É"},
            "É": {lower: "é", upper: "É"},

            "ê": {lower: "ê", upper: "Ê"},
            "Ê": {lower: "ê", upper: "Ê"},

            "ë": {lower: "ë", upper: "Ë"},
            "Ë": {lower: "ë", upper: "Ë"},

            "ô": {lower: "ô", upper: "Ô"},
            "Ô": {lower: "ô", upper: "Ô"},

            "ö": {lower: "ö", upper: "Ö"},
            "Ö": {lower: "ö", upper: "Ö"},

            "œ": {lower: "œ", upper: "Œ"},
            "Œ": {lower: "œ", upper: "Œ"},

            "ù": {lower: "ù", upper: "Ù"},
            "Ù": {lower: "ù", upper: "Ù"},

            "û": {lower: "û", upper: "Û"},
            "Û": {lower: "û", upper: "Û"},

            "ü": {lower: "ü", upper: "Ü"},
            "Ü": {lower: "ü", upper: "Ü"}

        };

    }

    function changeCase(str, up) {

        var dStr = str.trim();

        function useMap(o) {

            var d;

            if (up) {
                d = (caseMap[o] && caseMap[o].upper) || o;
            } else {
                d = (caseMap[o] && caseMap[o].lower) || o;
            }

            return d;

        }

        initCaseMap();

        if (up) {
            dStr = dStr.toUpperCase();
        } else {
            dStr = dStr.toLowerCase();
        }

        return dStr.replace(/[\u00E0-\u0153]/g, useMap);

    }

    to.camel = function (str, opt) {

        var oStr = String(str);

        var dStr = "";

        opt = opt || {};

        oStr = oStr.trim();

        function processWord(word) {

            function processLetter(letter, index) {

                if (index === 0) {
                    dStr += changeCase(letter, true);
                } else {
                    dStr += changeCase(letter, false);
                }

            }

            vm.forEach(word, processLetter);

            if (!opt.noSpace) {
                dStr += " ";
            }

        }

        vm.forEach(oStr.split(" "), processWord);

        return dStr.trim();

    };

    to.lower = function (str) {

        return changeCase(String(str), false);

    };

    to.search = function (str) {

        var str2 = String(str);

        if (!searchMap) {

            initSearchMap();

        }

        function useMap(a) {

            return searchMap[a] || a;

        }

        return str2.toLowerCase().replace(/[\u00E0-\u0153]/g, useMap);

    };

    to.upper = function (str) {

        return changeCase(String(str), true);

    };

    return to;

}());

vm.whereAmI = function () {

    var appVersion = window.navigator.appVersion.toLowerCase();
    var userAgent = window.navigator.userAgent.toLowerCase();
    var vendor = window.navigator.vendor.toLowerCase();

    var device;
    var os;
    var browser;
    var type;
    var version;

    //find device and os
    if (/android/.test(userAgent)) {
        os = "android";
        if (/mobile/.test(userAgent)) {
            device = "androidPhone";
        } else {
            device = "androidTablet";
        }
    } else if (/win/.test(appVersion)) {
        os = "windows";
        if (/phone/.test(userAgent)) {
            device = "windowsPhone";
        } else if (/touch/.test(userAgent)) {
            device = "windowsTablet";
        } else {
            device = "pc";
        }
    } else if (/linux/.test(appVersion)) {
        device = "pc";
        os = "linux";
    } else if (/mac/.test(appVersion)) {
        device = "pc";
        os = "mac";
    } else if (/blackberry/.test(userAgent) || (/bb10/).test(userAgent)) {
        device = "blackberry";
        os = "blackberry";
    } else if (userAgent.match(/ipad.+?os\s(\d+)/)) {
        device = "ipad";
        os = "ios";
    } else if (userAgent.match(/iphone(?:.+?os\s(\d+))?/)) {
        device = "iphone";
        os = "ios";
    } else if (userAgent.match(/ipod.+?os\s(\d+)/)) {
        device = "ipod";
        os = "ios";
    }

    //find browser
    if (/google\sinc/.test(vendor) && userAgent.match(/(?:chrome|crios)\/(\d+)/)) {
        browser = "chrome";
    } else if (userAgent.match(/(?:firefox|fxios)\/(\d+)/)) {
        browser = "firefox";
    } else if (userAgent.match(/edge\/(\d+)/)) {
        browser = "edge";
    } else if (userAgent.match(/(?:msie\s|trident.+?;\srv:)(\d+)/)) {
        browser = "ie";
    } else if (userAgent.match(/(?:^opera.+?version|opr)\/(\d+)/m)) {
        browser = "opera";
    } else if (userAgent.match(/phantomjs\/(\d+)/)) {
        browser = "phantom";
    } else if (userAgent.match(/version\/(\d+).+?safari/)) {
        browser = "safari";
    }

    //find version
    // eslint-disable-next-line no-useless-escape
    version = /chrome[\s\/]([\w.]+)/.exec(userAgent) || (/webkit[\s\/]([\w.]+)/).exec(userAgent) || (/opera(?:.*version)[\s\/]([\w.]+)/).exec(userAgent) || (/msie\s([\w.]+)/).exec(userAgent) || (/mozilla(?:.*?\srv:([\w.]+))/).exec(userAgent) || [];
    version = (version && version[1]) || "0";

    //find type
    if (device === "iphone" || device === "ipod" || device === "androidPhone" || os === "blackberry" || device === "windowsPhone") {
        type = "phone";
    } else if (device === "ipad" || device === "androidTablet" || device === "windowsTablet") {
        type = "tablet";
    } else {
        type = "computer";
    }

    return {
        browser: browser,
        device: device,
        os: os,
        type: type,
        version: version
    };

};

export default vm
