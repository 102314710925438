<template>
  <b-button class="btn btn-light push-right-1rem" style="position:relative;" :disabled="enImpression" @click="generer()" v-tooltip="{ content: 'Imprimer le bon de commande en un tableau', placement: 'top' }">
    <i class="fas fa-print"></i><span style="position:absolute;bottom:0;right:1px;">1</span>
  </b-button>
</template>

<script>
import { connecteData } from '@/mixins/connecteData'
import { arrondiNombre } from '@/helpers/utils'
import { mapGetters } from "vuex"
import { xlsx } from "@/mixins/xlsx"
import { formatHumanDateAvecSlashes } from "@/mixins/dateFormats"

export default {
  name: "BoutonImpressionCommande",
  mixins: [connecteData, xlsx, formatHumanDateAvecSlashes],
  props: {
    commande: {type: Object, required: true}
  },
  data() {
    return {
      enImpression: false
    }
  },
  computed: {
    ...mapGetters("fournisseurs", ["nomCourtFournisseur"]),
    ...mapGetters("definitions", ["obtenirFamille"]),
  },
  methods: {
    generer() {
      this.enImpression = true

      var allBorders = {//styles
				left: {style: "thin", color: {auto: 1}},
				right: {style: "thin", color: {auto: 1}},
				top: {style: "thin", color: {auto: 1}},
				bottom: {style: "thin", color: {auto: 1}}
      }
      var styles = {
        bold: {
          font: {bold: true}
        },
        boldRight: {
          alignment: {horizontal: "right"},
          font: {bold: true}
        },
        right: {
          alignment: {horizontal: "right"}
        },
        head: {
          border: allBorders,
					fill: {fgColor: {rgb: "FF808080"}},
					font: {sz: 11, bold: true, color: {rgb: "FFFFFFFF"}}
        },
        cell: {
          border: allBorders,
          font: {sz: 11}
        },
        firstCell: {
          border: {
            left: {style: "thin", color: {auto: 1}},
            right: {style: "thin", color: {auto: 1}},
            top: {style: "medium", color: {auto: 1}},
            bottom: {style: "thin", color: {auto: 1}}
          },
          font: {sz: 11}
        }
      }
      var worksheet = {
        "!pageSetup": {
          scale: 80,
          orientation: 'landscape'
        },
        "!cols": [
          {wch: 11},
          {wch: 5},
          {wch: 9},
          {wch: 25},
          {wch: 20},
          {wch: 20},
          {wch: 8},
          {wch: 8},
          {wch: 10},
          {wch: 10},
          {wch: 10}
        ],
        "!merges": [{
          s: {c: 0, r: 5},
          e: {c: 10, r: 6}
        }, {
          s: {c: 3, r: 8},
          e: {c: 4, r: 8}
        }],
        "A1": {
					v: this.etabCourant.eRS || "",
					t: "s",
					s: styles.bold
				},
        "A2": {
					v: this.etabCourant.adresse1 || "",
					t: "s"
				},
        "A3": {
					v: this.etabCourant.adresse2 || "",
					t: "s"
				},
        "A4": {
					v: this.etabCourant.adresse3 || "",
					t: "s"
				},
        "A5": {
					v: this.etabCourant.codePostal + " " + this.etabCourant.ville,
					t: "s"
				},
        "A6": {
					v: this.etabCourant.commentairePourToutFournisseur || "",
					t: "s",
          s: {
            alignment: {vertical: "center", wrapText: true},
            border: allBorders
          }
				},
        "A7": {v: "", t: "s", s: {border: allBorders}},
        "B6": {v: "", t: "s", s: {border: allBorders}},
        "B7": {v: "", t: "s", s: {border: allBorders}},
        "C6": {v: "", t: "s", s: {border: allBorders}},
        "C7": {v: "", t: "s", s: {border: allBorders}},
        "D6": {v: "", t: "s", s: {border: allBorders}},
        "D7": {v: "", t: "s", s: {border: allBorders}},
        "E6": {v: "", t: "s", s: {border: allBorders}},
        "E7": {v: "", t: "s", s: {border: allBorders}},
        "F6": {v: "", t: "s", s: {border: allBorders}},
        "F7": {v: "", t: "s", s: {border: allBorders}},
        "G6": {v: "", t: "s", s: {border: allBorders}},
        "G7": {v: "", t: "s", s: {border: allBorders}},
        "H6": {v: "", t: "s", s: {border: allBorders}},
        "H7": {v: "", t: "s", s: {border: allBorders}},
        "I6": {v: "", t: "s", s: {border: allBorders}},
        "I7": {v: "", t: "s", s: {border: allBorders}},
        "J6": {v: "", t: "s", s: {border: allBorders}},
        "J7": {v: "", t: "s", s: {border: allBorders}},
        "K6": {v: "", t: "s", s: {border: allBorders}},
        "K7": {v: "", t: "s", s: {border: allBorders}},
        "D2": {
					v: this.etabCourant.tel || "",
					t: "s"
				},
        "D3": {
					v: this.etabCourant.contact || "",
					t: "s"
				},
        "K1": {
          v: this.commande.nom,
					t: "s",
          s: styles.boldRight
        },
        "K4": {
          v: this.commande.nbLignes + " lignes",
					t: "s",
          s: styles.right
        },
        "A9": {
          v: "FOURN.",
					t: "s",
          s: styles.head
        },
        "B9": {
          v: "FAM.",
					t: "s",
          s: styles.head
        },
        "C9": {
          v: "REF.",
					t: "s",
          s: styles.head
        },
        "D9": {
          v: "DESIGNATION",
					t: "s",
          s: styles.head
        },
        "E9": {v: "", t: "s", s: styles.head},
        "F9": {
          v: "MARQUE",
					t: "s",
          s: styles.head
        },
        "G9": {
          v: "UC",
					t: "s",
          s: styles.head
        },
        "H9": {
          v: "QTE",
					t: "s",
          s: styles.head
        },
        "I9": {
          v: "PU HT",
					t: "s",
          s: styles.head
        },
        "J9": {
          v: "TOTAL HT",
					t: "s",
          s: styles.head
        },
        "K9": {
          v: "LIVRAISON",
					t: "s",
          s: styles.head
        }
      }

      var iLigne = 10
      this.commande.commandesFournisseurs.slice()
        .sort((a, b) => (a.dateLivraison || a.dateLivraisonSouhaitee) > (b.dateLivraison || b.dateLivraisonSouhaitee) ? 1 : -1)
        .forEach(fourn => {
          var nomFourn = this.nomCourtFournisseur(fourn.fournisseurId)
          fourn.lignes.filter(ligne => ligne.qDem).forEach((ligne, iLF) => {
            worksheet["A" + iLigne] = {
              v: nomFourn || "",
              t: "s",
              s: iLF ? styles.cell : styles.firstCell
            }
            worksheet["B" + iLigne] = {
              v: this.obtenirFamille(ligne.faId).code,
              t: "s",
              s: iLF ? styles.cell : styles.firstCell
            }
            worksheet["C" + iLigne] = {
              v: ligne.Ref || "",
              t: "s",
              s: iLF ? styles.cell : styles.firstCell
            }
            worksheet["D" + iLigne] = {
              v: ligne.dFou || "",
              t: "s",
              s: iLF ? styles.cell : styles.firstCell
            }
            worksheet["E" + iLigne] = {
              v: "",
              t: "s",
              s: iLF ? styles.cell : styles.firstCell
            }
            worksheet["F" + iLigne] = {
              v: ligne.marque || "",
              t: "s",
              s: iLF ? styles.cell : styles.firstCell
            }
            worksheet["G" + iLigne] = {
              v: ligne.uc || "",
              t: "s",
              s: iLF ? styles.cell : styles.firstCell
            }
            worksheet["H" + iLigne] = {
              v: ligne.qDem,
              t: "n",
              s: iLF ? styles.cell : styles.firstCell
            }
            worksheet["I" + iLigne] = {
              v: arrondiNombre(ligne.pu),
              t: "n",
              s: iLF ? styles.cell : styles.firstCell
            }
            worksheet["J" + iLigne] = {
              v: arrondiNombre(ligne.pu * ligne.qDem),
              t: "n",
              s: iLF ? styles.cell : styles.firstCell
            }
            worksheet["K" + iLigne] = {
              v: this.formatHumanDateAvecSlashes(fourn.dateLivraison || fourn.dateLivraisonSouhaitee),
              t: "s",
              s: iLF ? styles.cell : styles.firstCell
            }
            worksheet["!merges"].push({
              s: {c: 3, r: iLigne - 1},
              e: {c: 4, r: iLigne - 1}
            })
            iLigne += 1
          })
        })

      this.xlsx.saveFile(worksheet, this.commande.nom.replaceAll("/", "-").replaceAll("\\", "-").replaceAll("?", "-"))
      this.enImpression = false
    }
  }
}
</script>
