import service from '@/helpers/serviceGPAO'

export function chargerCommandes(etablissementId, params) {
  return service.post('/commandes/liste/' + etablissementId, params);
}

export function detailsCommande(id) {
  return service.get('commandes/' + id)
}

export function ajouterCommande(etablissementId, params) {
  return service.post('commandes/ajouter/' + etablissementId, params)
}

export function ajouterCommandeDepuisBesoin(etablissementId, data) {
  return service.post('commandes/ajouterDepuisBesoin/' + etablissementId, data)
}

export function modifierCommande(idCommande, data) {
  return service.put('commandes/modifier/' + idCommande, data)
}

export function ajouterLigneCommande(commande, idTarif, qteDemandee) {
  return service.post('commandes/modifier/lignes/ajouter/' + idTarif + '/' + qteDemandee, commande)
}

export function substituerLigneCommande(commande, idLigne, idTarif) {
  return service.post('commandes/modifier/lignes/substituer/' + idLigne + '/' + idTarif, commande)
}

export function recalculerCommande(commande) {
  return service.post('commandes/recalculer', commande)
}

export function receptionnerCommande(idCommande, data) {
  return service.put('commandes/receptionner/' + idCommande, data)
}

export function envoyerCommandeGlobale(idCommande) {
  return service.patch('commandes/envoyer/' + idCommande)
}

export function envoyerCommandeFournisseurs(idCommande, data) {
  return service.post('commandes/envoyer/' + idCommande, data)
}

export function articleFavori(produitId, fournisseurId) {
  let parametres = produitId
  if (fournisseurId)
    parametres += "/" + fournisseurId
  return service.patch('produits/modifierfavori/' + parametres)
}

export function articleFavoriRecette(produitId, recetteId, fournisseurId) {
  let parametres = produitId
  parametres += "/" + recetteId
  if (fournisseurId)
    parametres += "/" + fournisseurId
  return service.patch('produits/modifierfavorirecette/' + parametres)
}

export function renommerCommande(idCommande, nomCommande) {
  return service.patch('commandes/modifier/' + idCommande + '/' + nomCommande)
}

export function changerDateLivraison(idCommande, fournisseurId, date /* AAAAMMJJ*/) {
  return service.patch('commandes/changerDateLivraison/' + idCommande + '/' + fournisseurId + '/' + date)
}

export function supprimerCommande(idCommande) {
  return service.delete('commandes/supprimer/' + idCommande)
}

export function dupliquerCommande(idCommande, optionsDuplication) {
  return service.put('commandes/dupliquer/' + idCommande, optionsDuplication)
}

export function obtenirDatesLivrablesApres(idCommande, dateDebutSouhaitee) {
  return service.get('commandes/datesLivraisonsApres/' + idCommande + '/' + dateDebutSouhaitee)
}

export function envoyerBC(idCommande, data) {
  return service.post('commandes/envoyerBDC/' + idCommande, data)
}