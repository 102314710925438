<template>
  <div class="container-fluid">

    <b-modal id="modalDetailsFourn" :size="fournisseurCourantLocal || mode === 'creation' ? 'xl' : 'md'" hide-footer no-close-on-backdrop header-bg-variant="gradient-bleu" header-text-variant="light">
      <template slot="modal-title">
        <h3><i v-if="fournisseurCourantLocal" class='icone-fournisseurLocal mr-2'/>
        <template v-if="currentFournisseurId==0">
          Ajouter un fournisseur local
        </template>
        <template v-else>
          {{fournisseurNom(currentFournisseurId)}}
        </template>
        </h3>
      </template>
      <template slot="default">
        <ParametresDepot :fournisseurId="currentFournisseurId" :mode="mode" @add-fourn="addFourn($event)" @save-fourn="saveFourn($event)" @hide-fourn="hideFourn()" />
      </template>
    </b-modal>

    <div class="row">
      <div class="col-12 col-lg-3">
        <div v-if="autorisationCreationFournisseur" class="card" :ref="'topbar'">
          <div class="card-body">
            <div class="card-text flex-center-space-between">
              Ajouter un fournisseur
              <button type="button" class="btn btn-primary" @click="ajouterFournisseur()"><i class="fas fa-plus"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="card" :ref="'topbar'">
          <div class="card-body" v-tooltip="!vueParFamille && {content: 'Fonction uniquement disponible en vue par famille', placement: 'bottom'}">
            <div class="card-text flex-center-space-between">
              Cliquez sur un fournisseur pour éditer ses informations et le rendre actif / inactif
              <a v-if="!tousActifs" :class="'btn btn-primary btnAddAllAll ml-1' + (vueParFamille ? '' : ' disabled')"><span class="glyphicon glyphicon-ok"></span>Tout activer</a>
              <a v-if="tousActifs" :class="'btn btn-primary btnDelAllAll ml-1' + (vueParFamille ? '' : ' disabled')"><span class="glyphicon glyphicon-remove"></span>Tout désactiver</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-3">
        <div class="card" :ref="'topbar'">
          <div class="card-body">
            <div class="card-text flex-center-space-between">
              <ToggleButton v-model="vueParFamille" :labels="{checked: 'Vue par famille', unchecked: 'Vue générale'}" :color="{checked: '#025DBF', unchecked: '#2c3e50'}" :height="30" :width="150" v-tooltip="{content: 'Modifier le type d\'affichage', placement: 'bottom'}" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid" :key="keyMain">

      <b-container v-if="isLoading">
        <b-row>
          <b-col></b-col>
          <b-col>
            <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" />
          </b-col>
          <b-col></b-col>
        </b-row>
      </b-container>

      <div v-else>

        <div v-if="vueParFamille" class="row f" style="margin-left:-30px;margin-right:-30px">
          <div v-for="(famille, indexFamille) in familles" :key="indexFamille" class="choix-famille col-12 col-lg-6 col-xl-3" :data-idfamille="famille.familleId" style="padding-left:15px;padding-right:15px;margin-bottom:1rem">
            <div class="card h-100">
              <div class="card-header">
                <h4 class="card-title">
                  <iconeFamilleIngredient :familleId="famille.familleId" /> {{famille.familleNom}}</h4>
              </div>
              <div class="card-body">
                <div v-if="enMaj" style="width:100%;height:100%">
                  <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style="display: block;    margin-left: auto;    margin-right: auto" />
                </div>
                <template v-else>
                  <div v-for="(choix, indexChoix) in famille.choixFournisseurs" :key="indexChoix" :data-idfourn="choix.fournisseurId" style="display:inline">
                    <a :class="'m-1 btn btn-' + (choix.actif ? (nonParametre(choix.fournisseurId) ? 'danger' : 'primary') : 'secondary') + ' hasDetails'" :tabIndex="choix.tabIndex" @blur="blur">
                      <i v-if="fournisseurEstLocal(choix.fournisseurId)" class='icone-fournisseurLocal'/>
                      {{choix.fournisseurNom}}
                    </a>
                    <div class="details" style="display:none;">
                      <a class="btnDelOne" v-if="choix.actif">Rendre inactif</a>
                      <a class="btnAddOne" v-else>Rendre actif</a>
                      <a class="btnEdit"><i class="fa fa-pencil-alt"></i> Editer les données fournisseur</a>
                      <a class="btnAddOnly">Activer uniquement ce fournisseur</a>
                      <a class="btnAddAll">Activer tous les fournisseurs</a>
                      <a class="btnAddOneAll">Activer ce fournisseur pour toutes les familles</a>
                      <a class="btnDelOneAll">Désactiver ce fournisseur pour toutes les familles</a>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="row g">
          <div class="row">
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6" v-for="fourn in fournisseursVueGenerale" :key="fourn.id">
              <div class="card" @click="drawFourn({idFourn: fourn.id})" style="cursor:pointer;heigth:160px;">
                <div :class="'card-header text-white flex-center-space-between ' + (nonParametre(fourn.id) ? 'bg-gradient-rouge' : 'bg-gradient-bleu')">
                  <span style="font-weight:bold"><i v-if="fournisseurEstLocal(fourn.id)" class='icone-fournisseurLocal mr-2' />{{fourn.nom}}</span><i :class="fourn.favori ? 'fa fa-star' : 'fa fa-star-o'" style="cursor:pointer;margin-left:1rem"></i>
                </div>
                <div class="card-body">
                  <p style="font-size:12px;margin-bottom:5px;font-weight:bold">Franco : {{francoPort(fourn.id)}}</p>
                  <p style="font-size:12px;margin-bottom:5px;font-weight:bold">Dépôt : {{nomDepot(fourn.id)}}</p>
                  <p style="font-size:12px;margin-bottom:5px;font-weight:bold">Livre le : {{joursDeLivraison(fourn.id)}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import { getPrefFournisseurs, setPrefFournisseur, setInfosFournisseur } from '@/api/gpao/user'
import { ajouterFournisseurLocal, modifierFournisseurLocal } from '@/api/gpao/fournisseurs'
import { invaliderCache } from "@/helpers/mercuriales"
import vm from "@/helpers/vm-toolkit-vue"
import { connecteData } from "@/mixins/connecteData"
import jquery from "jquery"
import { showAlerts } from "@/mixins/alerts"
import ParametresDepot from '@/components/Divers/ParametresDepot'
import { fournisseurs } from '@/mixins/fournisseurs'
import iconeFamilleIngredient from '@/components/Divers/IconeFamilleIngredient'
import { USER_INFO } from "@/store/actions/user"
import { mapGetters } from 'vuex'
import { arrondiMercuriale } from '@/helpers/utils'

export default {
  name: "PageFournisseur",
  components: { ParametresDepot, iconeFamilleIngredient },
  mixins: [connecteData, showAlerts, fournisseurs],
  data() {
    return {
      keyMain: 1,
      isLoading: false,
      enMaj: false,
      tousActifs: false,
      familles: [],
      currentFournisseurId: 0,
      mode: "edition",
      vueParFamille: false
    }
  },
  computed: {
    ...mapGetters("auth",["isUtilisateur"]),
    ...mapGetters("fournisseurs", ["fournisseursCourants"]),
    utilisateurCourant() {
      return this.$store.state.user.userInfo
    },
    fournisseurCourantLocal() {
      if (this.currentFournisseurId==0)
        return true // ne doit arriver que sur l'ajout d'un fournisseur local
      return this.fournisseurEstLocal(this.currentFournisseurId)
    },
    autorisationCreationFournisseur() {
      if (this.isAdmin)
        return true
      if (this.isUtilisateur && this.utilisateurCourant.aOptionFournisseursLocaux)
        return true
      return false
    },
    fournisseursVueGenerale() {
      var fourns = []
      this.fournisseursCourants.forEach(f => {
        if (this.etabCourant.choixFournisseurs.find(c => c.actif && c.foId === f.id)) {
          fourns.push(f)
        }
      })
      fourns.sort((a, b) => a.nom > b.nom ? 1 : -1)
      return fourns
    }
  },
  methods: {
    fournisseurEstLocal(fournisseurId) {
      if (!fournisseurId)
        return false
      let fournisseur = this.parametresFournisseur(fournisseurId, this.etabCourantId)
      return fournisseur.estLocal
    },

    francoPort(fournisseurId) {
      let fournisseur = this.fournisseursCourants.find(d => d.id === fournisseurId)
      return arrondiMercuriale(fournisseur.francoPort, 2) + " €"
    },

    nomDepot(fournisseurId) {
      let fournisseur = this.parametresFournisseur(fournisseurId, this.etabCourantId)
      var depot = fournisseur.depots.find(d => d.id === Number(fournisseur.dfId))
      if (depot) {
        return depot.nom
      }
      return ""
    },

    joursDeLivraison(fournisseurId) {
      let fournisseur = this.parametresFournisseur(fournisseurId, this.etabCourantId)
      return fournisseur.Jours === "Lu,Ma,Me,Je,Ve,Sa,Di" ? "Tous les jours" : fournisseur.Jours || ""
    },

    nonParametre(fournisseurId) {
      let fournisseur = this.parametresFournisseur(fournisseurId, this.etabCourantId)
      return !fournisseur.Jours
    },

    ajouterFournisseur() {
      this.mode = "creation"
      this.currentFournisseurId = 0
      this.$bvModal.show('modalDetailsFourn')
    },

    async updateMain() {
      const r = await getPrefFournisseurs(this.etabCourantId)
      var tabIndex = 1
      this.familles = r.data
      this.tousActifs = true
      vm.forEach(this.familles, function (famille) {
        vm.forEach(famille.choixFournisseurs, function (choix) {
          if (!choix.actif) {
            this.tousActifs = false
          }
          choix.tabIndex = tabIndex
          tabIndex += 1
        }.bind(this))
      }.bind(this))
      this.keyMain += 1
      this.$store.commit("uxHelpers/hideSpinner")
    },

    bindMain() {
      vm.bind({
        details: {
          noHover: true,
          onShow: () => this.$nextTick(() => this.bindMenu())
        }
      })
      window.onscroll = this.bindMenu
      this.bindMenu()
    },

    drawFourn(e) {
      if (e.idFourn) {
        this.currentFournisseurId = e.idFourn
      } else {
        this.currentFournisseurId = this.getIDs(e).idFourn
      }
      this.mode = "edition"
      this.$bvModal.show('modalDetailsFourn')
    },

    async addFourn(fourn) {
      this.hideFourn()
      try {
        this.isLoading = true
        let response = await ajouterFournisseurLocal(this.utilisateurCourant.id, fourn)
        fourn.foId = response.data.foId
        fourn.eId = this.etabCourantId
        fourn.dfId=response.data.depotId
        await setInfosFournisseur(fourn)
        this.reinitialiserAffichage()
        this.$toast('Création enregistrée', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          className: ['toast-success'],
          duration: 1500
        })
      } finally {
        this.keyMain += 1
        this.isLoading = false
      }
    },
    async saveFourn(fourn) {
      fourn.dfId = Number(fourn.dfId)
      this.enMaj = true
      this.hideFourn()
      try {
        if (fourn.estLocal) {
          await modifierFournisseurLocal(this.utilisateurCourant.id, fourn)
        }
        await setInfosFournisseur(fourn)
        /*lscache.flush()
        await this.$store.dispatch("user/" + USER_INFO, null) // mise de toutes les infos etab TODO a voir si on peut pas l'enlever
        await this.$store.dispatch('fournisseurs/listeFournisseurs',this.etabCourantId ) // mise a jour de la liste des fournisseurs courants
        this.updateMain() // mise a jour de l'affichage       */
        this.$store.commit('user/setUserInfo', fourn)
        this.reinitialiserAffichage()
        this.keyMain += 1
        this.$toast('Modifications enregistrées', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          className: ['toast-success'],
          duration: 1500
        })
      } finally {
        this.keyMain += 1
        this.maj = false;
      }
    },
    hideFourn() {
      this.$bvModal.hide('modalDetailsFourn')
    },
    async reinitialiserAffichage() {

      await this.$store.dispatch("user/" + USER_INFO, null) // mise de toutes les infos etab TODO a voir si on peut pas l'enlever
      await this.$store.dispatch('fournisseurs/listeFournisseurs', this.etabCourantId) // mise a jour de la liste des fournisseurs courants
      this.updateMain() // mise a jour de l'affichage       
      this.enMaj = false
    },
    blur(e) {
      setTimeout(function () {
        this.hideMenu(e.target)
      }.bind(this), 200)
    },
    hideMenu(target) {

      var popover = jquery(target).siblings().addBack().closest(".popover")

      popover.siblings().addBack().removeClass("showDetails")

      popover.popover("hide")

    },
    getIDs(e, keepMenu) {

      var target = jquery(e.currentTarget)

      var idFourn = Number(target.closest("[data-idfourn]").attr("data-idfourn"))

      var idFamille = Number(target.closest("[data-idfamille]").attr("data-idfamille"))

      if (!keepMenu) {

        this.hideMenu(target)

      }

      return { idFourn, idFamille }

    },
    bindMenu() {

      var btnAddOne = jquery(".btnAddOne")
      btnAddOne.off("click")
      btnAddOne.on("click", this.addOne)

      var btnDelOne = jquery(".btnDelOne")
      btnDelOne.off("click")
      btnDelOne.on("click", this.delOne)

      var btnEdit = jquery(".btnEdit")
      btnEdit.off("click")
      btnEdit.on("click", this.drawFourn)

      var btnAddOnly = jquery(".btnAddOnly")
      btnAddOnly.off("click")
      btnAddOnly.on("click", this.addOnly)

      var btnAddAll = jquery(".btnAddAll")
      btnAddAll.off("click")
      btnAddAll.on("click", this.addAll)

      var btnAddOneAll = jquery(".btnAddOneAll")
      btnAddOneAll.off("click")
      btnAddOneAll.on("click", this.addOneAll)

      var btnDelOneAll = jquery(".btnDelOneAll")
      btnDelOneAll.off("click")
      btnDelOneAll.on("click", this.delOneAll)

      var btnAddAllAll = jquery(".btnAddAllAll")
      btnAddAllAll.off("click")
      btnAddAllAll.on("click", e => this.alerteConfirmation("Activer tous les fournisseurs", "Souhaitez-vous activer tous les fournisseurs pour toutes les familles ?", "Continuer", "Annuler")
        .then(result => {
          if (!result) {
            return
          }
          return this.addAllAll(e)
        })
      )

      var btnDelAllAll = jquery(".btnDelAllAll")
      btnDelAllAll.off("click")
      btnDelAllAll.on("click", e => this.alerteConfirmation("Désactiver tous les fournisseurs", "Souhaitez-vous désactiver tous les fournisseurs pour toutes les familles ?", "Continuer", "Annuler")
        .then(result => {
          if (!result) {
            return
          }
          return this.delAllAll(e)
        })
      )

    },

    addOne(e) {

      var params
      var after
      var ids

      if (e.idFourn && e.idFamille) {//appelé par une fonction, on renvoie une promise

        params = {
          etablissementId: this.etabCourantId,
          familleId: e.idFamille,
          fournisseurId: e.idFourn,
          actif: true
        }

        invaliderCache(e.idFamille)

      } else {//appelé par le DOM, on recharge les choix et on dessine

        ids = this.getIDs(e)

        params = {
          etablissementId: this.etabCourantId,
          familleId: ids.idFamille,
          fournisseurId: ids.idFourn,
          actif: true
        }

        invaliderCache(ids.idFamille)

        after = this.updateMain

      }

      return setPrefFournisseur(params)
        .then(() => {
          this.$store.commit('user/setPrefFournisseur', params)
          this.$toast('Modification enregistrée', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            className: ['toast-success'],
            duration: 1500
          })
          return after && after() || Promise.resolve()
        })
        .catch(e => {
          console.error(e)
          this.$toast("Echec de l'enregistrement", {
            horizontalPosition: "center",
            verticalPosition: "top",
            className: ["toast-error"],
            duration: 2000
          })
          return after && after() || Promise.resolve()
        })

    },
    delOne(e) {

      var params
      var after
      var ids

      if (e.idFourn && e.idFamille) {//appelé par une fonction, on renvoie une promise

        params = {
          etablissementId: this.etabCourantId,
          familleId: e.idFamille,
          fournisseurId: e.idFourn,
          actif: false
        }

        invaliderCache(e.idFamille)

      } else {//appelé par le DOM, on recharge les choix et on dessine

        ids = this.getIDs(e)

        params = {
          etablissementId: this.etabCourantId,
          familleId: ids.idFamille,
          fournisseurId: ids.idFourn,
          actif: false
        }

        invaliderCache(ids.idFamille)

        after = this.updateMain

      }

      return setPrefFournisseur(params)
        .then(() => {
          this.$store.commit('user/setPrefFournisseur', params)
          this.$toast('Modification enregistrée', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            className: ['toast-success'],
            duration: 1500
          })
          return after && after() || Promise.resolve()
        })
        .catch(e => {
          console.error(e)
          this.$toast("Echec de l'enregistrement", {
            horizontalPosition: "center",
            verticalPosition: "top",
            className: ["toast-error"],
            duration: 2000
          })
          return after && after() || Promise.resolve()
        })

    },
    addOnly(e) {

      var tasks = []

      var ids = this.getIDs(e)

      vm.forEach(this.familles, function (famille) {

        if (famille.familleId === ids.idFamille) {

          vm.forEach(famille.choixFournisseurs, function (choix) {

            if (choix.fournisseurId === ids.idFourn) {

              tasks.push(this.addOne({
                idFamille: choix.familleId,
                idFourn: choix.fournisseurId
              }))

            } else {

              tasks.push(this.delOne({
                idFamille: choix.familleId,
                idFourn: choix.fournisseurId
              }))

            }

          }.bind(this))

        }

      }.bind(this))

      return Promise.all(tasks)
        .then(this.updateMain)

    },
    addAll(e) {

      var tasks = []

      var ids = this.getIDs(e)

      vm.forEach(this.familles, function (famille) {

        if (famille.familleId === ids.idFamille) {

          vm.forEach(famille.choixFournisseurs, function (choix) {

            tasks.push(this.addOne({
              idFamille: choix.familleId,
              idFourn: choix.fournisseurId
            }))

          }.bind(this))

        }

      }.bind(this))

      return Promise.all(tasks)
        .then(this.updateMain)

    },
    addOneAll(e) {

      var tasks = []

      var ids = this.getIDs(e)

      vm.forEach(this.familles, function (famille) {

        vm.forEach(famille.choixFournisseurs, function (choix) {

          if (choix.fournisseurId === ids.idFourn) {

            tasks.push(this.addOne({
              idFamille: choix.familleId,
              idFourn: choix.fournisseurId
            }))

          }

        }.bind(this))

      }.bind(this))

      return Promise.all(tasks)
        .then(this.updateMain)

    },
    delOneAll(e) {

      var tasks = []

      var ids = this.getIDs(e)

      vm.forEach(this.familles, function (famille) {

        vm.forEach(famille.choixFournisseurs, function (choix) {

          if (choix.fournisseurId === ids.idFourn) {

            tasks.push(this.delOne({
              idFamille: choix.familleId,
              idFourn: choix.fournisseurId
            }))

          }

        }.bind(this))

      }.bind(this))

      return Promise.all(tasks)
        .then(this.updateMain)

    },
    addAllAll() {

      this.$store.commit("uxHelpers/showSpinner")

      var tasks = []

      vm.forEach(this.familles, function (famille) {

        vm.forEach(famille.choixFournisseurs, function (choix) {

          tasks.push({
            idFamille: choix.familleId,
            idFourn: choix.fournisseurId
          })

        })

      })

      return vm.schedule(tasks, this.addOne)
        .then(this.updateMain)

    },
    delAllAll() {

      this.$store.commit("uxHelpers/showSpinner")

      var tasks = []

      vm.forEach(this.familles, function (famille) {

        vm.forEach(famille.choixFournisseurs, function (choix) {

          tasks.push({
            idFamille: choix.familleId,
            idFourn: choix.fournisseurId
          })

        })

      })

      return vm.schedule(tasks, this.delOne)
        .then(this.updateMain)

    }
  },
  created() {
    this.updateMain()
  },
  updated() {
    this.bindMain()
  },
  beforeRouteLeave(to, from, next) {
    window.onscroll = undefined
    next()
  }
}
</script>

<style scoped>

</style>

<style>
.choix-famille .popover.bottom,
.filter .popover.bottom {
  margin-top: 0;
}
.popover {
  background-clip: padding-box;
  border: 0;
  border-radius: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  display: table !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.42857143;
  margin: 0;
  max-width: 276px;
  padding: 0;
  position: absolute;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  z-index: 1060;
}
.fade.in {
  opacity: 1;
}
.choix-famille .popover-content,
.filter .popover-content {
  padding: 5px 15px;
  background-color: #fff;
  display: table;
  white-space: nowrap;
}
.choix-famille .popover-content a {
  color: black;
  padding: 10px 20px;
}
.popover-content a {
  cursor: pointer;
  display: block;
  position: relative;
}
.choix-famille .popover-content a:focus,
.choix-famille .popover-content a:hover {
  background-color: #dadada !important;
  outline: 0;
  text-decoration: none;
}
#modalDetailsFourn .label-input {
  margin: 6px;
}
</style>
