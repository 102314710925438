<template>
  <div style="display:inline">
    <img :src="'/assets/images/icones/' + familleCode + couleurChoisie + '.png'" alt="" style="width:32px"
    v-tooltip="{ content: familleNomComplet(), placement: 'top' }"
    @click="$root.$emit('bv::hide::tooltip')">
  </div>
</template>

<script>
export default {
  name: "iconeFamilleIngredient",
  props: [ 'familleId', 'couleur' ],
  data() {
    return {
      couleurChoisie: '-NOIR'
    }
  },
  methods: {
    familleNomComplet() {
      if (this.familleCode) {
        return this.$store.state.definitions.familles.find(item => item.code === this.familleCode).nom
      } else {
        return ''
      }
    }
  },
  mounted() {
    
    //this.couleur ? this.couleurChoisie = '-BLANC' : this.couleurChoisie
    if (this.couleur && this.couleur.toLowerCase() === "blanc") {
      this.couleurChoisie = '-BLANC'
    }
  },
  computed: {
    familleCode() {
      var code = this.$store.state.definitions.familles
      code = code && code.find(el => el.id === this.familleId)
      return code && code.code
    }
  }
}
</script>

<style scoped>
.pictos-dropdown {
  background: white !important;
  border: 0;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.06);
}
</style>
