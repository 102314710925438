<template>
  <div :key="key" :style="composante.categorieGEMRCNId ? 'border-left:4px solid ' + configurationGEMRCN(composante.categorieGEMRCNId).couleur : ''">
    <div v-if="enChargement" class="composante-container" style="text-align:center;">
      <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style='height:80px;margin-left:-8px;' />
    </div>

    <div v-else-if="composante.recetteId" :class="mode === true ? 'composante-container' : 'composante-container apercu'">
      <div v-if="mode === true" class="flex-center-space-between">
        <div style="height:16px;overflow:hidden;white-space:nowrap;">
          <span style="cursor:default;color:#BFBFBF;">
            {{ composanteNom(composante.composanteId) }}
          </span>
        </div>
        <template v-if="!lectureSeule">
          <div style="white-space:nowrap;">
            <button v-if="peutDupliquer" v-tooltip="{ content: 'Dupliquer la recette'}" @click="dupliquerRecette({ serviceId: service.id, composanteId: composante.composanteId, recetteId: composante.recetteId })" type="button" class="btn btn-success" style="padding:0 4px;border-radius:8px;transform:scale(0.7);margin-right:-1px;"><i class="fas fa-clone"></i></button>
            <button v-tooltip="{ content: 'Changer la recette'}" @click="afficherModalRecettes" type="button" class="btn btn-primary" style="padding:0 4px;border-radius:8px;transform:scale(0.7);margin-right:-5px;"><i class="fas fa-exchange-alt"></i></button>
            <button v-tooltip="{ content: 'Effacer la recette'}" @click="effacerLaRecette({ serviceId: service.id, composanteId: composante.composanteId, cleComposee: composante.cleComposee })" type="button" class="btn btn-danger" style="padding:0 6.5px;border-radius:8px;margin-left:4px;transform:scale(0.7);"><i class="fas fa-times"></i></button>
          </div>
        </template>
      </div>

      <div v-if="mode === true" style="margin-top:-7px;height:12px;">
        <span v-if="regimeNom" class="bold600 texteRestriction" style="font-size:10px;">{{ regimeNom }}</span>
        <span v-if="textureNom" class="bold600 texteRestriction" style="font-size:10px;">{{ textureNom }}</span>
      </div>

      <div class="flex-center-space-between" style="align-items:flex-start;">
        <div>
          <div :style="mode === true ? 'height:45px;' : 'height:30px;'">
            <div style="height:30px;overflow-y:hidden;display:flex;flex-direction:column;justify-content:center;" :class="composante.cotationManquante ? 'warningText' : ''">
              <RecettePopUpIngredients :estLien=true :recetteId="composante.recetteId" :estDeclinaisonNonPersonnalisee="recetteEstDeclinaison(composante.recetteId) && !recetteEstDeclinaisonPersonnalisee(composante.recetteId)" :recetteNom="recetteNom(composante.recetteId)" :serviceId="service.id" :typeConviveId="menu.typeConviveId" :effectif="composante.effectif" :texteClass="(composante.cotationManquante? 'warningText':'' )" style="display:block;height:30px;" />
            </div>
            <div v-if="mode === true && composante.categorieGEMRCNId">
              <i class="fas fa-exclamation-triangle text-warning" v-if="!categorieGEMRCNDansRecette(composante.recetteId, composante.categorieGEMRCNId)" v-tooltip="'La recette sélectionnée ne correspond pas à la catégorie GEMRCN : ' + configurationGEMRCN(composante.categorieGEMRCNId).nom"></i>
              <span v-tooltip="configurationGEMRCN(composante.categorieGEMRCNId).nom" :style="'font-size:10px;cursor:default;color:' + (composante.categorieGEMRCNId ? configurationGEMRCN(composante.categorieGEMRCNId).couleur : '#BFBFBF')"><i> {{ abregerTexte(configurationGEMRCN(composante.categorieGEMRCNId).nom, 50) }} </i></span>
            </div>
          </div>
        </div>
        <div style="white-space:nowrap;">
          <div v-if="composante.recetteId && composante.prixServi" style="text-align:right;margin:0 4px;">{{composante.prixServi.toString().replace('.', ',')}} €</div>
          <ComposantePrix v-else-if="composante.recetteId && !composante.prixServi" :composante="composante" @prix-par-convive="prixParConvive($event, service.id)" />
        </div>
      </div>
      <div class="flex-center-space-between">
        <div style="font-weight:normal;font-style:italic;" v-tooltip="recetteListeAllergenes(composante.recetteId)">
          {{recetteAllergenes(composante.recetteId)}}
        </div>
        <div v-if="!estSurPlanNeutre" class="input-et-prix-container" style="margin-left:0;padding-right:0;">
          <span v-if="estPasse(service.decalageJour)">Servi :
            <InputNumber ref="effectifServi"
              v-model="composante.effectifServi"
              style="height:17px;width:30px;padding-left:0;text-align:left;"
              class="inputEffectif"
              typeFiltre="quantiteEntiere"
              :zeroSiVide="true"
              :readonly="/*estPasse(service.decalageJour + 7) || */composante.typeVerrou || lectureSeule > 0"
              @move="moveCase"
              @input="effectifChanged" />
            <i v-if="composante.typeVerrou" style="margin-right:15px" class="fas fa-lock" title="Verrouillé par le portage"></i>
          </span>
          <span>Prévu :
            <InputNumber ref="effectifPrevu"
              v-model="composante.effectif"
              style="height:17px;width:30px;padding-left:0;text-align:left;"
              class="inputEffectif" 
              typeFiltre="quantiteEntiere"
              :zeroSiVide="true"
              :readonly="estPasse(service.decalageJour) || composante.typeVerrou || lectureSeule > 0"
              @move="moveCase"
              @input="effectifChanged" />
            <i v-if="composante.typeVerrou" style="margin-right:5px" class="fas fa-lock" title="Verrouillé par le portage"></i>
          </span>
          
        </div>
      </div>
    </div>

    <div v-else-if="mode === true" class="composante-container" style="display:flex;align-items:center;flex-direction:column;justify-content:center;">
      <div style="display:inline-flex;">
        <button v-if="!lectureSeule" v-tooltip="{ content: 'Ajouter une recette'}" @click="afficherModalRecettes" type="button" class="btn btn-primary" style="padding:0 4px;border-radius:8px;"><i class="fas fa-plus"></i></button>
        <div style="cursor:default;font-size:14px;margin-left:4px;padding-top:2px;">{{ composanteNom(composante.composanteId) }}</div>
      </div>
      <div class="regime-texture-container">
        <span v-if="regimeNom"  class="bold600 texteRestriction">{{ regimeNom }}</span>
        <span v-if="textureNom"  class="bold600 texteRestriction">{{ textureNom }}</span>
      </div>
      <div v-if="mode === true && composante.categorieGEMRCNId">
        <span :style="'font-size:10px;cursor:default;color:' + (composante.categorieGEMRCNId ? configurationGEMRCN(composante.categorieGEMRCNId).couleur : '#BFBFBF')"><i> {{ configurationGEMRCN(composante.categorieGEMRCNId).nom }} </i></span>
      </div>
    </div>

    <div v-else class="composante-container apercu" style="display:flex;align-items:center;flex-direction:column;justify-content:center;">Pas de recette</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import InputNumber from '@/components/Inputs/InputNumber'
import RecettePopUpIngredients from '@/components/Recettes/RecettePopUpIngredients'
import ComposantePrix from '@/components/Menus/ComposantePrix'
import { optionsJours } from '@/mixins/menus'
import { constantesId } from "@/helpers/constantes"
import { abregerTexte } from "@/helpers/utils"

export default {
  name: "CaseMenu",
  props: ['composante', 'estPasse', 'menu', 'regimeIds', 'service', "textureIds", "mode", "lectureSeule"],
  components: { ComposantePrix, RecettePopUpIngredients, InputNumber },
  mixins: [optionsJours],
  data() {
    return {
      enChargement: false,
      key: 1
    }
  },
  computed: {
    ...mapGetters("definitions", ["composantesRepas", "regimes", "services", "textures", "configurationGEMRCN"]),
    regimeIdGestionParPlan() {
      if (this.regimeIds.length) {
        return this.regimes.find(el => el.value === this.regimeIds[0]).value
      } else {
        return ''
      }
    },
    jourNom() {
      return this.optionsJours.find(j=> j.id === this.service.jour).nom
    },
    regimeNom() {
      return this.composante.regimeId && this.composante.regimeId !== this.regimeIdGestionParPlan && this.regimes.find(el => el.value === this.composante.regimeId).text || ""
    },
    textureNom() {
      return this.composante.textureId && this.composante.textureId !== this.textureIdGestionParPlan && this.textures.find(el => el.value === this.composante.textureId).text || ""
    },
    textureIdGestionParPlan() {
      if (this.textureIds.length) {
        return this.textures.find(el => el.value === this.textureIds[0]).value
      } else {
        return ''
      }
    },
    peutDupliquer() {
      return this.composante.regimeId === "RE3" && this.composante.textureId === "TE1" && this.service.composantes.filter(c => c.composanteId === this.composante.composanteId).length > 1
    },
    estSurPlanNeutre() {
      if (!this.menu) {
        return false
      }
      return this.menu.typeConviveId === constantesId.typeConviveIdNeutre
    }
  },
  methods: {
    abregerTexte(texte, longueur) {
      return abregerTexte(texte, longueur)
    },
    composanteNom(id) {
      let result = this.composantesRepas.find(composante => composante.value === id)
      if (result) {
        return result.text
      }
    },
    recetteListeAllergenes(id) {
      let recette = this.recette(id)
      if (!recette)
        return ""
      let texteAllergenesNonSaisis = recette.saisieAllergenesIncomplete ? " (Attention, la liste des allergènes est peut-être incomplète car ils n'ont pas été renseignés pour un ou plusieurs ingrédients)" : ""
      if (!recette.allergenes || recette.allergenes.length === 0)
        return "Aucun allergène présent" + texteAllergenesNonSaisis
      return recette.allergenes.join(", ") + texteAllergenesNonSaisis
    },
    recetteAllergenes(id) {
      let recette = this.recette(id)
      if (!recette)
        return ""
      var count = recette.allergenes
      count = count && count.length
      if (!count) {
        if (recette.saisieAllergenesIncomplete)
          return "0 allergène *"
        return ""
      }
      if (count === 1) {
        return "1 allergène" + (recette.saisieAllergenesIncomplete ? " *" : "")
      }
      return count + " allergènes" + (recette.saisieAllergenesIncomplete ? " *" : "")
    },
    recette(id) {
      return id && this.menu?.recettes?.find(recette => recette.id === id)
    },
    recetteNom(id) {
      var recette = this.recette(id)
      return recette && recette.nom || ""
    },
    recetteEstDeclinaison(id) {
      let recette = this.recette(id)
      return recette && recette.estUneDeclinaison || false
    },
    recetteEstDeclinaisonPersonnalisee(id) {
      let recette = this.recette(id)
      return recette.estDeclinaisonPersonnalisee || false
    },
    categorieGEMRCNDansRecette(recetteId, categorieGEMRCNId) {
      let recette = this.recette(recetteId)
      if (!recette)
        return null
      return recette.categoriesGEMRCNIds && recette.categoriesGEMRCNIds.find(id => id === categorieGEMRCNId)
    },
    serviceNom(id) {
      return id && this.services.find(service => service.value === id).text || ""
    },
    prixParConvive(event, serviceId) {
      console.log("ajout prix manquant au menu")
      this.menu.services
        .find(service => service.id === serviceId).composantes
        .find(composante => composante.cleComposee === event.composante.cleComposee && composante.composanteId === event.composante.composanteId)
        .prixServi = event.prix
      this.$emit("prix-a-jour")
    },
    effacerLaRecette(params) {
      let result = this.menu.services
        .find(service => service.id === params.serviceId).composantes
        .find(composante => composante.cleComposee === params.cleComposee && composante.composanteId === params.composanteId)
      result.recetteId = null
      result.prixServi = 0
      this.$emit('effacer-la-recette', params);
    },
    afficherModalRecettes() {
      var params = {
        serviceId: this.service.id,
        serviceCode: this.service.serviceId,
        composanteId: this.composante.composanteId,
        cleComposee: this.composante.cleComposee,
        effectif: this.composante.effectif,
        recetteId: this.composante.recetteId,
        regimeIds: this.composante.regimeId ? [this.composante.regimeId] : [],
        textureIds: this.composante.textureId ? [this.composante.textureId] : [],
        categoriesGEMRCNIds: this.composante.categorieGEMRCNId ? [this.composante.categorieGEMRCNId] : []
      }
      var titre = this.jourNom + ", " + this.serviceNom(params.serviceCode) + " : " + this.composanteNom(params.composanteId)
      var specs = this.regimeNom
      specs += (specs ? " " : "") + this.textureNom
      if (specs) {
        titre += " (" + specs + ")"
      }
      params.titre = titre
      this.$root.$emit('afficher-modal-recettes', params)
    },
    dupliquerRecette(params) {
      this.$emit('dupliquer-la-recette', params)
    },
    etatChargement(enChargement) {
      this.enChargement = enChargement
      this.key += 1
    },
    effectifChanged() {
      this.$emit("effectif-changed")
    },
    moveCase(event) {
      this.$emit("move-case", event.direction)
    },
    focusCase() {
      var cell = this.$refs.effectifServi || this.$refs.effectifPrevu
      if (cell) {
        cell.$el.focus()
      }
    }
  },
  mounted() {
  },
  updated() {
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/couleurs.scss';
@import url(https://fonts.googleapis.com/css?family=Roboto);

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.hidden-text {
  visibility: hidden;
}
.titre-repas-container {
  margin-bottom: 2rem;
  padding: 0.3rem 0.6rem;
}
.composantes-container {
  padding: 0 0.6rem;
}
td {
  padding: 0 !important;
}
.jour-present-container {
  position: relative;
}
.jour-present {
  position: absolute;
  left: 7px;
  top: 0.5rem;
  letter-spacing: 1px;
  font-size: 1.2rem;
  font-weight: 600;
}
.regime-texture-container {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
}
.composante-container {
  border: 1px solid rgb(224, 224, 224);
  font-family: "Roboto";
  font-weight: bold;
  font-size: 11px;
  height: 95px;
  padding-left: 4px;
  padding-top: 1px;
}
.composante-container.apercu {
  height: 50px;
}
.input-et-prix-container {
  display: flex;
  justify-content: flex-end;
}
.titre-effectif-service {
  margin-bottom: 0.4rem;
  font-size: 0.7rem;
  font-weight: 600;
  letter-spacing: 0.03rem;
}
.inputEffectif {
 width:50px;
 font-size:0.8rem;
}
.texteRestriction {
  color: $mercuriale-light;
  margin-left: 8px;
  font-size: 11px;
}
</style>
