<template>
  <div style="display:inline">
    <i class="fas fa-info-circle cursor-pointer" @click="afficherMouvements()"></i>
    <b-modal :id="'modal' + id" size="xl"
      header-bg-variant="gradient-bleu"
      header-text-variant="light"
      hide-footer
      no-close-on-backdrop>
      <template slot="modal-title" v-if="lot">
        <h3>{{lot.designationFournisseur}} (réf. {{lot.reference}})</h3>
      </template>
      <template slot="default">
        <div v-if="lot">
          <MvtsStockListe :lot=lot />
        </div>
        <div v-else-if="ingredient">
          <div v-for="(lot, index) in ingredient.lots" :key="index">
            <div style="margin:15px 0 0 15px;">
              <span style="display:inline-block;">N° Lot : {{lot.NumLot}}</span>
              <span style="display:inline-block;margin-left:50px;">Désignation : {{lot.designationFournisseur}}</span>
              <span style="display:inline-block;margin-left:50px;" title="Date de Durabilité Minimale (ex. DLUO)">DLC / DDM : {{formatHumanDate(lot.dlcDluo)}}</span>
            </div>
            <MvtsStockListe :lot="lot" />
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 flex-end">
              <button class="btn btn-primary push-left-1rem" @click="hide()">Fermer</button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import MvtsStockListe from './MvtsStockListe.vue'
import { formatageDate } from '@/mixins/dateFormats'

export default {
  name: "BtnAffichageMvtsStock",
  props: ['id', 'lot', 'ingredient'],
  mixins: [formatageDate],
  components: {
    MvtsStockListe
  },
  data() {
    return {}
  },
  methods: {
    async afficherMouvements() {
      this.show()
    },
    show() {
      this.$bvModal.show('modal' + this.id)
    },
    hide() {
      this.$bvModal.hide('modal' + this.id)
    }
  }
}
</script>
