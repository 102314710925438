<template>
  <div class="input-group datepicker-trigger manual-datepicker">
    <b-input-group :style="inputStyle">
      <b-form-input ref="input" type="text" autocomplete="off" :key="key"
        :class="erreur ? 'erreur-date' : ''"
        :placeholder="placeholder"
        :disabled="disabled"
        v-model="inputValue"
        v-tooltip="erreur && { content: 'Date non reconnue', placement: 'top' }"
        @focus="inputFocused"
        @blur="inputBlured"
        @keyup.enter="inputBlured"
        @input="manualInput" />
      <b-input-group-append>
        <b-form-datepicker button-only dropleft hide-header button-variant="outline-primary" locale="fr-FR" :start-weekday="1"
          :no-close-on-select="true"
          :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
          label-current-month="Mois en cours"
          label-prev-year="Année précédente"
          label-next-year="Année suivante"
          label-prev-month="Mois précédent"
          label-next-month="Mois suivant"
          :disabled="disabled"
          :min="min"
          :max="max"
          :date-disabled-fn="isDateDisabled"
          v-model="dpValue"
          @input="dpChanged" />
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import { formatageDate, formatHumanDateAvecSlashes } from '@/mixins/dateFormats'
export default {
  name: "ManualDatepicker",/* Ce datepicker permet la saisie de la date au clavier, avec les formats JJ/MM/AA et JJ/MM/AAAA */
  mixins: [formatageDate, formatHumanDateAvecSlashes],
  props: {
    value: {type: String, default: () => ""},
    min: {type: String},
    max: {type: String},
    disabledWeekdays: {type: Array, default: () => []},
    disabled: {type: Boolean, default: () => false},
    placeholder: {type: String, default: () => "JJ/MM/AA"},
    inputStyle: {type: String, default: () => ""}
  },
  data() {
    return {
      currentValue: this.value,
      inputValue: this.value ? this.formatHumanDate(this.value) : "",
      dpValue: this.value ? this.formatDateAvecTirets(this.value) : "",
      savedValue: this.formatDateAvecTirets(this.value),
      minDate: this.min && new Date(this.min),
      maxDate: this.max && new Date(this.max),
      erreur: false,
      manual: false,
      key: 1
    }
  },
  methods: {
    checkInput() {
      if (!this.manual || !this.inputValue) {
        this.erreur = false
      } else {
        let aDate = this.inputValue.split("/")
        if (aDate.length !== 3) {
          this.erreur = true
        } else {
          let day = Number(aDate[0])
          if (day > 31) {
            day = 31
          }
          let month = Number(aDate[1])
          if (month > 12) {
            month = 12
          }
          let year = aDate[2]
          if (year.length === 2) {
            year = "20" + year
          }
          year = Number(year)
          if (!day || !month || !year || year.toString().length !== 4) {
            this.erreur = true
          } else {
            this.currentValue = new Date(year, month - 1, day)
            this.erreur = false
          }
        }
      }
      if (!this.erreur) {//gestion des bornes
        while(this.minDate && this.currentValue < this.minDate) {
          this.currentValue.setDate(this.currentValue.getDate() + 1);
        }
        while(this.maxDate && this.currentValue > this.maxDate) {
          this.currentValue.setDate(this.currentValue.getDate() - 1);
        }
      }
      return !this.erreur
    },
    manualInput() {//ajout d'un "/" si
      if (this.inputValue//on a une valeur
      && (!this.inputValue.match(/\//g) || this.inputValue.match(/\//g).length < 2)//et moins de 2 "/"
      && this.inputValue.length - this.inputValue.lastIndexOf("/") === 3) {//et deux caractères après le dernier "/"
        this.inputValue += "/"
      }
      if (this.inputValue.indexOf("//") > -1) {//suppression des doubles "/" (si l'utilisateur en rajoute un après l'insertion automatique)
        this.inputValue = this.inputValue.replace("//", "/")
        this.$nextTick(() => {
          this.$refs.input.$el.focus()
          this.$refs.input.$el.value = this.inputValue
        })
      }
    },
    inputFocused() {
      this.manual = true
      if (!this.erreur) {
        this.inputValue = this.formatHumanDateAvecSlashes(this.currentValue)
      }
      this.$nextTick(() => this.$refs.input.$el.select())
    },
    inputBlured() {
      if (this.checkInput()) {
        this.inputValue = this.formatHumanDate(this.currentValue)
        this.dpValue = this.formatDateAvecTirets(this.currentValue)
        this.emitChange()
      }
    },
    dpChanged(date) {
      this.manual = false
      this.erreur = false
      this.currentValue = date
      this.inputValue = this.formatHumanDate(this.currentValue)
      this.emitChange()
    },
    emitChange() {
      if (this.formatDateAvecTirets(this.currentValue) !== this.savedValue) {
        this.savedValue = this.formatDateAvecTirets(this.currentValue)
        this.$emit("saved", this.formatDateAvecTirets(this.currentValue))
      }
    },
    isDateDisabled(ymd, date) {
      if (this.disabledWeekdays.includes(date.getDay())) {
        return true
      }
      return false
    },
  }
}
</script>

<style>
.manual-datepicker .erreur-date {
  border: 1px solid red !important;
}
.manual-datepicker .dropleft .dropdown-menu {
  top: 35px!important;
  left: initial!important;
  right: 0px!important;
}
.manual-datepicker .b-form-datepicker footer {
  display: none;
}
.manual-datepicker .b-form-btn-label-control.show > button {
  color: lightgrey;
}
</style>
