<template>
  <div :key="key">
    <h3 v-if="showName">{{fournisseur.nom}}</h3>
    <div class="row">
      <div class="row col-6" v-if="fournisseur.estLocal">

        <fieldset class="form-group col-12">
          <label for="RS">Raison sociale</label>
          <input type="text" class="form-control" id="RS" v-model.trim="fournisseur.nom" @input="checkDonnees">
          <div class="error" v-if="erreurNom">
            Le nom est requis
          </div>
        </fieldset>

        <fieldset class="form-group col-8">
          <label for="nomCourt">Nom court</label>
          <input type="text" class="form-control" id="nomCourt" v-model.trim="fournisseur.nomCourt" @input="checkDonnees">
          <div class="error" v-if="!fournisseur.nomCourt">
            Le nom court est requis
          </div>
          <div class="error" v-else-if="erreurNomCourt">
            Le nom court doit faire au maximum 10 caractères
          </div>
        </fieldset>

        <fieldset class="form-group col-4">
          <label for="FP">Franco de port</label>
          <InputNumber id="FP" class="form-control" typeFiltre="montant" v-model="fournisseur.francoPort" @input="checkDonnees" />
          <div class="error" v-if="erreurFrancoPort">
            Le franco de port est requis
          </div>
        </fieldset>

        <fieldset class="form-group col-12">
          <label for="adresse1">Adresse</label>
          <input type="text" class="form-control" id="adresse1" v-model.trim="depot.adresse1">
          <input type="text" class="form-control" id="adresse2" v-model.trim="depot.adresse2">
          <input type="text" class="form-control" id="adresse3" v-model.trim="depot.adresse3">
        </fieldset>

        <fieldset class="form-group col-4">
          <label for="CP">Code postal</label>
          <input type="text" class="form-control" id="CP" v-model.trim="depot.codePostal">
        </fieldset>

        <fieldset class="form-group col-8">
          <label for="ville">Ville</label>
          <input type="text" class="form-control" id="ville" v-model.trim="depot.ville">
        </fieldset>

        <fieldset class="form-group col-6">
          <label for="familles">Familles</label>
          <MultiSelect :value="fournisseur.familles" :items="familles" itemText="nom" itemValue="id" placeholder="Familles..." emptyText="Toutes les familles ont été ajoutées" @change="fournisseur.familles = $event" @blur="checkDonnees" />
          <div class="error" v-if="erreurFamilles">
            Indiquez au moins une famille
          </div>
        </fieldset>

        <fieldset class="form-group col-6">
          <label for="familles">Etablissements</label>
          <MultiSelect :value="fournisseur.etablissements" :items="etablissementsCourantsTries" itemText="eRS" itemValue="eId" placeholder="Etablissements..." emptyText="Tous les établissements ont été ajoutés" @change="fournisseur.etablissements = $event" @blur="checkDonnees" />
          <div class="error" v-if="erreurEtablissements">
            Indiquez au moins un établissement
          </div>
        </fieldset>

        <div class="custom-control custom-checkbox" style="margin-left:20px;">
          <input class="custom-control-input" id="supprimer" type="checkbox" v-model="fournisseur.aSupprimer">
          <label for="supprimer" class="custom-control-label">Supprimer le fournisseur</label>
        </div>

      </div>

      <div :class="'row' + (fournisseur.estLocal ? ' col-6' : ' col-12')">

        <fieldset class="form-group col-12" v-if="!enAjoutFournisseurLocal">
          <label for="depot">Dépôt de livraison</label>
          <b-form-select v-model="fournisseur.dfId" :options="fournisseur.depots" :value-field="'id'" :text-field="'nom'" title="Choix du dépôt à utiliser" @change="checkDonnees" class="form-control" />
          <div class='error' v-if="!fournisseur.dfId">
            Vous devez indiquer un dépôt
          </div>
        </fieldset>

        <fieldset class="form-group col-12">
          <label for="codeClient">Mon code client</label>
          <input @focus="$event.target.select()" class="form-control" id="codeClient" type="text" v-model.trim="fournisseur.CCli">
        </fieldset>

        <fieldset class="form-group col-6">
          <label for="nomCommercial">Contact commercial</label>
          <input @focus="$event.target.select()" class="form-control" id="contactCommercial" type="text" v-model.trim="fournisseur.NomCom">
        </fieldset>

        <fieldset class="form-group col-6">
          <label for="telCommercial">Téléphone commercial</label>
          <input @focus="$event.target.select()" class="form-control" id="telCommercial" type="text" v-model.trim="fournisseur.TelCom">
        </fieldset>

        <fieldset class="form-group col-6">
          <label for="nomTelevente">Contact télévente</label>
          <input @focus="$event.target.select()" class="form-control" id="nomTelevente" type="text" v-model.trim="fournisseur.NomTelev">
        </fieldset>

        <fieldset class="form-group col-6">
          <label for="telTelevente">Téléphone télévente</label>
          <input @focus="$event.target.select()" class="form-control" id="telTelevente" type="text" v-model.trim="fournisseur.TelTel">
        </fieldset>

        <fieldset class="form-group col-6">
          <label for="fax">Fax</label>
          <input @focus="$event.target.select()" class="form-control" id="fax" type="text" v-model="fournisseur.fax">
        </fieldset>

        <fieldset class="form-group col-6">
          <label>Email(s)</label>
          <button class="ml-1" @click="addEmail"><i class="fas fa-plus"></i></button>
          <div v-for="(item, index) in fournisseur.Mails" :key="index" class="flex-center-space-between">
            <input @focus="$event.target.select()" class="form-control" type="text" v-model.trim="item.mail" @input="checkDonnees">
            <div class="error" v-if="item.erreur">
              Adresse email invalide
            </div>
            <button class="btn btn-danger ml-1" @click="removeEmail(index)"><i class="fas fa-minus"></i></button>
          </div>
        </fieldset>

        <fieldset class="form-group col-12">
          <label for="commentaire">Commentaire à afficher sur le bon de commande</label>
          <textarea @focus="$event.target.select()" class="form-control" id="commentaire" v-model="fournisseur.Comm"></textarea>
        </fieldset>

        <fieldset class="form-group col-8">
          <label for="jours">Jours de livraison</label>
          <div class="line" v-if="fournisseur.joursLivraison">
            <label class="label-checkbox"><input type="checkbox" v-model="fournisseur.joursLivraison.Lu" @change="checkDonnees">Lu</label>
            <label class="label-checkbox"><input type="checkbox" v-model="fournisseur.joursLivraison.Ma" @change="checkDonnees">Ma</label>
            <label class="label-checkbox"><input type="checkbox" v-model="fournisseur.joursLivraison.Me" @change="checkDonnees">Me</label>
            <label class="label-checkbox"><input type="checkbox" v-model="fournisseur.joursLivraison.Je" @change="checkDonnees">Je</label>
            <label class="label-checkbox"><input type="checkbox" v-model="fournisseur.joursLivraison.Ve" @change="checkDonnees">Ve</label>
            <label class="label-checkbox"><input type="checkbox" v-model="fournisseur.joursLivraison.Sa" @change="checkDonnees">Sa</label>
            <label class="label-checkbox"><input type="checkbox" v-model="fournisseur.joursLivraison.Di" @change="checkDonnees">Di</label>
          </div>
          <div class="error" v-if="erreurJours">
            Veuillez sélectionner au minimum un jour de livraison
          </div>
        </fieldset>

        <div class="line col-4 flex-space-between">
          <b-button class="btn btn-success" style="height:35px;align-self:flex-end;margin-left:-10px;" @click="saveFourn" :disabled="erreurDonnees" alt="Valider">Valider</b-button>
          <b-button class="btn btn-danger" style="height:35px;align-self:flex-end;margin-left:10px;" @click="hideFourn">Annuler</b-button>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import vm from "@/helpers/vm-toolkit-vue"
import { connecteData } from "@/mixins/connecteData"
import { showAlerts } from "@/mixins/alerts"
import { fournisseurs } from '@/mixins/fournisseurs'
import MultiSelect from "@/components/Inputs/MultiSelect"
import InputNumber from "@/components/Inputs/InputNumber"

export default {
  mixins: [
    connecteData,
    showAlerts,
    fournisseurs
  ],
  components: {MultiSelect, InputNumber},
  props: ['fournisseurId', 'etablissementId', 'showName', 'mode'],
  data() {
    return {
      tmpMail: "",
      erreurJours: false,
      erreurNom: false,
      erreurNomCourt: false,
      erreurFrancoPort: false,
      erreurFamilles: false,
      erreurEtablissements: false,
      erreurDonnees: false,
      fournisseur: {
        joursLivraison: {
          Lu: false,
          Ma: false,
          Me: false,
          Je: false,
          Ve: false,
          Sa: false,
          Di: false
        },
        dfId: "",
        Mail: "",
        Mails: [],
        depots: [],
        etablissements: [],
        nomCourt: '',
        estLocal: this.mode === "creation" ? true : false
      },
      depot: {},
      familles: this.$store.state.definitions.familles,
      enAjoutFournisseurLocal: this.mode === "creation" && this.fournisseurId === 0,
      key: 1
    }
  },
  methods: {
    initFourn() {
      if (!this.fournisseurId) {
        this.fournisseur.estLocal = true
        this.fournisseur.depots.push(this.depot)
        this.fournisseur.etablissements.push(this.etabCourantId)
        this.checkDonnees()
        this.key += 1
        return
      }

      this.fournisseur = this.parametresFournisseur(this.fournisseurId, this.etablissementId)

      this.fournisseur.id = this.fournisseurId

      this.fournisseur.joursLivraison = {
        Lu: false,
        Ma: false,
        Me: false,
        Je: false,
        Ve: false,
        Sa: false,
        Di: false
      }

      vm.forEach(this.fournisseur.joursLivraison, function (ignore, j) {
        this.fournisseur.joursLivraison[j] = Boolean(this.fournisseur.Jours && this.fournisseur.Jours.indexOf(j) !== -1)
      }.bind(this))

      if (this.fournisseur.dfId) {
        this.depot = this.fournisseur.depots.find(d => d.id === Number(this.fournisseur.dfId)) || {}
      }

      this.tmpMail = this.fournisseur.Mail || ""
      this.fournisseur.Mails = this.tmpMail.split(';').map(function(el) { return { mail: el }})

      this.checkDonnees()
      this.key += 1
    },
    checkDonnees() {
      var valid = this.checkJours()
      var last = this.checkMail()
      valid = valid && last
      if (this.fournisseur.estLocal) {
        last = this.checkNom()
        valid = valid && last
        last = this.checkNomCourt()
        valid = valid && last
        last = this.checkFrancoPort()
        valid = valid && last
        last = this.checkFamilles()
        valid = valid && last
        last = this.checkEtablissements()
        valid = valid && last
      }
      if (!this.enAjoutFournisseurLocal) {
        last = this.checkDepot()
        valid = valid && last
      }
      this.erreurDonnees = !valid
      return valid
    },
    addEmail() {
      this.fournisseur.Mails.push({ mail: "" })
      this.checkDonnees()
    },
    removeEmail(index) {
      this.fournisseur.Mails.splice(index, 1)
      this.checkDonnees()
    },
    checkMail() {
      this.fournisseur.Mails.forEach(function(el) {
        if (el.mail && !el.mail.split(";").reduce((ok, val) => ok && (!val || vm.is.email(val)), true)) {
          el.erreur = true
        } else {
          el.erreur = false
        }
      })
      this.fournisseur.Mail = this.fournisseur.Mails.filter(el => !el.erreur).map(el => el.mail).join(";")
      return !this.fournisseur.Mails.filter(el => el.erreur).length
    },
    checkJours() {
      this.fournisseur.Jours = ""
      vm.forEach(this.fournisseur.joursLivraison, (actif, jour) => {
        if (actif) {
          this.fournisseur.Jours += (this.fournisseur.Jours ? "," : "") + jour
        }
      })
      this.erreurJours = !this.fournisseur.Jours
      return !this.erreurJours
    },
    checkDepot() {
      if (this.fournisseur.dfId || this.mode === "creation") {
        return true
      }
      return false
    },
    checkNom() {
      this.erreurNom = !this.fournisseur.nom
      return !this.erreurNom
    },
    checkNomCourt() {
      this.erreurNomCourt = !this.fournisseur.nomCourt || this.fournisseur.nomCourt.length > 10
      return !this.erreurNomCourt
    },
    checkFrancoPort() {
      this.erreurFrancoPort = !this.fournisseur.francoPort && this.fournisseur.francoPort !== 0
      return !this.erreurFrancoPort
    },
    checkFamilles() {
      this.erreurFamilles = !this.fournisseur.familles || !this.fournisseur.familles.length
      return !this.erreurFamilles
    },
    checkEtablissements() {
      this.erreurEtablissements = !this.fournisseur.etablissements || !this.fournisseur.etablissements.length
      return !this.erreurEtablissements
    },

    saveFourn() {
      if (!this.checkDonnees()) {
        return
      }
      if (this.mode === "creation") {
        this.depot.nom = this.fournisseur.nom
        this.$emit("add-fourn", this.fournisseur)
      } else {
        this.$emit("save-fourn", this.fournisseur)
      }
    },

    hideFourn() {
      this.$emit("hide-fourn")
    }
  },
  mounted() {
    this.initFourn()
  }
}
</script>

<style scoped>
input[type="checkbox"] {
  margin: 2px 2px 2px 8px;
}
</style>
