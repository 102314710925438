<template>
  <div>
    <div v-if="gestionParPlan" style="padding: .4rem 0 .4rem .7rem;font-size: 1.2rem;text-transform: uppercase;">
      <span class="bold600">Régime {{regimeNomGestionParPlan}}</span> <span class="bold600" style="margin-left:1rem">Texture {{textureNomGestionParPlan}}</span>
    </div>
    <div v-if="estSurPlanNeutre" style="padding: .4rem 0 .4rem .7rem;font-size: 1.2rem;text-transform: uppercase;width:100%;text-align:center">
      <span class="bold600">Ce menu est sur un plan Neutre il ne sera pas utilisé concrétement</span>
    </div>
    <table class="table fixHeight tableMenus" style="table-layout:fixed;">
      <thead>
        <tr>
          <template v-for="(jour, i) in optionsJours">
            <th v-if="jourActif(jour.id)" :key="i" scope="col">
              <div class="flex-center-space-between">
                <template v-if="!lectureSeule">
                  <button v-b-modal="'changerEffectif'+jour.id+i" class="btn btn-primary">
                    <i class="fas fa-users cursor-pointer" v-tooltip="{ content: 'Changer les effectifs du jour'}"></i>
                  </button>
                </template>
                <b-modal :id="'changerEffectif'+jour.id+i" hide-footer @show="initEffectifsJour(jour.id)" :header-bg-variant="'success'" :header-text-variant="'light'">
                  <template slot="modal-title">
                    <h3><i class="fas fa-users" style="margin-right:1rem"></i>Effectifs du jour</h3>
                  </template>
                  <template slot="default">
                    <div class="container-fluid">
                      <div v-if="regimeIds.length === 1">
                        <div class="col-12 flex-center-space-evenly">
                          <div v-if="estPasse(i)">
                            <div>Effectif Servi par service</div>
                            <InputNumber v-model="effectifServiJour" :id="'effectifServiJour'+jour.id" :key="'effectifServiJour'+jour.id" typeFiltre="quantiteEntiere" class="inputEffectif" /><!-- :readonly="estPasse(i + 7)"-->
                          </div>
                          <div>
                            <div>{{estPasse(i) ? 'Théorique' : 'Effectif prévu'}}</div>
                            <InputNumber v-model="effectifJour" :id="'effectifJour'+jour.id" :key="'effectifJour'+jour.id" typeFiltre="quantiteEntiere" class="inputEffectif" :readonly="estPasse(i)" />
                          </div>
                        </div>
                      </div>
                      <div v-else-if="effectifJour">
                        <div class="row">
                          <div class="col-6">Régime</div>
                          <div class="col-3">Effectif Servi</div>
                          <div class="col-3">{{ estPasse(i) ? 'Théorique' : 'Effectif' }}</div>
                        </div>
                        <div v-for="regimeId in regimeIds" :key="regimeId" class="row" style="margin-top:10px;">
                          <div class="col-6" style="padding-top:5px;">{{ regimeNom(regimeId) }}</div>
                          <div class="col-3" v-if="estPasse(i)">
                            <InputNumber v-model="effectifServiJour[regimeId]" :key="'effectifServi'+jour.id+regimeId" typeFiltre="quantiteEntiere" :zeroSiVide="true" class="inputEffectif" /><!-- :readonly="estPasse(i + 7)"-->
                          </div>
                          <div class="col-3">
                            <InputNumber v-model="effectifJour[regimeId]" :key="jour.id+regimeId" typeFiltre="quantiteEntiere" :zeroSiVide="true" class="inputEffectif" :readonly="estPasse(i)" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="margin: 2rem 0; text-align:right">
                      <button class="btn btn-primary" style="background: #d33 !important;border-color: #d33 !important;" @click="setEffectifsJour(jour.id, i)">
                        Valider</button>
                      <button class="btn btn-danger" style="margin-left:3rem; background: #3085d6 !important;border-color: #3085d6 !important;" @click="closeModal('changerEffectif'+jour.id+i)">Annuler</button>
                    </div>
                  </template>
                </b-modal>
                <div :class="cotationManquanteJour(jour.id) ? 'warningText' : ''" style="font-weight:700;">{{jour.nom}} {{jourDate(jour.id)}} <span v-if="premierServiceSurJour(jour) && premierServiceSurJour(jour).jourPlanAlimentaire"><i>(PA "{{ premierServiceSurJour(jour).nomPlanAlimentaire }}" : J{{premierServiceSurJour(jour).jourPlanAlimentaire}})</i></span></div>

                <div :key="keyHeader" :class="cotationManquanteJour(jour.id) ? 'warningText' : ''" style="font-weight:700;">{{coutJour(jour.id)}}</div> <!-- v-if="!estSurPlanNeutre" -->
              </div>
            </th>
          </template>
        </tr>
      </thead>

      <tbody>
        <template v-for="serviceCode in servicesPresents">
          <tr :key="serviceCode">
            <td :colspan="joursActifs.length" v-b-toggle="'service-' + serviceCode" class="accordeon">
              <span><i class="fas fa-chevron-up"></i></span>
              {{serviceNom(serviceCode)}}
            </td>
          </tr>
          <b-collapse tag="tr" :id="'service-' + serviceCode" :key="serviceCode+1" visible>
            <td v-for="service in servicesActifs(serviceCode)" :class="{pasDeService: !service.composantes}" :key="service.jour">
              <div v-if="service.composantes" class="flex-center-space-between titre-repas-container bg-mercuriale-light-faible-opacite">
                <template v-if="!lectureSeule">
                  <button v-b-modal="'changerEffectif'+serviceCode+service.jour" class="btn btn-primary">
                    <i class="fas fa-users cursor-pointer" v-tooltip="{ content: 'Changer les effectifs du service'}"></i>
                  </button>
                </template>
                <b-modal :id="'changerEffectif'+serviceCode+service.jour" hide-footer @show="initEffectifsService(service)" header-bg-variant="success" header-text-variant="light">
                  <template slot="modal-title">
                    <h3><i class="fas fa-users" style="margin-right:1rem"></i>Effectifs du service</h3>
                  </template>
                  <template slot="default">
                    <div class="container-fluid">
                      <div v-if="regimeIds.length === 1">
                        <div class="flex-center-space-evenly">
                          <div v-if="estPasse(service.decalageJour)">
                            <div>Effectif Servi</div>
                            <InputNumber v-if="service.id !== -1" v-model="effectifServiService" :key="'effectifServi'+service.id" typeFiltre="quantiteEntiere" :zeroSiVide="true" class="inputEffectif" /><!-- :readonly="estPasse(service.decalageJour + 7)"-->
                          </div>
                          <div>
                            <div>{{ estPasse(service.decalageJour) ? 'Théorique' : 'Effectif prévu' }}</div>
                            <InputNumber v-if="service.id !== -1" v-model="effectifService" :key="service.id" typeFiltre="quantiteEntiere" :zeroSiVide="true" class="inputEffectif" :readonly="estPasse(service.decalageJour)" />
                          </div>
                        </div>
                      </div>
                      <div v-else-if="effectifService">
                        <div class="row">
                          <div class="col-6">Régime</div>
                          <div class="col-3">Effectif Servi</div>
                          <div class="col-3">{{ estPasse(service.decalageJour) ? 'Théorique' : 'Effectif' }}</div>
                        </div>
                        <div v-for="regimeId in regimeIds" :key="regimeId" class="row" style="margin-top:10px;">
                          <div class="col-6" style="padding-top:5px;">{{ regimeNom(regimeId) }}</div>
                          <div class="col-3" v-if="estPasse(service.decalageJour)">
                            <InputNumber v-if="service.id !== -1" v-model="effectifServiService[regimeId]" :key="'effectifServi'+service.id+regimeId" typeFiltre="quantiteEntiere" :zeroSiVide="true" class="inputEffectif" /><!-- :readonly="estPasse(service.decalageJour + 7)"-->
                          </div>
                          <div class="col-3">
                            <InputNumber v-if="service.id !== -1" v-model="effectifService[regimeId]" :key="service.id+regimeId" typeFiltre="quantiteEntiere" :zeroSiVide="true" class="inputEffectif" :readonly="estPasse(service.decalageJour)" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="margin: 2rem 0; text-align:right">
                      <button class="btn btn-primary" style="background: #d33 !important;border-color: #d33 !important;" @click="setEffectifsService(service,false)">
                        Appliquer sur le service uniquement</button><br /><br />
                      <button class="btn btn-primary" style="background: #d33 !important;border-color: #d33 !important;" @click="setEffectifsService(service,true)">
                        Appliquer sur le service et les composantes</button><br /><br />
                      <button class="btn btn-danger" style="margin-left:3rem; background: #3085d6 !important;border-color: #3085d6 !important;" @click="closeModal('changerEffectif'+serviceCode+service.jour)">Annuler</button>
                    </div>
                  </template>
                </b-modal>
                <div v-if="menu.excluEffectifPortage"> <span style='margin-right:10px' v-if="estPasse(service.decalageJour)" title="Effectif servi"><i class="fas fa-user-check"></i> {{(service.effectifServi)?service.effectifServi:'&#x3F;'}} </span>
                  <span title="Effectif prévu"><i class="fas fa-user"></i> {{service.effectif}}</span>
                </div>
                <div :class="cotationManquanteService(service) ? ' warningText' : 'bold600'" :key="keyHeader">{{coutService(service)}}</div> <!-- v-if="!estSurPlanNeutre" -->
              </div>
              <div v-if="service.composantes" class="composantes-container">
                <div v-for="(composante, i) in service.composantes" :key="i">
                  <CaseMenu :ref="'case_' + service.id + '_' + composante.cleComposee"
                    :menu="menu" :composante="composante" :service="service" :regimeIds="regimeIds" :textureIds="textureIds" :estPasse="estPasse" :mode="mode" :lectureSeule="lectureSeule"
                    @effacer-la-recette="effacerLaRecette" @dupliquer-la-recette="dupliquerLaRecette" @prix-a-jour="keyHeader += 1" @move-case="moveCase($event, 'case_' + service.id + '_' + composante.cleComposee)" @effectif-changed="effectifChanged" />
                </div>
              </div>
              <div v-else class="composantes-container">
                <!-- Pas de service ce jour -->
              </div>
            </td>
          </b-collapse>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { showAlerts } from '@/mixins/alerts'
import { constantesId } from "@/helpers/constantes"
import { closeModal, getDimancheDeLaSemaine } from "@/mixins/index"
import { obtenirMenuPlan } from "@/api/gpao/menus"
import { optionsJours } from '@/mixins/menus'
import InputNumber from '@/components/Inputs/InputNumber'
import CaseMenu from '@/components/Menus/CaseMenu'
import { arrondiMercuriale } from '@/helpers/utils'
import _ from 'lodash'
import vm from "@/helpers/vm-toolkit-vue"
import Vue from 'vue'

export default {
  name: "MenuEdition",
  props: ['menu', 'recetteId', 'composanteId', 'lundiDeLaSemaine', 'mode', 'lectureSeule'],
  components: { InputNumber, CaseMenu },
  mixins: [showAlerts,closeModal, getDimancheDeLaSemaine, optionsJours],
  data() {
    return {
      regimeIds: [],
      textureIds: [],
      effectifJour: null,
      effectifServiJour: null,
      effectifService: null,
      effectifServiService: null,
      keyHeader: 1,
      planDeMenu: null,
      scrollTop: 0,
      cases: []
    }
  },
  computed: {
    ...mapGetters("definitions", ["regimes", "services", "textures", "obtenirService"]),
    joursActifs() {
      return this.optionsJours.filter(o => this.menu.services.some(s => s.jour === o.id))
    },
    servicesPresents() {
      var services = []
      this.menu.services.forEach(s => services.find(e => e.serviceId === s.serviceId) || services.push({ serviceId: s.serviceId, def: this.obtenirService(s.serviceId) }))
      return services.sort((a, b) => a.def && a.def.ordre - b.def.ordre).map(s => s.serviceId)
    },
    gestionParPlan() {
      return this.regimeIds.length === 1 && this.textureIds.length === 1
    },
    regimeNomGestionParPlan() {
      if (this.regimeIds.length) {
        return this.regimes.find(el => el.value === this.regimeIds[0]).text
      } else {
        return ''
      }
    },
    textureNomGestionParPlan() {
      if (this.textureIds.length) {
        return this.textures.find(el => el.value === this.textureIds[0]).text
      } else {
        return ''
      }
    },
    estSurPlanNeutre() {
      if (!this.menu)
        return false
      return this.menu.typeConviveId === constantesId.typeConviveIdNeutre
    }
  },
  methods: {
    ...mapActions("uxHelpers", ["afficherAttente", "cacherAttente"]),
    estPasse(decalageJour) {
      var jour = new Date(this.lundiDeLaSemaine)
      jour.setDate(jour.getDate() + decalageJour)
      return new Date() >= new Date(jour)
    },
    premierServiceSurJour(jour) {
      return this.menu.services.find(s => s.jour === jour.id)
    },
    initEffectifsJour(jour) {
      var services = this.menu.services.filter(s => s.jour === jour)
      var servicesPlan = this.planDeMenu.services.filter(s => s.jour === jour)

      if (this.regimeIds.length === 1) {//pas de régimes spéciaux
        this.effectifJour = services
          .map(s => s.composantes.reduce((max, c) => c.effectif > max ? c.effectif : max, 0))//max des composantes par service
          .reduce((max, c) => c > max ? c : max, 0)//max des composantes du jour
        if (!this.effectifJour) {//si les effectifs ne sont pas présents sur les composantes, on cherche sur les services
          this.effectifJour = services.reduce((max, s) => s.effectif > max ? s.effectif : max, 0)
        }
        if (!this.effectifJour) {//si les effectifs ne sont pas présents sur le menu, on cherche dans les composantes du plan
          this.effectifJour = servicesPlan
            .map(s => s.planComposantes.reduce((max, c) => c.effectif > max ? c.effectif : max, 0))//max des composantes par service
            .reduce((max, c) => c > max ? c : max, 0)//max des composantes du jour
        }
        if (!this.effectifJour) {//si toujours rien, on cherche sur les services du plan
          this.effectifJour = servicesPlan.reduce((max, s) => s.effectif > max ? s.effectif : max, 0)
        }

        this.effectifServiJour = services
          .map(s => s.composantes.reduce((max, c) => c.effectifServi > max ? c.effectifServi : max, 0))//max des composantes par service
          .reduce((max, c) => c > max ? c : max, 0)//max des composantes du jour
        if (!this.effectifServiJour) {//si les effectifs servis ne sont pas présents sur les composantes, on cherche sur les services
          this.effectifServiJour = services.reduce((max, s) => s.effectifServi > max ? s.effectifServi : max, 0)
        }
        if (!this.effectifServiJour) {//sinon, on initialise avec les effectifs théoriques
          this.effectifServiJour = this.effectifJour
        }
      } else {//plusieurs régimes présents
        this.effectifJour = {}
        this.effectifServiJour = {}
        this.regimeIds.forEach(function (regimeId) {
          this.effectifJour[regimeId] = services
            .map(s => s.composantes.reduce((max, c) => (c.regimeId === regimeId && c.effectif > max) ? c.effectif : max, 0))//max des composantes par service pour le régime
            .reduce((max, c) => c > max ? c : max, 0)//max des composantes du jour pour le régime
          if (!this.effectifJour[regimeId] && regimeId === "RE3") {//si les effectifs ne sont pas présents sur les composantes, on cherche sur les services (pour le régime normal)
            this.effectifJour[regimeId] = services.reduce((max, s) => s.effectif > max ? s.effectif : max, 0)
          }
          if (!this.effectifJour[regimeId]) {//si les effectifs ne sont pas présents sur le menu, on cherche dans les composantes du plan
            this.effectifJour[regimeId] = servicesPlan
              .map(s => s.planComposantes.reduce((max, c) => (c.regimeId === regimeId && c.effectif > max) ? c.effectif : max, 0))//max des composantes par service pour le régime
              .reduce((max, c) => c > max ? c : max, 0)//max des composantes du jour pour le régime
          }
          if (!this.effectifJour[regimeId] && regimeId === "RE3") {//si toujours rien, on cherche sur les services du plan (pour le régime normal)
            this.effectifJour[regimeId] = servicesPlan.reduce((max, s) => s.effectif > max ? s.effectif : max, 0)
          }

          this.effectifServiJour[regimeId] = services
            .map(s => s.composantes.reduce((max, c) => (c.regimeId === regimeId && c.effectifServi > max) ? c.effectifServi : max, 0))//max des composantes par service pour le régime
            .reduce((max, c) => c > max ? c : max, 0)//max des composantes du jour pour le régime
          if (!this.effectifServiJour[regimeId] && regimeId === "RE3") {//si les effectifs servis ne sont pas présents sur les composantes, on cherche sur les services (pour le régime normal)
            this.effectifServiJour[regimeId] = services.reduce((max, s) => s.effectifServi > max ? s.effectifServi : max, 0)
          }
          if (!this.effectifServiJour[regimeId]) {//sinon, on initialise avec les effectifs théoriques
            this.effectifServiJour[regimeId] = this.effectifJour[regimeId]
          }
        }.bind(this))

        vm.forEach(this.effectifJour, function (effectif, regimeId) {//on retire la somme des effectifs non-normaux de l'effectif normal
          if (regimeId !== "RE3") {
            this.effectifJour["RE3"] -= effectif
          }
        }.bind(this))
        if (this.effectifJour < 0) {//pas d'effectif négatif, même en cas de saisie erronée
          this.effectifJour = 0
        }
        vm.forEach(this.effectifServiJour, function (effectif, regimeId) {//on retire la somme des effectifs non-normaux de l'effectif normal
          if (regimeId !== "RE3") {
            this.effectifServiJour["RE3"] -= effectif
          }
        }.bind(this))
        if (this.effectifServiJour < 0) {//pas d'effectif négatif, même en cas de saisie erronée
          this.effectifServiJour = 0
        }
      }
    },
    setEffectifsJour(jourId, index) {
      let services = this.menu.services.filter(el => el.jour === jourId)
      var total
      var totalServi
      if (this.regimeIds.length === 1) {
        total = this.effectifJour
        totalServi = this.effectifServiJour
      } else {
        total = Object.values(this.effectifJour).reduce((t, c) => t + c, 0)
        totalServi = Object.values(this.effectifServiJour).reduce((t, c) => t + c, 0)
      }
      services.forEach(service => {
        service.effectif = total
        this.setEffectifsComposantes(this.effectifJour, service, 'effectif')
      })
      services.forEach(service => {
        service.effectifServi = totalServi
        this.setEffectifsComposantes(this.effectifServiJour, service, 'effectifServi')
      })
      this.$emit("effectif-changed")
      this.closeModal('changerEffectif' + jourId + index)
      
    },
    initEffectifsService(service) {
      var servicePlan = this.planDeMenu.services.find(s => s.serviceId === service.serviceId && s.jour === service.jour)
     /* 
     // Pour gerer les cas cookprod, on ne va permettre de modifier qu'un effectif general, cumulé de tous les regimes
     this.regimeIds=["RE3"] 
     */
      if (this.regimeIds.length === 1) {//pas de régimes spéciaux
        this.effectifService = service.effectif
        if (!this.effectifService) {
          this.effectifService = service.composantes.reduce((max, c) => c.effectif > max ? c.effectif : max, 0)//max des composantes du service
          if (!this.effectifService) {//si les effectifs ne sont pas présents sur le menu, on cherche dans les composantes du plan
            this.effectifService = servicePlan.planComposantes.reduce((max, c) => c.effectif > max ? c.effectif : max, 0)
          }
          if (!this.effectifService) {//si toujours rien, on cherche sur les services du plan
            this.effectifService = servicePlan.effectif
          }
        }
        this.effectifServiService = service.effectifServi
        if (!this.effectifServiService)
          this.effectifServiService=service.effectif
        if (!this.effectifServiService) {
          this.effectifServiService = service.composantes.reduce((max, c) => c.effectifServi > max ? c.effectifServi : max, 0)

          if (!this.effectifServiService) {//sinon, on initialise avec les effectifs théoriques
            this.effectifServiService = this.effectifService
          }
        }
      } else {//plusieurs régimes présents
        this.effectifService = {}
        this.effectifServiService = {}
        this.regimeIds.forEach(function (regimeId) {
          this.effectifService[regimeId] = service.composantes.reduce((max, c) => (c.regimeId === regimeId && c.effectif > max) ? c.effectif : max, 0)
          if (!this.effectifService[regimeId] && regimeId === "RE3") {//si les effectifs ne sont pas présents sur les composantes, on cherche sur les services (pour le régime normal)
            this.effectifService[regimeId] = service.effectif
          }
          if (!this.effectifService[regimeId]) {//si les effectifs ne sont pas présents sur le menu, on cherche dans les composantes du plan
            this.effectifService[regimeId] = servicePlan.planComposantes.reduce((max, c) => (c.regimeId === regimeId && c.effectif > max) ? c.effectif : max, 0)
          }
          if (!this.effectifService[regimeId] && regimeId === "RE3") {//si toujours rien, on cherche sur les services du plan (pour le régime normal)
            this.effectifService[regimeId] = servicePlan.effectif
          }

          this.effectifServiService[regimeId] = service.composantes.reduce((max, c) => (c.regimeId === regimeId && c.effectifServi > max) ? c.effectifServi : max, 0)
          if (!this.effectifServiService[regimeId] && regimeId === "RE3") {//si les effectifs servis ne sont pas présents sur les composantes, on cherche sur les services (pour le régime normal)
            this.effectifServiService[regimeId] = service.effectifServi
          }
          if (!this.effectifServiService[regimeId]) {//sinon, on initialise avec les effectifs théoriques
            this.effectifServiService[regimeId] = this.effectifService[regimeId]
          }
        }.bind(this))

        vm.forEach(this.effectifService, function (effectif, regimeId) {//on retire la somme des effectifs non-normaux de l'effectif normal
          if (regimeId !== "RE3") {
            this.effectifService["RE3"] -= effectif
          }
        }.bind(this))
        if (this.effectifService["RE3"] < 0) {//pas d'effectif négatif, même en cas de saisie erronée
          this.effectifService["RE3"] = 0
        }
        vm.forEach(this.effectifServiService, function (effectif, regimeId) {//on retire la somme des effectifs non-normaux de l'effectif normal
          if (regimeId !== "RE3") {
            this.effectifServiService["RE3"] -= effectif
          }
        }.bind(this))
        if (this.effectifServiService["RE3"] < 0) {//pas d'effectif négatif, même en cas de saisie erronée
          this.effectifServiService["RE3"] = 0
        }
      }
    },
    setEffectifsService(service, aussiSurComposante) {
      var total
      var totalServi
      if (this.regimeIds.length === 1) {
        total = this.effectifService
        totalServi = this.effectifServiService
      } else {
        total = Object.values(this.effectifService).reduce((t, c) => t + c, 0)
        totalServi = Object.values(this.effectifServiService).reduce((t, c) => t + c, 0)
      }
      service.effectif = total
      //this.$set(service,"effectifServi",null)      
      service.effectifServi=totalServi
      if (aussiSurComposante) {
        this.setEffectifsComposantes(this.effectifService, service, 'effectif')
        this.setEffectifsComposantes(this.effectifServiService, service, 'effectifServi')
      }
      this.$emit("effectif-changed")
      this.closeModal('changerEffectif' + service.serviceId + service.jour)
    },
    setEffectifsComposantes(effectif, service, type) {
      var composantes = this.menu.services.find(el => el.serviceId === service.serviceId && el.jour === service.jour).composantes
      if (typeof effectif === "number") {//si pas de régimes spéciaux, même effectif pour toutes les composantes
        composantes.forEach(c => c[type] = effectif)
      } else {
        _.uniqBy(composantes, 'composanteId').map(c => c.composanteId).forEach(composanteId => {//pour chaque type de composante
          var composanteNormale = composantes.find(c => c.composanteId === composanteId && c.regimeId === "RE3")
          composanteNormale[type] = 0
          vm.forEach(effectif, function (regimeQtt, regimeId) {//pour chaque type de régime
            var composante = composantes.find(c => c.composanteId === composanteId && c.regimeId === regimeId)
            if (composante && regimeId !== "RE3") {//si une composante est définie pour ce régime, on lui assigne l'effectif correspondant
              composante[type] = regimeQtt
            } else {//sinon, on ajoute l'effectif à la composante normale
              composanteNormale[type] += regimeQtt
            }
          })
        })
      }
    },
    coutJour(jourId) {
      let cout = 0
      this.menu.services.forEach(service => {
        if (service.jour === jourId && service.composantes && service.composantes.length) {
          const composanteIds = _.uniq(service.composantes.map(c => c.composanteId))
          composanteIds.forEach(composanteId => {
            var total = 0
            var nb = 0
            service.composantes.filter(c => c.composanteId === composanteId).forEach(composante => {
              total += (composante.effectif || 0) * (composante.prixServi || 0)
              if (composante.prixServi) {
                nb += composante.effectif || 0
              }
            })
            if (total && nb) {
              cout += total / nb
            }
          })
        }
      })
      if (cout) {
        return arrondiMercuriale(cout) + ' €'
      }
    },
    coutService(service) {
      let cout = 0
      if (service.id !== -1 && service.composantes && service.composantes.length) {
        const composanteIds = _.uniq(service.composantes.map(c => c.composanteId))
        composanteIds.forEach(composanteId => {
          var total = 0
          var nb = 0
          service.composantes.filter(c => c.composanteId === composanteId).forEach(composante => {
            total += (composante.effectif || 0) * (composante.prixServi || 0)
            if (composante.prixServi) {
              nb += composante.effectif || 0
            }
          })
          if (total && nb) {
            cout += total / nb
          }
        })
      }
      if (cout) {
        return arrondiMercuriale(cout) + ' €'
      }
    },
    jourDate(jourId) {
      const lundi = new Date(this.lundiDeLaSemaine)
      const maDate = lundi.setDate(lundi.getDate() + (jourId ? jourId - 1 : 6))
      return new Date(maDate).toLocaleDateString()
    },
    effacerLaRecette(params) {
      let result = this.menu.services
        .find(service => service.id === params.serviceId).composantes
        .find(composante => composante.cleComposee === params.cleComposee && composante.composanteId === params.composanteId)
      result.recetteId = null
      result.prixServi = 0
      this.$emit('effacement-recette'); //TODO : sans doute inutile
    },
    dupliquerLaRecette(params) {
      this.menu.services
        .find(service => service.id === params.serviceId).composantes
        .filter(composante => composante.composanteId === params.composanteId && !composante.recetteId)
        .forEach(composante => Vue.set(composante, "recetteId", params.recetteId))
    },
    majRecette(params) {
      const composante = this.menu.services
        .find(service => service.id === params.serviceId).composantes
        .find(composante => composante.cleComposee === params.cleComposee && composante.composanteId === params.composanteId)
      var caseRecette = this.$refs['case_' + params.serviceId + '_' + params.cleComposee][0]
      if (params.recetteId) {
        composante.recetteId = params.recetteId
        caseRecette.etatChargement(false)
      } else {
        caseRecette.etatChargement(true)
      }
    },
    jourActif(jourId) {
      return this.menu.services.filter(service => service.jour === jourId).length > 0
    },
    servicesActifs(serviceId) {
      return this.menu.services.filter(service => service.serviceId === serviceId).sort((a, b) => (a.jour || 7) - (b.jour || 7))
    },
    servicesTous(serviceId) {
      var services = this.menu.services.filter(service => service.serviceId === serviceId)
      this.optionsJours.map(j => j.id).forEach(jourId => {
        if (!services.find(s => s.jour === jourId && s.serviceId === serviceId)) {
          services.push({
            jour: jourId,
            serviceId: serviceId
          })
        }
      })
      services = services.sort((a, b) => (a.jour || 7) - (b.jour || 7))
      return services
    },
    serviceNom(id) {
      let result = this.services.find(service => service.value === id)
      if (result) {
        return result.text
      }
    },
    loadData() {
      this.joursActifs.forEach((jour) => {
        this.servicesPresents.forEach((service) => {
          let servicePresent = this.menu.services.filter(s => s.jour == jour.id && s.serviceId == service)
          if (servicePresent.length === 0) {
            console.log("ajout service manquant au menu")
            // eslint-disable-next-line vue/no-mutating-props
            this.menu.services.push({
              "jour": jour.id,
              "serviceId": service,
              "id": -1
            })
          }
        })
      })
      this.$emit('reload-menu-non-modifie', this.menu) //TODO sans doute inutile

      return obtenirMenuPlan(this.menu.menuPlanId)
        .then(function (r) {
          this.planDeMenu = r.data
          this.initCases()
        }.bind(this))
    },
    regimeNom(regimeId) {
      return this.regimes.find(el => el.value === regimeId).text || ""
    },
    cotationManquanteJour(jour) {
      var manquant = false
      this.menu.services.filter(s => s.jour === jour).forEach(s => {
        if (s.composantes) {
          s.composantes.forEach(c => {
            if (c.cotationManquante) {
              manquant = true
            }
          })
        }
      })
      return manquant
    },
    cotationManquanteService(service) {
      var manquant = false
      if (service.composantes) {
        service.composantes.forEach(c => {
          if (c.cotationManquante) {
            manquant = true
          }
        })
      }
      return manquant
    },
    initCases() {//prépare le tableau des references de cases, dans l'ordre d'affichage
      var cases = []
      this.servicesPresents.forEach(serviceCode => {
        var services = this.servicesActifs(serviceCode)
        var cleComposees = services.find(s => s.composantes).composantes.map(c => c.cleComposee)
        cleComposees.forEach(cleComposee => {
          var ligne = []
          services.forEach(service => {
            ligne.push({
              key: "case_" + service.id + "_" + cleComposee,
              actif: Boolean(service.composantes && service.composantes.find(c => c.cleComposee === cleComposee) && service.composantes.find(c => c.cleComposee === cleComposee).recetteId)
            })
          })
          cases.push(ligne)
        })
      })
      this.cases = cases
    },
    moveCase(direction, source) {
      var iLigne, iCol, dest
      this.cases.forEach((ligne, iL) => {
        ligne.forEach((col, iC) => {
          if (col.key === source) {
            iLigne = iL
            iCol = iC
          }
        })
      })
      if (direction === "left" && iCol > 0) {
        do {
          iCol -= 1
        } while (iCol > 0 && !this.cases[iLigne][iCol].actif)
      } else if (direction === "right" && iCol < this.cases[0].length - 1) {
        do {
          iCol += 1
        } while (iCol < this.cases[0].length - 1 && !this.cases[iLigne][iCol].actif)
      } else if (direction === "up" && iLigne > 0) {
        do {
          iLigne -= 1
        } while (iLigne > 0 && !this.cases[iLigne][iCol].actif)
      } else if (direction === "down" && iLigne < this.cases.length - 1) {
        do {
          iLigne += 1
        } while (iLigne < this.cases.length - 1 && !this.cases[iLigne][iCol].actif)
      }
      dest = this.cases[iLigne][iCol].key
      if (this.$refs[dest] && this.$refs[dest][0]) {
        this.$refs[dest][0].focusCase()
      }
    },
    effectifChanged() {
      this.$emit("effectif-changed")
    }
  },
  mounted() {
    this.afficherAttente()

    this.loadData()

    let arrAllR = this.menu.services.map(s => {
      if (s.composantes) {
        return s.composantes.map(c => c.regimeId)
      }
    })
    let arrFirstR = arrAllR[0]
    if (arrFirstR) {
      let arrR = arrAllR.shift()
      let resultR = arrFirstR.concat(arrR)
      this.regimeIds = _.uniq(resultR)
    }

    let arrAllT = this.menu.services.map(s => {
      if (s.composantes) {
        return s.composantes.map(c => c.textureId)
      }
    })
    let arrFirstT = arrAllT[0]
    if (arrFirstT) {
      let arrT = arrAllT.shift()
      let resultT = arrFirstT.concat(arrT)
      this.textureIds = _.uniq(resultT)
    }

    this.cacherAttente()
  },
  updated() {
    let menus = document.getElementsByTagName("h4")
    if (menus) {
      for (let i = 0, l = menus.length; i < l; i++) {
        if (menus[i].id !== "1") {
          menus[i].className = "hidden-text";
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/couleurs.scss";
@import url(https://fonts.googleapis.com/css?family=Roboto);

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.hidden-text {
  visibility: hidden;
}
.titre-repas-container {
  margin-bottom: 2rem;
  padding: 0.3rem 0.6rem;
}
.composantes-container {
  padding: 0 0.6rem;
}
td {
  padding: 0 !important;
}
.accordeon {
  letter-spacing: 1px;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 5px 7px !important;
}
.accordeon.not-collapsed .fa-chevron-up {
  transform: rotate(180deg);
}
.regime-texture-container {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
}
.composante-container {
  border: 1px solid rgb(224, 224, 224);
  font-family: "Roboto";
  font-weight: bold;
  font-size: 11px;
  height: 95px;
  padding-left: 4px;
  padding-top: 1px;
}
.input-et-prix-container {
  display: flex;
  justify-content: flex-end;
}
.titre-effectif-service {
  margin-bottom: 0.4rem;
  font-size: 0.7rem;
  font-weight: 600;
  letter-spacing: 0.03rem;
}
.inputEffectif {
  width: 50px;
  font-size: 0.8rem;
}
.bleuAgap {
  /* todo a remplacer */
  background: $bleu-agap;
  border-color: $bleu-agap;
}
.rougeAgap {
  /* todo a remplacer */
  background: $rouge-agap;
  border-color: $rouge-agap;
}
.texteRestriction {
  color: $mercuriale-light;
  margin-left: 8px;
  font-size: 0.8rem;
}
.fixHeight > tbody > tr {
  /* fix hauteur des td https://stackoverflow.com/a/34781198 */
  height: 1px;
}
.fixHeight > tbody > tr > td.pasDeService {
  height: inherit;
  background: #f0f0f0;
  border-color: lightgray;
  border-width: 1px;
  border-left-style: solid;
  border-right-style: solid;
  vertical-align: middle;
  text-align: center;
}
.tableMenus > thead > tr > th {
  position: sticky;
  inset-block-start: 39px;
  background: white;
  z-index: 2;
}
</style>
