<template>
  <div class="card" style="height:300px;">
    <div class="card-content">
      <div class="card-body">
        <div class="widgetTitle">Mémo</div>
        <div v-if="!gotMemo" style="text-align:center;margin-top:50px;">
          <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style='height:100px;margin-left:-8px;' />
        </div>
        <div v-else>
          <textarea v-model="memo" @blur="saveMemo" class="form-control feuille-papier" rows="10" style="font-weight:normal;height: auto; "></textarea>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import _ from 'lodash'
import { setMemo } from '@/api/gpao/user'
import { showAlerts } from '@/mixins/alerts'

export default {
  name: "WidgetMemo",
  props: [  ],
  components: {  },
  mixins: [showAlerts],
  data() {
    return {
      memo: "",
      gotMemo: false,
    }
  },
  mounted() {
    this.chargerMemo()
  },
  computed: {
    
  },
  methods: {
    chargerMemo() {
      this.memo = _.cloneDeep(this.$store.state.definitions.informationsAccueil.memoUser)
      if (this.memo === "undefined") {
        this.memo = ""
      }
      this.$nextTick(() => this.gotMemo = true)
    },
    saveMemo() {
      return setMemo(this.memo)
        .then(function () {
          this.$store.commit("definitions/setMemo", this.memo)
          this.$toast('Mémo sauvegardé', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            className: ['toast-success'],
            duration: 1500
          })
          return
        }.bind(this))
    }
  }
}
</script>

<style scoped>
.info {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
