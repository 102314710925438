import Vue from 'vue'
import { chargerToutes, chargerUniverselles, chargerPersonnelles } from '@/api/gpao/recettes'

const recettes = {
  namespaced: true,
  state: {
    liste: [],
    lastRecette: {},
    recette: {},
    filtresEnMemoire: {
      categorieIdsEnMemoire: [],
      composanteIdsEnMemoire: [],
      regimeIdsEnMemoire: [],
      textureIdsEnMemoire: [],
      typeDeRechercheEnMemoire: '',
      termeDeRechercheEnMemoire: '',
    }
  },
  getters: {
    recetteListe(state) {
      return state.liste
    },
    obtenirRecette: (state) => (id) => {
      return state.liste.find(item => item.id === id)
    },
    /*
      // à mettre ailleurs ?
      obtenirRecetteOuIngredient: (state)=>(ligne) => {
        if (ligne.familleId) {
              return this["definitions/obtenirIngredient"](ligne.composantId)
            }

        return state.liste.find(x=>x.id == ligne.composantId)
      },*/
  },
  actions: {
    async chargerToutes({ commit }, opt) {
      return chargerToutes(opt.etablissementId)
      .then(function (response) {
        const data = response.data
        let key = opt.key
        // console.log('action fetchAllItems / recettes liste')
        // console.log(data)
        commit('setItems', {key, data})
        return response
      })
      .catch(function (error) {
        console.log(error)
      })
    },
    async chargerUniverselles({ commit }, key) {
      return chargerUniverselles()
      .then(function (response) {
        const data = response.data
        // console.log('action fetchAllItems / recettes liste')
        // console.log(data)
        commit('setItems', {key, data})
        return response
      })
      .catch(function (error) {
        console.log(error)
      })
    },
    async chargerPersonnelles({ commit }, key) {
      return chargerPersonnelles()
      .then(function (response) {
        const data = response.data
        // console.log('action fetchAllItems / recettes liste')
        // console.log(data)
        commit('setItems', {key, data})
        return response
      })
      .catch(function (error) {
        console.log(error)
      })
    }
  },
  mutations: {
    setItems(state, items) {
      Vue.set(state, items.key, items["data"])
      // console.log('mutation setItems / recettes liste')
      // console.log(state.liste)
    },
    setDerniereRecetteEnMemoire(state, item) {
      Vue.set(state, 'lastRecette', item)
    },
    setFiltresEnMemoire(state, item) {
      Vue.set(state.filtresEnMemoire, item.key, item["data"])
    }
  }
}

export default recettes
