<template>
  <div style="display:inline">
    <button class="btn btn-primary push-right-1rem" :disabled="disabled" @click="afficherImportInventaire()">
      <i class="fas fa-file-import"></i>
    </button>
    <b-modal id="modalImportInventaire" size="xl"
      header-bg-variant="gradient-bleu"
      header-text-variant="light"
      hide-footer
      no-close-on-backdrop>
      <template slot="modal-title">
        <h3>Import d'inventaire au {{formatHumanDate(date)}}</h3>
      </template>
      <template slot="default">
        <h4>Sélectionner un fichier à importer :</h4>
        <div class="flex-start mt-3">
        <button class="btn btn-primary cursor-pointer" v-tooltip="{ content: 'Importer le fichier d\'inventaire', placement: 'top' }" onclick="document.getElementById('selectedFile').click();">
            <i class="fas fa-file-import push-right-1rem"></i>Choisir un fichier
        </button>
        <input type="file" id="selectedFile" ref="file" style="visibility: hidden;" @change="uploadFile()" />
        </div>
        <div class="flex-start">
          {{fichierInventaire.name}}
        </div>
        <div class="flex-start mt-3 mb-3">
          Attention ! Les lignes sans quantité ou avec une quantité à "0" dans la colonne "Inventaire" ne sont pas prises en compte par l'import
        </div>
        <div class="flex-end">
        <button class="btn btn-success cursor-pointer push-left-2rem" :disabled="!fichierInventaire" v-tooltip="{ content: 'Importer l\'inventaire', placement: 'top' }" @click="importer()">
          <i class="fas fa-file-import push-right-1rem" :class="{ 'fa-spin ' : importEnCours }"></i>Importer
        </button>
        <button class="btn btn-primary cursor-pointer push-left-2rem" v-tooltip="{ content: 'Annuler l\'import', placement: 'top' }" @click="hide()">
          Annuler
        </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { importerNouvelInventaire, importerInventaireEnCreation } from '@/api/gpao/stock'
import { connecteData } from '@/mixins/connecteData'
import { formatageDate } from '@/mixins/dateFormats'
import { showAlerts } from '@/mixins/alerts'
export default {
  mixins: [connecteData, showAlerts, formatageDate],
  props: ['disabled','date','inventaireId'],
  data() {
    return {
      fichierInventaire: '',
      importEnCours: false
    }
  },
  methods: {
    async afficherImportInventaire() {
      this.show()
    },
    show() {
      this.$bvModal.show('modalImportInventaire')
    },
    hide() {
      this.$bvModal.hide('modalImportInventaire')
    },
    uploadFile() {
      this.fichierInventaire = this.$refs.file.files[0]
    },
    async importer() {
      if (!this.fichierInventaire) {
        return false;
      }

      this.importEnCours = true
      try {
        let fichier = new FormData()
        fichier.append('file', this.fichierInventaire)

        let response = {}
        if (this.inventaireId) {
          this.alertConfirmationSuppression("Inventaire existant", "Un inveitaire existe déjà, souhaitez-vous remplacer les données existantes par les données du fichier ?")
          response = await importerInventaireEnCreation(this.inventaireId, fichier)
        } else {
          response = await importerNouvelInventaire(this.etabCourantId, this.formatDateAvecTirets(this.date), fichier)
        }
        this.erreurs = response.data.erreurs
        this.avertissements = response.data.avertissements

        this.$emit('importer', response.data)
      }
      finally {
        this.importEnCours = false
        this.hide()
      }
    }
  }
}
</script>
