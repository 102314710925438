<template>
  <div :key="keyMain">

    <DetailsTarif ref="detailsTarif" />

    <b-modal v-model="afficherFavoris" size="xl" hide-footer header-bg-variant="gradient-bleu" header-text-variant="light" style="z-index:3;">
      <template slot="modal-title">
        <h3><i class="fa fa-star" style="margin-right:1rem"></i>Gestion des favoris : {{ ingredientFavori.nom }}</h3>
      </template>
      <template slot="default">
        <div class="container-fluid" v-if="ingredientFavori.articles">
          <ArticlesParProduit
            :articles="ingredientFavori.articles"
            :favoris="ingredientFavori.favoris"
            :ingredient="ingredientFavori"
            :gestion-favori-uniquement="true"
            @toggle-favori="onToggleFavoriModal" />
        </div>
      </template>
    </b-modal>
    
    <div v-if="mercurialesChargees" class="container-fluid bg-white" style="position:fixed;top:50px;height:80px;padding:10px;z-index:2;">
      <div class="line">
        <div class="filter">
          <label>Familles</label>
          <dropDownChoixFamille :key="keyFamilles" :familleSelectionnee="filters.pg" :familles="optionsFamilles" @select-famille="onSelectFamille($event.famille)" :afficherTexte="true" style="transform:scale(0.9);margin-left:-7px;"/>
        </div>
        <div class="filter selections">
          <label>Sélections</label>
          <b-dropdown class="pictos-dropdown" :key="keySelections">
            <template v-slot:button-content>
              <span style="color:black;">{{optionsSelections.length && optionsSelections.find(s => s.selectionId === filters.sel).nom}}</span>
            </template>
            <b-dropdown-item-button v-for="selection in optionsSelections" :key="selection.selectionId"  @click="onSelectSelection(selection)" class="drop-list">
              {{selection.nom}}
            </b-dropdown-item-button>
          </b-dropdown>
        </div>
        <div class="filter recherche">
          <label>Recherche</label>
          <input type="text" :value="filters.search || ''" class="search" style="width:250px">
          <a class="clearSearch" title="Vider" style="display:none;vertical-align:middle">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="gray5 absolute center-v right-1 pointer hover-red5 svg-inline--fa fa-times-circle fa-w-16 fa-fw fa-lg" style=""><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" class=""></path></svg>
          </a>
        </div>
      </div>
    </div>

    <div v-if="mercurialesChargees" class="container-fluid no-padding" id="mercuriales" style="margin-top:75px;">
      <div class="row">
        <div class="col-12 text-center push-top-2rem">{{filters.pg}}</div>
        <div class="col-12">
          <transition name="fade" mode="out-in"></transition>
        </div>
      </div>
    </div>

    <div v-else class="container-fluid bg-white" style="text-align:center;">
      <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" />
    </div>

  </div>
</template>

<script>
import { chargerMercuriales, chargerSelection, updateLigneCatalogue } from "@/helpers/mercuriales"
import { tousArticlesPour } from '@/api/gpao/mercuriales'
import { articleFavori } from '@/api/gpao/commandes'
import vm from "@/helpers/vm-toolkit-vue"
import { connecteData } from "@/mixins/connecteData"
import jquery from "jquery"
import dropDownChoixFamille from "@/components/Recettes/dropDownChoixFamille"
import ArticlesParProduit from '@/components/Stock/ArticlesParProduit'
import DetailsTarif from '@/components/Divers/DetailsTarif'
import { mapGetters } from "vuex"

export default {
  name: "PageMercuriales",
  mixins: [connecteData],
  components: {dropDownChoixFamille, ArticlesParProduit, DetailsTarif},
  data() {
    return {
      filters: {
        sel: "0"
      },
      mercuriales: [],
      optionsFamilles: [],
      keyFamilles: 1,
      optionsSelections: [],
      keySelections: 1,
      cart: {},
      pinnedOrder: null,
      currentCatalog: null,
      keyMain: 1,
      afficherFavoris: false,
      ingredientFavori: {}
    }
  },
  computed: {
    ...mapGetters("uxHelpers", ["mercurialesChargees"]),
    ...mapGetters("definitions", ["trouverIngredient", "trouverFamille"])
  },
  watch: {
    mercurialesChargees(newState) {
      this.keyMain += 1
      if (newState) {
        return chargerMercuriales(this.etabCourantId)
          .then(function(r) {
            this.mercuriales = r
            this.getIds()
            this.getCurrentCatalog()
            this.initData()
          }.bind(this))
      }
    }
  },
  methods: {
    getCurrentCatalog() {
      this.currentCatalog = this.mercuriales.find(m => m.code === this.filters.pg)
      return this.currentCatalog
    },

    setUrl() {
      let url="/" + this.etabCourantId + "/mercuriales"
      if (this.filters.pg)
        url += "/" + this.filters.pg
      if (this.filters.sel !== "0") {
        url+= "/" + this.filters.sel
      } 
      history.pushState({},"",url)
    },

    /* execute draw sur les produits correspondant à filters */
    getProds(draw) {
      var that = this
      var sel

      var cat = this.getCurrentCatalog()

      function selProd(prod) {
        if (sel && sel.Produits[prod.Id]) {
          draw(prod)
        }
      }

      function searchProd(prod) {
        var words

        function testWord(word) {
          return prod.search.indexOf(word) !== -1
        }

        words = that.filters.search.split(" ")

        if (words.every(testWord)) {
          draw(prod)
        }
      }

      function filterProd(prod) {
        if (sel.Produits[prod.Id]) {
          searchProd(prod)
        }
      }

      if (!cat) {
        return
      }

      if (this.filters.sel === "0") {//mercuriale
        if (this.filters.search) {
          cat.products.forEach(searchProd)
        } else {
          cat.products.forEach(draw)
        }
      } else if (this.filters.sel === "-1") {//mercurialeHM
        if (this.filters.search) {
          cat.productsHM.forEach(searchProd)
        } else {
          cat.productsHM.forEach(draw)
        }
      } else if (this.filters.sel === "-2") {//mercurialeL
        if (this.filters.search) {
          cat.productsL.forEach(searchProd)
        } else {
          cat.productsL.forEach(draw)
        }
      } else {//sélection
        sel = cat.selections[this.filters.sel]
        if (this.filters.search) {
          cat.products.forEach(filterProd)
          cat.productsHM.forEach(filterProd)
        } else {
          cat.products.forEach(selProd)
          cat.productsHM.forEach(selProd)
        }
      }
    },

    /* dessine la grille si le scrolling vertical a changé, ou avec drawGrid(true);  */
    drawGrid(e) {
      let start
      let grid

      if (e && e.stopPropagation && e.preventDefault) {
        e.stopPropagation()
        e.preventDefault()
        e.returnValue = false
      }

      grid = this.getCurrentCatalog().grid

      start = window.pageYOffset / ((grid.bodyHeight - grid.height) / (grid.allRows.length - grid.displayCount))

      if (e === true || start !== grid.start) {
        grid.start = start

        grid.rows = grid.allRows.slice(start, start + grid.displayCount + 1)

        if (e) {
          jquery(grid.drawTo)
            .find("tbody")
            .html(vm.st.getBody(grid)) //mise à jour du body
        } else {
          vm.st(grid) //dessin complet
        }

      }

      this.bindMain()
    },


		findProd(idProd, prods) {

			var prod;

			function find(list) {

				if (!list) {

					return;

				}

				vm.forEach(list, function (p) {

					if (p.Id === idProd) {

						prod = p;

					}

				});

			}

			if (typeof idProd === "number") {

				prod = this.mercuriales.find(m => {return m.id === idProd});

			} else if (prods) {

				if (prods.products) {

					find(prods.products);

				}

				if (!prod && prods.productsHM) {

					find(prods.productsHM);

				}

				if (!prod && prods.productsL) {

					find(prods.productsL);

				}

				if (!prods.products && !prods.productsHM) {

					find(prods);

				}

			} else {

        this.getCurrentCatalog()

				if (this.currentCatalog && this.currentCatalog.products) {

					find(this.currentCatalog.products);

				}

				if (!prod && this.currentCatalog && this.currentCatalog.productsHM) {

					find(this.currentCatalog.productsHM);

				}

				if (!prod && this.currentCatalog && this.currentCatalog.productsL) {

					find(this.currentCatalog.productsL);

				}

				if (!prod) {

					vm.forEach(this.mercuriales, function (cat) {

						if (!cat.EstGamme) {

							find(cat.products);

							if (!prod) {

								find(cat.productsHM);

							}

							if (!prod) {

								find(cat.productsL);

							}

						}

					});

				}

			}

			return prod;

		},


    drawMain(gridOnly) {
      var that = this

      function updateSelections() {
        that.optionsSelections = []

        that.optionsSelections.push({selectionId: "0", nom: "Mercuriale complète"})

        that.optionsSelections.push({selectionId: "-1", nom: "Produits hors marché"})

        if (that.canUpdateLocal && !that.currentCatalog.EstGamme) {
          that.optionsSelections.push({selectionId: "-2", nom: "Fournisseurs locaux"})
        }

        vm.forEach(
          that.currentCatalog.selections, //ajout des sélections
          function(a, b) {//tri par nom "naturel"

            var al = vm.to.search(a.nom)

            var bl = vm.to.search(b.nom)

            if (al === bl) {
              return 0
            }

            if (al < bl) {
              return -1
            }

            return 1
          },
          function(sel) {
            that.optionsSelections.push({selectionId: sel.selectionId, nom: sel.nom})
          }
        )

        that.keySelections += 1

        return Promise.resolve()
      }

      function drawTable() {
        var params
        var width
        var colCount
        var widthCount
        var height
        var rowHeight

        rowHeight = 24

        if (that.filters.sel === "-2") {
          width = (jquery('#mercuriales').width() - 600) / that.currentCatalog.fournisseursL.length
        } else {
          width = (jquery('#mercuriales').width() - 600) / that.currentCatalog.fournisseurs.length
        }

        if (width > 300) {
          colCount = "3"

          widthCount = "6"
        } else if (width > 250) {
          colCount = "3"

          widthCount = "5"
        } else if (width > 200) {
          colCount = "3"

          widthCount = "4"
        } else if (width > 150) {
          colCount = "3"
        } else if (width > 100) {
          colCount = "2"
        } else {
          colCount = "1"
        }

        if (jquery("#mercuriales").length) {
          height = window.innerHeight - jquery("#mercuriales").offset().top - 15
        }

        height = parseInt(height)

        params = {
          class: "gv-catalog",
          colCount: colCount,
          cols: (that.filters.sel === "-2" && that.currentCatalog.colsL[widthCount || colCount]) ||
            that.currentCatalog.cols[widthCount || colCount],
          displayCount: Math.floor((height - 46) / rowHeight),
          drawTo: '#mercuriales',
          head: (that.filters.sel === "-2" && that.currentCatalog.headL[colCount]) ||
            that.currentCatalog.head[colCount],
          height: height,
          rowHeight: rowHeight,
          allRows: []
        }

        that.getProds(function(prod) {
          params.allRows.push(prod.html[params.colCount])
        })

        params.bodyHeight = params.allRows.length * params.rowHeight

        that.currentCatalog.grid = params

        that.drawGrid()

        return Promise.resolve()
      }

      function drawVoid() {
        var body =
          "<div>Tous les fournisseurs sont désactivés, pas de Mercuriale disponible.</div>" +
          '<div>Vous pouvez en réactiver sur la page <a class="hasNav" data-url="#Fournisseurs">fournisseurs</a>.'

        jquery('#mercuriales').html(body)

        vm.bind()

        return Promise.resolve()
      }

      this.getCurrentCatalog()

      if (!this.currentCatalog) {
        return drawVoid()
      }

      if (gridOnly) {
        return drawTable()
          .then(this.bindMain)
      }

      return updateSelections()
        .then(drawTable)
        .then(this.showTooltip)
        .then(this.bindMain)
    },

    onToggleFavoriModal(e) {
      this.updateFavori(e.estFavori, e.produitId, e.fournisseurId)
    },

    updateFavori(actif, idProd, idFourn) {
      let ligne = this.currentCatalog.products.find(l => l.Id === idProd)
      if (idFourn) {
        ligne.tarifs[idFourn].estFavori = actif
      } else {
        ligne.estFavori = actif
      }
      updateLigneCatalogue(ligne)
      this.drawMain(true)
    },

    bindMain() {

      var that = this

			var search;
      var clearSearch;
      var modalFavori;
      var toggleFavori;
      var nomProduit;
			/* var btnEditSel;
			var btnNewSel;
			var btnNewProd;
			var btnNewFourn;
			var btnShowCart;
			var btnFullSearch;
			var btnDLOne;
			var btnDLAllC;
			var btnDLAllS;
			var btnDLSameS;
			var btnDLPap;
			var btnAddChecked;
			var checkbox; */


			function onSearch(e) {

				that.onSearch({target: e.currentTarget}, that.drawMain);

			}

			function onClearSearch(e) {

				that.onClearSearch(e.currentTarget, that.drawMain);

			}

      function onClick(current) {

        if (!current.locked) {

          return;

        }

				current.idProd = current.target.closest("[data-idprod]").data("idprod");

				current.prod = that.findProd(current.idProd);

				if (!current.prod) {

					current.prod = that.findProd(current.idProd, that.currentCatalog.productsHM);

					current.hm = true;

				}

				current.idFourn = current.target.data("idfourn");

				current.tarif = current.prod && current.prod.tarifs && current.prod.tarifs[current.idFourn];

				if (current.idFourn) {

					that.drawTarif(current, (current.prod.isHM && that.canUpdateHM) || (current.prod.isLocal && that.canUpdateLocal));

				} else {

					that.drawProd(current.prod, (current.prod.isHM && that.canUpdateHM) || (current.prod.isLocal && that.canUpdateLocal));

				}

      }

      async function openModalFavoris(e) {
        let idProd = e.target.closest("tr").dataset.idprod
        e.stopPropagation()

        var ingredient = that.trouverIngredient(idProd)
        const response = await tousArticlesPour(that.etabCourantId, ingredient.id, that.trouverFamille(that.filters.pg).id/*, ingredient.grammage*/)
        response.data.parProduit[idProd][0].ouvrirAccordeon = true
        ingredient.articles = response.data.parProduit
        ingredient.favoris = response.data.favoris || []
        that.ingredientFavori = ingredient
        that.afficherFavoris = true
      }

      async function onToggleFavori(e) {
        if (e.target.classList.contains("fa-spin")) {
          return
        }
        e.target.classList.add("fa-spin")
        e.stopPropagation()
        let idProd = e.target.closest("tr").dataset.idprod
        let idFourn = e.target.parentNode.dataset.idfourn || null

        let newState = await articleFavori(idProd, idFourn)

        that.updateFavori(Boolean(newState.data), idProd, idFourn)

        that.$toast('Favori modifié !', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          className: ['toast-success'],
          duration: 1500
        })
      }

      function onHoverProduit(e) {//gestion du tooltip sur le nom des produits
        if (e.target.offsetWidth < e.target.scrollWidth) {//nom du produit tronqué, on affiche les noms du produit et de l'ingrédient
          e.target.parentNode.title = e.target.dataset.produit + "\nIngrédient : " + e.target.dataset.ingredient
        } else {//pas d'overflow, on affiche seulement le nom de l'ingrédient
          e.target.parentNode.title = e.target.dataset.ingredient
        }
      }

			/* 
      
      function onCheck(e) {

				var checked;
				var prod;

				if (e && e.currentTarget) {//lancé par un clic, mise à jour de la sélection

					checked = e.currentTarget.checked;

					prod = that.findProd(jquery(e.currentTarget).closest("[data-idprod]").data("idprod"));

					if (prod.idType === 1) {//produit

						prod.checked = checked;

					} else if (prod.idType === 2 || (prod.idType === 3 && that.currentCatalog.EstGamme)) {//groupe

						that.getProds(function (p) {

							if (p.idGroup === prod.idGroup) {

								p.checked = checked;

							}

						});

					} else if (prod.idType === 3) {//sous-groupe

						that.getProds(function (p) {

							if (p.idSGroup === prod.idSGroup) {

								p.checked = checked;

							}

						});

					} else if (that.currentCatalog.EstGamme) {//famille

						prod.checked = checked;

						that.getProds(function (p) {

							if (p.idCat === prod.Id) {

								p.checked = checked;

							}

						});

					}

				}

				if (!checked) {//activation du bouton si nécessaire

					that.getProds(function (p) {

						if (p.checked && p.idType === 1) {

							checked = true;

						}

					});

				}

				if (checked) {

					if (btnAddChecked.attr("disabled")) {

						btnAddChecked.attr("disabled", false);

						that.showTooltip();

					}

				} else {

					if (!btnAddChecked.attr("disabled")) {

						btnAddChecked.attr("disabled", true);

					}

				}

				if (e) {//dessin

					that.drawGrid(true);

				}

			}

			function addChecked() {

				var msg;

				var count = 0;

				if (btnAddChecked.attr("disabled")) {

					vm.ask({
						head: "Information",
						body: "Veuillez cocher les produits à ajouter."
					});

					return;

				}

				that.getProds(function (p) {

					if (p.checked) {

						p.checked = false;

						if (p.idType === 1) {

							count += 1;

							that.addProd({prod: p}, {noSave: true});

						}

					}

				});

				if (count === 1) {

					msg = "1 produit a été ajouté";

				} else {

					msg = count + " produits ont été ajoutés";

				}

				if (that.pinnedOrder) {

					msg += " à le commande " + that.pinnedOrder.title + ", pour un total de " + vm.count(that.pinnedOrder.LignesCommande) + " produits.";

				} else {

					msg += "  au panier, pour un total de " + vm.count(that.cart) + " produits.";

				}

				vm.flash(msg);

				that.drawGrid(true);

				that.drawBtnCart();

			}

			function bindMenu() {

				btnDLOne = jquery(".btnDLOne");
				btnDLOne.off("click");
				btnDLOne.on("click", that.dl);

				btnDLAllC = jquery(".btnDLAllC");
				btnDLAllC.off("click");
				btnDLAllC.on("click", function () {

					return that.dl({allCats: true});

				});

				btnDLAllS = jquery(".btnDLAllS");
				btnDLAllS.off("click");
				btnDLAllS.on("click", function () {

					return that.dl({allSels: true});

				});

				btnDLSameS = jquery(".btnDLSameS");
				btnDLSameS.off("click");
				btnDLSameS.on("click", function () {

					return that.dl({selName: that.currentCatalog.selections[that.filters.sel].nom});

				});

				btnDLPap = jquery(".topbar .btnDLPap");
				btnDLPap.off("click");
				btnDLPap.on("click", function () {

					jquery(".btnDL").removeClass("showDetails").popover("hide");

					return that.post.getMercuPap()
						.then(function (result) {

							if (result && result.result && result.result.size) {

								vm.saveBlob(result.result, "Mercuriale papier - " + vm.v.date.iso() + ".xlsx");

							}

              vm.unlock(true);
              
              return

						})
						.catch(that.error);

				});

			}

			vm.bind({
				details: {
					noHover: true,
					onShow: bindMenu
				}
			}); */

			vm.st.bind({
        noLock: true,
				onChange: onClick,
				noTitle: true
      });

      search = jquery(".search");
      search.off("input");
      search.on("input", onSearch);

			clearSearch = jquery(".clearSearch");
      clearSearch.off("click");
      clearSearch.on("click", onClearSearch);

			modalFavori = jquery(".modalFavori");
      modalFavori.off("click");
      modalFavori.on("click", openModalFavoris);

			toggleFavori = jquery(".toggleFavori");
      toggleFavori.off("click");
      toggleFavori.on("click", onToggleFavori);

			nomProduit = jquery(".gv-catalog .prod > span");
      nomProduit.off("mouseenter");
      nomProduit.on("mouseenter", onHoverProduit);

			/* 

      checkbox = jquery(".gv-catalog input[type=\"checkbox\"]");
      checkbox.off("change");
      checkbox.on("change", onCheck);

			btnAddChecked = jquery(".gv-catalog .btnAddChecked");
			btnAddChecked.off("click");
			btnAddChecked.on("click", addChecked);

			btnEditSel = jquery(".btnEditSel");
      btnEditSel.off("click");
      btnEditSel.on("click", function () {

				if (btnEditSel.is(":hidden")) {

					return;

				}

				that.drawSelection();

			});

			btnNewSel = jquery(".btnNewSel");
      btnNewSel.off("click");
      btnNewSel.on("click", function () {

				if (btnNewSel.is(":hidden")) {

					return;

				}

				that.drawSelection(false, false, true);

			});

			btnNewProd = jquery(".btnNewProd");
      btnNewProd.off("click");
      btnNewProd.on("click", function () {

				if (btnNewProd.is(":hidden")) {

					return;

				}

				that.drawProd(undefined, that.canUpdateHM);

			});

			btnNewFourn = jquery(".btnNewFourn");
      btnNewFourn.off("click");
      btnNewFourn.on("click", function () {

				if (btnNewFourn.is(":hidden")) {

					return;

				}

				return that.fournisseurs.drawNew()
          .then(that.drawMain);

			});

			btnShowCart = jquery(".btnShowCart");
      btnShowCart.off("click");
      btnShowCart.on("click", function () {

				if (btnShowCart.attr("disabled")) {

					return;

				}

				that.drawCart();
				
			});

			btnFullSearch = jquery(".btnFullSearch");
			btnFullSearch.off("click");
			btnFullSearch.on("click", that.drawFullSearch);
      
      */

			if (that.filters.search) {
				clearSearch.show();
			} else {
				clearSearch.hide();
			}
      search.focus();
      window.onscroll = this.drawGrid;
      window.onresize = this.drawMain;
      return Promise.resolve();
    },

    getIds() {
      if (this.$route.params.familleCode) {
        this.filters.pg = this.$route.params.familleCode
      } else if (this.mercuriales.length && !this.filters.pg) {
        this.filters.pg = this.mercuriales[0].code
      }

      if (this.$route.params.selectionId) {
        this.filters.sel = this.$route.params.selectionId
        if (this.filters.sel !== "-1") {
          return chargerSelection(this.filters.sel)
          .then(function (sel) {
            this.currentCatalog.selections[this.filters.sel] = sel
            this.drawMain()
          }.bind(this))

        }
      } else {
        this.filters.sel = "0"
      }

      if (this.filters.pg && this.mercuriales.length) {
        this.drawMain()
      }

      this.keyFamilles += 1
    },

    drawProd(prod, canUpdate) {
      console.log(prod, canUpdate)
    },

    drawTarif(prod/*, canUpdate*/) {
      //console.log(prod, canUpdate)
      this.$refs.detailsTarif.afficher(prod)
    },

		onSelectSelection(selection) {

			if (selection.selectionId !== "-1" && selection.selectionId !== "0") {

        return chargerSelection(selection.selectionId)
          .then(function (sel) {

            this.currentCatalog.selections[selection.selectionId] = sel

            this.filters.sel = selection.selectionId

            this.setUrl()

            this.drawMain();

          }.bind(this))

      } else {

        this.filters.sel = selection.selectionId

        this.setUrl()

				this.drawMain();

      }

    },
    
    onSelectFamille(code) {
      var selName
      var cat
      var found

      if (this.filters.sel !== "0" && this.filters.sel !== "-1") {
        selName = this.currentCatalog.selections[this.filters.sel].Nom;
      }

      this.filters.pg = code;

      cat = this.getCurrentCatalog()

      if (this.filters.sel === "-2") {//pas de mercuriale locale pour les gammes

        if (cat.EstGamme) {

          this.filters.sel = "0";

        }

      } else if (this.filters.sel !== "0" && this.filters.sel !== "-1") {//recherche d'une sélection portant le même nom

        vm.forEach(cat.selections, function (s) {
          
          if (s.Nom === selName) {

            this.filters.sel = s.selectionId;

            found = true;

          }
          
        }.bind(this));

        if (!found) {

          this.filters.sel = "0";

        }

      }

      this.setUrl()

      this.drawMain()

      this.keyFamilles += 1

    },

		onSearch(params, done) {

			if (params.target) {

				params.target = jquery(params.target);

			} else {

				params.target = jquery("input.search");

			}

			if (params.str === undefined) {

				params.str = params.target.val();

			}

			if (params.str) {
				
				params.target.siblings(".clearSearch").show();
				
			} else {
				
				params.target.siblings(".clearSearch").hide();
				
			}

			params.target.val(params.str);

			this.filters.search = vm.to.search(params.str);

			done(true);

		},

		onClearSearch(target, done) {

			target = jquery(target);

			this.filters.search = "";

			target.siblings(".search").val("");

			target.hide();

			done(true);

    },
    
    initData() {
      this.optionsFamilles = []
      vm.forEach(
        this.mercuriales,
        function(a, b) {
          return a.indice > b.indice ? 1 : -1
        },
        function(pg) {
          this.optionsFamilles.push(pg.code)
        }.bind(this)
      )

    }

  },
  created() {
    return chargerMercuriales(this.etabCourantId)
      .then(function(r) {
        this.mercuriales = r
        this.getIds()
        this.getCurrentCatalog()
        this.initData()
      }.bind(this))
  },
  mounted() {
    this.getIds()
  },
  beforeRouteUpdate(to, from, next) {
    this.getIds()
    next()
  },
  beforeRouteLeave(to, from, next) {
    window.onscroll = undefined
    window.onresize = undefined
    next()
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.titre-plan {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  font-size: 1.5rem;
  padding: 0.5rem;
  color: white;
}
.fa-trash-alt {
  color: #ffffff;
  background: #ea0000de;
  padding: 10px;
  border-radius: 5px;
  margin-left: 0rem;
  cursor: pointer;
}
.fa-less-than,
.fa-greater-than {
  cursor: pointer;
  color: white;
}
.fa-less-than {
  margin-right: 0.5rem;
}
.fa-greater-than {
  margin-left: 0.5rem;
}
.arrows {
  margin: 0 !important;
  font-size: 2rem;
}
html body{
  background-color: white !important;
}
</style>

<style>
.filter {
  margin: 6px;
  font-size: 12px;
  font-weight: normal;
}
.line > * {
  display: inline-block;
  vertical-align: top;
}
.filter label {
  display: block;
  margin: 0;
}
.filter select {
  padding: 0 14px 0 0;
}
.filter * {
  font-weight: inherit;
}
html body .content.app-content {
  overflow: visible;
}
html body .content .content-wrapper {
  padding: 0;
}
.scroll-table > * {
  cursor: default;
  overflow-x: hidden;
  overflow-y: scroll;
  user-select: none;
}
.scroll-table > *:nth-of-type(1) {/* head */
	margin-bottom: -1px;
  z-index: -1;
}
.scroll-table > *:nth-of-type(2) {/* body */
	height: 100%;
}
.scroll-table table {
  background-color: transparent;
  border: 1px solid #ddd;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 12px;
  margin: 0;
  table-layout: fixed;
  width: 100%;
  max-width: 100%;
}
.scroll-table thead tr, .gv-stock thead tr {
  background-color: #F6F6F6 !important;
}
.scroll-table tr {
  background-color: inherit;
  height: 22px;
}
.scroll-table th, .scroll-table td {
  font-weight: normal;
  overflow: hidden;
  padding: 2px !important;
  text-align: left !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gv-catalog .sub-group {
  font-weight: bold;
  background-color: #D3D3D3 !important;
}
.gv-catalog .group {
  font-weight: bold;
  background-color: #89CBFD !important;
}
.gv-catalog .new {
  font-weight: bold;
  color: red;
  padding-left: 5px!important;
}
.watchMe {
  cursor: pointer;
}
.watchMe.watching, .watchMe.locked {
  background-color: #42A5F5 !important;
}
.cheapest {
  background-color: #008200 !important;
  color: white !important;
  font-weight: bold !important;
}
.clearSearch, .clearFullSearch {
  margin-left: -25px;
  text-decoration: none;
  cursor: pointer;
  display: inline;
}
.selections > .dropdown {
  will-change: unset!important;
  transform: scale(0.9);
  margin-left: -8px;
}
.selections > .dropdown > button {
  padding: 9px 10px 13px 12px;
}
.recherche > input {
  color: black;
  font-size: 13px;
  margin-top: 4px;
  padding: 16px 10px 21px 12px;
}
</style>
