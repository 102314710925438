<template>
  <div>
    <div v-if="familles && familles.length > 1">
      <b-dropdown class="pictos-dropdown" v-if="familleSelection" style="will-change: unset !important;"
      @click="$root.$emit('bv::hide::tooltip')">
        <template v-slot:button-content>
          <img :src="'/assets/images/icones/' + familleSelection + couleurChoisie + '.png'" alt="" style="width:32px;" v-tooltip="{content: familleNomComplet(familleSelection)}">
          <span v-if="afficherTexte" style="color:black;">{{familleNomComplet(familleSelection)}}</span>
        </template>
        <b-dropdown-item-button v-for="famille in familles" :key="famille"  @click="selectFamille(famille)" class="drop-list" @hover="$root.$emit('bv::hide::tooltip')">
          <img :src="'/assets/images/icones/' + famille + couleurChoisie + '.png'" alt="" style="width:19px;" @hover="$root.$emit('bv::hide::tooltip')">
          {{familleNomComplet(famille)}}
        </b-dropdown-item-button>
      </b-dropdown>
    </div>
    <div v-else>
      <img :src="'/assets/images/icones/' + familleSelection + couleurChoisie + '.png'" alt="" style="width:32px;margin-left:10px;margin-right:5px"
      v-tooltip="{ content: familleNomComplet(familleSelection), placement: 'top' }"
      @click="$root.$emit('bv::hide::tooltip')">
    </div>
  </div>
</template>

<script>
export default {
 /* couleur : -NOIR ou -BLANC*/
  props: { familleSelectionnee: String, familles: Array, ingredient: Object, couleur: String, familleIdSelectionnee: Number, afficherTexte: Boolean },
  data() {
    return {
      familleSelection: '',
      couleurChoisie: '-NOIR'
    }
  },
  methods: {
    familleNomComplet(code) {
      if (code === "Famille...") {
        return "Famille..."
      }

      if (!code) {
        return ""
      }

      if (code === "recette") {
        return "Recette"
      }

      let famille = this.$store.state.definitions.familles.find(item => item.code === code)
      if (famille) {
        return famille.nom
      }
      let gamme = this.$store.state.definitions.gammes.find(item => item.code === code)
      if (gamme) {
        return gamme.nom
      }
    },
    selectFamille(evt) {
      this.$emit('select-famille', {famille: evt, composant: this.ingredient})
    }
  },
  mounted() {
    this.couleurChoisie = this.couleur || '-NOIR'
    if (this.familleSelectionnee) {
      this.familleSelection = this.familleSelectionnee
    } else if (this.familleIdSelectionnee) {
      this.familleSelection = this.$store.state.definitions.familles.find(item => item.id === this.familleIdSelectionnee).code
    } else if (this.familleSelectionnee !== "") {
      this.familleSelection = "recette"
    } else {
      this.familleSelection = "Famille..."
    }
  }
}
</script>

<style lang="scss" scoped>
.pictos-dropdown {
  background: white !important;
  border: 0;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.06);
}
</style>
