<template>
  <div class="bg-white page-mvts-stock" style="margin-top:-15px;">
    <div class="container-fluid bg-gradient-bleu" style="min-height:60px;padding:10px">
      <RechercheIngredients :liste="ingredients" ref="recherche" placeHolder="Rechercher un ingrédient..." style="width:400px;display:inline-block;"
        @selection-ingredient="ingredientSelectionne($event)"
        @reset-ingredient="ingredient = null;" />
      <div class="datepicker-trigger" style="width:300px;display:inline-block;margin-left:10px;vertical-align: top;">
        <input type="text" autocomplete="off" id="datepicker-trigger-mvt-stock" class="datepicker" style="width:100%;height:40px;position:relative;" placeholder="Filtrer les résultats par date" :value="formatHumanDates(debut, fin)">
        <div v-if="debut && fin" style="position:absolute;top:7px;right:10px;color:grey;font-size:1.5rem" @click="debut = null;fin = null;key += 1;">
          <i class="fa fa-window-close" aria-hidden="true"></i>
        </div>
        <AirbnbStyleDatepicker trigger-element-id="datepicker-trigger-mvt-stock" mode="range" :fullscreen-mobile="true" :showShortcutsMenuTrigger="false"
          :date-one="debut" :date-two="fin" @apply="key += 1" @date-one-selected="debut = $event" @date-two-selected="fin = $event"/>
      </div>
      <label class="form-check-label text-white" for="regrouperLots" style="vertical-align:top;margin-left:25px;margin-top:9px;"><input v-model="regrouperLots" type="checkbox" id="regrouperLots" class="form-check-input" style="height:15px;" :disabled="!multiLots" />Regrouper les lots</label>
    </div>
    <div :key="key" class="container-fluid no-padding" style="min-height:65vh;background-color:inherit;">
      <div v-if="ingredient === null" style="margin:20px;"><!-- pas de recherche -->
        Recherchez un ingrédient pour afficher l'historique de ses mouvements en stock.
      </div>
      <div v-else-if="ingredient === undefined" style="margin:20px;"><!-- ingrédient trouvé, pas d'historique -->
        Aucun historique pour cet ingrédient.
      </div>
      <div v-else-if="ingredient.lots.length === 1 || !regrouperLots"><!-- affichage de l'historique détaillé par lots -->
        <div v-for="lot in ingredient.lots" :key="lot.id">
          <div style="margin:15px 0 0 15px;">
            <span style="display:inline-block;">N° Lot : {{lot.NumLot}}</span>
            <span style="display:inline-block;margin-left:50px;">Désignation : {{lot.designationFournisseur}}</span>
            <span style="display:inline-block;margin-left:50px;">DLC / DDM : {{formatHumanDate(lot.dlcDluo)}}</span>
          </div>
          <MvtsStockListe :lot="lot" :debut="debut" :fin="fin" />
        </div>
      </div>
      <div v-else><!-- affichage de l'historique global pour l'ingrédient -->
        <MvtsStockListe :lot="ingredient.lots" :debut="debut" :fin="fin" />
      </div>
    </div>
  </div>
</template>

<script>
import { connecteData } from "@/mixins/connecteData"
import { mapGetters } from "vuex"
import RechercheIngredients from "@/components/Recettes/RechercheIngredients"
import MvtsStockListe from '@/components/Stock/MvtsStockListe.vue'
import { obtenirLots } from '@/api/gpao/stock'
import { formatageDate } from '@/mixins/dateFormats'

export default {
  name: "PageMouvementsStock",
  mixins: [connecteData, formatageDate],
  components: {
    RechercheIngredients,
    MvtsStockListe
  },
  data() {
    return {
      key: 1,
      regrouperLots: false,
      debut: null,
      fin: null,
      ingredient: null,
      multiLots: false
    }
  },
  computed: {
    ...mapGetters("definitions", ["ingredients"])
  },
  methods: {
    ingredientSelectionne(e) {
      this.$refs.recherche.resultatsDeRechercheVisible = false
      this.ingredient = e
      return obtenirLots(e.id, this.etabCourantId)
        .then(response => {
          this.ingredient.lots = response.data
          this.multiLots = this.ingredient && this.ingredient.lots.length > 1
          return
        })
    }
  }
}
</script>

<style>
.page-mvts-stock .fa-window-close {
  right: 0!important;
}
</style>
