<template>
  <div>
    <b-modal :id="'modalAjoutFournisseur'+produit.produitId" size="x1" header-bg-variant="gradient-bleu" header-text-variant="light" hide-footer @hide="currentTabIndex = savTabIndex" no-close-on-backdrop>
      <!-- sans le no-close-on-backgrop, cliquer sur la multiselect qui dépasse de la modal ferme la modale.. -->
      <template slot="modal-title">
        <h3>Sélection d'un fournisseur</h3>
      </template>
      <template slot="default">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <v-select id="selectFournisseur" v-model="fournisseurId" :options="optionsFournisseurs" label='nom' placeholder="Fournisseur..." :reduce="f => f.fournisseurId" z-index=100 />
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-12 flex-end">
              <button class="btn vertAgap push-left-1rem" :disabled="!fournisseurId" @click="ajouterFournisseur()">Ajouter</button>
              <button class="btn rougeAgap push-left-1rem" @click="$bvModal.hide('modalAjoutFournisseur'+produit.produitId)">Annuler</button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <div class="container-fluid container-fiches">
      <div class="row">
        <div class="col-12 col-lg-3 col-xl-3">
          <div :class="selected ? 'card no-radius card-selected-outline' : 'card no-radius'" :id="cardId">
            <div class="card-header no-radius bg-gradient-bleu text-white">
              <div class="flex-center-space-between">
                <h4 class="card-title" @click="scroll" style="width:100%">
                  <span v-if=!surGreAGre>{{produit.produitId}} - {{produit.designation}}</span>
                  <span v-else>
                    <input class="saisieSurTitreBleu" type="text" v-model="produit.designation" placeholder="Désignation" required title="Désignation de votre produit" style="width:100%">
                  </span>
                </h4> <span class="tag-OK color-administration-primary push-left-1rem" v-if="estOk">OK</span>
              </div>
            </div>
            <div class="card-content" aria-expanded="true">
              <div class="card-body">
                <div class="row flex-center-space-between">
                  <div class="col-6">
                    <label>Grammage&nbsp;</label>
                    <InputNumber v-model="produit.grammage" :id="'grammage-' + produit.produitId" typeFiltre="quantite" style="width:60px" />&nbsp;g
                  </div>
                  <div v-if="surGreAGre" class="col-6">
                    <label title="Unité de cotation du produit" :for="'uc-' + produit.produitId">Coté en</label>
                    <input list="texteUC" type="text" v-model.trim="produit.uc" :id="'uc-' + produit.produitId" style="width:70%" title="Unité de cotation du produit" />
                    <datalist id="texteUC">
                      <option value="Kg" />
                      <option value="Litre" />
                      <option value="Carton" />
                      <option value="Unité" />
                      <option value="Pièce" />
                      <option value="Seau" />
                    </datalist>
                  </div>
                  <div v-else class="col-6">
                    <label title="Unité de cotation du produit">UC :</label>
                    {{produit.uc}}
                  </div>
                </div>
                <div v-bind:style="[ingredientExiste(produit) ? '' : {'color':'#D30943'}]">Ingrédient</div>
                <div v-if="ingredientExiste(produit)">
                  <div class="ingredient-label bleuAgap">{{ingredientNom(produit)}}</div>
                </div>
                <RechercheIngredients v-bind:style="[ingredientExiste(produit) ? '' : {'border':'1px solid #D30943'}]" :liste="ingredients" placeHolder="Rechercher un ingrédient..." :cacherAuto="true" 
                @selection-ingredient="setItemIngredientId($event, produit)" />
                <div class="push-top-1rem">Unité de production par défaut</div>
                <div v-if="surGreAGre" class="ingredient-label bleuAgap">{{upIngredient(produit.ingredientId)}}</div>
                <b-form-select v-else v-model="produit.ingredientUP" :options="optionsUp" class="selectpicker push-bottom-1rem">
                  <template slot="first">
                    <option :value="null||undefined" disabled>Sélectionnez une UP...</option>
                  </template>
                </b-form-select>
                <div class="flex-space-between">
                  <div class="flex-start" v-if="surGreAGre">
                    <div v-if="produit.utilisateurId==utilisateurCourant.id" title="Accessible pour vous (et vos subordonnées)" v-on:click="changerStatutProduit(produit)">
                      <i  :class="{ 'fa-spin ' : produit.enCoursSauve }" class="fas fa-user push-left-1rem" style="font-size: 24px;"></i>
                    </div>
                    <div v-else style="font-size: 24px;" title="Accessible à la cuisine" v-on:click="changerStatutProduit(produit)" :disabled="produit.enCoursSauve">
                      <i :class="{ 'fa-spin ' : produit.enCoursSauve }" class="far fa-building push-left-1rem"></i>
                    </div>
                  </div>
                  <div class="flex-end">
                    <b-button type="submit" class="btn bleuAgap push-right-1rem" @click.prevent="sauveProduit(produit)" :disabled="!produitEstModifie" :style="produitEstModifie ? 'border: 1px solid red !important' : ''" v-tooltip="{ content: 'Enregistrer les modifications', placement: 'top' }">
                      <i :class="{ 'fa-spin ' : enregistrementEnCours }" class="fas fa-save"></i>
                    </b-button>
                    <b-button type="button" class="btn btn-danger" @click.prevent="supprimeProduit(produit)" v-tooltip="{ content: 'Supprimer le gré à gré', placement: 'top' }">
                      &times;
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center push-bottom-1rem" style="color:#1babae" v-if="index !== 0 && !surGreAGre"><a @click.prevent="copyTarif(index)">Recopier les données de la fiche précédente</a></div>
          <div v-if="!surGreAGre" class="post-it">
            <b-form-textarea v-model="produit.commentairesLigne" style="min-height: 200px;"></b-form-textarea>
          </div>
        </div>
        <div :class="selected && produit.tarifs.length !== 0 ? 'col-12 col-lg-9 col-xl-9 position-absolue' : 'col-12 col-lg-9 col-xl-9'">
          <div>
            <div v-if="selected">
              <b-card>
                <b-tabs v-model="currentTabIndex">
                  <b-tab v-for="(tarif, i) in produit.tarifs" :key="i" :title-link-class="linkClass(i)">
                    <template v-slot:title>
                      <i v-if="erreurAllergenes(tarif) || erreurRepartitions(tarif)" class='fa fa-exclamation-triangle' style="color:#D30943!important;" v-tooltip="{content: 'Erreurs de tarif', placement: 'bottom', classes: ['tooltip-light']}" />
                      <i v-if="fournisseurEstLocal(tarif.fournisseurId)" class='icone-fournisseurLocal mr-2' />
                      {{nomFournisseur(tarif.fournisseurId)}}
                    </template>
                    <div class="card">
                      <div class="card-header no-radius bg-gradient-bleu text-white">
                        <div class="card-title flex-center-space-between">
                          <div class="flex-start">
                            {{tarif.designation}} <span v-if="produit.sousCategorie||produit.categorie"> - </span>
                            <span v-if="produit.sousCategorie" class="categorie-container color-administration-primary">{{produit.sousCategorie}}</span>&nbsp;
                            <span v-if="produit.categorie" class="categorie-container color-administration-primary">{{produit.categorie}}</span>
                                         <i v-if="isAdmin" class="far fa-copy" style="margin-left:10px" title="Copier l'ID interne" @click="copierIdInterne(tarif)"></i>
                          </div>
                          <div class="flex-end">
                            <b-button type="submit" class="btn btn-light push-right-1rem" :disabled="enregistrementEnCours || !tarifEstModifie(tarif.tarifId) || !tarifEstValide(tarif)" v-tooltip="{ content: 'Enregistrer les modifications', placement: 'top' }"
                             @click.prevent="sauveTarifFournisseur(tarif, produit)" :style="!enregistrementEnCours && tarifEstModifie(tarif.tarifId) ? 'border: 1px solid red' : ''">
                              <i :class="{ 'fa-spin ' : enregistrementEnCours }" class="fas fa-save"></i>
                            </b-button>
                            <b-button type="submit" class="btn btn-danger" :class="{ 'fa-spin ' : desactivationTarifEnCours }" :disabled="desactivationTarifEnCours || !tarif.tarifId" v-tooltip="{ content: 'Supprimer l\'article', placement: 'top' }" @click.prevent="desactiveTarifHM(tarif)">
                              &times;
                            </b-button>
                          </div>
                        </div>
                      </div>
                      <div class="container-fluid">
                        <div class="card-content">
                          <div class="card-body">
                            <div class="row">
                              <div style="height:48px" v-if="erreur">
                                <div class="alert alert-warning" role="alert">
                                  {{ erreur }}
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12" style="padding-left:0">
                                <div class="designation-container">
                                  <div class="designation-input-container">
                                    <div style="width:70%">
                                      <input style="width:70%" type="text" v-model.trim="tarif.designation" placeholder="Désignation" v-tooltip="{ content: 'Désignation chez le fournisseur', placement: 'top' }">
                                      <div class="error" v-if="!tarif.designation">Ce champ est requis</div>
                                    </div>
                                    <div @click="copyToClipboard(tarif.marque)" v-if="tarif.marque && !surGreAGre" class="label-input" v-tooltip="{ content: 'Marque produit', placement: 'top' }">{{tarif.marque}}</div>
                                    <div v-if="surGreAGre" class="label-input" v-tooltip="{ content: 'Marque produit', placement: 'top' }">
                                      <input style="width:80%" type="text" v-model.trim="tarif.marque" placeholder="Marque">
                                    </div>
                                    <div @click="copyToClipboard(tarif.reference)" v-if="tarif.reference && !surGreAGre" style="font-weight:bolder" class="label-input" v-tooltip="{ content: 'Référence produit', placement: 'top' }">{{tarif.reference}}</div>
                                    <div v-if="surGreAGre" class="label-input" v-tooltip="{ content: 'Référence produit', placement: 'top' }">
                                      <input style="width:70%" type="text" v-model.trim="tarif.reference" placeholder="Référence">
                                    </div>
                                    <div class="switch-container switch-administration" v-if="!surGreAGre">
                                      <ToggleButton v-model="tarif.ftEstOk" class="mx-2 switch-plan-de-menu" :labels="{checked: 'FT valide', unchecked: 'FT invalide'}" :width=150 title="Cette fiche technique est elle valide ?" @change="setTarifEstOk($event, tarif)"></ToggleButton>
                                    </div>
                                    <div v-if="surGreAGre">
                                      <div class="input-box flex-end">
                                        <div class="label-input">P.U</div>
                                        <InputNumber v-model="tarif.pu" :id="'pu-' + tarif.tarifId" typeFiltre="montant" v-tooltip="{ content: 'Prix Unitaire (selon PCB)', placement: 'top' }" />
                                        <div class="input-group-append">
                                          <span class="input-group-text">€</span>
                                        </div>
                                      </div>
                                      <div class="error" v-if="!tarif.pu">Ce champ est requis</div>
                                    </div>
                                  </div>
                                </div>
                                <div class="inline-inputs push-top-1rem">
                                  <div>
                                    <div class="flex-start">
                                      <div class="label-input">PCB :</div>
                                      <InputNumber v-if="surGreAGre" v-model="tarif.pcb" :id="'pcb-' + tarif.tarifId" typeFiltre="quantite" style="margin:2px;width:50px" />
                                      <div v-else style="margin:2px">{{tarif.pcb}} </div>
                                      <div class="input-group-append">
                                        <span class="input-group-text">{{produit.uc}}</span>
                                      </div>
                                    </div>
                                    <div class="error" v-if="!tarif.pcb">Ce champ est requis</div>
                                  </div>

                                  <div>
                                    <div class="flex-end">
                                      <div class="input-group-prepend">
                                        <span class="input-group-text ">1 {{produit.uc}} =</span>
                                      </div>
                                      <InputNumber v-model="tarif.parPCE" :id="'pce-' + tarif.tarifId" typeFiltre="quantite" :class="'saisieRepartition'" /><!-- :invalideSiVide="true"-->
                                      <div class="input-group-append">
                                        <span class="input-group-text">PCE, </span>
                                      </div>
                                      <InputNumber v-model="tarif.parKg" :id="'kg-' + tarif.tarifId" typeFiltre="quantite" :class="'saisieRepartition'" /><!-- :invalideSiVide="true"-->
                                      <div class="input-group-append">
                                        <span class="input-group-text"><i v-if="estInvalidePour('KG',tarif.parKg)" class="error fas fa-exclamation-circle" v-b-tooltip.hover title="Cette valeur devrait être 1"></i>KG, </span>
                                      </div>
                                      <InputNumber v-model="tarif.parL" :id="'l-' + tarif.tarifId" typeFiltre="quantite" :class="'saisieRepartition'" /><!-- :invalideSiVide="true"-->
                                      <div class="input-group-append">
                                        <span class="input-group-text"><i v-if="estInvalidePour('L',tarif.parL)" class="error fas fa-exclamation-circle" v-b-tooltip.hover title="Cette valeur devrait être 1"></i>L.</span>
                                      </div>
                                      <b-button style="margin-left:5px;" class="btn-primary" @click="viderLesValeursEn(tarif.tarifId)" v-tooltip="{ content: 'Réinitialiser les valeurs', placement: 'top' }">RAZ</b-button>
                                    </div>
                                    <div class="error" v-if="!tarif.parPCE && !tarif.parKg && !tarif.parL">Attention, les répartitions doivent être renseignées pour être prises en compte dans les calculs automatiques</div>
                                    <div class="flex-end" style="margin-top:3px;">
                                      <p>{{phraseConversion(tarif, produit.uc, false, false)}}</p>
                                    </div>
                                    <div class="flex-end">
                                      <p>{{phraseConversion(tarif, produit.uc, true, false)}}</p>
                                    </div>
                                    <div class="flex-end">
                                      <p>{{phraseConversion(tarif, produit.uc, true, true)}}</p>
                                    </div>
                                  </div>
                                </div>
                                <div class="inline-inputs flex-center-space-between">
                                 <div class="error p-0" style="width:12%">
                                <span v-show="!tarif.aucunAllergene && (!tarif.allergenesMajeurs || tarif.allergenesMajeurs.length === 0)" >  Allergènes non renseignés</span>
                                </div>
                                  <MultiSelect class="push-top-1rem push-right-2rem" :disabled="tarif.aucunAllergene" :value="tarif.allergenesMajeurs" :items="allergenes" itemText="valeur" itemValue="id" placeholder="Allergènes..." emptyText="Tous les allergènes ont été ajoutés" @change="tarif.allergenesMajeurs = $event" style="min-width:200px" />
                                  <div class="input-box flex-start">
                                    <input type="checkbox" :id="'chkAcunAllergene' + tarif.tarifId" class="form-check-input" v-model="tarif.aucunAllergene" :disabled="tarif.allergenesMajeurs && tarif.allergenesMajeurs.length !== 0" />
                                    <label class="form-check-label" :for="'chkAcunAllergene' + tarif.tarifId">Aucun allergène</label>
                                  </div>
                                  <div class="input-box " style="width:30%">
                                    <input type="text" v-if="!surGreAGre" v-model="tarif.codeEan" placeholder="Code EAN" title="Code EAN" style="width:100%" />
                                  </div>
                                
                                </div>
                             
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-content" v-if="!surGreAGre">
                        <div class="card-body card-pdf">
                          <span v-if="tarif.ftLien">
                            <!-- <a  :href="tarif.ftLien" target="_blank">{{tarif.ftLien}}</a> -->
                            <embed  v-if="tarif.ftLien.toLowerCase().startsWith('https')" title="Si le message d'erreur commence par BlobNotFound, la fiche technique est manquant sur le Cloud Agap."
                             :src="tarif.ftLien" width="100%" height="1300px"  ref="embed"
                            :onload="verifierSiFTBienChargee(tarif)"/>
                            <div v-else>
                              <p>Pour des raisons dépendantes du site du fournisseur, nous ne pouvons afficher directement la fiche technique.</p>
                              <p>Vous pouvez cliquer sur le lien suivant pour la télécharger :</p>
                              <a :href="tarif.ftLien" target="_blank" rel="noopener noreferrer">{{tarif.ftLien}}</a>
                            </div>
                          </span>
                          <span v-else>Aucune fiche technique</span>

                          <!--             <object :data="tarif.ftLien" type="application/pdf" width="100%" height="1300px">
                            <iframe :src="fetchPdf(tarif.ftLien)"></iframe>
                          </object> -->
                        </div>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab v-if="surGreAGre && optionsFournisseurs.length" :key="produit.tarifs.length" :title="'+ FOURNISSEUR'" :title-link-class="['bg-tab-new', 'vertAgap']" @click.stop="afficherAjoutFournisseur()" />
                </b-tabs>
              </b-card>
            </div>
          </div>
          <!--<div class="pas-de-fournisseur bg-gradient-administration" v-else>
            Pas de fournisseur
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { scroller } from 'vue-scrollto/src/scrollTo'
import { validerFicheTech, ajouterTarifHM, desactiverTarifHM } from '@/api/gpao/fournisseurs'
import { modifierProduit, supprimerProduit, changerAffectationProduit } from '@/api/gpao/produits'
import { constantesId } from "@/helpers/constantes"
import { fournisseurs } from '@/mixins/fournisseurs'
import { connecteData } from "@/mixins/connecteData"
import InputNumber from '@/components/Inputs/InputNumber'
import MultiSelect from '@/components/Inputs/MultiSelect'
import RechercheIngredients from '@/components/Recettes/RechercheIngredients'
import { mapGetters } from "vuex"
import { showAlerts } from "@/mixins/alerts"
import store from '@/store/'

export default {
  props: ['familleId', 'fournisseurs', 'produit', 'selected', 'filters', 'index', 'listData', 'surGreAGre'],
  components: {
    InputNumber,
    MultiSelect,
    RechercheIngredients
  },
  mixins: [connecteData, showAlerts, fournisseurs],
  data() {
    return {
      baseProduit: {},
      baseTarifs: [],
      optionsUp: ['KG', 'L', 'PCE'],
      optionsFournisseurs: [],
      fournisseurId: '',
      currentTabIndex: 0,
      savTabIndex: 0,
      messages: {
        checked: 'OUI',
        unchecked: 'NON'
      },
      checked: false,
      fields: {
        ingredientId: { type: 'number' },
        nom: { type: 'string' }
      },
      ingredientFilter: {
        logic: "and",
        filters: [
          { field: "ingredientId", operator: "startsWith", value: "nom" }
        ]
      },
      itemTemplate: 'nom',
      erreur: '',
      enregistrementEnCours: false,
      desactivationTarifEnCours: false
    }
  },
  computed: {
    ...mapGetters("definitions", ["ingredients"]),
    estOk() {
      return this.produit.status.includes('EST OK')
    },
    produitEstModifie() {
      return this.produit.grammage != this.baseProduit.grammage
        || this.produit.ingredientId != this.baseProduit.ingredientId
        || this.produit.ingredientUP != this.baseProduit.ingredientUP
        || this.produit.commentairesLigne != this.baseProduit.commentairesLigne
        || this.produit.uc != this.baseProduit.uc
        || this.produit.designation != this.baseProduit.designation
    },
    allergenes() {
      return constantesId.allergenes
    },
    cardId() {
      return 'card-' + this.produit.produitId
    }
  },
  methods: {
    copierIdInterne(tarif) {
        navigator.clipboard.writeText(tarif.tarifId);
        this.toastSucces("Id interne copiée dans le presse papier")

    },
verifierSiFTBienChargee(tarif) {
/*   if (tarif.reference=='53261')
  {
    console(this.$refs.embed.innerHTML);
    debugger;
  } */
},
    erreurAllergenes(tarif) {
      return (!tarif.allergenesMajeurs || tarif.allergenesMajeurs.length === 0) && !tarif.aucunAllergene
    },
    erreurRepartitions(tarif) {
      return !tarif.parKg && !tarif.parL && !tarif.parPCE
    },
    async changerStatutProduit(produit) {
      this.$set(produit, 'enCoursSauve',true)
      try {
        const reponse = await changerAffectationProduit(produit.produitId)
        this.$set(produit, 'utilisateurId',reponse.data)
        this.toastSucces("Affection du produit modifiée")
      } finally {
        this.$set(produit, 'enCoursSauve',false)
      }
    },
    estInvalidePour(up, repartition) {
      if (!this.baseProduit.uc)
        return false
      if (up !== this.baseProduit.uc.toUpperCase()) {
        return false;
      }
      return repartition != 1;
    },
    afficherErreur(message, error) {
      this.erreur = message
      if (error.response && error.response.data && error.response.data.detail) {
        this.erreur += " : " + error.response.data.detail
      }
      else if (error.message) {
        this.erreur += " : " + error.message
      }
    },
    tarifEstModifie(tarifId) {
      if (!tarifId)
        return true
      let tarif = this.produit.tarifs.find(el => el.tarifId === tarifId)
      let baseTarif = this.baseTarifs.find(el => el.tarifId === tarifId)
      if (!baseTarif)
        return true
      return tarif.designation != baseTarif.designation
        || tarif.marque != baseTarif.marque
        || tarif.reference != baseTarif.reference
        || tarif.pu != baseTarif.pu
        || tarif.pcb != baseTarif.pcb
        || tarif.parPCE !== baseTarif.parPCE
        || tarif.parKg !== baseTarif.parKg
        || tarif.parL !== baseTarif.parL
        || tarif.codeEan != baseTarif.codeEan
        || tarif.allergenesMajeurs != baseTarif.allergenesMajeurs
        || tarif.tracesAllergenes != baseTarif.tracesAllergenes
        || tarif.aucunAllergene != baseTarif.aucunAllergene
    },
    tarifEstValide(tarif) {
      return tarif && tarif.designation && tarif.pcb && tarif.pu
    },
    upIngredient(ingredientId) {
      if (!ingredientId)
        return ''
      let ingredient = this.ingredients.find(el => el.id === ingredientId)
      if (!ingredient)
        return ''
      return ingredient.up
    },
    estEanFournisseur(codeEAN) {
      return codeEAN && !codeEAN.startsWith("AGAP-") && !codeEAN.startsWith("HM-") && !codeEAN.startsWith("LOCAL-")
    },
    async peutEnregistrerTarif(tarif) {
      if (!tarif)
        return true
      let baseTarif = this.baseTarifs.find(el => el.tarifId === tarif.tarifId)
      if ((!tarif.allergenesMajeurs || tarif.allergenesMajeurs.length === 0) && (!tarif.tracesAllergenes || tarif.tracesAllergenes.length === 0) && !tarif.aucunAllergene
        && ((baseTarif.allergenesMajeurs && baseTarif.allergenesMajeurs.length !== 0) || (baseTarif.tracesAllergenes && baseTarif.tracesAllergenes.length !== 0) || baseTarif.aucunAllergene)) {
        this.alerteErreur("Des allergènes sont déjà renseignés sur cet article, vous devez renseigner des allergènes ou cocher la case 'Aucun allergène'")
        return false
      }
      if (!this.estEanFournisseur(tarif.codeEan))
        return true
      if (tarif.codeEan != baseTarif.codeEan) {
        return await this.alerteConfirmation("Changer le code EAN", "Vous avez renseigné un code EAN fournisseur. Des allergènes peuvent déjà exister sur ce code EAN. Si c'est le cas, les allergènes que vous avez renseignés sur l'article ne seront pas pris en compte.<br/>Souhaitez-vous l'affecter à cet article ?")
      } else if (tarif.plusieursTarifsSurCodeEAN && (tarif.allergenesMajeurs != baseTarif.allergenesMajeurs || tarif.tracesAllergenes != baseTarif.tracesAllergenes || tarif.aucunAllergene != baseTarif.aucunAllergene)) {
        return await this.alerteConfirmation("Changer les allergènes d'un code EAN affecté à plusieurs articles", "Vous avez modifié les allergènes d'un code EAN affecté à plusieurs articles. Si vous enregistrez vos modifications, elles seront appliquées à tous les articles rattachés à ce code EAN.<br/>Souhaitez-vous enregistrer vos modifications ?")
      }
      return true
    },
    setItemIngredientId(event, produit) {
      produit.ingredientId = event.id
    },
    copyToClipboard(str) {
      const el = document.createElement('textarea');
      el.value = str;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
    ingredientExiste(item) {
      return this.ingredients.find(el => el.id === item.ingredientId)
    },
    ingredientNom(item) {
      return this.ingredients.find(el => el.id === item.ingredientId).nom
    },
    viderLesValeursEn(tarifId) {
      let tarif = this.produit.tarifs.find(item => item.tarifId === tarifId)
      tarif.parPCE = 0
      tarif.parKg = 0
      tarif.parL = 0
      this.$emit('raz-tarifs', this.produit)
      document.getElementById('pce-' + tarifId).value = ''
      document.getElementById('kg-' + tarifId).value = ''
      document.getElementById('l-' + tarifId).value = ''
    },
    copyTarif(index) {
      let produitPrecedent = this.listData[index - 1]
      // eslint-disable-next-line vue/no-mutating-props
      this.produit.ingredientId = produitPrecedent.ingredientId
      // eslint-disable-next-line vue/no-mutating-props
      this.produit.ingredientUP = produitPrecedent.ingredientUP
    },
    // valueMapperFunction(options) {
    //   console.log(options)
    // },
    setTarifEstOk(event, tarif) {
      if (event.value) {
        tarif.ftEstOk = true
      } else {
        tarif.ftEstOk = false
      }
    },
    fetchPdf(item) {
      return 'https://docs.google.com/viewer?url=' + item
    },
    scroll() {
      const firstScrollTo = scroller()
      let id = '#' + this.cardId
      var options = {
        easing: 'ease-in',
        offset: -100,
        force: true,
        cancelable: true,
        x: false,
        y: true
      }
      firstScrollTo(id, 500, options)
    },
    nomFournisseur(fournisseurId) {
      return this.fournisseurs.find(item => item.fournisseurId === fournisseurId).nomCourt
    },
    fournisseurEstLocal(fournisseurId) {
      if (!fournisseurId)
        return false
      let fournisseur = this.parametresFournisseur(fournisseurId, this.etabCourantId)
      return fournisseur.estLocal
    },
    linkClass(idx) {
      if (this.currentTabIndex === idx) {
        return ['bg-gradient-bleu', 'text-white']
      } else {
        return ['color-administration-primary']
      }
    },
    afficherAjoutFournisseur() {
      // Sauvegarde de l'index de l'onglet sélectionné pour se repositionner dessus en cas d'annulation de l'ajout de fournisseur
      this.fournisseurId = ''
      this.savTabIndex = this.currentTabIndex
      this.$bvModal.show('modalAjoutFournisseur' + this.produit.produitId)
    },
    ajouterFournisseur() {
      this.erreur = undefined
      if (this.fournisseurId) {
        // Index de l'onglet actuel : "+ Fournisseur" => devient l'index de l'onglet du nouveau fournisseur à la fin de l'ajout
        this.savTabIndex = this.currentTabIndex
        let optionFournisseur = this.optionsFournisseurs.find(el => el.fournisseurId === this.fournisseurId)
        // eslint-disable-next-line vue/no-mutating-props
        this.fournisseurs.push({ fournisseurId: optionFournisseur.fournisseurId, nom: optionFournisseur.nomCourt, nomCourt: optionFournisseur.nomCourt })
        // eslint-disable-next-line vue/no-mutating-props
        this.produit.tarifs.push({ fournisseurId: this.fournisseurId })
        this.optionsFournisseurs = this.optionsFournisseurs.filter(el => el.fournisseurId !== this.fournisseurId)
        this.$bvModal.hide('modalAjoutFournisseur' + this.produit.produitId)
      }
    },
    async sauveTarifFournisseur(tarif, produit) {
      this.erreur = undefined
      if (!await this.peutEnregistrerTarif(tarif)) {
        return false
      }
      this.enregistrementEnCours = true
      let response = {}
      let data = {}
      data['designation'] = tarif.designation
      data['parPCE'] = tarif.parPCE
      data['parKg'] = tarif.parKg
      data['parL'] = tarif.parL
      data['fournisseurId'] = tarif.fournisseurId
      data['marque'] = tarif.marque
      data['reference'] = tarif.reference
      data['ftEstOk'] = tarif.ftEstOk
      data['ftLien'] = tarif.ftLien
      data['pcb'] = tarif.pcb
      data['pu'] = (this.surGreAGre) ? tarif.pu : undefined;
      data['allergenesMajeurs'] = tarif.allergenesMajeurs
      data['tracesAllergenes'] = tarif.tracesAllergenes
      data['codeEan'] = tarif.codeEan
      data['aucunAllergene'] = tarif.aucunAllergene
      produit.tarifs.find(el => el.tarifId === tarif.tarifId)['ftVerifieLe'] = Date.now()
      try {
        if (this.surGreAGre && tarif.tarifId === undefined) {
          data['prixAchat'] = tarif.pu;
          data['produitId'] = produit.produitId
          response = await ajouterTarifHM(data)
          this.$set(tarif, 'tarifId', response.data.id)
        }
        data['tarifId'] = tarif.tarifId
        response = await validerFicheTech(data)
        this.$set(tarif, 'codeEan', response.data.codeEan)
        this.$set(tarif, 'allergenesMajeurs', response.data.majeurs)
        this.$set(tarif, 'plusieursTarifsSurCodeEAN', response.data.aPlusieursTarifsRattaches)
        this.$set(tarif, 'aucunAllergene', response.data.aucunAllergene)
        this.baseTarifs = this.baseTarifs.filter(el => el.tarifId !== tarif.tarifId)
        this.baseTarifs.push(this.copieTarif(tarif))
        this.$emit('produit-modifie')
        this.$toast('Fiche sauvegardée', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          className: ['toast-success'],
          duration: 1500
        })
        return this.$store.dispatch('produits/produitsUtilisateurCourant')
      } finally {
        this.enregistrementEnCours = false
      }
    },
    async sauveProduit(produit) {
      this.erreur = undefined
      let data = {}
      this.enregistrementEnCours = true

      data['grammage'] = produit.grammage
      data['ingredientId'] = produit.ingredientId
      data['ingredientUP'] = produit.ingredientUP

      data['commentairesLigne'] = produit.commentairesLigne
      if (this.surGreAGre) {
        data['designation'] = produit.designation
        data['uc'] = produit.uc
      }
      try {
        let response = await modifierProduit(produit.produitId, data)
        this.baseProduit = Object.assign({}, this.baseProduit, this.produit)
        response.data.ingredientsModifies.forEach(ajour => {
          var index = this.ingredients.findIndex(x => x.id == ajour.id);
          this.ingredients[index] = ajour; // pas besoin de faire un vueset ?
        })
        this.$emit('produit-modifie')
        produit.ftVerifieLe = 'ok'
        this.$toast('Produit modifié', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          className: ['toast-success'],
          duration: 1500
        })
      } finally {
        this.enregistrementEnCours = false
      }
    },
    async supprimeProduit(produit) {
      this.erreur = undefined
      let result = await this.alerteConfirmation("Supprimer le produit", "Souhaitez-vous supprimer ce produit ? La suppression est définitive.")
      if (result) {
        await supprimerProduit(produit.produitId)
        this.$emit('produit-supprime')
          store.dispatch('definitions/rechargerIngredient', produit.ingredientId)
      }
    },
    async desactiveTarifHM(tarif) {
      this.erreur = undefined
      try {
        let result = await this.alerteConfirmation("Supprimer l'article", "Souhaitez-vous supprimer cet article ? La suppression est définitive.")
        if (result) {
          this.desactivationTarifEnCours = true
          await desactiverTarifHM(tarif.tarifId)
          let fournisseur = this.fournisseurs.find(el => el.fournisseurId === tarif.fournisseurId)
          this.optionsFournisseurs.push({ fournisseurId: fournisseur.fournisseurId, nomCourt: fournisseur.nomCourt, nom: fournisseur.nom })
          // eslint-disable-next-line vue/no-mutating-props
          this.fournisseurs = this.fournisseurs.filter(el => el.fournisseurId !== tarif.fournisseurId)
          // eslint-disable-next-line vue/no-mutating-props
          this.produit.tarifs = this.produit.tarifs.filter(el => el.tarifId !== tarif.tarifId)
          this.baseTarifs = this.baseTarifs.filter(el => el.tarifId !== tarif.tarifId)
          if (this.currentTabIndex === this.produit.tarifs.length) {
            this.currentTabIndex--
          }
          this.$emit('produit-modifie')
          this.$toast('Données article sauvegardées', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            className: ['toast-success'],
            duration: 1500
          })
          return this.$store.dispatch('produits/produitsUtilisateurCourant')
        }
      } catch (error) {
        this.afficherErreur("Impossible de supprimer le tarif", error)
      } finally {
        this.desactivationTarifEnCours = false
      }
    },
    copieTarifs(tarifs) {
      let copie = []
      tarifs.forEach(el => {
        copie.push(this.copieTarif(el))
      })
      return copie
    },
    copieTarif(tarif) {
      return {
        tarifId: tarif.tarifId,
        designation: tarif.designation,
        marque: tarif.marque,
        reference: tarif.reference,
        pu: tarif.pu,
        pcb: tarif.pcb,
        parPCE: tarif.parPCE,
        parKg: tarif.parKg,
        parL: tarif.parL,
        codeEan: tarif.codeEan,
        allergenesMajeurs: tarif.allergenesMajeurs,
        tracesAllergenes: tarif.tracesAllergenes,
        plusieursTarifsSurCodeEAN: tarif.plusieursTarifsSurCodeEAN,
        aucunAllergene: tarif.aucunAllergene
      }
    },
    chargerOptionsFournisseurs(fournisseurs) {
      this.optionsFournisseurs = []
      fournisseurs.forEach(f => {
        this.optionsFournisseurs.push({
          fournisseurId: f.fournisseurId,
          nomCourt: f.nomCourt,
          nom: f.nom
        })
      })
    },
    arrondirDecimales(nombre) {
      return nombre = Math.round((nombre + Number.EPSILON) * 100) / 100
    },
    phraseConversion(tarif, produitUc, auPcb, casReel) {
      var phrase = ""
      if (tarif.pcb > 0) {
        if (auPcb) {
          if (casReel)
            phrase += "Si je commande " + (1.5 * tarif.pcb) + " " + produitUc + " je recevrai "
          else
            phrase += "Au PCB je recevrai "
        }
        else
          phrase += "Si je commande 1 " + produitUc + " je recevrai "
        if (tarif.parKg > 0) {
          if (auPcb) {
            if (casReel)
              phrase += this.arrondirDecimales((tarif.parKg * (2 * tarif.pcb))) + " kg"
            else
              phrase += this.arrondirDecimales((tarif.parKg * tarif.pcb)) + " kg"
          }
          else
            phrase += this.arrondirDecimales(tarif.parKg) + " kg"

          if (tarif.parPCE > 0 || tarif.parL > 0)
            if (auPcb) {
              if (casReel)
                phrase += " (soit " + (tarif.parPCE > 0 ? this.arrondirDecimales(tarif.parPCE * (2 * tarif.pcb)) + " unité" + ((this.arrondirDecimales(tarif.parPCE * (2 * tarif.pcb))) > 1 ? "s" : "") : "") + (tarif.parPCE > 0 && tarif.parL > 0 ? " ou " : "") + (tarif.parL > 0 ? this.arrondirDecimales(tarif.parL * (2 * tarif.pcb)) + " litre" + ((this.arrondirDecimales(tarif.parL * (2 * tarif.pcb))) > 1 ? "s" : "") : "") + ")."
              else
                phrase += " (soit " + (tarif.parPCE > 0 ? this.arrondirDecimales(tarif.parPCE * tarif.pcb) + " unité" + ((this.arrondirDecimales(tarif.parPCE * tarif.pcb)) > 1 ? "s" : "") : "") + (tarif.parPCE > 0 && tarif.parL > 0 ? " ou " : "") + (tarif.parL > 0 ? this.arrondirDecimales(tarif.parL * tarif.pcb) + " litre" + ((this.arrondirDecimales(tarif.parL * tarif.pcb)) > 1 ? "s" : "") : "") + ")."
            }
            else
              phrase += " (soit " + (tarif.parPCE > 0 ? this.arrondirDecimales(tarif.parPCE) + " unité" + (tarif.parPCE > 1 ? "s" : "") : "") + (tarif.parPCE > 0 && tarif.parL > 0 ? " ou " : "") + (tarif.parL > 0 ? this.arrondirDecimales(tarif.parL) + " litre" + (tarif.parL > 1 ? "s" : "") : "") + ")."

          else
            phrase += "."
        }
        else if (tarif.parPCE > 0 || tarif.parL > 0)
          if (auPcb)
            phrase += (tarif.parPCE > 0 ? this.arrondirDecimales(tarif.parPCE * (2 * tarif.pcb)) + " unité" + ((this.arrondirDecimales(tarif.parPCE * (2 * tarif.pcb))) > 1 ? "s" : "") : "") + (tarif.parPCE > 0 && tarif.parL > 0 ? " ou " : "") + (tarif.parL > 0 ? this.arrondirDecimales(tarif.parL * (2 * tarif.pcb)) + " litre" + ((this.arrondirDecimales(tarif.parL * (2 * tarif.pcb)))  > 1 ? "s." : ".") : ".")
          else
            phrase += (tarif.parPCE > 0 ? this.arrondirDecimales(tarif.parPCE) + " unité" + (tarif.parPCE > 1 ? "s" : "") : "") + (tarif.parPCE > 0 && tarif.parL > 0 ? " ou " : "") + (tarif.parL > 0 ? this.arrondirDecimales(tarif.parL) + " litre" + (tarif.parL > 1 ? "s." : ".") : ".")
        
        else
          phrase = "Aucune conversion n'est paramétrée."
      }
      else
        phrase = "Aucun PCB n'est paramétré."

      return phrase
    }
  },
  created() {
    Object.assign(this.baseProduit, this.produit)
    this.baseTarifs = this.copieTarifs(this.produit.tarifs)
    this.chargerOptionsFournisseurs(this.fournisseurs.filter(f => this.produit.tarifs.map(t => t.fournisseurId).indexOf(f.fournisseurId) < 0))
  }
}
</script>

<style lang="scss" scoped>
.card-pdf {
  padding: 0 1.25rem;
}
.designation-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* .card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  border-top-right-radius: 0 !important;
} */
.card {
  border: 0;
  cursor: pointer;
}
.nav-tabs {
  border-bottom: 1px solid rgba(53, 199, 203, 0.22);
}
li {
  .nav-item {
    margin-right: 1rem !important;
  }
}
.pas-de-fournisseur {
  color: white;
  width: 200px;
  text-align: center;
  font-variant: all-small-caps;
  margin-top: 0.3rem;
  font-size: 1.5rem;
  line-height: 2rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.22);
  padding: 0 0 5px 0;
}
.switch-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.submit-container {
  text-align: center;
  margin-top: 2rem;
}
.switch-label {
  margin-right: 1rem;
}
.categorie-container {
  background: white;
  padding: 2px 4px;
}
.inline-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
}
.input-box {
  margin-left: 1rem;
  margin-right: 1rem;
}
.designation-input-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.container-fiches {
  border-bottom: 1px solid rgb(211, 211, 211);
  margin-bottom: 25px;
}
.tag-OK {
  background: #ffffff;
  padding: 0 3px;
  border-radius: 2px;
}
.post-it {
  padding: 2%;
  background-color: #ffffa5;
  box-shadow: 2px 4px 6px rgba(68, 68, 68, 0.28);
  border-bottom-right-radius: 60px 10px;
}
.number-input {
  width: 70px;
}
.saisieSurTitreBleu {
  border: 1px solid white !important;
  background-color: white;
  color: black;
}
.saisieRepartition {
  width: 60px;
}
.label-input {
  padding-right: 3px;
}
</style>
