<template>
  <div class="flex-center" style="width:276px;">
    <div class="arrows" @click="semainePrecedente(lundiDeLaSemaine)"><i class="fas fa-less-than"></i></div>
    <datepicker monday-first :language="fr" v-model="lundiDeLaSemaine" @input="surChangeDate($event)" :format="customFormatter" class="date-picker-semaine" id="myDatePicker" :disabled="disabled"></datepicker>
    <div class="arrows" @click="semaineSuivante(lundiDeLaSemaine)"><i class="fas fa-greater-than"></i></div>
  </div>
</template>
<script>         
import Datepicker from 'vuejs-datepicker'
import { fr } from 'vuejs-datepicker/dist/locale'
import { formatageDate } from '@/mixins/dateFormats'
import { getLundiDeLaSemaine } from '@/mixins/index'

export default {
  name: "ChoixSemaine",/* sélection d'une semaine */
  mixins: [formatageDate, getLundiDeLaSemaine],
  components: { Datepicker },
  props: {
    dateInitiale: Date,
    disabled: {type: Boolean, default: () => false}
  },
  data() {
    return {
      fr: fr,
      lundiDeLaSemaine: null,
    }
  },
  mounted() {
      this.lundiDeLaSemaine=this.dateInitiale
  },
  methods:
  {
    surChangeDate(evt) {
      this.lundiDeLaSemaine = evt
      this.$emit("choix-date-semaine",evt)
    },
    async semainePrecedente(lundi) {
      if (this.disabled) {
        return
      }
      let estOk = true
      if (this.$parent.peutChangerSemaine) {
        estOk = await this.$parent.peutChangerSemaine()
      }

      if (estOk) {
        this.lundiDeLaSemaine = new Date(lundi.getFullYear(), lundi.getMonth(), lundi.getDate() - 7)
        this.surChangeDate(this.lundiDeLaSemaine)
      }
    },
    async semaineSuivante(lundi) {
      if (this.disabled) {
        return
      }
      let estOk = true
      if (this.$parent.peutChangerSemaine) {
        estOk = await this.$parent.peutChangerSemaine()
      }
      if (estOk) {
        this.lundiDeLaSemaine = new Date(lundi.getFullYear(), lundi.getMonth(), lundi.getDate() + 7)
        this.surChangeDate(this.lundiDeLaSemaine)
      }
    },
  },
  computed: {
      customFormatter() {
      let date;
      if (this.lundiDeLaSemaine) {
        date = this.lundiDeLaSemaine
      } else {
        date = new Date()
      }
      let curr = new Date(date.getTime());
      let first = curr.getDate()
      let last = first + 6

      let firstDay = new Date(curr.setDate(first))
      let lastDay = new Date(curr.setDate(last))

      return firstDay.toLocaleDateString('fr-FR') + ' au ' + lastDay.toLocaleDateString('fr-FR')
    },
  }
}
</script>
<style scoped>
.fa-less-than,
.fa-greater-than {
  cursor: pointer;
  color: white;
}
.fa-less-than {
  margin-right: 0.5rem;
}
.fa-greater-than {
  margin-left: 0.5rem;
}
.arrows {
  margin: 0 !important;
  font-size: 2rem;
}
</style>
