<template>
  <div class="bg-white" id="fond" style="margin-top:-15px;">
    <PlanEdition ref="editionStructure" @retour-ajout-structure="surRetourAjoutSructure($event)" @structure-supprimee="setUrl();$router.go()" />

    <div class="container-fluid bg-gradient-bleu barreHaut" style="min-height:60px;padding:10px;align-items:center;" :key="keyHeader">
      <template v-if="ready">
        <div v-if="montrerLeMenu && menuId && nomDuMenu" class="titre-plan">{{nomDuMenu}}</div>
        <div v-else-if="nomDuPlanDeMenu" class="titre-plan">{{nomDuPlanDeMenu}}</div>
        <div v-if="menuOuPlanEnCours && menuOuPlanEnCours.lieuxPortageId && menuOuPlanEnCours.lieuxPortageId.length" class="flex-center" v-tooltip="{content: tooltiplieuxPortage(), placement: 'bottom'}">
          <i class="icone-portage" style="color:white;font-size:2em;max-height:30px"></i>
          <span class="badge badge-pill badge-success badge-up push-top-1rem">{{menuOuPlanEnCours.lieuxPortageId.length}}</span>
        </div>
        <div v-if="compAucunPlanDeMenu && !menuId && !montrerCreerMenuAvecPlan" style="color: white;font-size: 1rem;" class="error">Veuillez créer une structure de menu</div>
        <div v-if="!compAucunPlanDeMenu && !menuId && !montrerCreerMenuAvecPlan" style="color: white;font-size: 1rem;" class="error">Veuillez sélectionner une structure de menu</div>
        <b-form-select :value="menuId" @click.stop="checkDonneesModifiees()" @change="selectStructureMenu($event)" value-field="id" text-field="nom" :options="optionsPlansEtMenus" style="max-width:250px;">
          <template slot="first">
            <option :value="null" disabled>Sélectionnez</option>
          </template>
        </b-form-select>
        <ToggleButton name="afficherStructActives" v-model="afficherStructuresActives" @change="surChangementAffichageStrucActive()" @input="hasMods = true" 
          id="afficherStructActives" :disabled="estLectureSeule" :labels="{checked: 'Structures actives', unchecked: 'Toutes les structures'}" :color="{checked: '#16D39A', unchecked: '#CCCCCC'}" 
          :height="31" :width="190" style="margin-bottom:0;margin-left:10px;" />
        <ChoixSemaine :date-initiale="lundiDeLaSemaine" @choix-date-semaine="surChangementDate" style="margin-left:10px;margin-right:5px;" />
        <ToggleButton name="mode" id="mode" v-model="mode" :key="mode" @input="hasMods = true" :height="31" :width="145" style="margin-bottom:0;margin-left:10px;" 
          :labels="{checked: 'Mode complet', unchecked: 'Mode aperçu'}" :color="{checked: '#16D39A', unchecked: '#2C3E50'}" />
        <div class="boutons">
          <b-button @click="editerLePlanDeMenu()" v-if="(planId || menuId) && !estLectureSeule" class="btn-info">Éditer la structure de Menu</b-button>
          <b-button @click="validerMenu(menuId)" v-if="isAdmin && (planId || menuId) && !lectureSeule && !menuOuPlanEnCours.estValide" :disabled="donneesModifiees" class="btn-info">Valider le menu</b-button>
          <b-button @click="devaliderMenu(menuId)" v-if="isAdmin && (planId || menuId) && !lectureSeule && menuOuPlanEnCours.estValide" class="btn-info">Dévalider le menu</b-button>
          <MenuDuplication :disabled="donneesModifiees" :menuPlanId="menu && menu.menuPlanId" :lundiDeLaSemaine="lundiDeLaSemaine" :menuSurPlanAlimentaire="menu && menu.menuSurPlanAlimentaire" @menu-duplique="surDuplication" />
          <b-button v-if="montrerLeMenu" type="submit" class="btn btn-info" alt="Editer" @click="ouvrirOptionsExport()">
            <i :class="{ 'fa-spin ': enExportation }" class="fas fa-file-excel pr-2"></i>EXPORTER
          </b-button>
          <b-button v-if="montrerLeMenu && !estLectureSeule" :disabled="!donneesModifiees" type="submit" class="btn btn-success" alt="Enregistrer" @click="modifier(menu)">ENREGISTRER</b-button>
          <i v-if="montrerLeMenu && menuId && !estLectureSeule" @click.stop="supprimerMenu(menuId)" v-tooltip="{ content: 'Supprimer le Menu', placement: 'top' }" class="far fa-trash-alt" style="margin-left:auto;"></i>

          <b-modal v-model="afficherOptionsExport" size="md" hide-footer header-bg-variant="gradient-bleu" header-text-variant="light">
            <template slot="modal-title">
              <h3>Options d'export</h3>
            </template>
            <template slot="default">
              <div class="container-fluid">
                <div class="row">
                  <div class="card-body form-check background-primary form-check-inline">
                    <input type="checkbox" id="afficherPrix" class="form-check-input" v-model="paramExport.afficherPrix" />
                    <label class="form-check-label" for="afficherPrix">Afficher les prix ?</label>
                  </div>
                </div>
                <div class="row">
                  <div class="card-body form-check background-primary form-check-inline">
                    <input type="checkbox" id="afficherEffectif" class="form-check-input" v-model="paramExport.afficherEffectif" />
                    <label class="form-check-label" for="afficherEffectif">Afficher les effectifs ?</label>
                  </div>
                </div>
                <div class="row">
                  <div class="card-body form-check background-primary form-check-inline">
                    <input type="checkbox" id="afficherAllergenes" class="form-check-input" v-model="paramExport.afficherAllergenes" />
                    <label class="form-check-label" for="afficherAllergenes">Afficher les allergènes dans le menu ?</label>
                  </div>
                </div>
              </div>
              <div class="container-fluid flex-end">
                <b-button type="submit" class="btn btn-info" alt="Exporter" @click="exporter(menu)">
                  <i :class="{ 'fa-spin ': enExportation }" class="fas fa-file-excel pr-2"></i>EXPORTER
                </b-button>
              </div>
            </template>
          </b-modal>
        </div>
      </template>
    </div>

    <b-modal v-if="montrerModalPlanAlimentaire" v-model="montrerModalPlanAlimentaire" hide-footer header-bg-variant="gradient-bleu" header-text-variant="light">
      <template slot="modal-title">
        <h3>Créer un menu</h3>
      </template>
      <template slot="default">
        <div v-if="optionsPlansAlimentaires && optionsPlansAlimentaires.length" class="col-12 no-padding push-top-1rem">
          <b-form-select v-model="planAlimentaireSelectionneId" value-field="id" text-field="nom" :options="optionsPlansAlimentaires" style="width:100%;">
            <template slot="first">
              <option :value="null" selected>Aucun</option>
            </template>
          </b-form-select>
        </div>
        <div v-if="menuOuPlanEnCours && menuOuPlanEnCours.estUneDeclinaison" class="col-12">
          <label class="form-check-label" for="importerMenuUniversel" style="vertical-align:top;margin-left:25px;margin-top:9px;">
            <input v-model="importerMenuUniversel" :disabled="!menuOuPlanEnCours.menuUniverselDisponible" type="checkbox" id="importerMenuUniversel" class="form-check-input" style="height:15px;" />Importer menu Agap'pro
          </label>
          <div v-if="!menuOuPlanEnCours.menuUniverselDisponible" class="text-warning"><i class="fas fa-exclamation-triangle"></i> Aucun menu Agap'pro n'est encore disponible pour cette semaine</div>
        </div>
        <div class="flex-end">
          <b-button class="btn btn-primary mt-3" @click="creerMenuAvecPlan(planId, true)" :disabled="estLectureSeule">
            <span v-if="planAlimentaireSelectionneId">Créer les semaines de menus pour ce plan alimentaire</span>
            <span v-else>Créer le menu</span>
          </b-button>
          <b-button class="btn btn-primary mt-3 ml-2" @click="montrerModalPlanAlimentaire = false" :disabled="estLectureSeule">Annuler</b-button>
        </div>
      </template>
    </b-modal>

    <div class="container-fluid no-padding" style="min-height:65vh;background-color:inherit;">
      <div class="row">
        <div class="col-12 text-center push-top-2rem" v-if="montrerCreerMenuAvecPlan">
          <!-- Création de menu depuis bouton -->
          <template v-if="estLectureSeule">
            <b-button class="btn btn-secondary" style="margin-bottom: 1rem;margin-right: 10rem;" @click="creerMenuAvecPlan(planId, false)" :disabled="estLectureSeule">
              Aucun menu n'existe pour cette période sur {{nomDuPlanDeMenu}}
            </b-button>
          </template>
          <template v-else>
            <b-button class="btn btn-primary" style="margin-bottom: 1rem;margin-right: 10rem;" @click="creerMenuAvecPlan(planId, false)" :disabled="estLectureSeule">
              Créer une semaine {{nomDuPlanDeMenu}}
            </b-button>
          </template>
        </div>
        <div class="col-12">
          <transition name="fade" mode="out-in">
            <MenusEdition v-if="montrerLeMenu" ref="editionDetails"
              :key="menuEditionId" :menu="menu" :composanteId="composanteId" :lundiDeLaSemaine="lundiDeLaSemaine" :mode="mode" :lectureSeule="estLectureSeule"
              @effacement-recette="checkDonneesModifiees" @effectif-changed="checkDonneesModifiees" />
          </transition>
        </div>

        <transition name="fade">
          <b-modal v-if="montrerModalRecette" v-model="montrerModalRecette" @hide="cacherModalRecette" size="xl" hide-footer header-bg-variant="gradient-bleu" header-text-variant="light">
            <template slot="modal-title">
              <h3>{{ titreModalRecette }}</h3>
            </template>
            <template slot="default">
              <div class="col-12 no-padding push-top-1rem">
                <RecetteListe :menu="menu" :recetteList="recetteList" :composanteId="composanteId" :cleComposee="cleComposee" :serviceId="serviceId" :recetteId="recetteId" :composanteEffectif="composanteEffectif" :regimeIdsInit="regimesModalRecette" :textureIdsInit="texturesModalRecette" :categoriesGEMRCNIdsInit="categoriesGEMRCNModalRecette" @changement-recette="surChangementRecette" />
              </div>
            </template>
          </b-modal>
        </transition>
      </div>
    </div>

  </div>
</template>

<script>
import PlanEdition from '@/components/Menus/PlanEdition'
import { showAlerts } from '@/mixins/alerts'
import MenusEdition from '@/components/Menus/MenuEdition'
import { formatageDate } from '@/mixins/dateFormats'
import { connecteData } from "@/mixins/connecteData"
import { objetVide, alerteDonneesModifiees, getLundiDeLaSemaine } from '@/mixins/index'
import { creerMenu, modifierMenu, supprimerMenu, chargerMenuEtStructurePourDate, validerMenu, devaliderMenu } from '@/api/gpao/menus'
import { obtenirPourEditionDelinee } from "@/api/gpao/recettes"
import RecetteListe from '@/components/Recettes/RecetteListe'
import { exporterMenu } from '@/api/gpao/documents'
import { lancerTelechargementDeResponse } from "@/helpers/utils"
import { mapActions } from "vuex"
import MenuDuplication from '@/components/Menus/MenuDuplication'
import ChoixSemaine from '@/components/Inputs/ChoixSemaine.vue'
import { recetteLignesFromData, verifierAutorisations } from "@/mixins/recette"
import Vue from 'vue'
import lscache from "lscache"
import _ from 'lodash'

export default {
  name: "PageMenusListe",
  mixins: [showAlerts, objetVide, formatageDate, alerteDonneesModifiees, connecteData, getLundiDeLaSemaine, recetteLignesFromData, verifierAutorisations],
  components: { PlanEdition, MenusEdition, RecetteListe, MenuDuplication, ChoixSemaine },
  data() {
    return {
      menu: undefined,
      menusEtPlans: [],
      lundiDeLaSemaine: null,
      menuId: null,
      planId: null,
      recetteList: [],
      recetteId: null,
      composanteId: null,
      composanteEffectif: null,
      serviceId: null,
      plansDeMenus: [],
      plansDeMenusNonModifie: [],
      montrerLeMenu: false,
      montrerLePlan: false,
      aucunPlanDeMenu: false,
      btnRetourAuMenu: false,
      ready: false,
      nomDuPlan: '',
      menuEditionId: 1,
      cleComposee: null,
      montrerModalRecette: false,
      titreModalRecette: "",
      regimesModalRecette: [],
      texturesModalRecette: [],
      categoriesGEMRCNModalRecette: [],
      nomDuMenuData: '',
      dateChange: false,
      menuCree: false,
      baseUrl: '',
      enExportation: false,
      afficherOptionsExport: false,
      paramExport: { afficherPrix: false, afficherEffectif: false, afficherAllergenes: false },
      scrollTop: 0,
      lieuxPortage: [],
      mode: true,
      afficherStructuresActives: true,
      lectureSeule: false,
      keyHeader: 1,
      donneesModifiees: false,
      montrerModalPlanAlimentaire: false,
      optionsPlansAlimentaires: this.$store.state.definitions.plansAlimentaires,
      planAlimentaireSelectionneId: null,
      importerMenuUniversel: false
    }
  },
  computed: {
    menuOuPlanEnCours() {
      return this.menusEtPlans.find(x => x.id == this.menuId)
    },
    montrerCreerMenuAvecPlan() {
      if (!this.menuId)
        return false;
      return String(this.menuId).startsWith("p")
    },
    compAucunPlanDeMenu() {
      return this.aucunPlanDeMenu
    },
    nomDuPlanDeMenu() {
      return this.nomDuPlan
    },
    nomDuMenu() {
      let result = this.optionsPlansEtMenus.find(el => el.id === this.menuId)
      if (result) {
        // eslint-disable-next-line
        this.nomDuMenuData = result.nom
        return result.nom
      } else {
        // eslint-disable-next-line
        this.nomDuMenuData = ''
        return ''
      }
    },
    planDeMenuEditable() {
      return this.planId
    },
    optionsPlansEtMenus() {
      return this.menusEtPlans
    },
    menuVide() {
      return this.objetVide(this.menu)
    },
    creerUnPlan() {
      return this.menuId === "0"
    },
    estLectureSeule() {
      return this.lectureSeule || this.menuOuPlanEnCours?.estValide
    },
    enregistrerDonneesModifiees() {
      this.modifier(this.menu)
      return true
    }
  },
  watch: {
    afficherStructActives() {
      this.$store.commit('menus/saveFiltreStructActives', this.afficherStructuresActives)
      if (this.menuOuPlanEnCours)
        this.lectureSeule = this.menuOuPlanEnCours.nom.startsWith("[Inactif]") ? true : false;
    }
  },
  methods: {
    ...mapActions("uxHelpers", ["afficherAttente", "cacherAttente"]),
    tooltiplieuxPortage() {
      let texte = []
      this.menuOuPlanEnCours.lieuxPortageId.forEach(x => {
        const trouve = this.lieuxPortage.find(l => l.id == x).libelle
        texte.push(trouve)
      })
      texte.sort()
      return texte.join("<br>")
    },
    setUrl(menuId) {
      if (menuId) {
        //this.$router.push({name:"Menus", params:{etablissementId: this.etabCourantId, menuId: menuId,date: this.formatDateAvecTirets(this.lundiDeLaSemaine)}})
        history.pushState({}, '', "/" + this.etabCourantId + "/menus/" + this.formatDateAvecTirets(this.lundiDeLaSemaine) + '/' + menuId)
      } else {
        // this.$router.push({name:"Menus", params:{etablissementId: this.etabCourantId, date: this.formatDateAvecTirets(this.lundiDeLaSemaine)}})
        history.pushState({}, '', "/" + this.etabCourantId + "/menus/" + this.formatDateAvecTirets(this.lundiDeLaSemaine))
      }
    },
    checkDonneesModifiees() {
      if (!this.donneesInitiales) {
        this.donneesModifiees = false
      } else {
        const objInitiales = this.getMenuPourApi(this.donneesInitiales)
        objInitiales.composantes.sort((a, b) => { return a.id - b.id })
        const objEncours = this.getMenuPourApi(this.menu)
        objEncours.composantes.sort((a, b) => { return a.id - b.id })
        if (objInitiales.menuId !== objEncours.menuId) {//bugfix: évite les faux-positifs lors de la comparaison entre deux menus différents...
          this.donneesModifiees = false
        } else {
          const initiales = JSON.stringify(objInitiales)
          const encours = JSON.stringify(objEncours)
          this.donneesModifiees = initiales != encours
        }
      }
    },
    // appelé par le composant ChoixSemaine
    async peutChangerSemaine() {
      let estOk = !this.menu
      if (!estOk) {
        await this.checkDonneesModifiees();
        estOk = (!this.donneesModifiees)
      }
      return estOk
    },
    setDonneesInitiales(evt) {
      this.donneesInitiales = _.cloneDeep(evt)
    },
    editerLePlanDeMenu() {
      this.planId = this.planDeMenuEditable
      this.$refs.editionStructure.afficherModal(this.planId)
    },
    supprimerMenu(menuId) {
      return this.alerteConfirmation("Supprimer le menu", "Souhaitez-vous supprimer ce menu ?")
        .then((result) => {
          if (result) {
            // eslint-disable-next-line promise/no-nesting
            return supprimerMenu(this.nettoyerId(menuId))
              .then(() => {
                this.setUrl()
                this.$store.commit('menus/cleanDuMenuId')
                this.$router.go()
                return
              })
              .catch((error) => {
                console.error(error)
              })
          }
          return
        })
    },
    afficherModalRecettes(evt) {
      this.btnRetourAuMenu = false
      if (evt) {
        this.cleComposee = evt.cleComposee
        this.composanteId = evt.composanteId
        this.serviceId = evt.serviceId
        this.composanteEffectif = evt.effectif
        if (evt.recetteId) {
          this.recetteId = evt.recetteId
        }
        this.titreModalRecette = evt.titre
        this.regimesModalRecette = evt.regimeIds
        this.texturesModalRecette = evt.textureIds
        this.categoriesGEMRCNModalRecette = evt.categoriesGEMRCNIds
        this.montrerModalRecette = true
        this.scrollTop = window.document.documentElement.scrollTop//on garde le scroll avant affichage du modal
      }
    },
    cacherModalRecette() {
      this.montrerModalRecette = false
      this.$nextTick(function () {//on remet le scroll comme avant affichage du modal
        window.document.documentElement.scrollTop = this.scrollTop
      })
    },
    cacherModalPlanAlimentaire() {
      this.montrerModalPlanAlimentaire = false
      this.$nextTick(function () {//on remet le scroll comme avant affichage du modal
        window.document.documentElement.scrollTop = this.scrollTop
      })
    },
    ajouterRecetteDansMenu(evt) {
      var service = this.menu.services.find(service => service.id === evt.serviceId)
      const composante = service.composantes && service.composantes.find(composante => composante.cleComposee === evt.cleComposee && composante.composanteId === evt.composanteId)
      this.menu.recettes.push({ id: evt.recetteId, nom: evt.recetteNom, categoriesGEMRCNIds: evt.categoriesGEMRCNIds, allergenes: evt.allergenes, estUneDeclinaison: evt.estUneDeclinaison, estDeclinaisonPersonnalisee: evt.estDeclinaisonPersonnalisee })
      if (composante) {
        Vue.set(composante, "recetteId", evt.recetteId)
        composante.cotationManquante = evt.cotationManquante
        composante.prixServi = 0//force la mise à jour du prix de la recette
      }
      if (this.$refs.editionDetails) {
        this.$refs.editionDetails.majRecette(evt)
      }
      this.checkDonneesModifiees()
    },
    surChangementRecette(evt) {
      this.$refs.editionDetails.majRecette(evt)
      this.cacherModalRecette()
      return this.getDetailsRecette(evt.recetteId)
        .then(function (result) {
          this.ajouterRecetteDansMenu({
            recetteId: result.id,
            recetteNom: result.nom,
            composanteId: this.composanteId,
            serviceId: this.serviceId,
            cleComposee: this.cleComposee,
            cotationManquante: result.cotationManquante,
            categoriesGEMRCNIds: result.gemrcnIds,
            allergenes: result.allergenes,
            estUneDeclinaison: result.estUneDeclinaison,
            estDeclinaisonPersonnalisee: result.estDeclinaisonPersonnalisee
          })
          return
        }.bind(this))
    },
    hidePlan() {
      this.menuId = null
      this.montrerLePlan = false
      this.btnRetourAuMenu = false
    },
    nettoyerId(id) {
      return id.toString().slice(1)
    },
    getMenuPourApi(menu) {
      let menuPourApi = { menuId: menu && Number(menu.id), services: [], composantes: [] }
      if (menu && menu.services) {
        menu.services.map(service => {
          if (service.id !== -1) {
            menuPourApi.services.push(
              { id: service.id, effectif: service.effectif, effectifServi: service.effectifServi }
            )
          }
          if (service.composantes) {
            service.composantes.map(composante => {
              menuPourApi.composantes.push({ id: composante.id, recetteId: composante.recetteId, effectif: composante.effectif, effectifServi: composante.effectifServi })
            })
          }
        })
      }
      return menuPourApi
    },
    async modifier(menu) {
      await modifierMenu(this.getMenuPourApi(menu))
      this.menu = menu
      this.setDonneesInitiales(menu)
      this.checkDonneesModifiees()
      this.alerteSucces('Menu Sauvegardé !')
    },
    ouvrirOptionsExport() {
      this.afficherOptionsExport = true
    },
    async exporter(menu) {
      try {
        if (this.donneesModifiees) {
          await this.modifier(menu)
        }
        this.enExportation = true
        const response = await exporterMenu(menu.id, this.paramExport)
        lancerTelechargementDeResponse(response)
      }
      catch (erreur) {
        this.alerteErreur(erreur);
      }
      finally {
        this.enExportation = false
        this.afficherOptionsExport = false
      }
    },
    obtenirPlanDeMenu(id) {
      if (this.menusEtPlans.length) {
        let plan = this.menusEtPlans.find(p => p.id === id)
        if (plan) {
          this.nomDuPlan = plan.nom
          this.lectureSeule = plan.nom.startsWith("[Inactif]") ? true : false;
          plan = this.plansDeMenusNonModifie.find(p => p.nom === this.nomDuPlan)
          if (plan) {
            this.planId = plan.id
          } else {
            this.planId = id
          }
        } else {
          this.planId = undefined
        }
      }
    },
    async obtenirMenu(id) {
      this.$store.commit('uxHelpers/showSpinner')
      const data = await this.$store.dispatch('menus/obtenirMenu', id)
      this.menu = data
      this.setDonneesInitiales(data)
      this.menuId = 'm' + data.id
      this.planId = 'p' + this.menu.menuPlanId
      this.montrerLePlan = false
      this.montrerRecettes = false
      this.montrerLeMenu = true
      this.menuEditionId++
      this.$store.commit('uxHelpers/hideSpinner')
      this.lectureSeule = (this.menuOuPlanEnCours && this.menuOuPlanEnCours.nom.startsWith("[Inactif]")) ? true : false;
      this.ready = true
    },
    selectStructureMenu(evt) {
      this.menuId = evt
      this.formatDateAvecTirets(this.lundiDeLaSemaine)
      this.obtenirMenuPlan(evt)
    },
    menuCreeAvecBouton(id) {
      const menuId = 'm' + id
      this.setUrl(menuId)
      this.chargerMenusEtStructures(this.lundiDeLaSemaine, id)
    },
    async obtenirMenuPlan(id) {
      if (typeof (id) === 'number') {
        await this.obtenirMenu(id)
      } else if (id === 'p0') {// nouvelle structure de menu à créer provenant du select
        this.menuId = id
        this.nomDuPlan = ''
        this.planId = null
        this.montrerLePlan = true
        this.montrerLeMenu = false
        this.btnRetourAuMenu = true
        this.$refs.editionStructure.afficherModal()
      } else if (id.charAt(0) === 'p') {// nouveau menu à partir d'une structure de menu
        this.obtenirPlanDeMenu(id)
        this.menu = undefined;
        this.menuId = id;
        this.montrerLePlan = false
        this.montrerLeMenu = false
        this.btnRetourAuMenu = false
        this.setUrl(id)
      } else if (id.charAt(0) === 'm') {// menu existant
        this.setUrl(id)
        const menuId = this.nettoyerId(id)
        await this.obtenirMenu(menuId)
      } else if (id === '') {
        this.menuId = null
        this.planId = null
        this.montrerLePlan = false
        this.montrerLeMenu = false
        this.btnRetourAuMenu = false
      }
    },
    surDuplication(e) {
      if (e.structureMenuDestinationIds.length) {
        this.menu = null
        this.menuId = e.structureMenuDestinationIds[0]
      }
      return this.surChangementDate(e.dateDestination)
        .then(() => {
          this.keyHeader += 1
          this.checkDonneesModifiees()
          return
        })
    },
    surChangementDate(evt) {
      this.lundiDeLaSemaine = evt
      this.montrerLeMenu = false
      this.dateChange = true
      this.estLectureSeule;
      return this.chargerMenusEtStructures(this.lundiDeLaSemaine)
    },
    async apresSelectionDate() {
      let selection, structId, id
      if (this.menu) {
        structId = this.menu.menuPlanId
      }
      else {
        structId = this.menuId
      }
      if (structId) {
        id = structId.toString() //c'est pas clean, mais ca a l'air tout couvrir
        if (id.startsWith("p")) {
          id = id.slice(1)
        }
        selection = this.menusEtPlans.find(x => x.structId == id)
        if (!selection) {
          selection = this.menusEtPlans.find(x => x.id == 'p' + id)
        }
      }

      if (selection) {
        this.nomDuPlan = selection.nom
        this.menuId = selection.id
        if (!selection.estStructure) {
          await this.obtenirMenu(this.nettoyerId(selection.id))
        }
      }
      /*
        selection = this.plansDeMenusNonModifie.find(el => el.id === this.menu.menuPlanId)
      if (this.menuId && this.menuId.startsWith('p')) {
        const planId = parseInt(this.menuId.substring(1), 10)
        selection = this.plansDeMenusNonModifie.find(el => el.id == planId)
      }
      let result = ''
      if (selection) {
        this.nomDuplan = selection.nom
        result = this.menusEtPlans.find(p => p.nom === this.nomDuplan)
        if (result.id.charAt(0) === 'p') {
          this.menuId = result.id
          this.montrerCreerMenuAvecPlan = true
        }
        if (result.id.charAt(0) === 'm') {
          const menuId =
          await this.obtenirMenu(menuId)
        }
      }*/
      this.setUrl(this.menuId)
    },
    surRetourAjoutSructure(evt) {
      this.planId = evt.planId
      if (evt.creer) {
        this.creerMenuAvecPlan(this.planId)
      }
      else {
        this.menusEtPlans.unshift({ estStructure: true, estPerso: evt.estPerso, id: 'p' + evt.planId, nom: evt.nom, structId: null })
      }
    },
    creerMenuAvecPlan(planId, depuisModal) {
      if (!depuisModal && (this.optionsPlansAlimentaires?.length || this.menuOuPlanEnCours?.estUneDeclinaison)) {
        this.planAlimentaireSelectionneId = null
        this.importerMenuUniversel = false
        this.montrerModalPlanAlimentaire = true
        return
      }

      this.menuCree = true
      let id = typeof (planId) === 'number' ? planId : planId.slice(1)
      this.planId = id
      let apiDate = this.formatDateAvecTirets(this.lundiDeLaSemaine)

      creerMenu({ "dateDebut": apiDate, "menuPlanId": id, "planAlimentaireId": this.planAlimentaireSelectionneId, "importerMenuUniversel": this.importerMenuUniversel, "etablissementId": this.etabCourantId })
        .then((response) => {
          this.menuId = response.data
          this.menuCreeAvecBouton(this.menuId)
          this.checkDonneesModifiees()
          return
        })
        .catch((error) => {
          console.error(error)
        })

      this.montrerModalPlanAlimentaire = false
    },
    validerMenu(menuId) {
      this.alerteConfirmation("Valider le menu", "Le menu deviendra accessible aux utilisateurs et il ne sera plus possible de le modifier", "Valider", "Annuler")
        .then(result => {
          if (!result) {
            return
          }
          validerMenu(this.nettoyerId(menuId))
            .then((response) => {
              this.menuOuPlanEnCours.estValide = true
              return
            })
            .catch((error) => {
              console.error(error)
              this.alerteErreur(error);
            })
        })
    },
    devaliderMenu(menuId) {
      this.alerteConfirmation("Dévalider le menu", "Le menu ne sera plus accessible aux utilisateurs et il redeviendra possible de le modifier", "Dévalider", "Annuler")
        .then(result => {
          if (!result) {
            return
          }
          devaliderMenu(this.nettoyerId(menuId))
            .then((response) => {
              this.menuOuPlanEnCours.estValide = false
              return
            })
            .catch((error) => {
              console.error(error)
              this.alerteErreur(error);
            })
        })
    },
    async chargerMenusEtStructures(date, evt) {
      this.lundiDeLaSemaine = this.getLundiDeLaSemaine(date)
      let apiDate = this.formatDatePourApiSansSeparation(this.lundiDeLaSemaine)
      // if (!this.afficherStructActives)
      // this.afficherStructActives = true; 
      var payLoad = { apiDate: apiDate, etablissementId: this.etabCourantId, actifSeulement: this.afficherStructuresActives };
      const reponse = await chargerMenuEtStructurePourDate(payLoad)
      this.menusEtPlans = reponse.data.liste
      this.lieuxPortage = reponse.data.lieux
      this.plansDeMenus = this.menusEtPlans.filter(x => x.estStructure)
      this.plansDeMenusNonModifie = _.cloneDeep(this.plansDeMenus)
      this.menusEtPlans.forEach(x => {
        if (x.estStructure) {
          x.id = 'p' + x.id
        }
        else {
          x.id = 'm' + x.id
        }
      })
      this.menusEtPlans.push({ id: 'p0', nom: ' + CRÉEZ UNE STRUCTURE DE MENU' })
      this.aucunPlanDeMenu = this.menusEtPlans.length === 1

      if (this.dateChange) {
        this.dateChange = false
        await this.apresSelectionDate()
      }
      if (this.menuCree) {
        this.menuCree = false
        await this.obtenirMenuPlan(evt)
      }
      this.ready = true
    },
    surChangementAffichageStrucActive() {
      this.$store.commit('menus/saveFiltreStructActives', this.afficherStructuresActives);
      return this.chargerMenusEtStructures(this.lundiDeLaSemaine);
    },
    getDetailsRecette(recetteId) {
      lscache.enableWarnings(true)
      var recette = lscache.get("Recette-" + recetteId)
      if (recette && (recette.utilisateurId || recette.recetteOrigineId)) {//si c'est une recette unverselle non déclinée on n'utilise pas le cache
        return Promise.resolve(recette)
      }
      return obtenirPourEditionDelinee(recetteId, this.etabCourantId)
        .then(function (response) {
          this.verifierAutorisations(response.data)
          response.data.lignesCompletees = this.recetteLignesFromData(response.data)
          lscache.set("Recette-" + response.data.id, response.data, 5)
          return response.data
        }.bind(this))
    },
  },
  async mounted() {
    this.afficherAttente()
    this.baseUrl = window.location.origin + '/' + this.etabCourantId + '/menus'
    const initialiseParUrl = this.$route.params.date || this.$route.params.menuId
    this.afficherStructuresActives = this.$store.state.menus.filtreStrucActivesUniquement;
    if (this.$route.params.date && this.$route.params.menuId) {
      this.lundiDeLaSemaine = new Date(this.$route.params.date)
      await this.chargerMenusEtStructures(this.lundiDeLaSemaine)
      this.obtenirMenuPlan(this.$route.params.menuId)
    }
    else if (this.$route.params.menuId) {
      await this.chargerMenusEtStructures(new Date())
        .then(() => this.obtenirMenuPlan(this.$route.params.menuId))
    }
    if (this.$route.params.date) {
      this.lundiDeLaSemaine = new Date(this.$route.params.date)
      await this.chargerMenusEtStructures(this.lundiDeLaSemaine)
    }
    if (!initialiseParUrl) {
      let date = this.$store.state.menus.date
      if (date) {
        this.lundiDeLaSemaine = new Date(date)
        date = this.lundiDeLaSemaine
      } else {
        date = new Date()
      }
      await this.chargerMenusEtStructures(date)
      if (this.$store.state.menus.menuId && this.menusEtPlans.find(m => m.id === this.$store.state.menus.menuId)) {
        this.obtenirMenuPlan(this.$store.state.menus.menuId)
      }
    }

    if (this.$route.params.ajout) {
      while (!this.menu) {
        // eslint-disable-next-line promise/avoid-new
        await new Promise(resolve => setTimeout(resolve, 10))
      }
      this.composanteId = this.$route.params.ajout.composanteId
      this.serviceId = this.$route.params.ajout.serviceId
      this.cleComposee = this.$route.params.ajout.cleComposee
      this.surChangementRecette(this.$route.params.ajout)
    }

    this.cacherAttente()

    this.$root.$on('afficher-modal-recettes', this.afficherModalRecettes)

    //this.$root.$on('ajouter-recette-dans-menu', this.ajouterRecetteDansMenu)

  },
  beforeRouteLeave(to, from, next) {
    if (this.lundiDeLaSemaine && this.menuId) {
      this.$store.commit('menus/setRouteEnMemoire', { date: this.formatDateAvecTirets(this.lundiDeLaSemaine), menuId: this.menuId })
    }
    next();
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/couleurs.scss";

.barreHaut {
  width: 100%;
  min-height: 60px;
  padding: 10px;
  display: flex;
  align-items: flex-start;
}
.boutons {
  display: flex;
  flex-wrap: wrap;
  margin-top: -5px;
}
.boutons > * {
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
}
.radios {
  color: white;
}
.radios > label {
  white-space: nowrap;
  margin-bottom: 0;
  margin: 0 3px;
}
.radios > label > input {
  height: 13px;
  margin: 0 3px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.titre-plan {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  font-size: 1.5rem;
  padding: 0.5rem;
  color: white;
}
.fa-trash-alt {
  color: #ffffff;
  background: #ea0000de;
  padding: 10px;
  border-radius: 5px;
  margin-left: 0rem;
  cursor: pointer;
}
#lblLieuxCount {
  font-size: 16px;
  background: $vert-agap;
  color: #fff;
  padding: 0 5px;
  vertical-align: top;
  margin-left: -10px;
}
#fond {
  height: calc(100vh - 40px);
}
</style>
