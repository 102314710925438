<template>
  <div class="card" style="height:300px;">
    <div class="card-content">
      <div class="card-body">
        <div class="widgetTitle">DLC/DDM</div>

        <div v-if="!gotDLC" style="text-align:center;margin-top:50px;">
          <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style='height:100px;margin-left:-8px;' />
        </div>

        <div v-else>
          <div style="text-align:center;font-weight:bold;font-size:75px;margin-top:-10px;margin-bottom:-18px;">{{nombreDLC}}</div>
          <div style="text-align:center;font-size:10px;font-weight:bold;">produits arrivent</div>
          <div style="text-align:center;font-size:10px;font-weight:bold;">bientôt à expiration</div>
          <div v-if="dlcCourtes.length" style="margin-top:10px;">
            <div class="flex-center-space-between" style="font-size:13px;">
              <b>Produits</b><b title="Date de Durabilité Minimale (ex. DLUO)">DLC/DDM</b>
            </div>
            <div v-for="(produit, i) in dlcCourtes" :key="i" class="flex-center-space-between" :style="'margin-top:5px;' + (produit.depasse === 'dlc' ? ' color:#ea0000de;' : '') + (produit.depasse === 'dluo' ? ' color:orange' : '')" :title="(produit.depasse === 'dlc' ? 'DLC dépassée' : '') + (produit.depasse === 'dluo' ? 'DDM dépassée' : '')">
              <div style="font-size:12px;overflow:hidden;max-height:18px;">{{produit.nomProduit}}</div>
              <div style="font-size:12px;font-weight:bold;">{{produit.txtDate}}</div>
            </div>
          </div>
          <div style="position:absolute;bottom:0;left:0;width:100%;height:30px;">
            <router-link tag="div" style="text-align:center;color:grey;cursor:pointer;"
              :to="{ name: 'Stock', params: { etablissementId: this.etabCourantId } }"
              v-tooltip="{ content: 'Accéder au stock', placement: 'bottom' }">
              <i class="fas fa-chevron-down"></i>
            </router-link>
          </div>
        </div>

      </div>
    </div>
  </div>
  
</template>

<script>
import { obtenirStock } from '@/api/gpao/stock'
import { connecteData } from "@/mixins/connecteData"
import { formatageDate, formatHumanDateAvecSlashes } from '@/mixins/dateFormats'

export default {
  name: "WidgetDLC",
  mixins: [ formatageDate, formatHumanDateAvecSlashes, connecteData ],
  data() {
    return {
      dlcCourtes: [],
      gotDLC: false,
      nombreDLC: "00",
      maxLignesAffichage: 3
    }
  },
  mounted() {
    this.chargerDlcCourtes()
  },
  methods: {
    chargerDlcCourtes() {
      const today = new Date()
      const dateDebut = this.formatDateAvecTirets(today)
      const dateFin = this.formatDateAvecTirets(new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7))
      return obtenirStock(this.etabCourantId, dateDebut, false) // Ne récupère que les lignes présentes en stock (avec quantité différente de 0)
        .then(function (r) {
          this.dlcCourtes = []
          r.data.zonesStockage.forEach(function (zoneStockage) {
            zoneStockage.ingredients.forEach(function (ingredient) {
              ingredient.lots.forEach(function (lot) {
                var dlc = lot.dlcDluo && new Date(lot.dlcDluo)
                if (dlc) {
                  dlc = this.formatDateAvecTirets(dlc)
                  if (dlc && dlc <= dateFin) {
                    this.dlcCourtes.push({
                      nomProduit: ingredient.nom,
                      txtDate: this.formatHumanDateAvecSlashes(dlc),
                      date: dlc,
                      depasse: dlc >= dateDebut ? "" : (
                        lot.dlc ? "dlc" : "dluo"
                      )
                    })
                  }
                }
              }.bind(this))
            }.bind(this))
          }.bind(this))
          this.dlcCourtes.sort((a, b) => a.date > b.date ? 1 : -1)
          this.nombreDLC = String(this.dlcCourtes.length)
          if (this.nombreDLC.length < 2) {
            this.nombreDLC = "0" + this.nombreDLC
          }
          this.dlcCourtes = this.dlcCourtes.splice(0, this.maxLignesAffichage)
          this.$nextTick(() => this.gotDLC = true)
          return
        }.bind(this))
    }
  }
}
</script>
