<template>
  <div class="container-fluid">
    <div class="row">
      <b-modal v-model="afficherModale" size="xl" hide-footer header-bg-variant="gradient-bleu" header-text-variant="light">
        <template slot="modal-title">
          <h3><i class="fas fa-exchange-alt" style="margin-right:1rem"></i>Ajouter un produit gré à gré</h3>
        </template>
        <template slot="default">
          <AjoutProduitGreAGre :familleId="familleId" @produit-ajoute="produitAjoute($event)"/>
        </template>
      </b-modal>
      <div class="col-12">
        <div class="card bg-white">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-4 col-xl-auto" style="display:flex">
                <b-form-select value-field="id" text-field="nom" :options="optionsFamilles" class="selectpicker" v-model="familleId" @change="selectFamilleDeProduit($event)" title="Choix de la famille à afficher">
                  <template slot="first">
                    <option :value="null" disabled>Sélectionnez une famille...</option>
                  </template>
                </b-form-select>
                <button v-if="familleId && !surGreAGre" class="btn vertAgap" type="button" @click='surReordonnerFamille()' title="Trier par ordre alphabétique">
                  <i class="fas fa-sort"></i>
                </button>
              </div>
              <!-- on affiche pas la recherche textuelle en gre a gre si pas de famille => necessiterait une nouvelle api -->
              <div v-if="!surGreAGre || familleId" class="col-4 col-xl-auto">
                <input type="text" v-model="termeDeRecherche" @keyup="startTimeoutRecherche" :placeholder="placeholder" class="input-search" />
              </div>
              <div v-if="showSortingElements" class="col-12 col-xl-auto">
                <b-pagination class="flex-center-center" v-model="currentPage" :total-rows="totalRows" :per-page="perPage" />
              </div>
              <div v-if="!surGreAGre" class="col-12 col-xl-auto">
                <div v-if="showSortingElements" id="checkboxes">
                  <div v-for="(status,index) in statuses" :key="index" class="form-check background-primary form-check-inline">
                    <input class="form-check-input" type="checkbox" :id="'checkbox' + index" v-model="status.checked" />
                    <label :for="'checkbox' + index" class="form-check-label">{{ status.value }}</label>
                  </div>
                </div>
              </div>
              <div v-else-if="familleId" class="col-4 col-xl-auto" @click="ajouterProduitGreAGre()">
                <button class="btn vertAgap" type="button">
                  <i class="fas fa-plus"></i>
                  Ajouter un produit
                </button>
              </div>
              <div v-if="(!surGreAGre || familleId) && avertissements" class="col-4 col-xl-auto">
                <button type="button" :class="'btn btn-danger' + (filtrerAvertissements ? '' : ' disabled')" v-tooltip="{
                  content: (filtrerAvertissements
                    ? ('Filtre actif (' + nombreAvertissements + ')')
                    : avertissements),
                  placement: 'bottom',
                  classes: ['tooltip-light']
                }"
                @click="filtrerAvertissements = !filtrerAvertissements">
                  <i class="fa fa-exclamation-triangle"></i> Avertissements produits
                </button>
              </div>
              <div v-if="!surGreAGre || familleId" class="col-4 col-xl-auto">
                <button type="button" class="btn btn-warning disabled" v-tooltip="{ 
                  content: 'L\’affichage des allergènes est effectué à titre informatif.<br />Chaque utilisateur du logiciel doit vérifier par lui-même la véracité de ces informations auprès du ou des fournisseurs de denrées alimentaires concernés.<br />En cas d\’erreur, l\’utilisateur ne pourra pas engager la responsabilité de la société Agap\’pro, éditrice du logiciel.', 
                  placement: 'bottom', 
                  classes: ['tooltip-light']
                }">
                  <i class="fa fa-info"></i> Informations allergènes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="produitsAffiches && produitsAffiches.length">
      <div v-for="(item, index) in produitsAffiches" @click="selectedTab = item" :key="item.produitId">
        <div v-if="(filterEstOk && estOk(item)) || (filterAfaire && aFaire(item)) || (filterInvalide && invalide(item)) || (filterVide)">
          <FicheTechnique :ref="item.produitId" :class="{activeTab: selectedTab === item}"
          :index="index" :listData="produitsAffiches" :produit="item" :familleId="familleId" :fournisseurs="fournisseurs" :filters="selectedFilters" :selected="selectedTab === item" :surGreAGre="surGreAGre"
          @produit-supprime="produitSupprime()" @raz-tarifs="RAZTarifs($event)"  @produit-modifie="surProduitModifie()"/>
        </div>
      </div>
    </template>
    <template v-if="produitsAffiches && produitsAffiches.length ==0">
      &nbsp;&nbsp;Aucun produit trouvé
    </template>
  </div>
</template>

<script>
import FicheTechnique from "@/components/FichesTechniques/FicheTechnique"
import { obtenirDonneesProduits, verifFicheTechDesignation } from "@/api/gpao/fournisseurs"
import { reordonnerFamille } from "@/api/gpao/produits"
import { setProduitStatus } from "@/mixins/ficheTechnique"
import { showAlerts } from "@/mixins/alerts"
import { connecteData } from "@/mixins/connecteData"
import AjoutProduitGreAGre from '@/components/Divers/AjoutProduitGreAGre'
import { fournisseurs } from '@/mixins/fournisseurs'
import Fuse from "fuse.js"

import _ from "lodash"

export default {
  name: "PageFichesTechniques",
  components: {
    FicheTechnique,
    AjoutProduitGreAGre
  },
  mixins: [setProduitStatus, showAlerts, connecteData, fournisseurs],
  data() {
    return {
      enChargement: false,
      surGreAGre: false,
      familleId: null,
      typeDeRecherche: "",
      fournisseurs: [],
      selectedTab: null,
      optionsFamilles: [],
      perPage: 10,
      currentPage: 1,
      produits: [],
      produitsAffiches: [],
      statuses: [
        {
          checked: false,
          value: "EST OK"
        },
        {
          checked: false,
          value: "À FAIRE"
        },
        {
          checked: false,
          value: "INVALIDE"
        }
      ],
      termeDeRecherche: "",
      tousLesProduits: [],
      afficherModale: false,
      avertissements: "",
      filtrerAvertissements: false,
      timeoutRecherche: null
    }
  },
  watch: {
    filtrerAvertissements() {
      this.currentPage = 1
      this.rechercheDeProduit()
    },
    currentPage() {
      this.updateProduitsAffiches()
    }
  },
  computed: {
    filterEstOk() {
      return this.selectedFilters.includes("EST OK")
    },
    filterAfaire() {
      return this.selectedFilters.includes("À FAIRE")
    },
    filterInvalide() {
      return this.selectedFilters.includes("INVALIDE")
    },
    filterVide() {
      return this.selectedFilters.length === 0
    },
    placeholder() {
      if (this.familleId) {
        return "Affiner la recherche..."
      } else {
        return "Rechercher dans toutes les familles..."
      }
    },
    totalRows() {
      return this.produits.length
    },
    selectedFilters() {
      let filters = []
      let checkedFiters = this.statuses.filter(obj => obj.checked)
      checkedFiters.forEach(element => {
        filters.push(element.value)
      })
      return filters
    },
    showSortingElements() {
      return this.produits.length !== 0 && this.totalRows > this.perPage
    },
    nombreAvertissements() {
      var nb = this.produits.filter(p => this.erreurIngredient(p) || this.erreurAllergenes(p) || this.erreurRepartitions(p)).length
      if (nb === 1) {
        return nb + ' produit en erreur'
      }
      if (nb > 1) {
        return nb + ' produits en erreur'
      }
      return ""
    }
  },
  methods: {
    updateProduitsAffiches() {
      if (this.nombreAvertissements && this.filtrerAvertissements) {
        this.produits = this.produits.filter(p => this.erreurIngredient(p) || this.erreurAllergenes(p) || this.erreurRepartitions(p))
      }
      this.produitsAffiches = this.produits.length !== 0 && this.produits.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      )
    },
    surReordonnerFamille() {
      reordonnerFamille(this.familleId)
        .then(() => {
          this.toastSucces("Famille réordonnée !")
          this.selectFamilleDeProduit(this.familleId)
          return
        })
        .catch(() => this.toastErreur("Erreur sur réordonnancement de la famille")
      )
    },
    setUrl() {
      let url = this.$route.path //.substr(0,+1)
      var lastIndex = this.$route.path.indexOf("/", 1)
      if (lastIndex > 0) {
        url = this.$route.path.substr(0, lastIndex)
      }
      if (!url.endsWith("/"))
        url += "/"
      if (this.familleId) {
        url += this.familleId
      }
      if (this.selectedFilters.length) {
        url += "/" + this.selectedFilters
      }
      if (this.selectedFilters.length && this.termeDeRecherche) {
        url += "/" + this.termeDeRecherche
      }
      if (!this.selectedFilters.length && this.termeDeRecherche) {
        url += "/" + "EST OK,À FAIRE,INVALIDE" + "/" + this.termeDeRecherche
      }
      history.pushState({}, "", url)
    },
    RAZTarifs(produit) {
      let index = this.produits.indexOf(produit)
      this.$set(this.produits, index, produit)
    },
    setNewProduitStatus(produit) {
      this.setProduitStatus(produit)
    },
    setProduitPasOk(produit) {
      console.log("produit pas ok")
      _.remove(produit.status, function (el) {
        return el === "EST OK"
      })
    },
    ajouterProduitGreAGre() {
      this.afficherModale = true
    },
    produitAjoute(event) {
      this.afficherModale = false
      this.produits = []
      this.setUrl()
      let promiseRequete = obtenirDonneesProduits(this.familleId, this.surGreAGre ? this.etabCourantId : undefined)
      return this.obtenirLaListeDeProduits(promiseRequete)
        .then(function () {
          this.selectedTab = this.produitsAffiches.find(x => x.produitId === event.produit.produitId)
          this.scrollTo(event.produit.produitId)
          this.checkProduits()
        }.bind(this))
    },
    surProduitModifie() {
      this.updateProduitsAffiches()
      this.checkProduits()
    },
    async produitSupprime() {
      this.produits = []
      this.setUrl()
      let promiseRequete = obtenirDonneesProduits(this.familleId, this.surGreAGre ? this.etabCourantId : undefined)
      await this.obtenirLaListeDeProduits(promiseRequete)
      this.checkProduits()
    },
    async obtenirLaListeDeProduits(promiseRequete) {
      this.$store.commit('uxHelpers/showSpinner')
      try {
        const response = await promiseRequete
        let data = response.data
        this.familleId = data.familleId
        this.fournisseurs = data.fournisseurs
        this.fournisseurs.forEach(f => {
          f.nom = this.fournisseurNom(f.fournisseurId)
        })
        let categorie = null
        let sousCategorie = null
        for (let i = 0; i < data.produits.length; i++) {
          let produit = data.produits[i]
          if (!produit.ingredientId)            {
            produit.ingredientId='' // pour la réactivité
            }
          if (produit.hierarchieProduit === 2) {
            categorie = produit.designation
          }
          if (produit.hierarchieProduit === 3) {
            sousCategorie = produit.designation
          }
          if (produit.hierarchieProduit === 1) {
            produit["categorie"] = categorie
            produit["sousCategorie"] = sousCategorie
            this.setProduitStatus(produit)
            this.produits.push(produit)
          }
        }
        this.tousLesProduits = this.produits
        this.selectedTab = this.produits[0]
      } finally {
        this.updateProduitsAffiches()
        this.checkProduits()
        this.$store.commit("uxHelpers/hideSpinner")
      }
      return true
    },
    startTimeoutRecherche() {
      clearTimeout(this.timeoutRecherche)
      this.timeoutRecherche = setTimeout(this.rechercheDeProduit, 500)
    },
    rechercheDeProduit() {
      if (this.familleId) {
        this.setUrl()
        // pas de requête recherche à la mano dans les produits chargés avec une famille
        if (this.termeDeRecherche) {
          let fuse = new Fuse(this.tousLesProduits, {keys: ['designation', 'produitId']})
          this.produits = fuse.search(this.termeDeRecherche).map(r => r.item)
        } else {
          this.produits = this.tousLesProduits
        }
        this.currentPage = 1
        this.checkProduits()
        this.updateProduitsAffiches()
      } else {
        this.produits = []
        // requête de recherche dans toutes les familles
        let promiseRequete = verifFicheTechDesignation(this.termeDeRecherche)
        this.obtenirLaListeDeProduits(promiseRequete)
      }
    },
    selectFamilleDeProduit(event) {
      this.produits = []
      this.familleId = event
      this.termeDeRecherche = ""
      this.currentPage = 1
      this.setUrl()
      let promiseRequete = obtenirDonneesProduits(this.familleId, this.surGreAGre ? this.etabCourantId : undefined)
      return this.obtenirLaListeDeProduits(promiseRequete)
    },
    estOk(produit) {
      this.setUrl()
      return produit.status.includes("EST OK")
    },
    invalide(produit) {
      this.setUrl()
      return produit.status.includes("INVALIDE")
    },
    aFaire(produit) {
      this.setUrl()
      return produit.status.includes("À FAIRE")
    },
    scrollTo(produitId) {
      this.$refs[produitId][0].scroll()
    },
    erreurIngredient(produit) {
      return !produit.ingredientId
    },
    erreurAllergenes(produit) {
      return produit.tarifs.length && produit.tarifs.filter(p => (!p.allergenesMajeurs || p.allergenesMajeurs.length === 0) && !p.aucunAllergene).length
    },
    erreurRepartitions(produit) {
      return produit.tarifs.length && produit.tarifs.filter(p => !p.parKg && !p.parL && !p.parPCE).length
    },
    checkProduits() {
      var avertissements = []
      this.produits.forEach(produit => {
        var avertissement = ""
        var nb = 0
        var masc = false
        if (avertissements.length < 20) {
          if (this.erreurIngredient(produit)) {
            avertissement = "Ingrédient"
            nb += 1
            masc = true
          }
          if (this.erreurAllergenes(produit)) {
            avertissement += (avertissement ? ", a" : "A") + "llergènes"
            nb += 2
            masc = true
          }
          if (this.erreurRepartitions(produit)) {
            avertissement += (avertissement ? " et r" : "R") + "épartitions"
            nb += 2
          }
        }
        if (avertissement) {
          avertissement += " manquant" + (masc ? "" : "e") + (nb > 1 ? "s" : "") + " pour \"" + produit.designation + "\""
          avertissements.push(avertissement)
        }
      })
      if (avertissements.length) {
        this.avertissements = avertissements.join("<br/>")
      } else {
        this.avertissements = ""
      }
    }
  },
  async created() {
    this.surGreAGre = this.$route.path.startsWith("/mon")
    this.optionsFamilles = this.$store.state.definitions.familles

    const checkDesAutresParametres = (termeDeRecherche, status) => {
      if (termeDeRecherche) {
        this.termeDeRecherche = termeDeRecherche
        this.rechercheDeProduit()
      }
      if (status) {
        let arr = status.split(",")
        this.statuses = this.statuses.map(el => {
          let result = arr.find(item => item === el.value)
          if (result) {
            return { value: result, checked: true }
          } else {
            return el
          }
        })
      } else {
        this.$route.params.statuses = "?"
      }
    }
    if (this.$store.state.fichesTechniques.familleId) {
      this.familleId = this.$store.state.fichesTechniques.familleId
      let promiseRequete = obtenirDonneesProduits(this.familleId, (this.surGreAGre) ? this.etabCourantId : undefined)
      await this.obtenirLaListeDeProduits(promiseRequete)
    }
    checkDesAutresParametres(this.$store.state.fichesTechniques.termeDeRecherche, this.$store.state.fichesTechniques.statuses)
    this.setUrl()

    if (this.$route.params.familleId) {
      this.familleId = this.$route.params.familleId
      let requete = obtenirDonneesProduits(this.familleId, this.surGreAGre ? this.etabCourantId : undefined)
      await this.obtenirLaListeDeProduits(requete)
    }
    checkDesAutresParametres(this.$route.params.termeDeRecherche, this.$route.params.statuses)
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('fichesTechniques/setRouteEnMemoire', {
      familleId: this.familleId,
      statuses: this.statuses.toString(),
      termeDeRecherche: this.termeDeRecherche
    })
    next();
  }
}
</script>

<style lang="scss" scoped>
.form-check {
  color: white;
  padding: 3px 15px;
  border-radius: 3px;
  background: #4d80d1;
  box-shadow: inset 0 1px 0 rgba(154, 154, 154, 0.15),
    0 1px 1px rgb(202, 202, 202);
  input {
    margin-left: 0;
  }
}
.form-check-label {
  cursor: pointer;
}
.input-search {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.06);
  border: none;
  height: 29px;
  width: 284px;
  padding: 8px;
  font-size: 0.9rem;
}
::placeholder {
  color: #656565;
  opacity: 0.6;
}
</style>
