<template>
  <div style="display:inline">

    <b-button class="btn btn-light push-right-1rem" :disabled="disabled" @click="init()" v-tooltip="{ content: title, placement: 'top' }">
      <i class="fas fa-plus"></i>
    </b-button>

    <b-modal ref="modal" size="xl"
      header-bg-variant="gradient-bleu"
      header-text-variant="light"
      hide-footer
      no-close-on-backdrop>
      <template slot="modal-title">
        <h3>{{title}}</h3>
      </template>
      <template slot="default">

        <RechercheArticles
          :masquerVide="true"
          typeParDefaut="parIngredient"
          :fournisseurId="fournisseurId"
          @change-ingredient="changeIngredient($event)"
          @change-state="changeEtatRecherche($event)" />

        <h4 class="push-top-1rem" :key="stateSearch" v-if="stateSearch">{{stateSearch}}</h4>

        <div class="container-fluid" style="margin-top:5px;">
          <ArticlesParProduit v-if="ingredient && ingredient.parProduit"
            :articles="ingredient.parProduit"
            :favoris="ingredient.favoris"
            :ingredient="ingredient"
            :index="0"
            :key="keyArticles"
            @selection-article="selectionArticle($event.produit)" />
        </div>

        <div class="row">

          <div class="col-3 push-top-1rem">
            Quantité : <InputNumber v-model="quantite" typeFiltre="quantite" ref="quantite" style="width:70px" />
            {{ingredient.up}}
          </div>

          <div class="col-3 push-top-1rem">
            PCB : {{article.pcb}}
          </div>

          <div class="col-3 push-top-1rem">
            PU HT : {{(article.prixUnitaire || 0) | afficherPrix}}
          </div>

          <div class="col-3 push-top-1rem">
            Total HT : {{((quantite * article.prixUnitaire) || 0) | afficherPrix}}
          </div>

        </div>

        <div style="margin: 2rem 0px; text-align: right;">
          <button class="btn btn-primary" @click="envoyer()">Valider</button>
          <button class="btn btn-danger" @click="hide()" style="margin:0 10px;">Annuler</button>
        </div>

      </template>
    </b-modal>

  </div>
</template>

<script>
import { connecteData } from "@/mixins/connecteData"
import { mapGetters } from "vuex"
import InputNumber from "@/components/Inputs/InputNumber"
import RechercheArticles from "@/components/Divers/RechercheArticles"
import ArticlesParProduit from '@/components/Stock/ArticlesParProduit'
import vm from "@/helpers/vm-toolkit-vue"

export default {
  props: ['commandeGlobale', 'title', 'disabled', 'fournisseurId'],
  mixins: [connecteData],
  components: {
    RechercheArticles,
    ArticlesParProduit,
    InputNumber
  },
  data() {
    return {
      ingredient: {},
      quantite: 0,
      keyArticles: 1,
      stateSearch: "",
      article: {}
    }
  },
  computed: {
    ...mapGetters("definitions", ["ingredients"])
  },
  methods: {
    init() {
      this.ingredient = {}
      this.quantite = 0
      this.article = {}
      this.show()
    },
    changeEtatRecherche(etat) {
      this.stateSearch=etat
    },
    changeIngredient(ingredient) {
      this.ingredient = ingredient
      this.article = {}
      this.keyArticles += 1
    },
    selectionArticle(article) {
      vm.forEach(this.ingredient.parProduit, a => {//ouvre l'article sélectionné
        a.forEach(r => {
          r.ouvrirAccordeon = r.tarifId === article.tarifId
        })
      })
      this.ingredient.tarifId = article.tarifId
      this.article = article
      this.keyArticles += 1
      this.$refs.quantite.$vnode.elm.focus()
    },
    show() {
      this.$refs.modal.show()
    },
    hide() {
      this.$refs.modal.hide()
    },
    envoyer() {
      if (!this.article || !this.article.tarifId) {
        this.$toast("Veuillez sélectionner l'article à ajouter", {
          horizontalPosition: "center",
          verticalPosition: "top",
          className: ["toast-error"],
          duration: 2000
        })
        return
      }
      if (!Number(this.quantite)) {
        this.$toast("Veuillez sélectionner la quantité à ajouter", {
          horizontalPosition: "center",
          verticalPosition: "top",
          className: ["toast-error"],
          duration: 2000
        })
        this.$refs.quantite.$vnode.elm.focus()
        return
      }
      this.$emit("ajouter-produit", {tarifId: this.article.tarifId, quantite: Number(this.quantite), article: this.article})
      this.hide()
    }
  }
}
</script>
