<template>
  <div style="display:inline">
    <div class="card-body">
      <div class="table-responsive mb-0">
        <table class="table table-sm table-bordered">
          <thead>
            <tr>
              <th style="width:25px"/>
              <th>Date</th>
              <th>Quantité</th>
              <th>Type</th>
              <th>Commentaire</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(ligne, index) in listeMouvements" :key="index" :style="ligne.inventaire ? 'background-color:#eefbff' : ''">
              <td>
                <i v-if="ligne.entree" class="fas fa-plus" style="font-size:20px;color:green"></i>
                <i v-if="ligne.sortie" class="fas fa-minus couleur-bleu-agap" style="font-size:20px"></i>
              </td>
              <td style="vertical-align:middle;width:150px">
                {{formatHumanDate(ligne.date)}}
              </td>
              <td style="vertical-align:middle;width:100px">
                <div class="flex-end"><span v-if="ligne.entree">+</span>{{ligne.quantite}} {{ligne.uc}}</div>
              </td>
              <td style="vertical-align:middle;width:200px">
                {{ligne.type}}
              </td>
              <td style="vertical-align:middle">
                {{ligne.commentaire}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { formatageDate } from '@/mixins/dateFormats'
import { connecteData } from "@/mixins/connecteData"
import { obtenirMouvementsStock } from '@/api/gpao/stock'
import { showAlerts } from '@/mixins/alerts'

export default {
  name: "MvtsStockListe",
  props: ["lot", "debut", "fin"],
  mixins: [formatageDate, connecteData, showAlerts],
  data() {
    return {
      listeMouvements: []
    }
  },
  methods: {
    async afficherMouvements() {
      if (!this.lot)
        return
      var mouvements = []
      if (Array.isArray(this.lot)) {//concaténation des mouvements pour tous les lots
        let tasks = []
        this.lot.forEach(l => {
          tasks.push(obtenirMouvementsStock(l.id, this.etabCourantId)
            .then(r => mouvements = mouvements.concat(r.data)))
        })
        await Promise.all(tasks)
        mouvements.sort((a, b) => a.date > b.date ? -1 : 1)
      } else {
        let response = await obtenirMouvementsStock(this.lot.id, this.etabCourantId)
        mouvements = response.data
      }
      if (this.debut && this.fin) {
        this.listeMouvements = mouvements.filter(m => m.date >= this.debut && m.date <= this.fin)
      } else {
        this.listeMouvements = mouvements
      }
    }
  },
  mounted() {
    this.afficherMouvements()
  }
}
</script>
