<template>
  <div style="display:inline">

    <b-button :disabled="disabled" v-tooltip="{ content: 'Dupliquer la commande', placement: 'top' }" class="btn btn-light push-right-1rem" @click="show">
      <i class="fas fa-clone"></i>
    </b-button>

    <b-modal ref="modal" size="md" hide-footer header-bg-variant="gradient-bleu" header-text-variant="light">

      <template slot="modal-header">
        <h3>Options de duplication</h3>
      </template>

      <template slot="default">
        <label for="nomNouvelleCommande">Nom de la nouvelle commande</label>
        <input type="text" class="container-fluid" id="nomNouvelleCommande" v-model.trim="optionsDuplication.nom" maxlength="80">
        <div class="error" v-if="!$v.optionsDuplication.nom.required">Ce champ est requis</div>
        <div class="error" v-if="!$v.optionsDuplication.nom.minLength">Ce champ doit contenir au moins 3 caractères</div>

        <div class="card push-top-1rem">
          <div class="card-header">
            <h5>Options générales</h5>
          </div>
          <div class="card-body">
            <div class="form-check background-primary form-check-inline">
              <input class="form-check-input" type="checkbox" id="cbAvecQuantites" v-model="optionsDuplication.avecQuantites" />
              <label for="cbAvecQuantites" class="form-check-label">Dupliquer les quantités</label>
            </div>

            <!--<div class="form-check background-primary form-check-inline">
              <input class="form-check-input" type="checkbox" id="cbAvecTarifs" v-model="optionsDuplication.avecTarifs" />
              <label for="cbAvecTarifs" class="form-check-label">Dupliquer les tarifs</label>
            </div>-->

            <div class="datepicker-trigger push-top-1rem" style="position:static">
              <input type="text" autocomplete="off" id="datepicker-livraisonDebut" class="datepicker datepicker-suivi-des-commandes" style="width:100%" placeholder="Livraison souhaitée à partir du..." :value="'Livraisons souhaitées à partir du ' + formatHumanDate(optionsDuplication.dateLivraisonDebut)" />
              <AirbnbStyleDatepicker style="top:115px;" trigger-element-id="datepicker-livraisonDebut" mode="single" :fullscreen-mobile="true" :showShortcutsMenuTrigger="false" :monthsToShow="1" :date-one="optionsDuplication.dateLivraisonDebut" @date-one-selected="setDateLivraisonDebut($event)" />
            </div>
            <div class="error" v-if="!$v.optionsDuplication.dateLivraisonDebut.required">Ce champ est requis</div>

            <div v-if="datesLivraisonsFournisseurs && datesLivraisonsFournisseurs.length !== 0" class="push-top-1rem">
              Dates de livraisons disponibles :
              <div v-for="(fournisseur, index) in datesLivraisonsFournisseurs" :key="index">
                {{fournisseur.item2}} : {{formatHumanDate(fournisseur.item3)}}
              </div>
            </div>
          </div>
        </div>

        <div class="card push-top-1rem">
          <div class="card-header">
            <h5>Vers établissement (optionnel)</h5>
          </div>
          <div class="card-body">
            <b-form-select v-model="optionsDuplication.nouvelEtablissementId" :options="optionsEtablissements" value-field="eId" text-field="eRS">
              <template slot="first"> <!--  class="selectpicker" -->
                <option :value="null">Établissements...</option>
              </template>
            </b-form-select>
          </div>
        </div>

        <div style="margin: 2rem 0; text-align:right">
          <button class="btn btn-success" :disabled="!$v.optionsDuplication.nom.required || !$v.optionsDuplication.nom.minLength || !$v.optionsDuplication.dateLivraisonDebut.required" @click="dupliquerCommande()">Dupliquer</button>
          <button class="btn btn-danger" style="margin-left:3rem; background: #3085d6 !important;border-color: #3085d6 !important;"
            @click="hide">Annuler</button>
        </div>
      </template>

    </b-modal>
  </div>

</template>
<script>

import { dupliquerCommande, obtenirDatesLivrablesApres } from '@/api/gpao/commandes'
import { closeModal } from "@/mixins/index"
import { formatageDate } from '@/mixins/dateFormats'
import { connecteData } from "@/mixins/connecteData"
import { showAlerts } from '@/mixins/alerts'
import { required, minLength, maxLength } from "vuelidate/lib/validators"

export default {
  name: "BoutonDuplicationCommande",
  mixins: [closeModal, formatageDate, connecteData, showAlerts],
  props: [ 'commandeGlobale', 'disabled' ],
  data() {
    return {
      optionsDuplication: {
        nom: "",
        avecQuantites: false,
        avecTarifs: true,
        nouvelEtablissementId: null,
        dateLivraisonDebut: ""
      },
      datesLivraisonsFournisseurs: []
    }
  },
  computed: {
    optionsEtablissements() {
      let result = []
      this.etablissementsCourants.map(el => {
        result.push({eId: el.eId, eRS: el.eRS})
      })
      result.sort(function(a,b){  return a.eRS.localeCompare(b.eRS)})
      return result;
    }
  },
  validations: {
    optionsDuplication: {
      nom: {
        required,
        minLength: minLength(3),
        maxlength: maxLength(80)
      },
      dateLivraisonDebut: { required }
    }
  },
  methods: {
    setDateLivraisonDebut(date) {
      this.optionsDuplication.dateLivraisonDebut = date
      return obtenirDatesLivrablesApres(this.commandeGlobale.id, this.optionsDuplication.dateLivraisonDebut)
      .then((response) => {
        this.datesLivraisonsFournisseurs = response.data
        return
      })
    },
    dupliquerCommande() {
      return dupliquerCommande(this.commandeGlobale.id, this.optionsDuplication)
      .then(() => {
        this.alerteSucces('Commande dupliquée !')
        this.$emit('commande-dupliquee', null)
        return
      })
    },
    show() {
      this.$refs.modal.show()
    },
    hide() {
      this.$refs.modal.hide()
    }
  }
}
</script>
