<template>
  <div class="bg-whitepage-plan-alimentaires" style="margin-top:-15px;">
    <div class="container-fluid bg-gradient-bleu" style="min-height:60px;padding:10px;">
      <button class="btn" style="background-color: white; font-weight: bold; font-size: 12px;" alt="Retour" @click="retournerListe()">Retour</button>
      <button class="btn ml-2 btn-success text-white" style="font-weight: bold; font-size: 12px;" alt="Dupliquer" :disabled="donneesModifiees" @click="afficherModalDuplicationPA()">Dupliquer</button>
      <button class="btn ml-2 btn-success text-white" style="font-weight: bold; font-size: 12px;" alt="Enregistrer" :disabled="!donneesModifiees" @click="enregistrer()">Enregistrer</button>
    </div>

    <div :key="key" class="container-fluid no-padding" style="min-height: 65vh;background-color: inherit;">
      <table class="table fixHeight tableMenus" style="table-layout: fixed;display: contents;">
        <thead>
          <tr>
            <th v-for="(jour, iJour) in currentPlan.jours" :key="iJour">
              {{jour.jour}} sur {{currentPlan.jours.length}}
            </th>
          </tr>
        </thead>

        <tbody v-if="currentPlan.jours">
          <template v-for="(typeService, iTypeService) in currentPlan.jours[0].services">
            <tr :key="iTypeService">
              <td :colspan="currentPlan.jours.length" v-b-toggle="'service-' + iTypeService" class="accordeon" style="font-size: 16px;font-weight: 600;">
                <span><i class="fas fa-chevron-up"></i></span>
                {{typeService.nom}}
              </td>
            </tr>
            <b-collapse tag="tr" :id="'service-' + iTypeService" :key="iTypeService+'-c'" visible>
              <td v-for="(jour, iJour) in currentPlan.jours" :key="iJour" style="padding: 0.2rem;">
                <div v-for="(service, iService) in jour.services" :key="iService">
                  <div v-if="service.serviceId === typeService.serviceId" class="composantes-container" style="width: 250px;">
                    <div v-for="(composante, iComposante) in service.composantes" :key="iComposante">
                      <CasePlanAlimentaire :ref="'case_' + iJour + '_' + iService + '_' + iComposante" :composante="composante" :service="service" :jour="jour"
                        :categorie="categorieGEMRCN(composante.categorieGEMRCNId)" :limites="limitesFiltrees(categorieGEMRCN(composante.categorieGEMRCNId), service)" @effacer-categorie="effacerCategorie" />
                    </div>
                  </div>
                </div>
              </td>
            </b-collapse>
          </template>
        </tbody>
      </table>
    </div>

    <transition name="fade">
      <b-modal v-if="montrerModalCategorie" v-model="montrerModalCategorie" @hide="cacherModalCategorie" size="lg" hide-footer header-bg-variant="gradient-bleu" header-text-variant="light">
        <template slot="modal-title">
          <h3>Ajout de catégorie GEMRCN</h3>
        </template>
        <template slot="default">
          <div class="col-12 no-padding push-top-1rem">
            <h4>{{ titreModal }}</h4>
            <MultiSelect class="push-top-1rem push-right-2rem" style="width:100%" :value="composantesModal" :items="composantes" placeholder="Composantes..." emptyText="Toutes les composantes ont été ajoutées" @change="composantesModal = $event" />

            <label><b>Catégorie GEMRCN</b></label>
            <div v-for="(categorie, iCategorie) in categoriesFiltrees" :key="iCategorie" class="row" style="border: 1px solid lightgray;margin: 0;padding: 5px;" @click="selectionnerCategorie(categorie)">
              <div class="col-1" :style="'border-radius: 5px;background-color:' + categorie.couleur"></div>
              <div class="col-10">
                <div>{{ categorie.nom }}</div>
                <div v-for="(limite, iLimite) in limitesFiltrees(categorie, serviceModal)" :key="iLimite">
                  <div :class="'mt-2 ' + (limiteEstValide(limite) ? 'text-success' : 'text-danger')">Décompte : {{ limite.compte }}</div>
                  <div :class="limiteEstValide(limite) ? 'text-success' : 'text-danger'">Recommandation : {{ limite.texteAAfficher }}</div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-modal>
    </transition>

    <transition name="fade">
      <b-modal v-if="montrerModalDuplicationPA" v-model="montrerModalDuplicationPA" @hide="cacherModalDuplicationPA" size="sm" hide-footer header-bg-variant="gradient-bleu" header-text-variant="light">
        <template slot="modal-title">
          <h3>Duplication</h3>
        </template>
        <template slot="default">
          <div class="col-12 no-padding push-top-1rem">
            <label><b>Nom du plan de destination</b></label>
            <input type="text" v-model.trim="nomNouveauPlanAlimentaire" style="width:100%">
            <div class="flex-end">
              <button class="btn ml-2 mt-3 btn-primary" style="font-weight: bold; font-size: 12px;" alt="Dupliquer" @click="dupliquer()">Dupliquer</button>
              <button class="btn ml-2 mt-3 btn-primary" style="font-weight: bold; font-size: 12px;" alt="Annuler" @click="cacherModalDuplicationPA()">Annuler</button>
            </div>
          </div>
        </template>
      </b-modal>
    </transition>
  </div>
</template>

<script>
import { connecteData } from "@/mixins/connecteData"
import { chargerPlan } from '@/api/gpao/plansAlimentaires'
import { showAlerts } from "@/mixins/alerts"
import { chargerDefinitions, chargerDonneesEtablissement } from '@/helpers/definitions'
import { chargerConfigurations, enregistrerConfigurationPlan, dupliquerPlanAlimentaire } from '@/api/gpao/plansAlimentaires'
import { alerteDonneesModifiees } from "@/mixins/index"
import CasePlanAlimentaire from "@/components/Menus/CasePlanAlimentaire"
import MultiSelect from "@/components/Inputs/MultiSelect"
import _ from "lodash"

export default {
  name: "PagePlanAlimentaireEdition",
  components: { CasePlanAlimentaire, MultiSelect },
  mixins: [ connecteData, showAlerts, alerteDonneesModifiees ],
  data() {
    return {
      key: 1,
      currentPlan: {},
      periodes: this.$store.state.definitions.periodesPlansAlimentaires,
      composantes: this.$store.state.definitions.typesComposantes,
      categories: [],
      scrollTop: 0,
      montrerModalCategorie: false,
      titreModal: "",
      composanteSelectionnee: null,
      serviceModal: null,
      jourModal: null,
      composantesModal: [],
      montrerModalDuplicationPA: false,
      nomNouveauPlanAlimentaire: ""
    }
  },
  computed: {
    donneesModifiees() {
      return !_.isEqual(this.donneesInitiales, this.getDataPlanAlimentaire())
    },
    enregistrerDonneesModifiees() {
      this.enregistrer()
      return true
    },
    categoriesFiltrees() {
      return this.categories.filter(c => c.typesComposantesIds.find(tc => this.composantesModal.includes(tc)))
    }
  },
  watch: {
    
  },
  async mounted() {
    let planAlimentaireId = this.$route.params.planAlimentaireId
    if (planAlimentaireId) {
      let result = await chargerPlan(planAlimentaireId)
      this.currentPlan = result.data
      this.initialiserDonneesModifiees()
      //this.$route.meta.title = this.currentPlan.nom // => Système non fiable pour afficher le nom du PA dans le titre de la page
    }
    this.rechargerCategories()
    this.$root.$on('afficher-modal-categorie', this.afficherModalCategorie)
  },
  methods: {
    async rechargerDefinitions() {
      await chargerDefinitions()
        .then(() => {
          if (this.etabCourantId) {
            chargerDonneesEtablissement(this.etabCourantId)
          }
          return
        })
    },
    getDataPlanAlimentaire() {
      var data = _.cloneDeep(this.currentPlan)
      return JSON.stringify(data)
    },
    initialiserDonneesModifiees() {
      this.donneesInitiales = this.getDataPlanAlimentaire()
    },
    limitesFiltrees(categorie, service) {
      let limite = categorie?.limites?.filter(l => l.servicesIds && l.servicesIds.find(s => s === service.serviceId))
      if (!limite || !limite.length) {
        let compte = this.currentPlan.jours.reduce((tj, j) => {
          return tj + j.services.reduce((ts, s) => {
            return ts + s.composantes.reduce((tc, c) => {
              return tc + (c.categorieGEMRCNId === categorie?.id ? 1 : 0)
            }, 0)
          }, 0)
        }, 0)
        return [{ compte: compte, servicesIds: [ service.serviceId ], frequenceMin: 0, frequenceMax: null, texteAAfficher: "Libre" }]
      }
      return limite
    },
    limiteEstValide(limite) {
      return limite.compte >= limite.frequenceMin && (!limite.frequenceMax || limite.compte <= limite.frequenceMax)
    },
    periode(periodeId) {
      let periode = this.periodes.find(p => p.value == periodeId)
      if (periode)
        return periode.text
      return ""
    },
    retournerListe() {
      this.$router.push({ name: 'PlansAlimentaires', params: { etablissementId: this.etabCourantId } })
    },
    afficherModalDuplicationPA(e) {
      this.montrerModalDuplicationPA = true
    },
    cacherModalDuplicationPA() {
      this.montrerModalDuplicationPA = false
      this.$nextTick(function () {//on remet le scroll comme avant affichage du modal
        window.document.documentElement.scrollTop = this.scrollTop
      })
    },
    async dupliquer() {
      if (!this.nomNouveauPlanAlimentaire) {
        this.alerteErreur("Le nom est obligatoire.")
        return false
      }

      await dupliquerPlanAlimentaire(this.nomNouveauPlanAlimentaire, this.currentPlan)
      this.cacherModalDuplicationPA()
      this.alerteSucces('Plan alimentaire dupliqué !')
      await this.rechargerDefinitions()
    },
    async enregistrer() {
      await enregistrerConfigurationPlan(this.currentPlan, this.etabCourantId)
      this.initialiserDonneesModifiees()
      this.alerteSucces('Plan alimentaire enregistré !')
    },
    afficherModalCategorie(e) {
      if (e) {
        this.composantesModal = [this.composantes.find(c => c.text === e.composante.nom)?.value]
        this.serviceModal = e.service
        this.jourModal = e.jour
        this.titreModal = "Jour " + e.jour.jour + " sur " + this.currentPlan.jours.length + ", " + e.composante.nom
        this.composanteSelectionnee = e.composante
        this.scrollTop = window.document.documentElement.scrollTop//on garde le scroll avant affichage du modal
        this.montrerModalCategorie = true
      }
    },
    cacherModalCategorie() {
      this.montrerModalCategorie = false
      this.$nextTick(function () {//on remet le scroll comme avant affichage du modal
        window.document.documentElement.scrollTop = this.scrollTop
      })
    },
    selectionnerCategorie(categorie) {
      this.composanteSelectionnee.categorieGEMRCNId = categorie.id
      this.compterCategories()
      this.cacherModalCategorie()
      this.key += 1
    },
    effacerCategorie(e) {
      e.composante.categorieGEMRCNId = null
      this.compterCategories()
      this.key += 1
      console.log(e)
    },
    async rechargerCategories() {
      let resultat = await chargerConfigurations(this.currentPlan.id)
      this.categories = resultat.data
      this.compterCategories()
      this.key += 1
    },
    compterCategorie(categorie) {
      if (categorie.limites) {
        categorie.limites.forEach(l => {
          l.compte = 0
          l.servicesIds.forEach(s => {
            l.compte += this.currentPlan.jours.reduce((tj, j) => {
              return tj + j.services.filter(x => x.serviceId === s).reduce((ts, s) => {
                return ts + s.composantes.reduce((tc, c) => {
                  return tc + (c.categorieGEMRCNId === categorie.id ? 1 : 0)
                }, 0)
              }, 0)
            }, 0)
          })
        })
      }
    },
    compterCategories() {
      this.categories.forEach(categorie => {
        this.compterCategorie(categorie)
      })
    },
    categorieGEMRCN(categorieId) {
      return this.categories.find(c => c.id === categorieId)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
